import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
export class AddExpense extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        qty:"",total:"",cost:"",details:"",staff:"",cat:"",name:""

    }

    componentDidMount(){

        /**refresh categories */
        this.props.getMainData("EXPENSECAT")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg,
                qty:"",total:"",cost:"",details:"",name:""
                })

                /**refresh categories */
                this.props.getMainData("EXPENSECAT")
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    addCategory =(e)=>{

        const {qty,total,cost,details,staff,cat,name} = this.state;

        if(cat===""|| name===""||total===""||total==="0"){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Enter all feilds marked with a *"})
        }else{
            this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
            const data = new FormData();
            data.append("qty",qty);
            data.append("total",total);
            data.append("cost",cost);
            data.append("details",details);
            data.append("staff",staff);
            data.append("cat",cat);
            data.append("name",name);

            /**upload new user*/
            this.props.addUser(data,"ADD_EXPENSE")
        }
    }

   

    render() {
        
        let catList = [];
        let staffDataList = []
        const myUserData =  this.state.mainData

        if(myUserData !==null){
        
            if(myUserData.expenseCatData.length>0){
                catList.push(<option key="" value="">SELECT CATEGORY</option>)
                myUserData.expenseCatData.map((values,i) =>{
                    if(parseInt(values.status) ===1){
                        catList.push(
                            <option key={i} value={values.id}>{values.name}</option>
                        )
                    }
                    
                });
            }else{
                catList.push(  
                    <option value="">No category found</option>   
                )
            }

            if(myUserData.userData.length>0){
                staffDataList.push(<option key="" value="">Select Member</option>)
                myUserData.userData.map((values,i) =>{
                    if(parseInt(values.status) ===1){
                    staffDataList.push(
                        <option key={i} value={values.id}>{values.clubNumber+" ("+values.firstName+")"}</option>
                    )}
                });
            }else{
                staffDataList.push(  
                    <option value="">No Member found</option>   
                )
            }
        }
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>ADD EXPENSE</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        <Col xs="12" >
                            {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}
                          
                            <FormGroup>
                                <Label for="gender">Select Category*</Label>
                                <Input type="select" onChange={this.setValue} name="cat">
                                    {catList}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">Select Member</Label>
                                <Input type="select" onChange={this.setValue} name="staff">
                                    {staffDataList}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Item Name*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.name} name="name" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Item Quantity</Label>
                                <Input type="number" min="1" onChange={this.setValue} value={this.state.qty} name="qty" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Item Cost</Label>
                                <Input type="number" min="1" onChange={this.setValue} value={this.state.cost} name="cost" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Total Amount*</Label>
                                <Input type="number" min="1" onChange={this.setValue} value={this.state.total} name="total" />
                            </FormGroup>

                            <FormGroup className="des">
                                <Label for="username"  className="loanLabel">Description</Label>
                                <Input type="textarea" onChange={this.setValue} className=" loanArea" value={this.state.details} name="details" />
                            </FormGroup>
                        
                        </Col>
                  
                        <Col xs="12" >
                            {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
                            <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Add Expense</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(AddExpense)
