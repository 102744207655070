import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {checkRight} from "../../GeneralFunctions/general"
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
export class SystemSettings extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        updateID:null,cdate:null,
        agentStatus:0,approveDays:1

    }

    componentDidMount(){
        const{mainData,msg,mainDataStatus} = this.props;
        /**refresh categories */
        this.props.getMainData("STAFFREGDATA")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
        
                this.setState({isSaving:false,mainData:mainData,
                cdate:mainData.SystemSetting.currentDate,
                updateID:mainData.SystemSetting.id,agentStatus:mainData.SystemSetting.clientsToOnlyAgents,
                approveDays:mainData.SystemSetting.approvedRequestLastsFor,
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg
                })
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false})
            }
        } 
    }

    

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    updateSettings =(e)=>{
        const {cdate,updateID,agentStatus,approveDays} = this.state;

        if(checkRight(1)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{
            if(updateID ===null ||cdate===null ){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Something went wrong, please refresh page"})
            }else{
                this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
            
                const data = new FormData();
                data.append("updateID",updateID);
                data.append("cdate",cdate);
                /**upload new user*/
                this.props.addUser(data,"UPDATE_SYSTEM_SETTINGS")
            }
        }

       
    }
    render(){
        let daysList =[]
        for(var x =1;x<=30;x++){
            daysList.push(<option value={x}>{x}</option> )
        }
  
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>UPDATE SYSTEM SETTINGS</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        <Col xs="12" >
                            {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}

                            <FormGroup>
                                <Label for="username">Current Date</Label>
                                <Input type="date" onChange={this.setValue} value={this.state.cdate} name="cdate" />
                            </FormGroup>

                        </Col>
                  
                    <Col xs="12" >
                     
                        {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
      
                        <Button onClick={this.updateSettings} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Update Settings</Button>
                    </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(SystemSettings)
