import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData} from "../../Redux/ducks/mainReducer";
import {getName2,datesDiffe,isDateInSelectedRage} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
export class EditedPayments extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,
       
        alertStatus :false, alertType :"error", alertTitle:null,

        closedLoanDataList:[]
  

    }
    componentDidMount(){
        this.props.getMainData("GENERAL_PAYDATA")
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,msg:msg,mainData:userData})
            }else{
                this.setState({isLoading:true,mainData:null,msg:null})
            }
        }  
    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt} = this.state

        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
        
            this.setState({isLoading:true})
            let activeLoanDataList = [];
            if(this.state.mainData !==null){
                const myUserData =  this.state.mainData.edittedData
                if(myUserData.length>0){
                    let myNo = 0
                    myUserData.map((values, i) =>{
                        myNo = myNo+1
                        let myNuDate = values.created_at.split(' ')
                        if(isDateInSelectedRage(startFrom,endAt,myNuDate[0]) ===true){
                            activeLoanDataList.push(
                                <tr key={i}>
                                    <td>{myNo}</td>
                                
                                    <td>{values.loanID}</td>
                                    <td>{getName2(this.state.mainData.userData,values.account,"EDIT")}</td>
                                    <td>{values.oldAmount}</td>
                                    <td>{values.newAmount}</td>
                                    <td>{values.comment}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.edit_by,"STAFF")}</td>
                                    <td>{values.created_at}</td>
                                </tr>
                            )
                        }
                        
                    });
                }
            }

            if (activeLoanDataList.length>0){
                this.setState({closedLoanDataList:activeLoanDataList,isLoading:false})
            }else{
                this.setState({isLoading:false,closedLoanDataList:[], alertStatus :true, alertType :"info", alertTitle:"No edited payments found in the selected range"})

            }
        }
    }

    render() {
        const { redirect,redirectSelectedID,closedLoanDataList,startFrom,endAt } = this.state;

        if (redirect) {
            return <Redirect to={{pathname:"/see_clients", state:{redirectSelectedID:redirectSelectedID}}}/>
        }
        /**displaying  users*/
        let activeLoanDataList = closedLoanDataList;
    
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
            if(activeLoanDataList.length>0){
                if(this.state.mainData !==null){
                    const myUserData =  this.state.mainData.edittedData
                    activeLoanDataList = []
                    if(myUserData.length>0){
                        let myNo = 0
                        myUserData.map((values, i) =>{
                            
                                myNo = myNo+1
                                let myNuDate = values.created_at.split(' ')
                                if(isDateInSelectedRage(startFrom,endAt,myNuDate[0]) ===true){
                                    if(searchAction ==="LOANID"){
                                        if(values.loanID === searchword.toUpperCase() ){
                                           
                                            activeLoanDataList.push(
                                                <tr key={i}>
                                                    <td>{myNo}</td>
                                               
                                                    <td>{values.loanID}</td>
                                                    <td>{getName2(this.state.mainData.userData,values.account,"EDIT")}</td>
                                                    <td>{values.oldAmount}</td>
                                                    <td>{values.newAmount}</td>
                                                    <td>{values.comment}</td>
                                                    <td>{getName2(this.state.mainData.staffData,values.edit_by,"STAFF")}</td>
                                                    <td>{values.created_at}</td>
                                                </tr>
                                            )
                                        }
                                    }else if(searchAction ==="NAME"){
                                        var myarray = getName2(this.state.mainData.userData,values.account,"EDIT").split(' ');
                                        if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() || values.name === searchword.toUpperCase()){
                                         
                                            activeLoanDataList.push(
                                                <tr key={i}>
                                                    <td>{myNo}</td>
                                                 
                                                    <td>{values.loanID}</td>
                                                    <td>{getName2(this.state.mainData.userData,values.account,"EDIT")}</td>
                                                    <td>{values.oldAmount}</td>
                                                    <td>{values.newAmount}</td>
                                                    <td>{values.comment}</td>
                                                    <td>{getName2(this.state.mainData.staffData,values.edit_by,"STAFF")}</td>
                                                    <td>{values.created_at}</td>
                                                </tr>
                                            )
                                        }
                                
                                    }else if(searchAction ==="PAYCODE"){
                                        if(values.payCode === searchword.toUpperCase() ){
                               
                                            activeLoanDataList.push(
                                                <tr key={i}>
                                                    <td>{myNo}</td>
                                                   
                                                    <td>{values.loanID}</td>
                                                    <td>{getName2(this.state.mainData.userData,values.account,"EDIT")}</td>
                                                    <td>{values.oldAmount}</td>
                                                    <td>{values.newAmount}</td>
                                                    <td>{values.comment}</td>
                                                    <td>{getName2(this.state.mainData.staffData,values.edit_by,"STAFF")}</td>
                                                    <td>{values.created_at}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                }
                            
                        });
                    }
                }
            }else{
                alert("No data found, Consider setting search range")
            }
        }
        

     
        return (
            <div>
                <div className="">
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW EDITED PAYMENTS</h4>
                    </div>
                    <Col xs="12" className="" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='table-container'>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                  
                                    <th>Loan ID</th>
                                    <th>Client Name</th>
                                    <th>Old Amount</th>
                                    <th>New Amount</th>
                                    <th>Comment</th>
                                    <th>Edited By</th>
                                    <th>Entry Date</th>
 
                                </tr>
                                <tr>
                                    <th></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                    <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>

                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                                    {activeLoanDataList}
                               
                            </tbody>}
                        </Table>
                        </div>
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
  
 });
export default connect(
    mapStateToProps,
    {getMainData}
)(EditedPayments)
