import React, { Component } from 'react'
import {connect } from 'react-redux';
import {logOut,userLogin} from "../../Redux/ducks/authReducer";
import {Alert,Spinner} from 'reactstrap';
import { Redirect } from "react-router-dom";

export class Login extends Component {
    constructor(props) {
        super(props);
        this.props.logOut("loginPage");
        this.state = {
            username:'',
            password:'',
            password2:'',
            errorMsg:'',
            isValidating:false,
            goDashboard:false,
            resetPassword:false,
            loginStatus:true
        };
    }
    componentDidMount(){
        /**set backend url */
        
        if(process.env.REACT_APP_MODE ==="development"){
            const urlData ={
                REACT_APP_API_URL:process.env.REACT_APP_API_URL,
                REACT_APP_IMG_URL:process.env.REACT_APP_IMG_URL,
                REACT_APP_FRONTEND_URL:process.env.REACT_APP_FRONTEND_URL
            }
            window.localStorage.setItem('appUrls',JSON.stringify(urlData));
        }else{
            const domainname = window.location.hostname
            const appPath = "https://"+domainname
            /*console.log(appPath)*/
            const urlData ={
                REACT_APP_API_URL:appPath+"/api",
                REACT_APP_IMG_URL:appPath,
                REACT_APP_FRONTEND_URL:appPath+"/SMS#"
            }
            window.localStorage.setItem('appUrls',JSON.stringify(urlData));
        }
    }

    componentDidUpdate(prevProps){
        const{userLoginStatus,userLoginMsg} = this.props;
        if(userLoginStatus !==prevProps.userLoginStatus){
        
            if(userLoginStatus===false){
                const userD = JSON.parse(window.localStorage.getItem('userData'));
                if(parseInt(userD.resetPassword)===0){
                    /*go to dashboard*/
                    this.setState({
                        errorMsg:'',isValidating:false,goDashboard:true,loginStatus:true
                    });
                    
                    this.props.history.push('/dashboard');

                    if(this.state.resetPassword ===true){
                        alert("Password has been reset")
                    }
                
                }else{
                    this.setState({
                        errorMsg:'',isValidating:false,goDashboard:false,resetPassword:true,loginStatus:false
                    });
                }

            }else if(userLoginStatus===true){
                this.setState({errorMsg: userLoginMsg,isValidating:false,goDashboard:false});
            }
        }
    }

    onChange = e =>{
        this.setState({[e.target.name] : e.target.value});
    };

    loginBTN= e =>{
        e.preventDefault();
        this.setState({isValidating:false})
        if(this.state.username ==="" || this.state.password ===""){
            this.setState({errorMsg : "Please fill in all fields"});
        }else{
            this.setState({errorMsg : "",isValidating:true});
            this.props.userLogin("LOGIN",this.state.username,this.state.password);
        }
    };

    resertBTN= e =>{
        e.preventDefault();
        const {username,password,password2} = this.state
        this.setState({isValidating:false})
        if(username ==="" || password ===""|| password2 ===""){
            this.setState({errorMsg : "Please fill in all fields"});
        }else if(password !==password2){
            this.setState({errorMsg : "Passwords do not match"});
        }else{
            this.setState({errorMsg : "",isValidating:true});
            this.props.userLogin("RESET",this.state.username,this.state.password);
        }
    };
    render(){
      
        return(
            <div className="loginWrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12 col-md-9">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                            <img src={process.env.PUBLIC_URL+'/assets/img/logo.png'} alt="login"/>
                                        </div>
                                        <div className="col-lg-6">
                                            {this.state.loginStatus ===true?
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <div className="h4 text-gray-900 mb-4 mlTitle">YES WE CAN</div>
                                                    <div className="h4 text-gray-900 mb-4 mlTitle2">Club Management System</div>
                                                </div>
                                                <form className="user">
                                                    {this.state.errorMsg?<Alert color="danger">{this.state.errorMsg}</Alert>:null}
                                                    <div className="form-group">
                                                    <input type="text" name="username" onChange={this.onChange} className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Email..."/>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password" onChange={this.onChange} className="form-control form-control-user" id="exampleInputPassword" placeholder="Password"/>
                                                    </div>
                                                    {this.state.isValidating?<div><Spinner className="mySpinner" color="primary" /><Spinner className="mySpinner" color="primary" type="grow"/>{'Validating...'}</div>:null}
                                                    <a href="#" onClick={this.loginBTN} className="btn btn-primary btn-user btn-block">
                                                    LOGIN
                                                    </a>
                                                </form>
                                                
                                            </div>:null}

                                            {this.state.resetPassword ===true?
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">RESET PASSWORD</h1>
                                                </div>
                                                <form className="user">
                                                    {this.state.errorMsg?<Alert color="danger">{this.state.errorMsg}</Alert>:null}
                                                    <div className="form-group">
                                                    <input type="text" name="username" disabled onChange={this.onChange} value={this.state.username} className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Enter Username..."/>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password" onChange={this.onChange} className="form-control form-control-user" id="exampleInputPassword" placeholder="Enter New Password"/>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="password" name="password2" onChange={this.onChange} className="form-control form-control-user" id="exampleInputPassword" placeholder="Repeat New Password"/>
                                                    </div>
                                                    {this.state.isValidating?<div><Spinner className="mySpinner" color="primary" /><Spinner className="mySpinner" color="primary" type="grow"/>{'Reseting...'}</div>:null}
                                                    <a href="#" onClick={this.resertBTN} className="btn btn-danger btn-user btn-block">
                                                    RESET PASSWORD
                                                    </a>
                                                </form>
                                            </div>:null}

                                            <div className="text-center myCopy">{new Date().getFullYear()} © <a href="https://mutanik.com" target="_blank">*Mutanik Technologies*</a>  V {process.env.REACT_APP_VERSION}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        )
    }
}
const mapStateToProps = (state) =>({
    userLoginStatus:state.authReducer.userLoginStatus,
    userLoginMsg:state.authReducer.userLoginMsg
});
export default connect(
    mapStateToProps,{logOut,userLogin}
)(Login)
