import React from 'react';
import { Route, Redirect} from 'react-router-dom';
import Aside from '../Components/Includes/Aside.js';
import Nav from '../Components/Includes/Nav';
import Footer from '../Components/Includes/Footer';


class PrivateRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    const loginStatus = JSON.parse(window.localStorage.getItem('loginStatus'));

    return (
      <Route
        {...rest}
        render={(props) =>
          loginStatus ? (
            <div>
              <div id="wrapper">
                <Aside />
                <div id="content-wrapper" className="d-flex flex-column">
                  <div id="content">
                    <Nav />
                    <div className="container-fluid">
                      <Component {...props} />
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
              <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
              </a>

            </div>
          ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
        }
      />
    );
  }
}

export default PrivateRoute;
