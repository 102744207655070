import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,
Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData,getCatData,getSelectedID_Data,addLoanData,changeStatus,changePassword} from "../../Redux/ducks/mainReducer";
import {getName,getName2} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
export class SeeBlacklist extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state={
            isLoading:false,
            isModalLoading:true,
            userData:null,
            msg:null,
            mainData:null,
            branchSearch:"0",
            nameSearch:null
        
            
        }
    }
    componentDidMount(){
       
        this.setState({isLoading:true})
        /**get rights object */

        this.props.getMainData("BLACKLISTED_CLIENTS");
       
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
            
                this.setState({isLoading:false,userData:userData.userData,msg:msg,mainData:userData,
                })
            }else{
                this.setState({isLoading:true,userData:null,msg:null})
            }
        }

    }


    closeModal = ()=>{
        this.setState({modal:false,passwordModal:false})
        //refresh categories
        this.props.getMainData("BLACKLISTED_CLIENTS");
    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.props.getSelectedID_Data(selectedID,"CLIENT")

        this.setOther_selectedUser(selectedID)   
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }


    handlePrint = () => {
       
        this.componentRef && this.componentRef.handlePrint();
      };

    handleDateClick = (date) => {
    this.setState({ selectedDate: date });
    };

 
    render() {
        const userD = JSON.parse(window.localStorage.getItem('userData'));
        /**displaying  users*/
        let userDataList = [];
        const myUserData =  this.state.userData
        let no = 0
        if(myUserData !==null){
            if(myUserData.length>0){
               
                myUserData.map((values, i) =>{
                    if(parseInt(values.blacklistStatus) ===1){
                        no = no+1
                        userDataList.push(
                            <tr key={i}>
                                <th scope="row">{no}</th>
                                <td>{getName(this.state.mainData.branchData,values.branch)}</td>
                                <td>{values.account}</td>
                                <td>{values.name}</td>
                                <td>{values.blacklistReason}</td>
                                <td>{getName2(this.state.mainData.staffData,values.blacklistedBy,"STAFF")}</td>
                                <td>{values.dob}</td>
                                <td>{values.sex}</td>
                                <td>{values.address}</td>
                                <td>{values.phone1}</td>
                                <td>{values.occupation}</td>
                                <td><Label className="status2">Blacklisted</Label></td>
                            </tr>
                        )
                    }
                   
                });
            }else{
                userDataList.push(
                    <div>
                        <br/><Alert color="primary">No data found</Alert>
                    </div>
                )
            }
        }

        /**account */
        const searchword = this.state.accountSearch
        if (searchword) {
            if(myUserData !==null){
                userDataList = []
                if(myUserData.length>0){
                    no = 0
                    myUserData.map((values, i) =>{
                        if(values.account === searchword.toUpperCase() ){
                            no = no+1
                            userDataList.push(
                                <tr key={i}>
                                    <th scope="row">{no}</th>
                                    <td>{getName(this.state.mainData.branchData,values.branch)}</td>
                                    <td>{values.account}</td>
                                    <td>{values.name}</td>
                                    <td>{values.blacklistReason}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.blacklistedBy,"STAFF")}</td>
                                    <td>{values.dob}</td>
                                    <td>{values.sex}</td>
                                    <td>{values.address}</td>
                                    <td>{values.phone1}</td>
                                    <td>{values.occupation}</td>
                                    <td><Label className="status2">Blacklisted</Label></td>
                                </tr>
                            )
                        }
                    });
                }
            }
        }

        /**name */
        const searchword2 = this.state.nameSearch
        if (searchword2) {
            if(myUserData !==null){
                userDataList = []
                if(myUserData.length>0){
                    no =0
                    myUserData.map((values, i) =>{
                        var myarray = values.name.split(' ');
                        console.log(myarray[0])
                        if(myarray[0] === searchword2.toUpperCase() || myarray[1] === searchword2.toUpperCase() || values.name === searchword2.toUpperCase()  ){
                            no = no+1
                            userDataList.push(
                                <tr key={i}>
                                    <th scope="row">{no}</th>
                                    <td>{getName(this.state.mainData.branchData,values.branch)}</td>
                                    <td>{values.account}</td>
                                    <td>{values.name}</td>
                                    <td>{values.blacklistReason}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.blacklistedBy,"STAFF")}</td>
                                    <td>{values.dob}</td>
                                    <td>{values.sex}</td>
                                    <td>{values.address}</td>
                                    <td>{values.phone1}</td>
                                    <td>{values.occupation}</td>
                                    <td><Label className="status2">Blacklisted</Label></td>
                                </tr>
                            )
                        }
                    });
                }
            }
        }

        const searchword3 = this.state.branchSearch
        if (searchword3) {
            if(myUserData !==null){
                userDataList = []
                if(myUserData.length>0){
                    no = 0
                    myUserData.map((values, i) =>{
                        if(parseInt(searchword3)===0){
                            no = no+1
                            userDataList.push(
                                <tr key={i}>
                                    <th scope="row">{no}</th>
                                    <td>{getName(this.state.mainData.branchData,values.branch)}</td>
                                    <td>{values.account}</td>
                                    <td>{values.name}</td>
                                    <td>{values.blacklistReason}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.blacklistedBy,"STAFF")}</td>
                                    <td>{values.dob}</td>
                                    <td>{values.sex}</td>
                                    <td>{values.address}</td>
                                    <td>{values.phone1}</td>
                                    <td>{values.occupation}</td>
                                    <td><Label className="status2">Blacklisted</Label></td>
                                </tr>
                            )
                        }else if(values.branch === parseInt(searchword3)){
                            no = no+1
                            userDataList.push(
                                <tr key={i}>
                                    <th scope="row">{no}</th>
                                    <td>{getName(this.state.mainData.branchData,values.branch)}</td>
                                    <td>{values.account}</td>
                                    <td>{values.name}</td>
                                    <td>{values.blacklistReason}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.blacklistedBy,"STAFF")}</td>
                                    <td>{values.dob}</td>
                                    <td>{values.sex}</td>
                                    <td>{values.address}</td>
                                    <td>{values.phone1}</td>
                                    <td>{values.occupation}</td>
                                    <td><Label className="status2">Blacklisted</Label></td>
                                </tr>
                            )
                        }
                    });
                }
            }
        }


        /**branch */
        const branchList = [];
        if(this.state.mainData !==null){
            const myCatData =  this.state.mainData.branchData
            //const branchID = JSON.parse(window.localStorage.getItem('userData')).branchID
            //loop through the array
            if(myCatData.length>0){
                branchList.push(<option key="" value="0">SELECT ALL</option>)
                myCatData.map((values,i) =>{
                    branchList.push(
                        <option key={i} value={values.id}>{values.name}</option>
                    )
                    
                });
            }
        }

        return (
            <div>
             
                <Row>
                    <div className="block-header">
                        <h4>VIEW BLACKLISTED CLIENTS</h4>
                    </div>
                    <Col xs="12" className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
        
                        <>
                        {/*<Button onClick={this.handlePrint}>Print Table</Button>
                        <ReactToPrint
                        trigger={() => <Button>Hidden Print Button</Button>}
                        content={() => this.componentRef}
                                    />*/}
                        <Col xs="12" md="12" className="tableWrapper">
                            <Row>
                                
                                <Col md="12" xs="12">
                                    <Label className='balB'>TOTAL CLIENTS BLACKLISTED : {no.toLocaleString()}</Label>
                                </Col>
                            </Row>
                            <div className='table-container' ref={el => (this.componentRef = el)}>
                                <Table striped responsive  
                            >
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Branch</th>
                                            <th>Account</th>
                                            <th>Client Name</th>
                                            <th>Blacklist Reason</th>
                                            <th>Blacklisted By</th>
                                            <th>DOB</th>
                                            <th>Gender</th>
                                            <th>Address</th>
                                            <th>Phone</th>
                                            <th>Occupation</th>
                                            <th>Status</th>
                                        
        
                                        </tr>
                                        <tr>
                                    
                                            <th></th>
                                            <th>
                                                <Input type="select" className="clientName" onChange={this.setValue} name="branchSearch">
                                                    {branchList}
                                                </Input>
                                            </th>
                                            <th><Input type="text" className="account" onChange={this.setValue} value={this.state.accountSearch} name="accountSearch" id="username"/></th>
                                            <th><Input type="text" className="clientName" onChange={this.setValue} value={this.state.nameSearch} name="nameSearch" id="username" /></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        
                                        
                                        </tr>
                                    </thead>
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="12">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                        {
                                            userDataList.length>0?
                                            userDataList
                                            :
                                            <tr>
                                                <td colSpan="12">
                                                    <h6>No data found</h6>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>}
                                </Table>
                            </div>
                        </Col>
                        
                        </>
                    </Col>
                </Row>
           
            </div>
        )
    }
}

const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
    showDataStatus : state.mainReducer.showDataStatus,
    showData : state.mainReducer.showData,
    catData : state.mainReducer.catData,
    catStatus : state.mainReducer.catStatus,
    updateStatus : state.mainReducer.updateStatus,
    updateData:state.mainReducer.updateData,
    changedStatus : state.mainReducer.changeStatus,
    changePassStatus : state.mainReducer.changePassStatus,
});

export default connect(
    mapStateToProps,
    {getMainData,getCatData,getSelectedID_Data,addLoanData,changeStatus,changePassword}
)(SeeBlacklist)
