import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser,restUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner} from 'reactstrap'
import MultiSelect from "@kenshooui/react-multi-select";
import ReactJsAlert from "reactjs-alert"
export class AddUser extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        noCat:null,
        subData:[],
        rightList:[],
        selectedRight: [],
        branchesList:[],
        selectedBranch:[],
        alertStatus :false, alertType :"error", alertTitle:null,
        accType:"",branch:"",surname:"",firstname:"",othername:"",gender:"",dob:"",marital_status:"",
        religion:"",title:"",nin:"",tr_regNo_old:"",tr_regNo_new:"",ippsNo:"",nationalID:"",utsNo:"",
        ca:"",ca_esc:"",fa:"",fa_esc:"",cs:"",cs_esc:"",posting_date:"",trained_sub:"",
        teaching_load:"",res:"",salary_scale:"",salary_gross:"",phone:"",phone2:"",email:"",password1:"",
        password2:"",photo:"",address:"",nin:"",position:"",kin:"",kinPhone:"",username:""

    }

    componentDidMount(){
        const{mainData,msg,mainDataStatus} = this.props;
        /**refresh categories */
        this.props.getMainData("STAFFREGDATA")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData})
            

            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,btnDisabled:false,
                    accType:"",surname:"",firstname:"",othername:"",dob:"",
                    title:"",nin:"",tr_regNo_old:"",tr_regNo_new:"",ippsNo:"",nationalID:"",utsNo:"",
                    ca:"",ca_esc:"",fa:"",fa_esc:"",cs:"",cs_esc:"",posting_date:"",trained_sub:"",
                    teaching_load:"",res:"",salary_scale:"",salary_gross:"",phone:"",phone2:"",email:"",password1:"",
                    password2:"",photo:"",address:"",nin:"",kin:"",kinPhone:"",username:"",
                    alertStatus :true, alertType :"success", alertTitle:msg,
                })

            
            }else if(addUserStatus===false){
                this.setState({isSaving:false,btnDisabled:false,alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    /**for multiple select */
    handleSelectChange = (selectedRight) =>{
        this.setState({ selectedRight});
    }

    handleSelectChange2 = (selectedBranch) =>{
        this.setState({ selectedBranch});
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})

        if(e.target.name ==="position"){
            if(e.target.value !==""){
                let catID = parseInt(e.target.value)
                this.state.mainData.catData.map((values,i) =>{
                    if(catID ===parseInt(values.catID)){
                        var rights = values.rights.substring(1, values.rights.length-1);
                        const arrayRight = rights.split(',');
                        let selectedRight =[]
                        let rightList =[]
                        for (let i = 0; i < arrayRight.length; i++) {
                            const sRight = parseInt(arrayRight[i].replace(/"/g, ''))
                            this.state.mainData.rightData.map((values3, k) =>{
                               
                                if(sRight ===parseInt(values3.id)){
                                    rightList.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                    selectedRight.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                }
                                
                            });
                        }

                        this.setState({
                            selectedRight:selectedRight,
                            rightList:rightList
                        })
                    }
                });
            }else{
                this.setState({
                    selectedRight:[],
                    rightList:[]
                })
            }
        }

    }

    /**add new user*/
    addUser =(e)=>{
        this.props.restUser()
        const {surname,firstname,othername,gender,dob,marital_status,
            religion,phone,phone2,email,password1,password2,address,nin,position,kin,kinPhone,username,
            rightList,selectedRight,selectedBranch} = this.state;

    

       if(surname===""||firstname===""||dob===""||phone===""||email===""||
            password1===""|| password2===""||gender===""||religion===""||address===""||nin===""||position===""||kin===""||kinPhone===""){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Please enter all fields"})
        }else if(selectedRight.length<1){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select at least one right"})
        }else if(password1 !== password2){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Passwords do not match"})
        }else{
            this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
            
            /**arranging  rights */
            const rightSelectedList = [];
            selectedRight.map((values) =>{
                rightSelectedList.push(values.id)
            })

            /**arranging  rights */
            const rightList2 = [];
            rightList.map((values) =>{
                rightList2.push(values.id)
            })

           


            const data = new FormData();
          
        
            data.append("surname",surname);
            data.append("firstname",firstname);
          
            data.append("gender",gender);
            data.append("dob",dob);
      
            data.append("religion",religion);
            data.append("rightsList",rightList2);
            data.append("rightSelectedList",rightSelectedList);
            data.append("phone",phone);
            data.append("phone2",phone2);
            data.append("email",email);
            data.append("password1",password1);
            data.append("password2",password2);
            data.append("address",address);
            data.append("nin",nin);
            data.append("position",position);
            data.append("kin",kin);
            data.append("kinPhone",kinPhone);
        
            /**upload new user*/
            this.props.addUser(data,"SYSTEM_USER")
        }
    }
    render() {
        /**displaying  categories*/
        const catDataList = [];
        if(this.state.mainData !==null){
            const myCatData =  this.state.mainData.catData
            //loop through the array
            if(myCatData.length>0){
                catDataList.push(<option key="" value="">SELECT ACCOUNT TYPE</option>)
                myCatData.map((values,i) =>{
                    catDataList.push(
                        <option key={i} value={values.catID}>{values.catName}</option>
                    )
                
                });
            }else{
                catDataList.push(  
                    <option value="">No Accounts Found</option>   
                )
            }
        }

      

        /**displaying  levels*/
        const religionDataList = [];
        if(this.state.mainData !==null){
            const myreligionData =  this.state.mainData.religionData
            //loop through the array
            if(myreligionData.length>0){
                religionDataList.push(<option key="" value="">SELECT RELIGION</option>)
                myreligionData.map((values,i) =>{
                    religionDataList.push(
                        <option key={i} value={values.id}>{values.name}</option>
                    )
                });
            }else{
                religionDataList.push(  
                    <option value="">No religion found</option>   
                )
            }
        }

        /**rightDataList */
        const rightDataList = [];
        if(this.state.mainData !==null){
            const myData =  this.state.mainData.rightData
            //loop through the array
            if(myData.length>0){
                myData.map((values,i) =>{
                    rightDataList.push(
                        {id: values.id, label: values.name}
                    )
                });
            }
        }


        
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>ADD A NEW MEMBER</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        <Col xs="6" >
                            
                          
                            <FormGroup>
                                <Label for="username">Lastname*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.surname} name="surname" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="username">Firstname*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.firstname} name="firstname"/>
                            </FormGroup>
                     
                            <FormGroup>
                                <Label for="gender">Gender*</Label>
                                <Input type="select" onChange={this.setValue} name="gender">
                                    <option value="">SELECT GENDER</option> 
                                    <option value="Male">Male</option> 
                                    <option value="Female">Female</option> 
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="dob">Date Of Birth*</Label>
                                <Input type="date" onChange={this.setValue} value={this.state.dob} name="dob"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="religion">Religion*</Label>
                                <Input type="select" onChange={this.setValue} name="religion">
                                    {religionDataList}
                                </Input>
                            </FormGroup>
                        
                         

                            <FormGroup>
                                <Label for="username">Address*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.address} name="address"/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="username">National ID*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.nin} name="nin"/>
                            </FormGroup> 

                            <FormGroup>
                                <Label for="phone">Phone No.*</Label>
                                <Input type="number" onChange={this.setValue} value={this.state.phone} name="phone"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="phone2">Phone No. 2</Label>
                                <Input type="number" onChange={this.setValue} value={this.state.phone2} name="phone2" />
                            </FormGroup>

                           

                        </Col>
                        <Col xs="6" >
                            

                            <FormGroup>
                                <Label for="email">Email*</Label>
                                <Input type="email" onChange={this.setValue} value={this.state.email} name="email" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="position">Select Account Type*</Label>
                                <Input type="select" onChange={this.setValue} name="position">
                                    {catDataList}
                                </Input>
                            </FormGroup>

                           
                            <FormGroup>
                                <Label for="teaching_sub">Rights *(Uncheck to revoke right)</Label>
                                <MultiSelect 
                                    responsiveHeight={'210px'}
                                    items={this.state.rightList}
                                    selectedItems={this.state.selectedRight}
                                    onChange={this.handleSelectChange}
                                />
                            </FormGroup>

                              
                            <FormGroup>
                                <Label for="username">Next of Kin*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.kin} name="kin"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Next of Kin Phone*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.kinPhone} name="kinPhone"/>
                            </FormGroup> 

                            <FormGroup>
                                <Label for="password1">Password*</Label>
                                <Input type="password" onChange={this.setValue} value={this.state.password1} name="password1" id="password1" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password2">Confrim Password*</Label>
                                <Input type="password" onChange={this.setValue} value={this.state.password2} name="password2" id="password2" />
                            </FormGroup>

                        </Col>
                 

                    <Col xs="12" >
                        {this.state.successMsg?<Alert color="success">{this.state.successMsg}</Alert>:null}
                        {this.state.erroMsg?<Alert color="danger">{this.state.erroMsg}</Alert>:null}
                        {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
      
                        <Button onClick={this.addUser} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Add Member</Button>
                    </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser,restUser}
)(AddUser)
