import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getName2, getFine, generateDates } from '../../GeneralFunctions/general';
import ReactJsAlert from 'reactjs-alert';
import { Alert, Label, Row, Col, Table, FormGroup, Input, Button, Spinner } from 'reactstrap';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

class PaymentChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertStatus: false,
            alertType: 'error',
            alertTitle: null,
            clientDetailsData: this.props.mainData,
            from:this.props.from,
            paymentDataList: [],
            showCalender: false,

            /**payment chart */
            selectedDate: null,
            chartStartDate: null,
            chartEndDate: null,
            searchLoanPaymentChart: '',
            searchloanIDChart: '',
            showCalender: false,
            chartList: [],
            activeMonth: null,
            loanStartDate: null,
            aCleared:null,aStartDate:null,aAmount:0
        };
    }

    setValue = (e) => {
        this.setState({ [e.target.name]: e.target.value,aCleared:null,aStartDate:null,aAmount:0 });
    };

    handlePrevClick = () => {
        const activeData = new Date(this.state.activeMonth);
        const prevMonth = activeData.getMonth() - 1;
        const yr = activeData.getFullYear();
        const prevDate = new Date(yr, prevMonth, 1);

        this.setState({ activeMonth: prevDate });
    };

    handleNextClick = () => {
        const activeData = new Date(this.state.activeMonth);
        const nextMonth = activeData.getMonth() + 1;
        const yr = activeData.getFullYear();
        const nextDate = new Date(yr, nextMonth, 1);

        this.setState({ activeMonth: nextDate });
    };

    getPaymentStatus = (date) => {
        const { chartList } = this.state;

        // Find the payment details for the given date
        const paymentDetails = chartList.filter((item) => item.date === date);

        // Return the first payment detail if found, or null otherwise
        return paymentDetails.length > 0 ? paymentDetails[0] : null;
    };
    calculateMissedTotal = (action) => {
        const { chartList } = this.state;
       
        const missedPayments = chartList.filter(payment => payment.status === action);
        const missedTotal = missedPayments.length;

        return missedTotal
    };

    formatDate = (inputDate) => {
        const date = new Date(inputDate);

        // Get year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
        const day = String(date.getDate()).padStart(2, '0');

        // Construct the formatted date string in "YYYY-MM-DD" format
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    };

    /**search loan paymen */
    searchLoanPaymentChart = () => {
        const { searchloanIDChart, clientDetailsData ,from} = this.state;
        if (searchloanIDChart === '') {
            this.setState({ alertStatus: true, alertType: 'error', alertTitle: 'Select loan ID to search' });
        } else {
            this.setState({ showCalender: false });
            let returnedDates = getName2(clientDetailsData.loanData, searchloanIDChart, 'LOAN');
            let startDate = returnedDates[0];
            let endPayDate = returnedDates[1];
            let currentDate = from==="CLIENT"?clientDetailsData.systemSettingData.currentDate:clientDetailsData.settingsData.currentDate;

            let paidAdvanceList = JSON.stringify(returnedDates[2]);
            let loanInstallment = parseInt(returnedDates[3]);
            let closedAt = returnedDates[4];
            let status = returnedDates[5];
            let payData = returnedDates[6];
            let loanAmount = returnedDates[7];
            let datesList = null;

            this.setState({aCleared:closedAt,aStartDate:startDate,aAmount:loanAmount})

            if (parseInt(status) === 0) {
                datesList = generateDates(startDate, closedAt);
            } else {
                const endPayDate2 = new Date(endPayDate);
                const currentDate2 = new Date(currentDate);
                if (currentDate2 <= endPayDate2) {
                    datesList = generateDates(startDate, currentDate);
                } else {
                    datesList = generateDates(startDate, currentDate);
                }
            }
            const clientDetails = clientDetailsData;
            if (clientDetails !== null) {
                let chartList = [];
                for (const date of datesList) {
                    let madePayment = 0;
                    let paidAmount = 0;

                    if (payData.length > 0) {
                        payData.map((values, i) => {
                            if (values.payDate === date) {
                                madePayment = 1;
                                paidAmount = paidAmount + parseInt(values.amount);
                            }
                        });
                    }

                    /**if no payment checkAdvance */
                    if (parseInt(madePayment) === 0 || parseInt(paidAmount) === 0) {
                        if (paidAdvanceList.includes(date) === true) {
                            const newDateObject = { date: date, status: 'advance', amount: loanInstallment, points: 2 };
                            chartList.push(newDateObject);
                        } else {
                            const newDateObject = { date: date, status: 'missed', amount: 0, points: -2 };
                            chartList.push(newDateObject);
                        }
                    }

                    if (parseInt(madePayment) === 1 && parseInt(paidAmount) > 0) {
                        if (parseInt(paidAmount) >= parseInt(loanInstallment)) {
                            const newDateObject = { date: date, status: 'full', amount: paidAmount, points: 1 };
                            chartList.push(newDateObject);
                        } else {
                            const newDateObject = { date: date, status: 'partial', amount: paidAmount, points: 1 };
                            chartList.push(newDateObject);
                        }
                    }
                }

                // Extract the year and month from the chartStartDate
                const [year, month] = returnedDates[0].split('-').map(Number);

                // Create a new Date object with the extracted year and month
                let newChartStartDate = new Date(year, month - 1, 1);

                this.setState({
                    chartStartDate: returnedDates[0],
                    chartEndDate: returnedDates[1],
                    chartList: chartList,
                    showCalender: true,
                    activeMonth: newChartStartDate,
                    loanStartDate: returnedDates[0],
                });
            }
        }
    };

    render() {
        const { clientDetailsData,from } = this.state;
        let loanIDsList = [];
        const myStaffData1 = clientDetailsData;
        if (myStaffData1 !== null) {
            /**loanIDs */
            const myloanData2 = clientDetailsData.loanData;
            if (myloanData2.length > 0) {
                loanIDsList.push(<option value="">Select Loan ID</option>);
                myloanData2.map((values, i) => {
                    loanIDsList.push(<option value={values.loanID}>{values.loanID}</option>);
                });
            }
        }
        return (
            <div>
                <ReactJsAlert autoCloseIn={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })} />
                <Row>
                    <Col xs="12" md="12" className="text-center myName">
                        <h6>{from ==="CLIENT"?clientDetailsData.userData.name+"'s Payment Chart":clientDetailsData.clientData.name+"'s Payment Chart"}</h6>
                    </Col>
                    <Col xs="12" md="6">
                        <Input type="select" onChange={this.setValue} className="loanBox" name="searchloanIDChart">
                            {loanIDsList}
                        </Input>
                    </Col>
                    <Col xs="12" md="6">
                        <Button onClick={this.searchLoanPaymentChart} style={{ fontSize: 12 }} className="btn btn-secondary showPayDetailsBtn">
                            SEARCH PAYMENTS
                        </Button>
                    </Col>
                    <Col xs="12" md="12">
                        <Label className="amountLine"><span>Loan Date</span> : {this.state.aStartDate} <span>Amount</span> : {this.state.aAmount} <span>Cleared At</span> : {this.state.aCleared}</Label>
                    </Col>
                    <Col xs="6" md="3">
                        <span className="letterWrapper">
                            {from==="CLIENT"?
                            <span className="payment-tooltip2" style={{ backgroundColor: 'red' }}>
                                {this.calculateMissedTotal("missed")>0?(this.calculateMissedTotal("missed")-1):this.calculateMissedTotal("missed")} {/* Display the total missed payments */}
                            </span>:
                            <span className="payment-tooltip2" style={{ backgroundColor: 'red' }}>
                                {this.calculateMissedTotal("missed")>0?(this.calculateMissedTotal("missed")-1):this.calculateMissedTotal("missed")} {/* Display the total missed payments */}
                            </span>
                            }
                            
                            {' '}
                            <>Missed Payt</>
                        </span>
                    </Col>
                    <Col xs="6" md="3">
                        <span className="letterWrapper">
                            <span className="payment-tooltip2" style={{ backgroundColor: '#96a511' }}>
                            {this.calculateMissedTotal("partial")}
                            </span>{' '}
                            <>Partial Payt</>
                        </span>
                    </Col>
                    <Col xs="6" md="3">
                        <span className="letterWrapper">
                            <span className="payment-tooltip2" style={{ backgroundColor: '#09924e' }}>
                            {this.calculateMissedTotal("full")}
                            </span>{' '}
                            <>Full Payt</>
                        </span>
                    </Col>
                    <Col xs="6" md="3">
                        <span className="letterWrapper">
                            <span className="payment-tooltip2" style={{ backgroundColor: '#5B2C6F' }}>
                            {this.calculateMissedTotal("advance")}
                            </span>{' '}
                            <>Advanced Payt</>
                        </span>
                    </Col>

                    <Col xs="12" md="12">
                        {this.state.showCalender === true ? (
                            <>
                                <div className="moveBtn">
                                    <button onClick={this.handlePrevClick}>{"< Previous"}</button>
                                    <button onClick={this.handleNextClick}>{"Next >"}</button>
                                </div>
                                <Calendar
                                    prev2Label={null} // Disable double previous arrow
                                    next2Label={null} // Disable double next arrow
                                    prevLabel={null} // Disable double previous arrow
                                    nextLabel={null} // Disable double next arrow
                                    className={'myCalender'}
                                    onClickDay={this.handleDateClick}
                                    activeStartDate={this.state.activeMonth}
                                    view={'month'}
                                    onViewChange={() => {}}
                                    selectRange={true}
                                    tileClassName={({ date }) => {
                                        //Check if the date is within the loan period (from 2023-07-01 to 2023-08-10)
                                        const startDate = new Date(this.state.chartStartDate);
                                        const endDate = new Date(this.state.chartEndDate);
                                        if (date >= startDate && date <= endDate) {
                                            return 'loan-period';
                                        }
                                        return null;
                                    }}
                                    tileContent={({ date }) => {
                                        //Convert the input date to a string in "YYYY-MM-DD" format
                                        const dateString = this.formatDate(date);
                                        const paymentData = this.getPaymentStatus(dateString);

                                        if (paymentData) {
                                            const { status, amount, points } = paymentData;

                                            if (dateString === paymentData.date) {
                                                if (new Date(paymentData.date) > new Date(this.state.loanStartDate)) {
                                                    let backgroundColor;
                                                    let tooltipText;
                                                    let tooltipLetter;

                                                    if (status === 'missed') {
                                                        backgroundColor = 'red';
                                                        tooltipText = `Missed Payment -> Amount: ${amount}, Points: ${points}`;
                                                        tooltipLetter = "M";
                                                    } else if (status === 'partial') {
                                                        backgroundColor = '#96a511';
                                                        tooltipText = `Partial Payment -> Amount: ${amount}, Points: ${points}`;
                                                        tooltipLetter = 'P';
                                                    } else if (status === 'full') {
                                                        backgroundColor = '#09924e';
                                                        tooltipText = `Full Payment -> Amount: ${amount}, Points: ${points}`;
                                                        tooltipLetter = 'F';
                                                    } else if (status === 'advance') {
                                                        backgroundColor = '#5B2C6F';
                                                        tooltipText = `Paid in Advance -> Amount: ${amount}, Points: ${points}`;
                                                        tooltipLetter = 'A';
                                                    }

                                                    return (
                                                        <Tooltip title={tooltipText}>
                                                            <div className="payment-tooltip" style={{ backgroundColor }}>
                                                                {tooltipLetter}
                                                            </div>
                                                        </Tooltip>
                                                    );
                                                } else {
                                                    if (status !== 'missed') {
                                                        let backgroundColor;
                                                        let tooltipText;
                                                        let tooltipLetter;

                                                        if (status === 'missed') {
                                                            backgroundColor = 'red';
                                                            tooltipText = `Missed Payment -> Amount: ${amount}, Points: ${points}`;
                                                            tooltipLetter = 'M';
                                                        } else if (status === 'partial') {
                                                            backgroundColor = '#96a511';
                                                            tooltipText = `Partial Payment -> Amount: ${amount}, Points: ${points}`;
                                                            tooltipLetter = 'P';
                                                        } else if (status === 'full') {
                                                            backgroundColor = '#09924e';
                                                            tooltipText = `Full Payment -> Amount: ${amount}, Points: ${points}`;
                                                            tooltipLetter = 'F';
                                                        } else if (status === 'advance') {
                                                            backgroundColor = '#5B2C6F';
                                                            tooltipText = `Paid in Advance -> Amount: ${amount}, Points: ${points}`;
                                                            tooltipLetter = 'A';
                                                        }

                                                        return (
                                                            <Tooltip title={tooltipText}>
                                                                <div className="payment-tooltip" style={{ backgroundColor }}>
                                                                    {tooltipLetter}
                                                                </div>
                                                            </Tooltip>
                                                        );
                                                    }
                                                }
                                            }
                                        }
                                        return null;
                                    }}
                                />
                            </>
                        ) : null}
                    </Col>
                </Row>
                <hr />
            </div>
        );
    }
}

export default PaymentChart;
