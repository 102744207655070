import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {addLoanData,getMainData} from "../../Redux/ducks/mainReducer";
import {getName,getName2,datesDiffe,isDateInSelectedRage} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
export class GeneralReport extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        reportData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,staff:"",
       
        alertStatus :false, alertType :"error", alertTitle:null,
        showExpense:false,
        closedLoans:0,totalFines:0,newClients:0,capital:0,totalExpenses:0,expensesArray:[],
        generalData:null
  

    }
    componentDidMount(){
        this.props.getMainData("GENERAL_REPORT");
        this.setState({isLoading:true})
    }
   
    componentDidUpdate(prevProps){
        const{msg,reportData,reportStatus,generalDataStatus,generalData} = this.props;

        if(reportStatus !==prevProps.reportStatus){
            if(reportStatus===true){
                this.setState({isLoading:false,reportData:reportData,btnDisabled:false,
                    closedLoans:reportData.closedLoans,totalFines:reportData.totalFines,
                    newClients:reportData.newClients,capital:reportData.capital
                })

                //loan data
                let newP=0
                let oldP=0
                let cashOut=0
                let renewedLoans=0
                let totalTenewedLoans=0
                reportData.givenLoans.map((values,i) =>{
                    if(values.creationStatus ==="NORMAL"){
                        cashOut =cashOut+values.loanAmount
                    }else{
                        renewedLoans = renewedLoans+1
                        totalTenewedLoans =totalTenewedLoans+values.loanAmount
                    }
                })
                //expenses
                let totalExpenses = 0
                let expensesArray = []
                let no = 0
                reportData.expData.map((values2,i) =>{
                    totalExpenses = totalExpenses+values2.amount
                    no = no+1
                    expensesArray.push(
                        <tr>
                            <td>{no}</td>
                            <td>{getName(reportData.expCat,values2.catID)}</td>
                            <td>{values2.name}</td>
                            <td>{values2.cost}</td>
                            <td>{values2.qty}</td>
                            <td>{values2.details}</td>
                            <td>{values2.amount}</td>
                        </tr>
                    )
                })

                this.setState({
                    totalExpenses:totalExpenses,expensesArray:expensesArray
                })

            }else{
                this.setState({isLoading:false,reportData:null,btnDisabled:false})

            }
        }

        if(generalDataStatus !==prevProps.generalDataStatus){
            this.setState({isLoading:false})
            if(generalDataStatus ===true){
                this.setState({generalData:generalData})
            }else{
                this.setState({generalData:null})
            }

        }

    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt,staff} = this.state

        if(startFrom ===null || endAt ===null || staff===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
        
            this.setState({isLoading:true,btnDisabled:true})
            
            const data = new FormData();
            data.append("staff",staff);
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
       
            /**upload new user*/
            this.props.addLoanData(data,"GENERAL_REPORT")
        }
    }

    showExpenses = ()=>{
        const {showExpense} = this.state
        if(showExpense ===true){
            this.setState({showExpense:false})
        }else{
            this.setState({showExpense:true})
        }
    }
    

    render() {
        const {generalData} = this.state
        const reportDataList = [];
        const reportDataLoans = [];
        const capitalDataList = []
        if(generalData !==null){
            /**capital */
            if(generalData.capitalData.length>0){
                let no1 = 0
                let totalCapital = 0
                generalData.capitalData.map((values,i) =>{
                    no1 = no1+1
                    totalCapital = totalCapital + parseInt(values.amount)
                    capitalDataList.push(
                        <tr key={i}>
                            <td>{no1}</td>
                            <td>{values.entryDate}</td>
                            <td>{values.amount}</td>
                            <td>{values.details}</td>
                            <td>{getName2( generalData.userData,values.addedBy,"STAFF")}</td>
                            <td>{values.created_at}</td>
                        </tr>
                    )
                })

                reportDataList.push(
                    <tr>
                        <td>{"TOTAL CAPITAL ADDED"}</td>
                        <td>{"0"}</td>
                        <td>{totalCapital}</td>
                    </tr>
                )
            }else{
                reportDataList.push(
                    <tr>
                        <td>{"TOTAL CAPITAL ADDED"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )
            }

            /**USERS */
            if(generalData.userData.length>0){
                let totalUsers = 0
                generalData.userData.map((values1,i) =>{
                    if(parseInt(values1.status)===1){
                        totalUsers = totalUsers + 1
                    }
                })
                reportDataList.push(
                    <tr>
                        <td>{"TOTAL STAFF"}</td>
                        <td>{totalUsers}</td>
                        <td>{"0"}</td>
                    </tr>
                )
            }else{
                reportDataList.push(
                    <tr>
                        <td>{"TOTAL STAFF"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )
            }

            /**clients */
            if(generalData.clientData.length>0){
                let totalClients = 0
                let totalClientsBlacklisted = 0
                generalData.clientData.map((values2,i) =>{
                    totalClients = totalClients + 1
                    if(parseInt(values2.blacklistStatus) ===1){
                        totalClientsBlacklisted = totalClientsBlacklisted+1
                    }
                })

                reportDataList.push(
                    <tr>
                        <td>{"TOTAL CLIENTS"}</td>
                        <td>{totalClients}</td>
                        <td>{"0"}</td>
                    </tr>
                )

                reportDataList.push(
                    <tr>
                        <td>{"TOTAL BLACKED LIST CLIENTS"}</td>
                        <td>{totalClientsBlacklisted}</td>
                        <td>{"0"}</td>
                    </tr>
                )
            }else{
                reportDataList.push(
                    <tr>
                        <td>{"TOTAL CLIENTS"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )

                reportDataList.push(
                    <tr>
                        <td>{"TOTAL BLACKED LIST CLIENTS"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )
            }

            /**loans 
             * totalLoans, totalClosedLoans, totalRenewedLoans,
             * totalDefaultedLoans, totalActiveLoans
             * 
            */
            if(generalData.loanData.length>0){
                let totalLoans = 0
                let totalLoansAmount = 0
                let totalLoansAmountExp = 0
                let totalLoansPaid = 0
                let totalLoansProcess = 0

                let totalLoansC = 0
                let totalLoansAmountC = 0
                let totalLoansAmountExpC = 0
                let totalLoansPaidC = 0
                let totalLoansProcessC = 0

                let totalLoansR = 0
                let totalLoansAmountR = 0
                let totalLoansAmountExpR = 0
                let totalLoansPaidR = 0
                let totalLoansProcessR = 0

                let totalLoansD = 0
                let totalLoansAmountD = 0
                let totalLoansAmountExpD = 0
                let totalLoansPaidD = 0
                let totalLoansProcessD = 0

                generalData.loanData.map((values3,i) =>{
                    totalLoans = totalLoans + 1
                    totalLoansPaid = parseInt(values3.amountPaid) + totalLoansPaid
                    totalLoansAmount = parseInt(values3.loanAmount) + totalLoansAmount
                    totalLoansProcess = parseInt(values3.processingFee) + totalLoansProcess
                    if(values3.loanRenewed ==="YES"){
                        totalLoansAmountExp = parseInt(values3.amountPaid) + totalLoansAmountExp
                    }else{
                        totalLoansAmountExp = parseInt(values3.expectedAmount) + totalLoansAmountExp
                    }
                    
                    /**closed */
                    if(parseInt(values3.status) ===0){
                        totalLoansC = totalLoansC + 1
                        totalLoansAmountC = parseInt(values3.loanAmount) + totalLoansAmountC
                        totalLoansPaidC = parseInt(values3.amountPaid) + totalLoansPaidC
                        totalLoansProcessC = parseInt(values3.processingFee) + totalLoansProcessC
                        if(values3.loanRenewed ==="YES"){
                            totalLoansAmountExpC = parseInt(values3.amountPaid) + totalLoansAmountExpC
                        }else{
                            totalLoansAmountExpC = parseInt(values3.expectedAmount) + totalLoansAmountExpC
                        }
                    }
                    /**closed */
                    if(values3.creationStatus !=="NORMAL"){
                        totalLoansR = totalLoansR + 1
                        totalLoansAmountR = parseInt(values3.loanAmount) + totalLoansAmountR
                        totalLoansPaidR = parseInt(values3.amountPaid) + totalLoansPaidR
                        totalLoansProcessR = parseInt(values3.processingFee) + totalLoansProcessR
                        if(values3.loanRenewed ==="YES"){
                            totalLoansAmountExpR = parseInt(values3.amountPaid) + totalLoansAmountExpR
                        }else{
                            totalLoansAmountExpR = parseInt(values3.expectedAmount) + totalLoansAmountExpR
                        }
                    }

                    /**defaulter */
                    if(parseInt(values3.defaulterStatus) ===1){
                        totalLoansD = totalLoansD + 1
                        totalLoansAmountD = parseInt(values3.loanAmount) + totalLoansAmountD
                        totalLoansPaidD = parseInt(values3.amountPaid) + totalLoansPaidD
                        totalLoansAmountExpD = parseInt(values3.expectedAmount) + totalLoansAmountExpD
                        totalLoansProcessD = parseInt(values3.processingFee) + totalLoansProcessD
                        
                    }
                })

                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL LOANS (TL)"}</td>
                        <td>{totalLoans.toLocaleString()}</td>
                        <td>{totalLoansAmount.toLocaleString()}</td>
                        <td>{totalLoansProcess.toLocaleString()}</td>
                        <td>{totalLoansAmountExp.toLocaleString()}</td>
                        <td>{totalLoansPaid.toLocaleString()}</td>
                        <td>{(totalLoansAmountExp-totalLoansPaid).toLocaleString()}</td>
                    </tr>
                )

                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL CLOSED LOANS"}</td>
                        <td>{totalLoansC.toLocaleString()}</td>
                        <td>{totalLoansAmountC.toLocaleString()}</td>
                        <td>{totalLoansProcessC.toLocaleString()}</td>
                        <td>{totalLoansAmountExpC.toLocaleString()}</td>
                        <td>{totalLoansPaidC.toLocaleString()}</td>
                        <td>{(totalLoansAmountExpC-totalLoansPaidC).toLocaleString()}</td>
                    </tr>
                )

                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL RENEWED LOANS"}</td>
                        <td>{totalLoansR.toLocaleString()}</td>
                        <td>{totalLoansAmountR.toLocaleString()}</td>
                        <td>{totalLoansProcessR.toLocaleString()}</td>
                        <td>{totalLoansAmountExpR.toLocaleString()}</td>
                        <td>{totalLoansPaidR.toLocaleString()}</td>
                        <td>{(totalLoansAmountExpR-totalLoansPaidR).toLocaleString()}</td>
                    </tr>
                )

                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL DEFAULTED LOANS (TDL)"}</td>
                        <td>{totalLoansD.toLocaleString()}</td>
                        <td>{totalLoansAmountD.toLocaleString()}</td>
                        <td>{totalLoansProcessD.toLocaleString()}</td>
                        <td>{totalLoansAmountExpD.toLocaleString()}</td>
                        <td>{totalLoansPaidD.toLocaleString()}</td>
                        <td>{(totalLoansAmountExpD-totalLoansPaidD).toLocaleString()}</td>
                    </tr>
                )

                reportDataLoans.push(
                    <tr>
                        <td>{"ACTIVE WORKING CAPITAL(TL bal - TDL bal)"}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td className='balB'>{((totalLoansAmountExp-totalLoansPaid)-(totalLoansAmountExpD-totalLoansPaidD)).toLocaleString()}</td>
                    </tr>
                )

            }else{
                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL LOANS(TL)"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )
                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL CLOSED LOANS"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )
                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL RENEWED LOANS"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )

                reportDataLoans.push(
                    <tr>
                        <td>{"TOTAL DEFAULTED LOANS(TDL)"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                        <td>{"0"}</td>
                    </tr>
                )
                reportDataLoans.push(
                    <tr>
                        <td>{"ACTIVE WORKING CAPITAL(TL bal - TDL bal)"}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                        <td>{}</td>
                    </tr>
                )
            }
        }

     
        return (
            <div>
                <div className="container">
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>GENERATE GENERAL REPORT</h4>
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {/**general */}
                        <div className='table-container'>
                        <Table>
                            <thead>
                                <tr>
                                    <th cols="35">ITEM</th>
                                    <th>NUMBER</th>
                                    <th>TOTAL AMOUNT</th>
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                              
                            <tbody>
                                {reportDataList}
                            </tbody>}
                        </Table>
                        </div>
                      

                        {/**loans */}
                        <div className='table-container'>
                        <Table>
                            <thead>
                                <tr>
                                    <th cols="35">ITEM</th>
                                    <th>NUMBER</th>
                                    <th>TOTAL AMOUNT</th>
                                    <th>TOTAL PROCESSING FEE</th>
                                    <th>TOTAL EXPECTED AMOUNT</th>
                                    <th>TOTAL PAID AMOUNT</th>
                                    <th>TOTAL BALANCE AMOUNT</th>
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                              
                            <tbody>
                                {reportDataLoans}
                            </tbody>}
                        </Table>
                        </div>


                        
                        {/*<Row>
                           
                           <Col md="4" xs="12">
                               <FormGroup>
                                   <Label for="dob">Start From*</Label>
                                   <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                               </FormGroup>
                           </Col>
                           <Col md="4" xs="12">
                               <FormGroup>
                                   <Label for="dob">End At*</Label>
                                   <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                               </FormGroup>
                           </Col>
                           <Col md="4" xs="12">
                               <FormGroup>
                                   <Label for="dob"></Label>
                                   <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                               </FormGroup>
                           </Col>
                       </Row>


                        <Table>
                            <thead>
                                <tr>
                                    <th cols="25">ITEM</th>
                                    <th>NUMBER</th>
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                              
                            <tbody>
                                <tr>
                                    <td>Given Loans</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>Closed Loans</td>
                                    <td>{this.state.closedLoans}</td>
                                </tr>

                                <tr>
                                    <td>Renewed Loans</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>Expired Loans</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>New Clients</td>
                                    <td>{this.state.newClients}</td>
                                </tr>

                                <tr>
                                    <td>Normal Collection</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>B&D(Defaulter) Collections</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>Processing Fee(New Client)</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>Processing Fee(Old Client)</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>Capital Added</td>
                                    <td>{this.state.capital}</td>
                                </tr>

                                <tr>
                                    <td>Total Fines</td>
                                    <td>{this.state.totalFines}</td>
                                </tr>

                                <tr>
                                    <td>Total Cash Out</td>
                                    <td>0</td>
                                </tr>

                                <tr>
                                    <td>Total Expenses</td>
                                    <td>{this.state.totalExpenses}</td>
                                </tr>
                               
                                <Button onClick={this.showExpenses} className="btn btn-block btn-sm btn-secondary">Show Expenses</Button>
                                {
                                    this.state.showExpense ===true?
                                    <>
                                        <tr>
                                            <th>NO.</th>
                                            <th>CATEGORY</th>
                                            <th>NAME</th>
                                            <th>COST</th>
                                            <th>QTY</th>
                                            <th>DETAILS</th>
                                            <th>AMOUNT</th>
                                        </tr>
                                        {this.state.expensesArray}
                                    </>
                                        
                                    :null
                                }

                                <tr>
                                    <td>CLOSING BALANCE</td>
                                    <td>0</td>
                                </tr>
                               
                            </tbody>}
                        </Table>*/}

                        
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    msg : state.mainReducer.msg,
    reportData:state.mainReducer.updateData,
    reportStatus:state.mainReducer.updateStatus,
    generalDataStatus : state.mainReducer.dataStatus,
    generalData : state.mainReducer.mainData,
});
export default connect(
    mapStateToProps, {addLoanData,getMainData}
)(GeneralReport)
