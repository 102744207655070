import React, { Component } from 'react'
import {Link,Redirect} from "react-router-dom";
import {getName2,getLevel} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import {Label,Row,Col,FormGroup} from 'reactstrap'

export class LoanRequestApprovals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertStatus :false, alertType :"error", alertTitle:null,
            clientDetailsData:this.props.mainData
        };
    
    }

    render() {
        const {clientDetailsData} = this.state
        let staffNames =[];
        if(clientDetailsData !==null){
            const staff = JSON.parse(clientDetailsData.loanReqData.visitedBy)
            if(staff.length>0){
                staff.map((values,i)=>{
                    staffNames.push(getName2(clientDetailsData.staffData,values,"STAFF"))
                })
            }
        }
        return (
            <div>
                <Row>
                                                
                    <Col xs="6" md="6">
                        <FormGroup className="topWords"><Label className="loanLabel">Client Name: <span> {this.state.clientDetailsData.loanReqData.client["name"]}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Account Number : <span> {this.state.clientDetailsData.loanReqData.client["account"]}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Client Status : <span > <b style={{color:"blue"}}>{this.state.clientDetailsData.loanReqData.loanStatus}</b></span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Status : <span> {parseInt(this.state.clientDetailsData.loanReqData.status)===1?"OPEN":"CLOSED"}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Status Level : <span> {getLevel(this.state.clientDetailsData.loanReqData.levelStatus)}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Approved/Rejected By : <span> {getName2(this.state.clientDetailsData.staffData,this.state.clientDetailsData.loanReqData.approvedBy,"STAFF")}</span></Label></FormGroup>


                    </Col>
                    <Col xs="6" md="6">
                        <FormGroup className="topWords"><Label className="loanLabel">Approval/Rejection Date : <span> {this.state.clientDetailsData.loanReqData.ApprovalDate}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Security Type : <span> {this.state.clientDetailsData.loanReqData.securityType}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Security Description  : <span> {this.state.clientDetailsData.loanReqData.securityDes}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Phone 1 : <span> {this.state.clientDetailsData.loanReqData.phone1}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Phone 2 : <span> {this.state.clientDetailsData.loanReqData.phone2}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Approved Amount: <span> {this.state.clientDetailsData.loanReqData.approvedAmount}</span></Label></FormGroup>
                    </Col>
                    <Col xs="12" md="12">
                    <FormGroup className="topWords"><Label className="loanLabel">Comment : <span> {this.state.clientDetailsData.loanReqData.approvalComment}</span></Label></FormGroup>
                    </Col>
                    
                    
                </Row>
            </div>
        )
    }
}

export default (LoanRequestApprovals)