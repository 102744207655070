import React, { Component } from 'react'
import {Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData,addLoanData} from "../../Redux/ducks/mainReducer";
import {datesDiffe,getName} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
export class GenerateUserReport extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,
        reportData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,staff:"",
       
        alertStatus :false, alertType :"error", alertTitle:null,

        closedLoanDataList:[],action:"",branch:"",staffDataList:[]
  

    }
    componentDidMount(){
        this.props.getMainData("STAFFREGDATA")
    }
    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,reportData,reportStatus} = this.props;

        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData})
            }
        } 
        if(reportStatus !==prevProps.reportStatus){
            if(reportStatus===true){
                this.setState({isLoading:false,reportData:reportData,btnDisabled:false})

            }
            if(reportStatus===false){
                this.setState({isLoading:false,reportData:null,btnDisabled:false})

            }
        }

    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        const {mainData} = this.state
        this.setState({[e.target.name]:e.target.value})

        if(e.target.name ==='branch'){
            let staffDataList =[]
            if(e.target.value !==""){
                if(mainData !==null){
                    const myCatData =  mainData.staffData
                    if(myCatData.length>0){
                        staffDataList.push(<option value="">SELECT STAFF</option>)
                        staffDataList.push(<option value="0">ALL STAFF</option>)
                        myCatData.map((values,i) =>{
                            if(parseInt(values.branchID) === parseInt(e.target.value) && parseInt(values.status)===1){
                            
                                staffDataList.push(
                                    <option key={i} value={values.id}>{values.username+" ("+values.fname+")"}</option>
                                )
                            }
                        
                        });
                    }else{
                        staffDataList.push(  
                            <option value="">No staff found</option>   
                        )
                    }
                }
            }
            this.setState({staffDataList:staffDataList,reportData:null})
        }
        if(e.target.name ==='action'){
            this.setState({reportData:null})
        }
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = (e) =>{

        const {startFrom,endAt,staff,action,branch} = this.state
        //this.setState({isLoading:false,btnDisabled:false})

        if(startFrom ===null || endAt ===null || staff===""||action===""||branch===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
            this.setState({isLoading:true,btnDisabled:true})
          
            const data = new FormData();
            data.append("staff",staff);
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            data.append("reportAction",action);
            data.append("branchID",branch);

            if(parseInt(action)===2){
                this.setState({isLoading:false,btnDisabled:false})
                this.setState({  alertStatus :true, alertType :"info", alertTitle:"We are currently enhancing this report and anticipate its release in the near future"})

            }else{
                /**upload new user*/
                this.props.addLoanData(data,"STAFF_REPORT")
            }
        }
    }

    render() {
        /**displaying  categories*/
       
        const branchDataList = [];
        if(this.state.mainData !==null){
           
            const userData = JSON.parse(window.localStorage.getItem('userData'))
            let branchesAllowedArray = JSON.parse(userData.branchesAllowed)
            if(branchesAllowedArray.length>0){
                branchDataList.push(<option key="" value="">SELECT BRANCH</option>)
                branchesAllowedArray.map((values,i) =>{
                  
                    branchDataList.push(
                        <option key={i} value={values}>{getName(this.state.mainData.branchData,values)}</option>
                    )
                });
            }

            //loop through the array
           
        }

        const reportDataList1 = [];
        const reportDataList = [];
 
        if(this.state.reportData !==null){
            let myCatData =  this.state.reportData
            //loop through the array
            var no = 0
            var totalExp = 0
            var totalAdvance = 0
            var totalFines = 0
            var totalPay = 0
            var totalActual = 0
            var totalDeff1 = 0
            var totalDeff2 = 0
            
            let loopData = null
            if(parseInt(this.state.action) ===3){
                //turn into object
                var myCatData1 = Object.entries(myCatData);
                loopData = myCatData1
            }else{
                loopData =myCatData
            }
            loopData.map((values,i) =>{
                if(parseInt(this.state.action) ===1){
                    no = no+1
                    let actual = parseInt(values.totalLoanPayment)+parseInt(values.totalFines)
                    let deff1 = (parseInt(values.totalAdvance)+actual)-parseInt(values.totalExpectection)
                    let deff2 = actual-parseInt(values.totalExpectection)

                    totalExp = totalExp+parseInt(values.totalExpectection)
                    totalAdvance = totalAdvance+parseInt(values.totalAdvance)
                    totalFines = totalFines+parseInt(values.totalFines)
                    totalPay = totalPay+parseInt(values.totalLoanPayment)
                    totalActual = totalActual+actual
                    totalDeff1 = totalDeff1+deff1
                    totalDeff2 = totalDeff2+deff2

                    reportDataList1.push(
                        <tr key={i}>
                            <td>{no}</td>
                            <td>{values.date}</td>
                            <td>{parseInt(values.totalExpectection).toLocaleString()}</td>
                            <td>{parseInt(values.totalAdvance).toLocaleString()}</td>
                            <td>{parseInt(values.totalFines).toLocaleString()}</td>
                            <td>{parseInt(values.totalLoanPayment).toLocaleString()}</td>
                            <td>{actual.toLocaleString()}</td>
                            <td style={deff1<0?{color:"red"}:{color:"green"}}>{deff1.toLocaleString()}</td>
                            <td style={deff2<0?{color:"red"}:{color:"green"}}>{deff2.toLocaleString()}</td>
                        </tr>
                    )
                }else if(parseInt(this.state.action) ===3){
                    no = no+1
                    let actual = parseInt(values[1].totalLoanPayment)+parseInt(values[1].totalFines)
                    let deff1 = (parseInt(values[1].totalAdvance)+actual)-parseInt(values[1].totalExpectection)
                    let deff2 = actual-parseInt(values[1].totalExpectection)
                 
                    totalExp = totalExp+parseInt(values[1].totalExpectection)
                    totalAdvance = totalAdvance+parseInt(values[1].totalAdvance)
                    totalFines = totalFines+parseInt(values[1].totalFines)
                    totalPay = totalPay+parseInt(values[1].totalLoanPayment)
                    totalActual = totalActual+actual
                    totalDeff1 = totalDeff1+deff1
                    totalDeff2 = totalDeff2+deff2

                    reportDataList1.push(
                        <tr key={i}>
                            <td>{no}</td>
                            <td>{values[1].clientName}</td>
                            <td>{parseInt(values[1].totalExpectection).toLocaleString()}</td>
                            <td>{parseInt(values[1].totalAdvance).toLocaleString()}</td>
                            <td>{parseInt(values[1].totalFines).toLocaleString()}</td>
                            <td>{parseInt(values[1].totalLoanPayment).toLocaleString()}</td>
                            <td>{actual.toLocaleString()}</td>
                            <td style={deff1<0?{color:"red"}:{color:"green"}}>{deff1.toLocaleString()}</td>
                            <td style={deff2<0?{color:"red"}:{color:"green"}}>{deff2.toLocaleString()}</td>
                        </tr>
                    )
                }else{
                    no = no+1
                    reportDataList.push(
                        <tr key={i}>
                            <td>{no}</td>
                            <td>{values.date}</td>
                            <td>{values.totalClients}</td>
                            <td>{values.activeClients}</td>
                            <td>{values.paidClients}</td>
                            <td>{values.notPaidClients}</td>
                            <td>{values.paidInAdvance}</td>
                            <td>{values.clearedClients}</td>
                            <td>{values.renewedClients}</td>
                            <td>{values.newClients}</td>
                            <td>{values.totalDefaulterClients}</td>
                            <td>{values.defaulterPaidClients}</td>
                            <td>{values.defaulterAmount}</td>
                            <td>{values.totalCollection}</td>
                            <td>{values.totalGiveOut}</td>
                            <td>{values.closingClients}</td>
                            <td>{values.cp}</td>
                        </tr>
                    )
                }
            
            });

            {/**actual vs exp report total */}
            if(parseInt(this.state.action) ===1||parseInt(this.state.action) ===3){
                reportDataList1.push(
                    <tr className='tableTotals'>
                        <td>{""}</td>
                        <td>{"TOTALS"}</td>
                        <td>{totalExp.toLocaleString()}</td>
                        <td>{totalAdvance.toLocaleString()}</td>
                        <td>{totalFines.toLocaleString()}</td>
                        <td>{totalPay.toLocaleString()}</td>
                        <td>{totalActual.toLocaleString()}</td>
                        <td>{totalDeff1.toLocaleString()}</td>
                        <td>{totalDeff2.toLocaleString()}</td>
                    </tr>
                )
            } 
        }

     
        return (
            <div>
            
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>GENERATE STAFF DAILY REPORT</h4>
                    <h6 className='pageComment'>Staff Daily Report & Actual Vs Expected Report</h6>
                    </div>
                    <Col xs="12" className="" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Branch*</Label>
                                    <Input type="select" onChange={this.setValue} name="branch">
                                    {branchDataList}
                                    </Input>                                
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Staff*</Label>
                                    <Input type="select" onChange={this.setValue} name="staff">
                                    {this.state.staffDataList}
                                    </Input>                                
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Report Type*</Label>
                                    <Input type="select" onChange={this.setValue} name="action">
                                        <option value={""}>Select Report Type</option>
                                        <option value={"1"}>Actual Vs Expected Staff</option>
                                        <option value={"3"}>Actual Vs Expected Client</option>
                                        <option value={"2"}>Staff Daily Report</option>
                                    </Input>                                
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='table-container'>
                        <Table striped responsive>
                            <thead>
                                {/**staff daily */}
                                {parseInt(this.state.action) ===2?
                                <tr>
                                    <th>No</th>
                                    <th>Date</th>
                                    <th>Total Clients</th>
                                    <th>Active Clients</th>
                                    <th>Paid Clients</th>
                                    <th>Not Paid </th>
                                    <th>Paid In Advance</th>
                                    <th>Cleared Clients</th>
                                    <th>Renewed Clients</th>
                                    <th>New Clients</th>
                                    <th>B&D Clients</th>
                                    <th>Paid B&D Clients</th>
                                    <th>B&D Amount</th>
                                    <th>Total Collection</th>
                                    <th>Given Out</th>
                                    <th>Clossing Clients</th>
                                    <th>CP(%)</th>
                                </tr>:null}
                                 {/**actual vs expected staff */}
                                {parseInt(this.state.action) ===1?
                                <tr>
                                    <th>No</th>
                                    <th>Date</th>
                                    <th>Expected Amount(EA)</th>
                                    
                                    <th>Paid In Advance(PA)</th>
                                    <th>Fines Paid(FP)</th>
                                    <th>Loan Payment(LP)</th>
                                    <th>Actual Amount(AA)(FP+LP)</th>
                                    <th>Difference With Advance((PA+AA)-EA)</th>
                                    <th>Difference Without Advance(AA-EA)</th>
                                </tr>:null}
                                {/**actual vs expected client */}
                                {parseInt(this.state.action) ===3?
                                <tr>
                                    <th>No</th>
                                    <th>Client Name</th>
                                    <th>Expected Amount(EA)</th>
                                    <th>Paid In Advance(PA)</th>
                                    <th>Fines Paid(FP)</th>
                                    <th>Loan Payment(LP)</th>
                                    <th>Actual Amount(AA)(FP+LP)</th>
                                    <th>Difference With Advance((PA+AA)-EA)</th>
                                    <th>Difference Without Advance(AA-EA)</th>
                                </tr>:null}
                            </thead>
                            
                            {this.state.isLoading===true?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                              
                            <tbody>
                                {(parseInt(this.state.action) ===1||parseInt(this.state.action) ===3)?reportDataList1:reportDataList}
                       
                          
                            </tbody>}
                        </Table>
                        </div>
                      
                    </Col>
                </Row>
           
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    reportData:state.mainReducer.updateData,
    reportStatus:state.mainReducer.updateStatus,
});
export default connect(
    mapStateToProps, {getMainData,addLoanData}
)(GenerateUserReport)
