import React, { Component } from 'react'
export class Footer extends Component {
    state = {
        myDate:new Date().getFullYear()
    }
    render() {
        return (
            <div>
                <footer className="sticky-footer bg-white">
                    <div className="container my-auto">
                        <div className="copyright text-center my-auto">
                            <span>Version {process.env.REACT_APP_VERSION}, Copyright &copy; {this.state.myDate}, Developed By <a href="https://mutanik.com" target="_blank" rel="noopener noreferrer">Mutanik Technologies(U) Ltd</a></span>
                        </div>
                    </div>

                   
                </footer>
            </div>
        )
    }
}

export default Footer

