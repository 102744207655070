import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,getData,restUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'
import {getName2,getName} from "../../GeneralFunctions/general"
import MultiSelect from "@kenshooui/react-multi-select";
import ReactJsAlert from "reactjs-alert"
import {checkRight} from "../../GeneralFunctions/general"
export class StaffClientList extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        noCat:null,
        userData:[],
        loanList:[],
        selectedLoans: [],
        alertStatus :false, alertType :"error", alertTitle:null,
        transferFrom:"",transferTo:"",refresh:false,userID:"",clientsListData:null

    }

    componentDidMount(){
        /**refresh categories */
        this.props.getMainData("GETBRADATA")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus,addUserData} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData})
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
              
                this.setState({isSaving:false,btnDisabled:false,clientsListData:addUserData
                })
            }else if(addUserStatus===false){
                this.setState({clientsListData:null,isSaving:false,btnDisabled:false,alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.name ==="transferFrom"){
         
            if(e.target.value !==""){
               
                let branchID = parseInt(e.target.value)
                let rightList =[]
                let no = 0 
                rightList.push(
                    <option value={""}>{"Select Staff"}</option>
                    )
                this.state.mainData.userData.map((values,i) =>{
                    var branchesArray = JSON.parse(values.allowedBranches)
                    if (branchesArray.includes(branchID.toString())) {
                        rightList.push(
                        <option key={i} value={values.id}>{values.username+" ("+ values.fname+" "+ values.lname+")"}</option>
                        )
                    }
                   
                });

                this.setState({
                    userData:rightList
                })

            }else{
                this.setState({
                    userData:[]
                })
            }
        }
    }

    searchData = () =>{
   
        const {transferFrom,userID} = this.state
        if(transferFrom ===null||userID===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else{
            const data = new FormData();
            data.append("userID",userID);
            data.append("branchID",transferFrom);


            this.props.getData(data,"GETSTAFFLIST")
            this.setState({isSaving:true})
           
        }
    }

    render() {
        /**displaying  categories*/
        const branchData = [];
        if(this.state.mainData !==null){
            const myCatData =  this.state.mainData.branchesData
            const myBranches =  JSON.parse(this.state.mainData.myBranches)
            //loop through the array
            if(myCatData.length>0){
                branchData.push(<option key="" value="">SELECT BRANCH</option>)
                for (let i = 0; i < myBranches.length; i++) {
                    const sRight = parseInt(myBranches[i])
                    myCatData.map((values,i) =>{
                        if(parseInt(values.id)===sRight){
                            branchData.push(
                                <option key={i} value={values.id}>{values.name}</option>
                            )
                        }
                        
                    });

                }
            }else{
                branchData.push(  
                    <option value="">No Branches Found</option>   
                )
            }
        }
        let clientsList = []
        let myNo = 0
        if(this.state.clientsListData !==null){
            if(this.state.clientsListData.length>0){
               
                this.state.clientsListData.map((values2, i) =>{
                    myNo = myNo+1
                    clientsList.push(
                        <tr key={i}>
                            <td>{myNo}</td>
                            <td>{getName2(this.state.mainData.userData,values2.agent,"STAFF")}</td>
                            <td>{getName(this.state.mainData.branchesData,values2.branchID)}</td>
                            <td>{values2.clientName}</td>
                            <td>{values2.loanID}</td>

                        </tr>
                    )
                });
            }else{
                clientsList.push(
                    <tr >
                        <td>{"No data found"}</td>
                    </tr>
                )
            }
        }
       


        return (
            <div>
                 <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>STAFF'S CLIENT LIST</h4>
                    <h6 className='pageComment'>Only active staff are shown</h6>
                    </div>
                    <Col xs="12" className="tableWrapper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                    
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="gender">Select Branch*</Label>
                                    <Input type="select" onChange={this.setValue} name="transferFrom">
                                        {branchData}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="gender">Select Staff*</Label>
                                    <Input type="select" onChange={this.setValue} name="userID">
                                        {this.state.userData}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                            <Col md="12" xs="12">
                                <Label className='balB'>TOTAL CLIENTS : {myNo.toLocaleString()}</Label>
                            </Col>
                        </Row>
                       <div className='table-container'>
                            <Table striped responsive className="stallflistTB">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Staff Name</th>
                                        <th>Branch</th>
                                        <th>Client Name</th>
                                        <th>Loan ID</th>
                                    </tr>

                                   
                                </thead>
                                
                                {this.state.isSaving===true?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    clientsList
                                    
                                }
                            </Table>
                       </div>
               
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.getMyDataStatus,
    addUserData:state.mainReducer.getMyData,
});
export default connect(
    mapStateToProps, {getMainData,getData,restUser}
)(StaffClientList)
