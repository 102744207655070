import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,getData} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export class MemberReportAll extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        qty:"",total:"",cost:"",details:"",staff:"",cat:"",name:"",myDate:"",
        reportData:null,getReport:false

    }

    componentDidMount(){

        /**refresh categories */
        this.props.getMainData("repoData")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus,getReportData} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,reportData:getReportData,getReport:true})

            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,getReport:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    addCategory =(e)=>{

        const {staff} = this.state;

        if(staff===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Select Member"})
        }else{
            this.setState({isSaving:true,btnDisabled:true})
            const data = new FormData();
            data.append("userID",staff);
           
        
            /**upload new user*/
            this.props.getData(data,"GET_MEMBERREPORT")
        }
    }

    generatePDF = () => {
        const { reportData } = this.state;
        
        // Create a new jsPDF instance with landscape orientation and A4 size
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
        });
        
        // Get page width for centering header text
        const pageWidth = doc.internal.pageSize.getWidth();
        
        // Define header text for the document
        //const headerText = ['Loan Repayment Schedule'];
        
        // Set font size for header text
        doc.setFontSize(12);
        
        // Center header text above the table
        /*headerText.forEach((text, index) => {
            const textWidth = doc.getTextWidth(text);
            const x = (pageWidth - textWidth) / 2;
            const y = 20 + (index * 10); // Adjusting vertical position
            doc.text(text, x, y);
        });*/
        
        // Define table headers for the two tables
        const tableHeaders = [
            ['No.', 'Name', 'Loan', 'Subscription', 'Fines', 'Welfare','Total']
        ];
        
        const tableHeaders2 = [
            ['No.', 'Name', 'Loan In Range', 'Intrest In Range', 'Fines In Range', 'Total']
        ];

        const tableHeaders3 = [
            ['No.', 'Name', 'Paid Loan', 'Paid Intrest', 'Paid Subscription', 'Paid Fine', 'Paid Welfare', 'Total']
        ];
    
        // If there is loan data, process and generate table rows
        if (reportData && reportData.length > 0) {
            const rows = this.generateLoanDataRows(reportData, "OPEN");
            
            // Define column widths for the first table
            const columnWidths = [10, 55, 45, 45, 35, 45,45];
            const topMargin = 25; // Adjust top margin for the first table

            // Table title for the first table
            const tableTitle1 = "OPENING OUTSTANDING BALANCE";
            
            // Set font size and add title above the first table
            doc.setFontSize(12);
            const textWidth1 = doc.getTextWidth(tableTitle1);
            const x1 = (pageWidth - textWidth1) / 2;
            const y1 = topMargin - 5; // Position the title just above the first table
            doc.text(tableTitle1, x1, y1);
            
            // Generate the first table in the PDF
            doc.autoTable({
                head: tableHeaders,
                body: rows,
                startY: topMargin,
                columnStyles: this.getColumnStyles(columnWidths),
                styles: this.getTableStyles(),
                headStyles: this.getHeaderStyles(),
                pageBreak: 'auto',
                margin: { top: 30, bottom: 10 },
            });
        }
    
        if (reportData && reportData.length > 0) {
            const rows2 = this.generateLoanDataRows(reportData, "NEW");
            
            // Define column widths for the second table
            const columnWidths2 = [10, 55, 50, 50, 50, 50];
            const topMargin2 = doc.lastAutoTable.finalY + 20; // Position second table after the first one

            // Table title for the second table
            const tableTitle2 = "NEW DATA GENERATED WITH IN RANGE";

            // Set font size and add title above the second table
            doc.setFontSize(12);
            const textWidth2 = doc.getTextWidth(tableTitle2);
            const x2 = (pageWidth - textWidth2) / 2;
            const y2 = topMargin2 - 5; // Position the title just above the second table
            doc.text(tableTitle2, x2, y2);
            
            // Generate the second table in the PDF
            doc.autoTable({
                head: tableHeaders2,
                body: rows2,
                startY: topMargin2,
                columnStyles: this.getColumnStyles(columnWidths2),
                styles: this.getTableStyles(),
                headStyles: this.getHeaderStyles(),
                pageBreak: 'auto',
                margin: { top: 30, bottom: 10 },
            });
        }

        if (reportData && reportData.length > 0) {
            const rows3 = this.generateLoanDataRows(reportData, "PAY");
            
            // Define column widths for the second table
            const columnWidths3 = [10, 55, 35, 35, 35, 35, 35, 35];
            const topMargin3 = doc.lastAutoTable.finalY + 20; // Position second table after the first one

            // Table title for the second table
            const tableTitle3 = "PAYMENTS";

            // Set font size and add title above the second table
            doc.setFontSize(12);
            const textWidth3 = doc.getTextWidth(tableTitle3);
            const x2 = (pageWidth - textWidth3) / 2;
            const y2 = topMargin3 - 5; // Position the title just above the second table
            doc.text(tableTitle3, x2, y2);
            
            // Generate the second table in the PDF
            doc.autoTable({
                head: tableHeaders3,
                body: rows3,
                startY: topMargin3,
                columnStyles: this.getColumnStyles(columnWidths3),
                styles: this.getTableStyles(),
                headStyles: this.getHeaderStyles(),
                pageBreak: 'auto',
                margin: { top: 30, bottom: 10 },
            });
        }
    
    
        // Save the generated PDF
        doc.save("member_Report.pdf");
    };
    
    // Method to generate rows from loan data
    generateLoanDataRows = (loanData, action) => {
        let myNo = 0; // Initialize counter
        let tnewLoan = 0
        let tnewGot = 0
        let tfinesGot = 0
   

        let tpaidLoan = 0
        let tpaidIntrest = 0
        let tpaidContribution = 0
        let tpaidFine = 0
        let tpaidWelfare = 0
        
        return loanData.map((values) => {
            if (action === "OPEN") {
                myNo += 1;
                return [
                    myNo,
                    values.name,
                    parseInt(values.openLoan).toLocaleString(),
                    parseInt(values.openContribution).toLocaleString(),
                    parseInt(values.openFines).toLocaleString(),
                    parseInt(values.openWelfare).toLocaleString(),
                    (parseInt(values.openLoan)+parseInt(values.openContribution)+parseInt(values.openFines)+parseInt(values.openWelfare)).toLocaleString()
                ];
            }else if(action === "NEW") {
                myNo += 1;
                tnewLoan = tnewLoan+parseInt(values.newLoan)
                tnewGot = tnewGot+parseInt(values.newGot)
                tfinesGot = tfinesGot+parseInt(values.finesGot)

                return [
                    myNo,
                    values.name,
                    parseInt(values.newLoan).toLocaleString(),
                    parseInt(values.newGot).toLocaleString(),
                    parseInt(values.finesGot).toLocaleString(),
                    (parseInt(values.newLoan)+parseInt(values.newGot)+parseInt(values.finesGot)).toLocaleString(),
    
                ];
            }else if(action === "PAY") {
                myNo += 1;
                tpaidLoan = tpaidLoan+parseInt(values.paidLoan)
                tpaidIntrest = tpaidIntrest+parseInt(values.paidIntrest)
                tpaidContribution = tpaidContribution+parseInt(values.paidContribution)
                tpaidFine = tpaidFine+parseInt(values.paidFine)
                tpaidWelfare = tpaidWelfare+parseInt(values.paidWelfare)

             
                return [
                    myNo,
                    values.name,
                    parseInt(values.paidLoan).toLocaleString(),
                    parseInt(values.paidIntrest).toLocaleString(),
                    parseInt(values.paidContribution).toLocaleString(),
                    parseInt(values.paidFine).toLocaleString(),
                    parseInt(values.paidWelfare).toLocaleString(),
                    (parseInt(values.paidLoan)+parseInt(values.paidIntrest)+parseInt(values.paidContribution)+parseInt(values.paidFine)+parseInt(values.paidWelfare)).toLocaleString()
                ];
            }
        });
    };
    
    // Method to get column styles
    getColumnStyles = (widths) => {
        return widths.reduce((styles, width, index) => {
            styles[index] = { cellWidth: width };
            return styles;
        }, {});
    };
    
    // Method to get table styles
    getTableStyles = () => ({
        fontSize: 9,
        cellPadding: 2,
        overflow: 'linebreak',
        halign: 'center',
        fontStyle: 'normal',
    });
    
    // Method to get header styles
    getHeaderStyles = () => ({
        fillColor: [20, 224, 239],  // Header background color
        fontStyle: 'bold',
        textColor: [3, 4, 2],        // Header text color (black)
    });

   

    render() {
        const userData = JSON.parse(window.localStorage.getItem('userData'));
        const {reportData} = this.state
        let catList = [];
        let staffDataList = []
        const myUserData =  this.state.mainData
       
        let openData = []
        let gotData=[]
        let paymentData = []

        const uData =  userData.usersData
        catList.push(<option value={""}>{"SELECT MEMBER"}</option>)
        if(uData.length>0){
            uData.map((values, i) =>{
                catList.push(<option value={values.id}>{values.firstName+" "+values.lastName}</option>)
            })
        }else{
            catList.push(  
                <option value="">No data found</option>   
            )
        }




    
        let myNo=0
        let topenLoan = 0
        let topenContribution = 0
        let topenFines = 0
        let topenWelfare = 0
    

        let tnewLoan = 0
        let tnewGot = 0
        let tfinesGot = 0
   

        let tpaidLoan = 0
        let tpaidIntrest = 0
        let tpaidContribution = 0
        let tpaidFine = 0
        let tpaidWelfare = 0
        
        if(reportData !==null){
            reportData.map((values,i) =>{
                myNo = myNo+1

                topenLoan = topenLoan+parseInt(values.openLoan)
                topenContribution = topenContribution+parseInt(values.openContribution)
                topenFines = topenFines+parseInt(values.openFines)
                topenWelfare = topenWelfare+parseInt(values.openWelfare)

                tnewLoan = tnewLoan+parseInt(values.newLoan)
                tnewGot = tnewGot+parseInt(values.newGot)
                tfinesGot = tfinesGot+parseInt(values.finesGot)

                tpaidLoan = tpaidLoan+parseInt(values.paidLoan)
                tpaidIntrest = tpaidIntrest+parseInt(values.paidIntrest)
                tpaidContribution = tpaidContribution+parseInt(values.paidContribution)
                tpaidFine = tpaidFine+parseInt(values.paidFine)
                tpaidWelfare = tpaidWelfare+parseInt(values.paidWelfare)

                openData.push(
                    <tr key={i}>
                        <th scope="row">{myNo}</th>
                        <td>{values.name}</td>
                        <td>{parseInt(values.openLoan).toLocaleString()}</td>
                        <td>{parseInt(values.openContribution).toLocaleString()}</td>
                        <td>{parseInt(values.openFines).toLocaleString()}</td>
                        <td>{parseInt(values.openWelfare).toLocaleString()}</td>
                        <td>{(parseInt(values.openLoan)+parseInt(values.openContribution)+parseInt(values.openFines)+parseInt(values.openWelfare)).toLocaleString()}</td>
                    </tr>
                )

                gotData.push(
                    <tr key={i}>
                        <th scope="row">{myNo}</th>
                        <td>{values.name}</td>
                        <td>{parseInt(values.newLoan).toLocaleString()}</td>
                        <td>{parseInt(values.newGot).toLocaleString()}</td>
                        <td>{parseInt(values.finesGot).toLocaleString()}</td>
                        <td>{(parseInt(values.newLoan)+parseInt(values.newGot)+parseInt(values.finesGot)).toLocaleString()}</td>
                    </tr>
                )
                
                paymentData.push(
                    <tr key={i}>
                        <th scope="row">{myNo}</th>
                        <td>{values.name}</td>
                        <td>{parseInt(values.paidLoan).toLocaleString()}</td>
                        <td>{parseInt(values.paidIntrest).toLocaleString()}</td>
                        <td>{parseInt(values.paidContribution).toLocaleString()}</td>
                        <td>{parseInt(values.paidFine).toLocaleString()}</td>
                        <td>{parseInt(values.paidWelfare).toLocaleString()}</td>
                        <td>{(parseInt(values.paidLoan)+parseInt(values.paidIntrest)+parseInt(values.paidContribution)+parseInt(values.paidFine)+parseInt(values.paidWelfare)).toLocaleString()}</td>
                    </tr>
                )
            })

          

            gotData.push(
                <tr className='tableTotals'>
                    <th scope="row">{""}</th>
                    <td>{"TOTALS"}</td>
                    <td>{parseInt(tnewLoan).toLocaleString()}</td>
                    <td>{parseInt(tnewGot).toLocaleString()}</td>
                    <td>{parseInt(tfinesGot).toLocaleString()}</td>
                    
                    <td>{(parseInt(tnewLoan)+parseInt(tnewGot)+parseInt(tfinesGot)).toLocaleString()}</td>
                </tr>
            )

            paymentData.push(
                <tr className='tableTotals'>
                    <th scope="row">{""}</th>
                    <td>{"TOTALS"}</td>
                    <td>{parseInt(tpaidLoan).toLocaleString()}</td>
                    <td>{parseInt(tpaidIntrest).toLocaleString()}</td>
                    <td>{parseInt(tpaidContribution).toLocaleString()}</td>
                    <td>{parseInt(tpaidFine).toLocaleString()}</td>
                    <td>{parseInt(tpaidWelfare).toLocaleString()}</td>
                    <td>{(parseInt(tpaidLoan)+parseInt(tpaidIntrest)+parseInt(tpaidContribution)+parseInt(tpaidFine)+parseInt(tpaidWelfare)).toLocaleString()}</td>
                </tr>
            )
           
    
   

           
        }
        return (
            <div>
                <div>
                    <Row className="mainWrraper">
                        <div className="block-header">
                            <h4>SEE MEMBER's REPORT </h4>
                        </div>
                    <Col xs="12" md="12" className="tableWrapper" >
                      
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                            <Col xs="12" >
                                {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}
                            
                                {/*<FormGroup>
                                    <Label for="gender">Select Payment Type*</Label>
                                    <Input type="select" onChange={this.setValue} name="cat">
                                        {catList}
                                    </Input>
                                </FormGroup>*/}

                                <FormGroup>
                                    <Label for="gender">Select Member*</Label>
                                    <Input type="select" onChange={this.setValue} name="staff">
                                        {catList}
                                    </Input>
                                </FormGroup>

                            </Col>
                    
                            <Col xs="12" >
                                {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}</div>:null}
                                <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Get Report </Button>
                            </Col>
                            {
                                this.state.getReport ===true? 
                                <Col xs="12" >
                                    <Button style={{ marginTop: 10 }}  onClick={this.generatePDF} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-success">Download Report </Button>
                                </Col>:null
                            }
                        </Row>
                        <div className='table-container '>
                            <br/><br/>
                            <h6>OPENING OUTSTANDING BALANCE</h6>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Loan</th>
                                        <th>Subscription</th>
                                        <th>Fines</th>
                                        <th>Welfare</th>
                                        <th>Total</th>
                                    
                                    </tr>
                                </thead>

                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {openData}
                                </tbody>}
                            </Table>
                            <br/><br/>
                            <h6>NEW DATA GENERATED WITH IN RANGE</h6>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Loan In Range</th>
                                        <th>Intrest In Range</th>
                                        <th>Fines In Range</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>

        

                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {gotData}
                                </tbody>}
                            </Table>
                            <br/><br/>
                            <h6>PAYMENTS</h6>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Name</th>
                                        <th>Paid Loan</th>
                                        <th>Paid Intrest</th>
                                        <th>Paid Subscription</th>
                                        <th>Paid Fine</th>
                                        <th>Paid Welfare</th>
                                        <th>Total</th>
                                    
                                    </tr>
                                </thead>

                
                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {paymentData}
                                </tbody>}
                            </Table>
                        </div>
                    </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    getReportData : state.mainReducer.getMyData,
    addUserStatus:state.mainReducer.getMyDataStatus,
});
export default connect(
    mapStateToProps, {getMainData,getData}
)(MemberReportAll)
