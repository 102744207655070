import React, { Component } from 'react'
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {addCategory,checkCategory,restAddCatIntials} from "../../Redux/ducks/mainReducer";
export class AddUserCategory extends Component {
  state = {
    catName:'',addAll:false,
    addCat:false,seeCat:false,editCat:false,deleteCat:false,
    addUser:false,seeUser:false,editUser:false,deleteUser:false,
    addProj:false,seeProj:false,editProj:false,stopProj:false,restartProj:false,
    addAct:false,seeAct:false,editAct:false,approveAct:false,deleteAct:false,
    fullRep:false,actRep:false,budgetRep:false,balRep:false,seeRec:false,
    erroMsg:'',
    successMsg:'',
    checkStatusM:null,
    isUploading: false,
    btnDisabled:false,

  };
  closeModel = e => {
    this.setState({model:false});
    //refresh by redirecting
    window.location.href = process.env.REACT_APP_FRONTEND_URL;
};
  
  componentDidUpdate(prevProps){
    const{addCatStatus,msg,checkCatStatus} = this.props;
    if(addCatStatus !== prevProps.addCatStatus){
        if(addCatStatus===true){
            this.setState({isUploading:false,btnDisabled:false,successMsg:msg,erroMsg:'',
              catName:'',addAll:false,
              addCat:false,seeCat:false,editCat:false,deleteCat:false,
              addUser:false,seeUser:false,editUser:false,deleteUser:false,
              addProj:false,seeProj:false,editProj:false,stopProj:false,restartProj:false,
              addAct:false,seeAct:false,editAct:false,approveAct:false,deleteAct:false,
              fullRep:false,actRep:false,budgetRep:false,balRep:false,seeRec:false
          })
        }else{
            this.setState({isUploading:false,btnDisabled:false,successMsg:'',erroMsg:msg})
        }
    }

    if(checkCatStatus !==prevProps.checkCatStatus){
      if(checkCatStatus===false){
        this.setState({checkStatusM:""})
      }
    }
  }

  /**add cat */
  addCat = e =>{
    this.setState({[e.target.name]:e.target.value})
  }

  /**single checkbox selected */
  boxClicked = e =>{
      if (this.state[e.target.name]===true){
          this.setState({[e.target.name]:false})
      }else{
          this.setState({[e.target.name]:true})
      }
  };

  /**selecting all checkboxes */
  allClicked = e =>{
    if(this.state[e.target.name]===true){
        this.setState({
            [e.target.name]:false,
            addCat:false,seeCat:false,editCat:false,deleteCat:false,
            addUser:false,seeUser:false,editUser:false,deleteUser:false,
            addProj:false,seeProj:false,editProj:false,stopProj:false,restartProj:false,
            addAct:false,seeAct:false,editAct:false,approveAct:false,deleteAct:false,
            fullRep:false,actRep:false,budgetRep:false,balRep:false,seeRec:false
        })
    }else{
        this.setState({
            [e.target.name]:true,
            addCat:true,seeCat:true,editCat:true,deleteCat:true,
            addUser:true,seeUser:true,editUser:true,deleteUser:true,
            addProj:true,seeProj:true,editProj:true,stopProj:true,restartProj:true,
            addAct:true,seeAct:true,editAct:true,approveAct:true,deleteAct:true,
            fullRep:true,actRep:true,budgetRep:true,balRep:true,seeRec:true
        })
    }
  }

  /**uploading */
  addCategory = e =>{
    alert("this feature is deactivated")
    /*
    this.setState({erroMsg:"",checkStatusM:null,successMsg:'',isUploading:false,btnDisabled:false})

    if(this.state.catName ===""){
        this.setState({erroMsg:"Please enter category"})
    }else if(/\s/.test(this.state.catName)){
        this.setState({erroMsg:"Category name must not contain spaces"})
    }else if(this.state.addCat===false&&this.state.seeCat===false&&this.state.editCat===false&&this.state.deleteCat===false&&
        this.state.addUser===false&&this.state.seeUser===false&&this.state.editUser===false&&this.state.deleteUser===false&&
        this.state.addProj===false&&this.state.seeProj===false&&this.state.editProj===false&&this.state.stopProj===false&&this.state.restartProj===false&&
        this.state.addAct===false&&this.state.seeAct===false&&this.state.editAct===false&&this.state.approveAct===false&&this.state.deleteAct===false&&
        this.state.fullRep===false&&this.state.actRep===false&&this.state.budgetRep===false&&this.state.balRep===false&&this.state.seeRec===false){
            this.setState({erroMsg:"Please add at least one right to  the new category"})
    }else{

        this.setState({erroMsg:"",isUploading:true,btnDisabled:true})
        /**check if cat name exists *
        this.props.checkCategory(this.state.catName,null);
        /**run this after two seconds *
        setTimeout(() => {
          if(this.props.checkCatStatus===true){
            this.setState({isUploading:false,btnDisabled:false,checkStatusM:this.props.checkCatMsg})
          }else{
             /**rest addCatIntials *
            this.props.restAddCatIntials()
            /**convert true to 1 and false to 0*
            this.props.addCategory(this.state.catName,
              this.state.addCat? 1 : 0,this.state.seeCat? 1 : 0,this.state.editCat? 1 : 0,this.state.deleteCat? 1 : 0,
              this.state.addUser? 1 : 0,this.state.seeUser? 1 : 0,this.state.editUser? 1 : 0,this.state.deleteUser? 1 : 0,
              this.state.addProj? 1 : 0,this.state.seeProj? 1 : 0,this.state.editProj? 1 : 0,this.state.stopProj? 1 : 0,this.state.restartProj? 1 : 0,
              this.state.addAct? 1 : 0,this.state.seeAct? 1 : 0,this.state.editAct? 1 : 0,this.state.approveAct? 1 : 0,this.state.deleteAct? 1 : 0,
              this.state.fullRep? 1 : 0,this.state.actRep? 1 : 0,this.state.budgetRep? 1 : 0,this.state.balRep? 1 : 0,this.state.seeRec? 1 : 0
              );
          }
        }, 2000);
    } */
  }

  render() {
    return (
    <div>
      <div className="container">
                <Row className="mainWrraper">
        <div className="block-header">
          <h4>ADD A NEW USER CATEGORY</h4>
        </div>
        <Col xs="12" className="mainWrraper">
          
          <FormGroup className="catName">
            <Label>Category Name</Label>
            <Input type="text" className="formInput catName" name="catName" value={this.state.catName} onChange={this.addCat}/>
          </FormGroup>

          <FormGroup>
            <Input type="checkbox" className="filled-in" id="acc1" name="addAll" onChange={this.allClicked} checked={this.state.addAll}/>    
            <Label>Select All</Label>
          </FormGroup>

          <FormGroup>
            <Label className="rightsTitle">Category rights</Label>
            <Row>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ac1" name="addCat" onChange={this.boxClicked} checked={this.state.addCat}/>
                  <Label >Add Category</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ac2" name="seeCat" onChange={this.boxClicked} checked={this.state.seeCat}/>
                  <Label>View Category</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ac3" name="editCat" onChange={this.boxClicked} checked={this.state.editCat}/>
                  <Label>Edit Category</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ac4" name="deleteCat" onChange={this.boxClicked} checked={this.state.deleteCat}/>
                  <Label>Delete Category</Label>
                </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label className="rightsTitle">User rights</Label>
            <Row>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="au1" name="addUser" onChange={this.boxClicked} checked={this.state.addUser}/>
                  <Label >Add Users</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="au2" name="seeUser" onChange={this.boxClicked} checked={this.state.seeUser}/>
                  <Label>View Users</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="au3" name="editUser" onChange={this.boxClicked} checked={this.state.editUser}/>
                  <Label>Edit Users</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="au4" name="deleteUser" onChange={this.boxClicked} checked={this.state.deleteUser}/>
                  <Label>Delete Users</Label>
                </Col>
            </Row>
          </FormGroup>


          <FormGroup>
            <Label className="rightsTitle">Project rights</Label>
            <Row>
                <Col xs="2">
                <Input type="checkbox" className="filled-in" id="ap1" name="addProj" onChange={this.boxClicked} checked={this.state.addProj}/>
                  <Label >Add Project</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="ap2" name="seeProj" onChange={this.boxClicked} checked={this.state.seeProj}/>
                  <Label>View Project</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="ap3" name="editProj" onChange={this.boxClicked} checked={this.state.editProj}/>
                  <Label>Edit Project</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="ap4" name="stopProj" onChange={this.boxClicked} checked={this.state.stopProj}/>
                  <Label>Stop Project</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="ap5" name="restartProj" onChange={this.boxClicked} checked={this.state.restartProj}/>
                  <Label>Restart Project</Label>
                </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label className="rightsTitle">Activity rights</Label>
            <Row>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="aa1" name="addAct" onChange={this.boxClicked} checked={this.state.addAct}/>
                  <Label >Add Activity</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="aa2" name="seeAct" onChange={this.boxClicked} checked={this.state.seeAct}/>
                  <Label>View Activity</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="aa3" name="editAct" onChange={this.boxClicked} checked={this.state.editAct}/>
                  <Label>Edit Activity</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="aa4" name="approveAct" onChange={this.boxClicked} checked={this.state.approveAct}/>
                  <Label>Approve Activity</Label>
                </Col>
                <Col xs="2">
                  <Input type="checkbox" className="filled-in" id="aa5" name="deleteAct" onChange={this.boxClicked} checked={this.state.deleteAct}/>
                  <Label>Delete Activity</Label>
                </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label className="rightsTitle">Report rights</Label>
            <Row>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ar1" name="fullRep" onChange={this.boxClicked} checked={this.state.fullRep}/>
                  <Label >Full Report</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ar2" name="actRep" onChange={this.boxClicked} checked={this.state.actRep}/>
                  <Label>Activity Report</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ar3" name="budgetRep" onChange={this.boxClicked} checked={this.state.budgetRep}/>
                  <Label>Budget Report</Label>
                </Col>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ar4" name="balRep" onChange={this.boxClicked} checked={this.state.balRep}/>
                  <Label>Balance Sheet Report</Label>
                </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label className="rightsTitle">Receipt rights</Label>
            <Row>
                <Col xs="3">
                  <Input type="checkbox" className="filled-in" id="ar11" name="seeRec" onChange={this.boxClicked} checked={this.state.seeRec}/>
                  <Label >See Receipt</Label>
                </Col>
                <Col xs="9"></Col>
            </Row>
          </FormGroup>

          <FormGroup>
          {this.state.successMsg?<Alert color="success">{this.state.successMsg}</Alert>:null}
          {this.state.erroMsg?<Alert color="danger">{this.state.erroMsg}</Alert>:null}
          {this.state.checkStatusM?<Alert color="danger">{this.state.checkStatusM}</Alert>:null}
          {this.state.isUploading?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Uploading...'}</div>:null}
            
            <Row>
              <Col xs="4"></Col>
              <Col xs="4">
                <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">ADD CATEGORY</Button>
              </Col>
              <Col xs="4"></Col>
            </Row>
          </FormGroup>    
        </Col>
      </Row>
    </div>
    </div>
    )
  }
}
const mapStateToProps = state =>({
  addCatStatus : state.mainReducer.addCatStatus,
  msg : state.mainReducer.msg,
  checkCatStatus : state.mainReducer.checkCatStatus,
  checkCatMsg : state.mainReducer.checkCatMsg,
});
export default connect(
  mapStateToProps, {addCategory,checkCategory,restAddCatIntials}
)(AddUserCategory)
