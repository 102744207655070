import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table,Modal,
    ModalBody, ModalFooter,ModalHeader} from 'reactstrap'
import {checkRight,getName2} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
export class AddLoanType extends Component {
    state={
        isSaving:false,
        isSaving2:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        isLoading:true,
        alertStatus :false, alertType :"error", alertTitle:null,
        name:"",rate:"",modal:false,
        status:"",statusList:[],name1:"",rate1:"",date1:"",updatedBy1:"",

    }

    componentDidMount(){
        /**refresh categories */
        this.props.getMainData("LOANTYPES")
    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData,isLoading:false})
            }else if(mainDataStatus===false){
                this.setState({isSaving:false,isLoading:false})
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,isSaving2:false,successMsg:msg,btnDisabled:false,alertStatus :true, alertType :"success", alertTitle:msg})

                /**refresh categories */
                this.props.getMainData("LOANTYPES")
            }else if(addUserStatus===false){
                this.setState({isSaving:false,isSaving2:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    addCategory =(e)=>{
        if(checkRight(13)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{
            const {name,rate} = this.state;

            if(name===""||rate===""){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
            }else if(parseInt(rate)<1){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Intrest rate amount must not be less than one"})
            }else if(parseInt(rate)>100){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Intrest rate amount must not exceed 100"})
            }else{
                this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
                const data = new FormData();
                data.append("name",name);
                data.append("rate",rate);
    
                /**upload new user*/
                this.props.addUser(data,"ADD_LOANTYPE")
            }
        }
    }

    viewUser=(e)=>{
  
        const selectedID = e.target.id;
        this.setState({modal:true,selectedID:selectedID})
        const myUserData =  this.state.mainData.loanTypeData
        let catList =[]
        myUserData.map((values, i) =>{
            if(parseInt(values.id) ===parseInt(selectedID)){
                if(parseInt(values.status)===1){
                    catList.push(<option key={i} value={values.status}>{"Active"}</option>)
                    catList.push(<option value={0}>{"Innactive"}</option>)
                }else{
                    catList.push(<option key={i} value={values.status}>{"Innactive"}</option>)
                    catList.push(<option value={1}>{"Active"}</option>)
                }

               
                this.setState({
                    status:values.status,
                    statusList:catList,
                    name1:values.name,
                    rate1:values.rate,
                    date1:values.updated_at,
                    updatedBy1:values.added_by,
                })

            }
        });  
    }

    closeModal = ()=>{
        this.props.getMainData("LOANTYPES")
        this.setState({modal:false})
    }

    changeFee=(e)=>{
        if(checkRight(14)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{
            const {name1,rate1,status,selectedID} = this.state;
            if(name1===""||rate1===""){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
            }else if(parseInt(rate1)<1){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Intrest rate amount must not be less than one"})
            }else if(parseInt(rate1)>100){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Intrest rate amount must not exceed 100"})
            }else{
                this.setState({isSaving2:true})
                const data = new FormData();
                data.append("updateID",selectedID);
                data.append("status",status);
                data.append("name",name1);
                data.append("rate",rate1);
            
                this.props.addUser(data,"UPDATE_LOANTYPE")
            }
        }
    }

    render() {
        
        let expenseCatDataList = [];
        const myUserData =  this.state.mainData
        var no =0
        if(myUserData !==null){
            if(myUserData.loanTypeData.length>0){
                myUserData.loanTypeData.map((values, i) =>{
                    no = no+1
                    expenseCatDataList.push(
                        <tr key={i}>

                            <th scope="row">{no}</th>
                            <td>{values.name}</td>
                          
                            <td>{parseInt(values.rate).toLocaleString()}</td>
                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                            <td><Label className="status2">Inactive</Label></td>
                            } 
                            <td>{getName2(myUserData.staffData,values.added_by,"STAFF")}</td>
                            <td>{values.updated_at}</td>
                            <td><Button id={values.id} onClick={this.viewUser}>Edit</Button></td>
                        </tr>
                    )
                });
            }else{
                expenseCatDataList.push(
                    <div>
                        <br/><Alert color="primary">No laon types added yet</Alert>
                    </div>
                )
            }
        }
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>ADD LOAN TYPE</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Update Loan Type</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                <Col xs="12" >
                                <FormGroup>
                                    <Label for="username">Name*</Label>
                                    <Input type="text" onChange={this.setValue} value={this.state.name1} name="name1" />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="username">Intrest Rate*</Label>
                                    <Input type="number" min="1" onChange={this.setValue} value={this.state.rate1} name="rate1" />
                                </FormGroup>

                               
                                <FormGroup>
                                    <Label for="username">Updated By</Label>
                                    <Input type="text"  disabled  value={getName2(this.state.mainData.staffData,this.state.updatedBy1,"STAFF")} />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="username">Updated At</Label>
                                    <Input type="text" disabled   value={this.state.date1}/>
                                </FormGroup>
                                

                                <FormGroup>
                                    <Label for="gender">Select Status</Label>
                                    <Input type="select" onChange={this.setValue} name="status">
                                        {this.state.statusList}
                                    </Input>
                                </FormGroup>

                                </Col>
                            
                    
                                <Col xs="12" >
                                    {this.state.isSaving2?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Updating...'}</div>:null}
                                    <Button onClick={this.changeFee} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Update Loan Type</Button>
                                </Col>
                            </ModalBody>
                        </Modal>
                        :null}
                        
                        
                        
                        
                        <Col xs="12" md="8">
                            <FormGroup>
                                <Label for="username">Name*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.name} name="name" />
                            </FormGroup>
                        </Col>
                        <Col xs="12" md="4">
                            <FormGroup>
                                <Label for="username">Intrest Rate*</Label>
                                <Input type="number" min="1" onChange={this.setValue} value={this.state.rate} name="rate" />
                            </FormGroup>
                        </Col>
                        
                  
                        <Col xs="12" >
                        
                            {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
        
                            <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Add Loan Type</Button>
                        </Col>

                       

                        <Col xs="12" md="12" className="tableWrapper">
                            <div className="table-container">
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Loan Type</th>
                                            <th>Intrest Rate</th>
                                            <th>Status</th>
                                            <th>Updated By</th>
                                            <th>Updated At</th>
                                        
                                            <th>Edit</th>
                                        </tr>
                                    </thead>
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                        {expenseCatDataList}
                                    </tbody>}
                                </Table>
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(AddLoanType)
