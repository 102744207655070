import React from 'react';

export const getName =(data,dataID) =>{
    var className = ""
    if(data!==null){
        //loop through the array
        if(data.length>0){
            data.map((values,i) =>{
                if (parseInt(values.id) === parseInt(dataID)){
                    className = values.name
                }
            });
        }
    }
    return className
}

export const getName2 =(data,dataID,action) =>{
    var className = ""
    if(data!==null){
        //loop through the array
        if(data.length>0){
            data.map((values,i) =>{
                if(action ==="EDIT"){
                    if (values.account === dataID){
                        className = values.name
                    }
                }else if(action ==="ACCOUNT"){
                    if (parseInt(values.catID) === parseInt(dataID)){
                        className = values.catName
                    }
                }else if(action ==="LOAN"){
                    if (values.loanID === dataID){
                        let datesLit = []
                        datesLit.push(values.startDate)
                        datesLit.push(values.endPayDate)
                        datesLit.push(values.advancedPayDates)
                        datesLit.push(values.installments)
                        datesLit.push(values.closed_At)
                        datesLit.push(values.status)
                        datesLit.push(values.payments)
                        datesLit.push(values.loanAmount)

                        className = datesLit
                    }
                }else{
                    if (parseInt(values.id) === parseInt(dataID)){
                        if(action ==="TERM"){
                            className = values.termName
                        }
    
                        if(action ==="STAFF"){
                            className = values.firstName+" ("+values.clubNumber+")"
                        }
    
                        if(action ==="CLIENT"){
                            className = values.name
                        }
                       
                    }
                }
               
            });
        }
    }
    return className
}

//work on dates 
export const datesDiffe =(date11,date22,action) =>{
    const date1 = new Date(date11);
    const date2 = new Date(date22);
    const diffTime = Math.abs(date2 - date1);
    var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

    /**check its negative date */
    if(date1.getTime()>date2.getTime()){
        diffDays = diffDays*-1
    }

    return diffDays;
}

export const isDateInSelectedRage =(date11,date22,dateToCheck) =>{
    const start = new Date(date11);
    const end = new Date(date22);
    let searchDate = new Date(dateToCheck);
  
    if(dateToCheck.length>10){
        const ddnew = dateToCheck.slice(0, 10);
        searchDate = new Date(ddnew);
    }
    
    if (searchDate >= start && searchDate <= end ){
        return true
    }else{
        return false
    }
}

//
export const getArraysIntersection=(a1,a2)=>{
    //returns Intersection ['a','b']
    return  a1.filter(function(n) { return a2.indexOf(n) !== -1;});
}
//getFine
export const getFine =(data,loanID,payCode) =>{
    var fine = 0
    if(data!==null){
        //loop through the array
        if(data.length>0){
            data.map((values,i) =>{
                if (values.loanID ===loanID && values.payCode ===payCode){
                    fine = parseInt(fine)+parseInt(values.amount)
                }
            });
        }
    }
    return fine
}

//checkRight
export const checkRight =(rightID) =>{
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const rightsArray = userD.rights

    let rightStatus = false
    if(rightsArray!==null){
        rightsArray.map((values,i) =>{
            if(parseInt(values)===parseInt(rightID)){
                rightStatus = true
            }
        });
        
    }
    return rightStatus
}

//checkRight
export const checkCategoryRight =(rightCatID) =>{
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const rightsCatArray = userD.userRightsCategory

    let rightStatus = false
    if(rightsCatArray!==null){
        rightsCatArray.map((values,i) =>{
            if(parseInt(values)===parseInt(rightCatID)){
                rightStatus = true
            }
        });
        
    }
    return rightStatus
}

export const getLastPayDate =(payData,loanID,startPay,paidInAdvance) =>{
    var datePaid = startPay
    if(payData!==null){
        //loop through the array
        let datesArray =[]
        if(payData.length>0){
            payData.map((values,i) =>{
                if(values.loanID ===loanID){
                    datesArray.push(values.payDate) 
                }
            });
        }

        if(paidInAdvance !=="[]"){     
            var array = JSON.parse(paidInAdvance)
            array.map((values2,i) =>{
                datesArray.push(values2)
            })
        }

        if(datesArray.length>0){
         
            //get biggest date 
            var maxDate = new Date(Math.max(...datesArray.map(date => new Date(date))));
            let realMaxDate = maxDate.toISOString().split('T')[0]
            datePaid = realMaxDate
        }  
    }
    return datePaid
}

function isSunday(date) {
    return new Date(date).getDay() === 0;
}
  
export const getEndDate =(startDate)=> {
    const daysToAdd = 30;
    let currentDate = new Date(startDate);
    let daysAdded = 0;
  
    while (daysAdded < daysToAdd) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(nextDate.getDate() + 1);
      
      if (!isSunday(nextDate)) {
        currentDate = nextDate;
        daysAdded++;
      } else {
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }
  
    return currentDate.toISOString().slice(0, 10);
}

export const getMonth =(month)=> {
    let monthArray = ["Month","January", "February", "March", "April",  "May", "June", "July", "August",  "September", "October", "November", "December"]
    return monthArray[month]
}

export const generateDates=(startDate, endDate)=> {
    const dates = [];
    const current = new Date(startDate);
    const lastDate = new Date(endDate);
    
  
    while (current <= lastDate) {
        //console.log(new Date(current))
        const formattedDate = current.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
        const parts = formattedDate.split('/');
        const convertedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
        dates.push(convertedDate);
        current.setDate(current.getDate() + 1);
    }
  
    return dates;
}

export const isNumber=(value)=> {
    // returns true , false
    const regex = /[a-zA-Z]/; // This regular expression matches any lowercase or uppercase letter
    if(regex.test(value) ===false){
        return true
    }else{
        return false
    }
}

export const getLevel=(levelStatus)=>{
    const levelStatus2 = parseInt(levelStatus)
    if(levelStatus2 ===1){
        return <b style={{color:"black"}}>PENDING</b>
    }
    if(levelStatus2 ===2){
        return <b style={{color:"blue"}}>FIELD REPORT</b>
    }
    if(levelStatus2 ===3){
        return <b style={{color:"red"}}>REJECTED</b>
    }
    if(levelStatus2 ===4){
        return <b style={{color:"green"}}>APPROVED</b>
    }
    if(levelStatus2 ===5){
        return <b style={{color:"red"}}>EXPIRED</b>
    }
}




