import React, { Component } from 'react'
import {Link,Redirect} from "react-router-dom";
import {getName2,getLevel} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import {Alert,Label,Row,Col,Table,FormGroup,Input, Button,
    Spinner} from 'reactstrap'
import {getFine} from "../../GeneralFunctions/general"
export class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertStatus :false, alertType :"error", alertTitle:null,
            clientDetailsData:this.props.mainData,
            searchloanID: "",paymentDataList:[]
        };
    
    }
    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**search loan paymen */
    searchLoanPayment=()=>{
        const {searchloanID,clientDetailsData} = this.state
        if(searchloanID===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select loan ID to search",paymentDataList:[]})
      
        }else{
            let paymentDataList = []
            const myStaffData1 =  clientDetailsData
            if(myStaffData1 !==null){
                /**get payments */
                var no = 0
                var loanBalance =0

                clientDetailsData.loanData.map((values1, i) =>{
                    if(searchloanID ===values1.loanID.toUpperCase()){
                        loanBalance = values1.expectedAmount

                        let payData = values1.payments
                        let finePaymentData = values1.paidfines
                        if(payData.length>0){
                            payData.map((values, i) =>{
                                no = no+1
                                loanBalance = loanBalance-values.amount
                                paymentDataList.push(
                                    <tr key={i}>
                                        <td>{no}</td>
                                        <td>{values.id}</td>
                                        <td>{values.loanID}</td>
                                        <td>{values.bookNo}</td>
                                        <td>{values.amount.toLocaleString()}</td>
                                        <td>{getFine(finePaymentData,values.loanID,values.payCode).toLocaleString()}</td>
                                        <td>{loanBalance.toLocaleString()}</td>
                                        <td>{values.payDate}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                                
                            })
                        }else{
                            paymentDataList.push(
                                <div>
                                    <br/><Alert color="primary">No payments made on the selected loan </Alert>
                                </div>
                            )
                        }
                    }
                })

                this.setState({
                    paymentDataList :paymentDataList
                })
            }
        }
    }

    render() {
        const {clientDetailsData} = this.state
        let loanIDsList = [];
        const myStaffData1 =  clientDetailsData
        if(myStaffData1 !==null){
            /**loanIDs */
            const myloanData2 =  clientDetailsData.loanData
            if(myloanData2.length>0){
                loanIDsList.push(
                    <option value="">Select Loan ID</option>
                )
                myloanData2.map((values, i) =>{
                    loanIDsList.push(
                        <option value={values.loanID}>{values.loanID}</option>
                    )
                });
            }
        }
        return (
            <div>
                <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                <Row style={{"margin-bottom":"10px"}}>
                    <Col xs="12" md="12" className="text-center myName">
                        <h6>{"Loan Payment History"}</h6>
                    </Col>
                    <Col xs="12" md="6">
                        <Input type="select" onChange={this.setValue} className="loanBox" name="searchloanID">
                            {loanIDsList}
                        </Input>
                    </Col>
                   
                    <Col xs="12" md="6">
                        <Button onClick={this.searchLoanPayment} style={{fontSize:12}} className="btn btn-secondary showPayDetailsBtn">SEARCH PAYMENTS</Button>
                    </Col>

                    <Col xs="12" md="12">
                        <FormGroup className="loanBox2">
                            {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                        </FormGroup>
                        <div className='table-container2'>
                            <Table striped responsive>
                                <thead >
                                    <tr style={{"background":"#6c757d"}}>
                                        <th>No</th>
                                        <th>ID</th>
                                        <th>LoanID</th>
                                        <th>Book No</th>
                                        <th>Loan Amount</th>
                                        <th>Fine Amount</th>
                                        <th>Loan Balance</th>
                                        <th>Paid At</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {this.state.paymentDataList}
                                </tbody>
                            </Table>
                        </div>

                    </Col>
                </Row>
                <hr/>
            </div>
        )
    }
}

export default (PaymentHistory)