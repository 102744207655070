import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {checkRight} from "../../GeneralFunctions/general"
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
export class RenewalSettings extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        updateID:null,
        renewAfter:null,
        renewalTimes:null,
        renewRate:null,
        allowProcessing:null,
        allowRate:null

    }

    componentDidMount(){
        const{mainData,msg,mainDataStatus} = this.props;
        /**refresh categories */
        this.props.getMainData("STAFFREGDATA")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData,
                updateID:mainData.RenewalSetting.id,
                renewAfter:mainData.RenewalSetting.renewalAfter,
                renewalTimes:mainData.RenewalSetting.maxRenewalTimes,
                renewRate:mainData.RenewalSetting.renewalRate,
                allowProcessing:mainData.RenewalSetting.allowProcessingFee,
                allowRate:mainData.RenewalSetting.allowRenewalIntrest
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg
                })
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false})
            }
        } 
    }

    

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    updateSettings =(e)=>{
        const {renewAfter,updateID,renewalTimes,renewRate,allowProcessing,allowRate} = this.state;
        if(checkRight(2)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{
            if(updateID ===null){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Something wentrong, please refresh page"})

            }else if(parseInt(renewAfter)<1){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Generate Renewal Request After' must be greater than zero"})

            }else if(parseInt(renewalTimes)<1){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Maxamum Renewal Times' must be greater than zero"})

            }else if(parseInt(renewRate) <0 || parseInt(renewRate)>100){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Renewal Intrest Rate ' must be between zero and 100"})

            }else{
                this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
            
                const data = new FormData();
                data.append("updateID",updateID);
                data.append("renewAfter",renewAfter);
                data.append("renewalTimes",renewalTimes);
                data.append("renewRate",renewRate);
                data.append("allowProcessing",allowProcessing);
                data.append("allowRate",allowRate);
        
                /**upload new user*/
                this.props.addUser(data,"UPDATE_RENEWAL_SETTINGS")
            }
        }
    }
    render() {

        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>UPDATE LOAN RENEWAL SETTINGS</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        <Col xs="12" >
                            {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}

                            <FormGroup>
                                <Label for="username">Generate Renewal Request After(Days)</Label>
                                <Input type="number" min="1" onChange={this.setValue} value={this.state.renewAfter} name="renewAfter" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Maxamum Renewal Times</Label>
                                <Input type="number" min="1" onChange={this.setValue} value={this.state.renewalTimes} name="renewalTimes" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Renewal Intrest Rate (%)</Label>
                                <Input type="number" min="0" max="100" onChange={this.setValue} value={this.state.renewRate} name="renewRate" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Allow Renwal Intrest Rate</Label>
                                
                                <Input type="select" onChange={this.setValue} name="allowRate">
                                    <option value={this.state.allowRate}>{parseInt(this.state.allowRate) ===1? "YES" :"NO" }</option> 
                                    <option value="1">YES</option> 
                                    <option value="0">No</option> 
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Add Processing Fee</Label>

                                <Input type="select" onChange={this.setValue} name="allowProcessing">
                                    <option value={this.state.allowProcessing}>{parseInt(this.state.allowProcessing) ===1? "YES" :"NO" }</option> 
                                    <option value="1">YES</option> 
                                    <option value="0">No</option> 
                                </Input>
                            </FormGroup>
                        </Col>
                  
                    <Col xs="12" >
                     
                        {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
      
                        <Button onClick={this.updateSettings} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Update Settings</Button>
                    </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(RenewalSettings)
