import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {getData} from "../../Redux/ducks/mainReducer";
import {getName2,datesDiffe,isDateInSelectedRage} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
export class SeeFines extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,
       
        alertStatus :false, alertType :"error", alertTitle:null,

        fineAction:"GIVEN",

        closedLoanDataList:[],myNo:0,amountGiven:0,totalBalance:0
  

    }
  
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,mainData:userData})

                let activeLoanDataList = [];
                let myNo = 0
                let amountGiven = 0
                if(userData !==null){
                    if(this.state.fineAction ==="GIVEN"){
                        const myUserData =  userData.fineData
                        if(myUserData.length>0){
                            myUserData.map((values, i) =>{
                               
                                myNo = myNo+1
                                amountGiven = amountGiven+parseInt(values.amount)
                                
                                activeLoanDataList.push(
                                    <tr key={i}>
                                        <td>{myNo}</td>
                                        <th>{this.state.fineAction}</th>
                                        <th>{values.loanID}</th>
                                        <td>{values.loan["clientName"]}</td>
                                        <td>{values.amount}</td>
                                        <td>{values.fineType}</td>
                                        <td>{values.finedFrom}</td>
                                        <td>{values.finedTo}</td>
                                        <td>{getName2(userData.staffData,values.added_by,"STAFF")}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                                    
                                
                            });
                        }
                    }
                    
                }

                if (activeLoanDataList.length>0){
                    this.setState({closedLoanDataList:activeLoanDataList,isLoading:false,
                    amountGiven:amountGiven,myNo:myNo
                    })
                }

            }else if(userDataStatus===false){
                this.setState({isLoading:true,mainData:null,closedLoanDataList:[],alertStatus :true, alertType :"info", alertTitle:msg})
            }
        }  
    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        const {mainData} = this.state
        this.setState({[e.target.name]:e.target.value,closedLoanDataList:[]})

        if(e.target.name==="fineAction"){
            if(e.target.value.toString()==="GIVEN"){
           
                let activeLoanDataList = [];
                let myNo = 0
                let amountGiven = 0
                if(mainData !==null){
                    const myUserData =  mainData.fineData
                    if(myUserData.length>0){
                        myUserData.map((values, i) =>{
                            
                            myNo = myNo+1
                            amountGiven = amountGiven+parseInt(values.amount)
                            
                            activeLoanDataList.push(
                                <tr key={i}>
                                    <td>{myNo}</td>
                                    <th>{e.target.value.toString()}</th>
                                    <th>{values.loanID}</th>
                                    <td>{values.loan["clientName"]}</td>
                                    <td>{values.amount}</td>
                                    <td>{values.fineType}</td>
                                    <td>{values.finedFrom}</td>
                                    <td>{values.finedTo}</td>
                                    <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                    <td>{values.created_at}</td>
                                </tr>
                            )
                        });
                    }
                }

                if(activeLoanDataList.length>0){
                    this.setState({closedLoanDataList:activeLoanDataList,isLoading:false,amountGiven:amountGiven,myNo:myNo})
                }else{
                    this.setState({closedLoanDataList:[],isLoading:false,
                        amountGiven:0,myNo:0,alertStatus :true, alertType :"error", alertTitle:"No given/ entered fines in the selected range"
                    })
                }
            }else if(e.target.value.toString() ==="FORGIVEN"){
                let activeLoanDataList = [];
                let myNo = 0
                let amountGiven = 0
                if(mainData !==null){
                    const myUserData =  mainData.finePaymentData
                    if(myUserData.length>0){
                        myUserData.map((values, i) =>{
                            if(parseInt(values.forgiven)===1){
                                myNo = myNo+1
                                amountGiven = amountGiven+parseInt(values.amount)
                                activeLoanDataList.push(
                                    <tr key={i}>
                                        <td>{myNo}</td>
                                        <th>{e.target.value.toString()}</th>
                                        <th>{values.loanID}</th>
                                        <td>{values.loan["clientName"]}</td>
                                        <td>{values.amount}</td>
                                        <td>{values.payCode}</td>
                                        <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                        <td>{values.payDate}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            }
                        
                        });
                    }
                }

                if(activeLoanDataList.length>0){
                    this.setState({closedLoanDataList:activeLoanDataList,isLoading:false,amountGiven:amountGiven,myNo:myNo})
                }else{
                    this.setState({closedLoanDataList:[],isLoading:false,
                        amountGiven:0,myNo:0,alertStatus :true, alertType :"error", alertTitle:"No forgiven fines in the selected range"
                    })
                }

            }else if(e.target.value.toString()==="PAID"){
                let activeLoanDataList = [];
                let myNo = 0
                let amountGiven = 0
                if(mainData !==null){
                    const myUserData =  mainData.finePaymentData
                    if(myUserData.length>0){
                        myUserData.map((values, i) =>{
                            if(parseInt(values.forgiven)===0){
                                myNo = myNo+1
                                amountGiven = amountGiven+parseInt(values.amount)
                                activeLoanDataList.push(
                                    <tr key={i}>
                                        <td>{myNo}</td>
                                        <th>{e.target.value.toString()}</th>
                                        <th>{values.loanID}</th>
                                        <td>{values.loan["clientName"]}</td>
                                        <td>{values.amount}</td>
                                        <td>{values.payCode}</td>
                                        <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                        <td>{values.payDate}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            }
                        
                        });
                    }
                }

                if(activeLoanDataList.length>0){
                    this.setState({closedLoanDataList:activeLoanDataList,isLoading:false,amountGiven:amountGiven,myNo:myNo})
                }else{
                    this.setState({closedLoanDataList:[],isLoading:false,
                        amountGiven:0,myNo:0,alertStatus :true, alertType :"error", alertTitle:"No paid fines in the selected range"
                    })
                }
            }

        }
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt} = this.state

        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
            this.setState({isLoading:true})
            const data = new FormData();
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            this.props.getData(data,"GETFINES")
            
        }
    }

    render() {
        const { redirect,redirectSelectedID,closedLoanDataList,startFrom,endAt,myNo,amountGiven,totalBalance,fineAction,mainData } = this.state;

        if (redirect) {
            return <Redirect to={{pathname:"/see_clients", state:{redirectSelectedID:redirectSelectedID}}}/>
        }
        /**displaying  users*/
        let activeLoanDataList = closedLoanDataList;

        let myNo1 = myNo
        let amountGiven1 = amountGiven
     
    
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
    
        if (searchword) {
            if(activeLoanDataList.length>0){
                if(this.state.mainData !==null){
                    if(fineAction ==="GIVEN"){
                        const myUserData =  mainData.fineData
                        if(myUserData.length>0){
                            myNo1 = 0
                            amountGiven1=0
                            activeLoanDataList = []
                            myUserData.map((values, i) =>{
                                if(searchAction ==="LOANID"){
                                    if(values.loanID === searchword.toUpperCase() ){
                                        myNo1 = myNo1+1
                                        amountGiven1 = amountGiven1+parseInt(values.amount)
                                        
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo1}</td>
                                                <th>{fineAction}</th>
                                                <th>{values.loanID}</th>
                                                <td>{values.loan["clientName"]}</td>
                                                <td>{values.amount}</td>
                                                <td>{values.fineType}</td>
                                                <td>{values.finedFrom}</td>
                                                <td>{values.finedTo}</td>
                                                <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }else if(searchAction ==="NAME"){
                                    var myarray = values.loan["clientName"].split(' ');
                                    if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() || values.loan["clientName"] === searchword.toUpperCase()){
                                        myNo1 = myNo1+1
                                        amountGiven1 = amountGiven1+parseInt(values.amount)
                                        
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo1}</td>
                                                <th>{fineAction}</th>
                                                <th>{values.loanID}</th>
                                                <td>{values.loan["clientName"]}</td>
                                                <td>{values.amount}</td>
                                                <td>{values.fineType}</td>
                                                <td>{values.finedFrom}</td>
                                                <td>{values.finedTo}</td>
                                                <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }
                            })
                        }
                    }else if(fineAction ==="PAID"){
                        const myUserData =  mainData.finePaymentData
                        if(myUserData.length>0){
                            myNo1 = 0
                            amountGiven1=0
                            activeLoanDataList = []
                            myUserData.map((values, i) =>{
                                if(searchAction ==="LOANID"){
                                    if(values.loanID === searchword.toUpperCase() ){
                                        if(parseInt(values.forgiven)===0){
                                            myNo1 = myNo1+1
                                            amountGiven1 = amountGiven1+parseInt(values.amount)
                                            activeLoanDataList.push(
                                                <tr key={i}>
                                                    <td>{myNo1}</td>
                                                    <th>{fineAction}</th>
                                                    <th>{values.loanID}</th>
                                                    <td>{values.loan["clientName"]}</td>
                                                    <td>{values.amount}</td>
                                                    <td>{values.payCode}</td>
                                                    <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                                    <td>{values.payDate}</td>
                                                    <td>{values.created_at}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                }else if(searchAction ==="NAME"){
                                    var myarray = values.loan["clientName"].split(' ');
                                    if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() || values.loan["clientName"] === searchword.toUpperCase()){
                                        if(parseInt(values.forgiven)===0){
                                            myNo1 = myNo1+1
                                            amountGiven1 = amountGiven1+parseInt(values.amount)
                                            activeLoanDataList.push(
                                                <tr key={i}>
                                                    <td>{myNo1}</td>
                                                    <th>{fineAction}</th>
                                                    <th>{values.loanID}</th>
                                                    <td>{values.loan["clientName"]}</td>
                                                    <td>{values.amount}</td>
                                                    <td>{values.payCode}</td>
                                                    <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                                    <td>{values.payDate}</td>
                                                    <td>{values.created_at}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                }
                            })
                        }
                    }else if(fineAction ==="FORGIVEN"){
                        const myUserData =  mainData.finePaymentData
                        if(myUserData.length>0){
                            myNo1 = 0
                            amountGiven1=0
                            activeLoanDataList = []
                            myUserData.map((values, i) =>{
                                if(searchAction ==="LOANID"){
                                    if(values.loanID === searchword.toUpperCase() ){
                                        if(parseInt(values.forgiven)===1){
                                            myNo1 = myNo1+1
                                            amountGiven1 = amountGiven1+parseInt(values.amount)
                                            activeLoanDataList.push(
                                                <tr key={i}>
                                                    <td>{myNo1}</td>
                                                    <th>{fineAction}</th>
                                                    <th>{values.loanID}</th>
                                                    <td>{values.loan["clientName"]}</td>
                                                    <td>{values.amount}</td>
                                                    <td>{values.payCode}</td>
                                                    <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                                    <td>{values.payDate}</td>
                                                    <td>{values.created_at}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                }else if(searchAction ==="NAME"){
                                    var myarray = values.loan["clientName"].split(' ');
                                    if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() || values.loan["clientName"] === searchword.toUpperCase()){
                                        if(parseInt(values.forgiven)===1){
                                            myNo1 = myNo1+1
                                            amountGiven1 = amountGiven1+parseInt(values.amount)
                                            activeLoanDataList.push(
                                                <tr key={i}>
                                                    <td>{myNo1}</td>
                                                    <th>{fineAction}</th>
                                                    <th>{values.loanID}</th>
                                                    <td>{values.loan["clientName"]}</td>
                                                    <td>{values.amount}</td>
                                                    <td>{values.payCode}</td>
                                                    <td>{getName2(mainData.staffData,values.added_by,"STAFF")}</td>
                                                    <td>{values.payDate}</td>
                                                    <td>{values.created_at}</td>
                                                </tr>
                                            )
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            }else{
                alert("No data found, Consider setting search range")
            }
        }
        
        return (
            <div>
               
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW FINES</h4>
                
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Col xs="12" md="12" className="tableWrapper">
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>

                            <Col md="12" xs="12">
                                    <Label className='balB'> TOTAL AMOUNT GIVEN :{amountGiven1.toLocaleString()}</Label>
                            </Col>
                        </Row>
                        <div className='table-container'>
                            <Table striped responsive>
                            

                                {this.state.fineAction ==="PAID" ||this.state.fineAction ==="FORGIVEN"?
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Fine Action</th>
                                            <th>loanID</th>
                                            <th>ClientName</th>
                                            <th>Amount</th>
                                            <th>Pay Code</th>
                                            <th>Added By</th>
                                            <th>Pay Date</th>
                                            <th>Created At</th>
                                        </tr>
                                        <tr>
                                        
                                            <th></th>
                                            <th><Input type="select" name="fineAction" onChange={this.setValue}>
                                                    <option value="GIVEN">GIVEN FINES</option>
                                                    <option value="PAID">PAID FINES</option>
                                                    <option value="FORGIVEN">FORGIVEN FINES</option>
                                                </Input>
                                            </th>
                                            <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                            <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            
                                        </tr>


                                    </thead>
                                :

                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Fine Action</th>
                                            <th>loanID</th>
                                            <th>ClientName</th>
                                            <th>Amount</th>
                                            <th>Fine Type</th>
                                            <th>Fined From</th>
                                            <th>Fined To</th>
                                            <th>Added By</th>
                                            <th>Created At</th>

                                        </tr>
                                        <tr>
                                         
                                            <th></th>
                                            <th><Input type="select" name="fineAction" onChange={this.setValue}>
                                                    <option value="GIVEN">GIVEN FINES</option>
                                                    <option value="PAID">PAID FINES</option>
                                                    <option value="FORGIVEN">FORGIVEN FINES</option>
                                                </Input>
                                            </th>
                                            <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                            <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                }
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                            {activeLoanDataList}
                                    
                                    </tbody>}
                            </Table>
                       </div>
                        </Col>
                        
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.getMyDataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.getMyData,
  
 });
export default connect(
    mapStateToProps,
    {getData}
)(SeeFines)
