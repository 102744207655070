import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData} from "../../Redux/ducks/mainReducer";
import {getName2} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
export class ActiveLoans extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        currentID:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        status:null,
        currentStatus:'',
        mainData:null,
        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null

    }
    componentDidMount(){
        this.setState({isLoading:true})
        this.props.getMainData("ACTIVELOAN_DATA")
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,msg:msg,mainData:userData})
            }else if(userDataStatus===true){
                this.setState({isLoading:false,mainData:null,msg:msg})
            }
        }  
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    render() {
        const { redirect,redirectSelectedID } = this.state;

        if (redirect) {
            return <Redirect to={{pathname:"/see_clients", state:{redirectSelectedID:redirectSelectedID}}}/>
        }
        /**displaying  users*/
        let activeLoanDataList = [];
        let myNo = 0
        let totalBalance = 0
        if(this.state.mainData !==null){
            const myUserData =  this.state.mainData.loanData
            console.log(myUserData)
            if(myUserData.length>0){
                myNo = 0
                totalBalance = 0
                myUserData.map((values, i) =>{
                        myNo = myNo+1
                        totalBalance = totalBalance+parseInt(values.amountExpected -values.amountPaid)
                        activeLoanDataList.push(
                            <tr key={i}>
                                <td>{myNo}</td>
                                <th scope="row">{values.loanID}</th>
                                <td>{values.member.firstName+" "+values.member.lastName}</td>
                                <td>{values.accNo}</td>
                                <td>{values.duration}</td>
                                <td>{values.processingFee}</td>
                                <td>{values.intrestRate}</td>
                                <td>{values.amount}</td>
                                <td>{values.amountExpected}</td>
                                <td>{values.amountPaid}</td>
                                <td>{values.amountExpected -values.amountPaid}</td>
                                <td>{values.startDate}</td>
                                <td>{values.endDate}</td>
                                <td>{values.monthlyPay}</td>
                                <td>{values.security}</td>
                                <td>{values.guarator ==null? "": values.guarator.firstName+" "+values.guarator.lastName}</td>
                                <td>{values.approvedby.firstName+" "+values.approvedby.lastName}</td>
                                <td>{values.created_AT}</td>
                            </tr>
                        )
 
                });
            }else{
                activeLoanDataList.push(
                    <div>
                        <br/><Alert color="primary">No data found</Alert>
                    </div>
                )
            }
        }

        /**account */
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
            activeLoanDataList = []
            if(this.state.mainData !==null){
                const myUserData =  this.state.mainData.loanData
                if(myUserData.length>0){
                    myNo = 0
                    totalBalance=0
                    myUserData.map((values, i) =>{
                        if(parseInt(values.status) ===1  && parseInt(values.defaulterStatus) !==1){
                            
                            if(searchAction ==="LOANID"){
                                if(values.loanID === searchword.toUpperCase() ){
                                    myNo = myNo+1
                                    totalBalance = totalBalance+parseInt(values.amountExpected -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.member.firstName+" "+values.member.lastName}</td>
                                            <td>{values.accNo}</td>
                                            <td>{values.duration}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrestRate}</td>
                                            <td>{values.amount}</td>
                                            <td>{values.amountExpected}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.amountExpected -values.amountPaid}</td>
                                            <td>{values.startDate}</td>
                                            <td>{values.endDate}</td>
                                            <td>{values.monthlyPay}</td>
                                            <td>{values.security}</td>
                                            <td>{values.guarator ==null? "": values.guarator.firstName+" "+values.guarator.lastName}</td>
                                            <td>{values.approvedby.firstName+" "+values.approvedby.lastName}</td>
                                            <td>{values.created_AT}</td>
                                        </tr>
                                    )
                                }
                            }else if(searchAction ==="ACCOUNT"){
                                if(values.accNo === searchword.toUpperCase() ){
                                    myNo = myNo+1
                                    totalBalance = totalBalance+parseInt(values.amountExpected -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.member.firstName+" "+values.member.lastName}</td>
                                            <td>{values.accNo}</td>
                                            <td>{values.duration}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrestRate}</td>
                                            <td>{values.amount}</td>
                                            <td>{values.amountExpected}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.amountExpected -values.amountPaid}</td>
                                            <td>{values.startDate}</td>
                                            <td>{values.endDate}</td>
                                            <td>{values.monthlyPay}</td>
                                            <td>{values.security}</td>
                                            <td>{values.guarator ==null? "": values.guarator.firstName+" "+values.guarator.lastName}</td>
                                            <td>{values.approvedby.firstName+" "+values.approvedby.lastName}</td>
                                            <td>{values.created_AT}</td>
                                        </tr>
                                    )
                                }
                            }
                        }
                    });
                }
            }
        }

        return (
            <div>
                <Row className="mainWrraper">
                    <div className="block-header">
                        <h4>VIEW ACTIVE LOANS</h4>
                    </div>
                    <Col xs="12" className="mainWrraper">
                        <Col xs="12" md="12" className="tableWrapper">
                            <Row>
                                <Col md="12" xs="12">
                                    <Label className='balB'>TOTAL CLIENTS: {myNo.toLocaleString()}, TOTAL LOAN BALANCE : {totalBalance.toLocaleString()}</Label>
                                </Col>
                            </Row>
                            <div className='table-container'>
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Loan ID</th>
                                            <th>Mmember</th>
                                            <th>Account</th>
                                            <th>Loan Duration</th>
                                            <th>Processing Fee</th>
                                            <th>Rate(%)</th>
                                            <th>Loan Amount</th>
                                            <th>Amount Expected</th>
                                            <th>Amount Paid</th>
                                            <th>Loan Balance</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Installment</th>
                                            <th>Security</th>
                                            <th>Guarator</th>
                                            <th>Approved By</th>
                                            <th>Date</th>
        
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                            <th></th>
                                            <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="ACCOUNT" ?this.state.nameSearch:null} id="ACCOUNT"/></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                        {activeLoanDataList}
                                    </tbody>}
                                </Table>
                            </div>
                        </Col>
                      
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
  
 });
export default connect(
    mapStateToProps,
    {getMainData}
)(ActiveLoans)
