import React, { Component,PureComponent } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {addLoanData} from "../../Redux/ducks/mainReducer";
import {datesDiffe,getMonth} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export class BusinessGraph extends Component {
    state={
        isLoading:false,
   
        userData:null,
        catData:null,
        clientDetailsData:null,
        msg:null,
        currentID:'',
        btnDisabled:false,
        status:null,

        currentStatus:'',
        reportData:null,
        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,staff:"",
        alertStatus :false, alertType :"error", alertTitle:null,
        closedLoanDataList:[],errorMsg:null,searchAction:"1",myYear:new Date().getFullYear(),
        startFrom2:new Date().getMonth()+1,endAt2:new Date().getMonth()+1
    }
   
    componentDidUpdate(prevProps){
        const{msg,reportData,reportStatus} = this.props;
        if(reportStatus !==prevProps.reportStatus){
            if(reportStatus===true){
                this.setState({isLoading:false,reportData:reportData,btnDisabled:false,errorMsg:msg})
            }else if(reportStatus===false){
                this.setState({isLoading:false,reportData:null,btnDisabled:false,errorMsg:msg})
            }
        }
    }

    viewUser=(e)=>{
        const selectedID = e.target.id;
        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt,searchAction,startFrom2,endAt2,myYear} = this.state
        if(parseInt(searchAction)===1){
            if(startFrom ===null || endAt ===null){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
            }else if(datesDiffe(startFrom,endAt,null)<0){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
            }else if(startFrom ===endAt){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be the same as 'End At' date *"})
    
            }else{
                this.setState({isLoading:true,btnDisabled:true,errorMsg:null})
                const data = new FormData();
                data.append("startFrom",startFrom);
                data.append("endAt",endAt);
                data.append("searchAction",searchAction);
                data.append("myYear",myYear);
                /**upload new user*/
                this.props.addLoanData(data,"BIZ_GRAPH")
            }
        }else{
            if(parseInt(startFrom2)>parseInt(endAt2)){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"'Start From' month can not be greater than 'End At' month *"})
            }else if(startFrom2===endAt2){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"'Start From' month can not be the same as 'End At' month *"})
            }else{
                this.setState({isLoading:true,btnDisabled:true,errorMsg:null})
                const data = new FormData();
                data.append("startFrom",startFrom2);
                data.append("endAt",endAt2);
                data.append("searchAction",searchAction);
                data.append("myYear",myYear);
                /**upload new user*/
                this.props.addLoanData(data,"BIZ_GRAPH")
            }
        }
        
    }

    render() {
        const yearList = [];
        yearList.push(
            <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option> 
        )
        for (let x=2020;x<=2050;x++){
            yearList.push(
                <option value={x}>{x}</option> 
            )
        }

        const monthList = [];
        monthList.push(
            <option value={new Date().getMonth()+1}>{getMonth(new Date().getMonth()+1)}</option> 
        )
        for (let x=1;x<=12;x++){
            monthList.push(
                <option value={x}>{getMonth(x)}</option> 
            )
        }
     
        return (
            <div>
              
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>BUSINESS PERFORMANCE GRAPH</h4>
                    </div>
                    <Col xs="12" className="mainWrraper2" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                            <Col md="12" xs="12">
                                <FormGroup>
                                    <Input type="select" onChange={this.setValue} name="searchAction">
                                        <option value="1">Search By Date</option> 
                                        <option value="2">Search By Month</option> 
                                    </Input>
                                </FormGroup>
                            </Col>
                            { parseInt(this.state.searchAction)===1?<>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label for="dob">Start From*</Label>
                                        <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label for="dob">End At*</Label>
                                        <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                    </FormGroup>
                                </Col>
                            </>:null}

                            {parseInt(this.state.searchAction)===2?<>
                                <Col md="3" xs="12">
                                    <FormGroup>
                                        <Label for="dob">Start From*</Label>
                                        <Input type="select" onChange={this.setValue} name="startFrom2">
                                        {monthList}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="3" xs="12">
                                    <FormGroup>
                                        <Label for="dob">End At*</Label>
                                        <Input type="select" onChange={this.setValue} name="endAt2">
                                        {monthList}
                                        </Input>
                                  
                                    </FormGroup>
                                </Col>
                                <Col md="2" xs="12">
                                    <Label for="dob">Year*</Label>
                                    <FormGroup>
                                        <Input type="select" onChange={this.setValue} name="myYear">
                                            {yearList}
                                        </Input>                              
                                    </FormGroup>
                                </Col>
                            </>:null}
                           
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>

                            {this.state.isLoading?
                            <Col xs="12">
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </Col>:null}
    
                        </Row>
                        {this.state.reportData !==null?
                        <ResponsiveContainer width="100%" height="80%">
                            <LineChart
                            width={500}
                            height={300}
                            data={this.state.reportData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            
                            <XAxis dataKey={parseInt(this.state.searchAction)===1?"Date":"Month"}/>:
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="Total_Cashout" stroke="#1220A9" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="Total_Collection" stroke="#15A912" />
                            <Line type="monotone" dataKey="Total_Expense" stroke="#EB3E3E" />
                            </LineChart>
                        </ResponsiveContainer>:null}


                    
                    </Col>
                </Row>
     
            </div>
        )
    }
}
const mapStateToProps = state =>({
    msg : state.mainReducer.msg,
    reportData:state.mainReducer.updateData,
    reportStatus:state.mainReducer.updateStatus,
});
export default connect(
    mapStateToProps, {addLoanData}
)(BusinessGraph)
