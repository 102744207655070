import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup,
Modal,ModalBody, ModalFooter,ModalHeader
} from 'reactstrap'
import {connect } from 'react-redux';
import {getData} from "../../Redux/ducks/mainReducer";
import {getName2,getName,datesDiffe,isDateInSelectedRage} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
import TableScrollbar from 'react-table-scrollbar'
export class AllPayments extends Component {
    state={
        isLoading:false,
        msg:null,
        modal:false,
        btnDisabled:false,
        mainData:null,
        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,
       
        alertStatus :false, alertType :"error", alertTitle:null,
        isLoading2:false,expensesList:[]
    }
    componentDidMount(){
        //this.props.getMainData("GENERAL_PAYDATA")
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,mainData:userData})
            }else if(userDataStatus===false){
                this.setState({mainData:null,isLoading:false, alertStatus :true, alertType :"info", alertTitle:msg})
            }
        }  
    }

 

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt} = this.state
        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{

            this.setState({totalPayment:0,isLoading:true,mainData:null,msg:""})
            const data = new FormData();
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            this.props.getData(data,"ALLPAYMENTSDATA")

        }
    }

    closeModal = ()=>{
        this.setState({modal:false,isLoading2:false})
    }

    viewUser=async(e)=>{
      
        let selectedID = e.target.id;
       
        this.setState({isLoading2:true,modal:true})
        /**get cat details */
        const detailsArray =  this.state.mainData.paymentData
        let expensesList = []
        let myNo=0
   
        detailsArray.map((values, i) =>{
            if(parseInt(values.id)===parseInt(selectedID)){
                values.distributions.map((values, i) =>{
                    myNo = myNo+1
                    expensesList.push(
                        <tr key={i}>
                            <th scope="row">{myNo}</th>
                            <td>{getName(this.state.mainData.payTypeData,values.payTypeID)}</td>
                            <td>{values.amount.toLocaleString()}</td>
                        </tr>
                    )

                })
              
            }
            
        });
      
        this.setState({
            expensesList:expensesList,isLoading2:false             
        })
       
    }
  
    render() {
        const {mainData,searchAction,nameSearch} = this.state;
        let activeLoanDataList = []
        let myNo = 0
        let totalPayment =0
        if(mainData !==null){
            const myUserData =  mainData.paymentData
            activeLoanDataList = []
            if(myUserData.length>0){
                myNo = 0
                totalPayment =0
           
                myUserData.map((values, i) =>{
                    myNo = myNo+1
                    totalPayment = totalPayment+parseInt(values.amount)
                    //console.log(values.distributions)
                    activeLoanDataList.push(
                        <tr key={i}>
                            <td>{myNo}</td>
                            
                            <td>{values.member.firstName+" "+values.member.lastName}</td>
                            <td>{values.member.clubNumber}</td>
                            <td>{values.amount}</td>
                            <td>{values.details}</td>
                            <td>{values.entryDate}</td>
                            <td>{values.created_at}</td>
                            <td><Button id={values.id} onClick={this.viewUser}>Get Details</Button></td>
                        </tr>
                    )
                 
                });
            }
        }

        let searchword = nameSearch
        if (searchword) {
            if(mainData !==null){
                const myUserData =  mainData.paymentData
                activeLoanDataList = []
                let totalPaymentList = []
                if(myUserData.length>0){
                    myNo = 0
                    totalPayment =0
                
                    myUserData.map((values, i) =>{
                        if(searchAction ==="LOANID"){
                            if(values.loanID === searchword.toUpperCase() ){
                                totalPayment = totalPayment+parseInt(values.amount)
                                myNo = myNo+1
                
                                activeLoanDataList.push(
                                    <tr key={i} >
                                        <td>{myNo}</td>
                                        <td>{values.loanID}</td>
                                        <td>{values.member.firstName+" "+values.member.lastName}</td>
                                        <td>{values.amount}</td>
                                        <td>{values.comment}</td>
                                        <td>{values.approvedby.firstName+" "+values.approvedby.lastName}</td>
                                        <td>{values.approvalDate}</td>
                                        <td>{values.rejectReason}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            }
                        }
                    });

                }
            }
         
        }
        
        return (
            <div>
                <div>
                <Row className="mainWrraper">
                    <div className="block-header">
                        <h6>VIEW ALL PAYMENTS</h6>
                    </div>
                    <Col xs="12" md="12" className="tableWrapper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                            <Row> 
                                <Table striped>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Payment Type</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    
                                    {this.state.isLoading2?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                        {this.state.expensesList}
                                    </tbody>}
                                </Table>
                            
                            </Row>
                            </ModalBody>
                        </Modal>: null}
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                            <Col md="12" xs="12">
                                <Label className='balB'>TOTAL PAYMENTS : {totalPayment.toLocaleString()}</Label>
                            </Col>
                        </Row>
                     
                        <div className='table-container'>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                   
                                    <th>Name</th>
                                    <th>MIN</th>
                                    <th>Amount</th>
                                    <th>Comment</th>
                                    <th>Pay Date</th>
                                    <th>Created_At</th>
                                    <th>See Distributions</th>

                                </tr>
                          
                           
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                                {activeLoanDataList}
                            </tbody>}
                        </Table>
                        </div>
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.getMyDataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.getMyData,
  
 });
export default connect(
    mapStateToProps,
    {getData}
)(AllPayments)
