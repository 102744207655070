import React, { Component} from 'react'
import {Alert,Label,Row,Col,Table,FormGroup,Input, Button,Modal,ModalBody, ModalFooter,ModalHeader,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData,getSelectedID_Data,resetShowCatDetails
    ,checkCategory,updateCategory,deleteData} from "../../Redux/ducks/mainReducer";

    export class SeeUserCategory extends Component {
    state = {
        alertOpen:true,
        isLoading:true,
        isModalLoading:true,
        catData:null,
        msg:null,
        model:false,
        deleteModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
    

        catName:'',addAll:false,
        addCat:false,seeCat:false,editCat:false,deleteCat:false,
        addUser:false,seeUser:false,editUser:false,deleteUser:false,
        addProj:false,seeProj:false,editProj:false,stopProj:false,restartProj:false,
        addAct:false,seeAct:false,editAct:false,approveAct:false,deleteAct:false,
        fullRep:false,actRep:false,budgetRep:false,balRep:false,seeRec:false,
        CseeCat: 0,CeditCat: 0,CdelCat: 0,
    }
    
    componentDidMount(){
        const{catData,msg,catDataStatus,showCatDataStatus,showCatData,deleteCatStatus} = this.props;
        
        /**get rights object */
        const userRights = JSON.parse(window.localStorage.getItem('userData')).rights
        /**loop through rights */
        /*userRights.map((values) =>{
            this.setState({
                CseeCat: values.seeCat,CeditCat: values.editCat,CdelCat: values.delCat,
            })
        })*/
        
        
        this.props.getMainData("CAT");
        if(catDataStatus===true){
            this.setState({isLoading:false,catData:catData,msg:msg,alertOpen:true})
        }else{
            this.setState({isLoading:true,catData:null,msg:null})
        }

        /**show details */
        if(showCatDataStatus===true){
            
            this.setState({isModalLoading:false,showCatData:showCatData,msg:msg})

            showCatData.map((values) =>{
                this.setState({isModalLoading:false,showCatData:showCatData,msg:msg,
                    catName:values.catName,
                    addCat:values.addCat===1?true:false,seeCat:values.seeCat===1?true:false,editCat:values.editCat===1?true:false,deleteCat:values.delCat===1?true:false,
                    addUser:values.addUser===1?true:false,seeUser:values.seeUser===1?true:false,editUser:values.editUser===1?true:false,deleteUser:values.delUser===1?true:false,
                    addProj:values.addProj===1?true:false,seeProj:values.seeProj===1?true:false,editProj:values.editProj===1?true:false,stopProj:values.stopProj===1?true:false,restartProj:values.restartProj===1?true:false,
                    addAct:values.addAct===1?true:false,seeAct:values.seeAct===1?true:false,editAct:values.editAct===1?true:false,approveAct:values.approveAct===1?true:false,delAct:values.deleteAct===1?true:false,
                    fullRep:values.fullRep===1?true:false,actRep:values.actRep===1?true:false,budgetRep:values.budgetRep===1?true:false,balRep:values.balRep===1?true:false,seeRec:values.seeRec===1?true:false
                })
                /**incase all rights are selected */
                if(values.addCat===1&&values.seeCat===1&&values.editCat===1&&values.delCat===1&&
                    values.addUser===1&&values.seeUser===1&&values.editUser===1&&values.delUser===1&&
                    values.addProj===1&&values.seeProj===1&&values.editProj===1&&values.stopProj===1&&values.restartProj===1&&
                    values.addAct===1&&values.seeAct===1&&values.editAct===1&&values.approveAct===1&&values.delAct===1&&
                    values.fullRep===1&&values.actRep===1&&values.budgetRep===1&&values.balRep===1&&values.seeRec===1&&values.seeRec===1){
                    this.setState({addAll:true})
                }else{
                    this.setState({addAll:false})
                }
            
            }); 
        }else{
            this.setState({isModalLoading:true,showCatData:null,msg:null})
        }

        /**deleting category */
        if(deleteCatStatus===true){
            /**refresh categories */
            this.props.getMainData("CAT");

            this.setState({
                isDeleting:false,successDeleteMsg:msg,deleteModal:false,
                model:false
            })
        }else{
            this.setState({successModalMsg:''})
        }

    }
    componentDidUpdate(prevProps){
        const{catData,msg,catDataStatus,showCatData,showCatDataStatus,updateCatStatus,deleteCatStatus} = this.props;
        if(catDataStatus !== prevProps.catDataStatus){
            if(catDataStatus===true){
                this.setState({isLoading:false,catData:catData,msg:msg,alertOpen:true})
            }else{
                this.setState({isLoading:true,catData:null,msg:null})
            }
            
        }

        if(showCatDataStatus !== prevProps.showCatDataStatus){
           
            if(showCatDataStatus===true){
                showCatData.map((values, i) =>{
                this.setState({isModalLoading:false,showCatData:showCatData,msg:msg,
                    catName:values.catName,
                    addCat:values.addCat===1?true:false,seeCat:values.seeCat===1?true:false,editCat:values.editCat===1?true:false,deleteCat:values.delCat===1?true:false,
                    addUser:values.addUser===1?true:false,seeUser:values.seeUser===1?true:false,editUser:values.editUser===1?true:false,deleteUser:values.delUser===1?true:false,
                    addProj:values.addProj===1?true:false,seeProj:values.seeProj===1?true:false,editProj:values.editProj===1?true:false,stopProj:values.stopProj===1?true:false,restartProj:values.restartProj===1?true:false,
                    addAct:values.addAct===1?true:false,seeAct:values.seeAct===1?true:false,editAct:values.editAct===1?true:false,approveAct:values.approveAct===1?true:false,deleteAct:values.delAct===1?true:false,
                    fullRep:values.fullRep===1?true:false,actRep:values.actRep===1?true:false,budgetRep:values.budgetRep===1?true:false,balRep:values.balRep===1?true:false,seeRec:values.seeRec===1?true:false
                    })

                    /**incase all rights are selected */
                    if(values.addCat===1 && values.seeCat===1&&values.editCat===1&&values.delCat===1&&
                        values.addUser===1&&values.seeUser===1&&values.editUser===1&&values.delUser===1&&
                        values.addProj===1&&values.seeProj===1&&values.editProj===1&&values.stopProj===1&&values.restartProj===1&&
                        values.addAct===1&&values.seeAct===1&&values.editAct===1&&values.approveAct===1&&values.delAct===1&&
                        values.fullRep===1&&values.actRep===1&&values.budgetRep===1&&values.balRep===1&&values.seeRec===1){
                            
                            this.setState({addAll:true})
                    }else{
                        this.setState({addAll:false})
                    }
                });
            }else{
                this.setState({isModalLoading:true,showCatData:null,msg:null})
            }
            
        }
        //update status
        if(updateCatStatus !== prevProps.updateCatStatus){
            if(updateCatStatus===true){
                this.setState({
                    isUpdating:false, btnDisabled:false,
                    successModalMsg:msg,erroModalMsg:'',
                    isModalLoading:false
              })
              
            }else{
                this.setState({isModalLoading:false,isUpdating:false,btnDisabled:false,successModalMsg:'',erroModalMsg:msg})
            }
        }
        /**deleting category */
        if(deleteCatStatus !== prevProps.deleteCatStatus){
            if(deleteCatStatus===true){
                /**refresh categories */
                this.props.getMainData("CAT");
                this.setState({
                    isDeleting:false,successDeleteMsg:msg,deleteMsg:'',deleteModal:false,
                    model:false
              })
              
            }else if(deleteCatStatus===false){
                this.props.getMainData("USER");
                this.setState({successModalMsg:'',deleteMsg:msg,isDeleting:false,deleteModal:false,
                model:false})
            }
        }

    }
    closeAlert =()=>{
        this.setState({
            alertOpen:false
        })
    }
    closeModal = ()=>{
        this.setState({model:false})
        //refresh categories
        this.props.getMainData("CAT");
    }

    viewCatas=(e)=>{
        const selectedID = e.target.id;
        alert("this feature is deactivated")
        /**get cat details *
        this.props.getSelectedID_Data(selectedID,"CAT")
        this.setState({
            model:true,currentID:selectedID,
            isUpdating:false,btnDisabled:false,
            erroModalMsg:"",successDeleteMsg:"",
            successModalMsg:'',deleteMsg:"",
        })*/
    }
    /**single checkbox selected */
    boxClicked = e =>{
        if (this.state[e.target.name] ===true){
            this.setState({[e.target.name]:false})
        }else{
            this.setState({[e.target.name]:true})
        }
    };
    addCat = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**selecting all checkboxes */
    allClicked = e =>{
        if (this.state[e.target.name] ===true){
            this.setState({
                [e.target.name]:false,
                addCat:false,seeCat:false,editCat:false,deleteCat:false,
                addUser:false,seeUser:false,editUser:false,deleteUser:false,
                addProj:false,seeProj:false,editProj:false,stopProj:false,restartProj:false,
                addAct:false,seeAct:false,editAct:false,approveAct:false,deleteAct:false,
                fullRep:false,actRep:false,budgetRep:false,balRep:false,seeRec:false
            })
        }else{
            this.setState({
                [e.target.name]:true,
                addCat:true,seeCat:true,editCat:true,deleteCat:true,
                addUser:true,seeUser:true,editUser:true,deleteUser:true,
                addProj:true,seeProj:true,editProj:true,stopProj:true,restartProj:true,
                addAct:true,seeAct:true,editAct:true,approveAct:true,deleteAct:true,
                fullRep:true,actRep:true,budgetRep:true,balRep:true,seeRec:true
            })
        }
    }

    /**updating */
    updateCategory = e =>{
        
        this.setState({erroModalMsg:"",successModalMsg:''})
        if(this.state.catName ===""){
            this.setState({erroModalMsg:"Please enter category"})
        }else if(/\s/.test(this.state.catName)){
            this.setState({erroModalMsg:"Category name must not contain spaces"})
        }else if(this.state.addCat===false&&this.state.seeCat===false&&this.state.editCat===false&&this.state.deleteCat===false&&
            this.state.addUser===false&&this.state.seeUser===false&&this.state.editUser===false&&this.state.deleteUser===false&&
            this.state.addProj===false&&this.state.seeProj===false&&this.state.editProj===false&&this.state.stopProj===false&&this.state.restartProj===false&&
            this.state.addAct===false&&this.state.seeAct===false&&this.state.editAct===false&&this.state.approveAct===false&&this.state.deleteAct===false&&
            this.state.fullRep===false&&this.state.actRep===false&&this.state.budgetRep===false&&this.state.balRep===false&&this.state.seeRec===false){
                this.setState({erroModalMsg:"Please add at least one right to  the new category"})
        }else{
            
            this.setState({erroModalMsg:"",isUpdating:true,btnDisabled:true})
            /**check if cat name exists */
            this.props.checkCategory(this.state.catName,this.state.currentID);
            /**run this after two seconds */
            setTimeout(() => {
                if(this.props.checkCatStatus===true){
                    this.setState({isUpdating:false,btnDisabled:false,erroModalMsg:this.props.checkCatMsg})
                }else{
                    /**convert true to 1 and false to 0*/
                    this.props.updateCategory(this.state.currentID,this.state.catName,
                    this.state.addCat? 1 : 0,this.state.seeCat? 1 : 0,this.state.editCat? 1 : 0,this.state.deleteCat? 1 : 0,
                    this.state.addUser? 1 : 0,this.state.seeUser? 1 : 0,this.state.editUser? 1 : 0,this.state.deleteUser? 1 : 0,
                    this.state.addProj? 1 : 0,this.state.seeProj? 1 : 0,this.state.editProj? 1 : 0,this.state.stopProj? 1 : 0,this.state.restartProj? 1 : 0,
                    this.state.addAct? 1 : 0,this.state.seeAct? 1 : 0,this.state.editAct? 1 : 0,this.state.approveAct? 1 : 0,this.state.deleteAct? 1 : 0,
                    this.state.fullRep? 1 : 0,this.state.actRep? 1 : 0,this.state.budgetRep? 1 : 0,this.state.balRep? 1 : 0,this.state.seeRec? 1 : 0
                );
            }
        }, 2000);
    } 
  }
  /**delete cat */
  deleteCategory = e =>{
    this.setState({isDeleting:false,deleteModal:true})
  }
  /**dont delete */
  noDelete = e =>{
    this.setState({deleteModal:false})
  }

  yesDelete = e =>{
    this.setState({isDeleting:true})

    this.props.deleteData(this.state.currentID,"CAT")
  }
 
    render(){
        /**displaying  categories*/
        const catDataList = [];
        if(this.state.catData !==null){
            const myCatData =  this.state.catData
            //loop through the array
            if(myCatData.length>0){
                myCatData.map((values,i) =>{
                    catDataList.push(
                        <tr key={i}>
                            <th scope="row">{values.catID}</th>
                            <td>{values.catName}</td>
                            <td>{values.created_At}</td>
                            <td><Button id={values.catID} onClick={this.viewCatas}>View Details</Button></td>
                        </tr>
                    )
                });
            }else{
                catDataList.push(
                    <div>
                        <br/><Alert color="primary">No data uploaded yet</Alert>
                    </div>
                )
            }
        }

        return (
            <div>
                <div className="container">
                <Row className="mainWrraper">
                    <div className="block-header">
                        <h4>VIEW  CATEGORIES</h4>
                    </div>
                    <Col xs="12" md="12" className="mainWrraper2">
                        {this.state.successDeleteMsg?<Alert color="success" isOpen={this.state.alertOpen} toggle={this.closeAlert}>{this.state.successDeleteMsg}</Alert>:null}
                        {this.state.deleteMsg?<Alert color="danger" isOpen={this.state.alertOpen} toggle={this.closeAlert}>{this.state.deleteMsg}</Alert>:null}
                        {this.state.model ? 
                        <Modal  isOpen={this.state.model} className="modalWrapper modal-dialog modal-lg">
                            {this.state.deleteModal?
                                <div>
                                    <Modal  isOpen={this.state.deleteModal}>
                                        {this.state.isDeleting?
                                            <ModalBody>
                                                {this.state.deleteMsg?
                                                    <Alert color="danger">{this.state.deleteMsg}</Alert>
                                                :<div>
                                                    <Spinner className="mySpinner" color="danger"/><Spinner className="mySpinner" color="danger" type="grow"/>{'Deleting...'}
                                                </div>
                                                }
                                            </ModalBody>
                                        :
                                        <div>
                                            <ModalBody>
                                                Are you sure you want to delete this category permanently?
                                            </ModalBody>

                                            <ModalFooter>
                                                <Button color="danger" onClick={this.yesDelete}>Yes Delete </Button>{' '}
                                                <Button color="secondary" onClick={this.noDelete}>Cancel</Button>
                                            </ModalFooter>
                                        </div>
                                        }

                                    </Modal>
                                </div>
                            :null}
                            
                            <ModalHeader toggle={this.closeModal}>Category Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                {this.state.isModalLoading?
                                <div><br/>
                                    <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                </div>:
                                <div>
                                    <FormGroup className="catName">
                                        <Label>Category Name</Label>
                                        {this.state.catName==="ADMIN"?
                                        <Input type="text" disabled className="formInput catName" name="catName" value={this.state.catName} onChange={this.addCat}/>

                                        :
                                        <Input type="text" className="formInput catName" name="catName" value={this.state.catName} onChange={this.addCat}/>
                                        }
                                    </FormGroup>

                                    <FormGroup>
                                        <Input type="checkbox" className="filled-in" id="acc1" name="addAll" onChange={this.allClicked} checked={this.state.addAll}/>    
                                        <Label>Select All</Label>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="rightsTitle">Category rights</Label>
                                        <Row>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ac1" name="addCat" onChange={this.boxClicked} checked={this.state.addCat}/>
                                            <Label >Add Category</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ac2" name="seeCat" onChange={this.boxClicked} checked={this.state.seeCat}/>
                                            <Label>View Category</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ac3" name="editCat" onChange={this.boxClicked} checked={this.state.editCat}/>
                                            <Label>Edit Category</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ac4" name="deleteCat" onChange={this.boxClicked} checked={this.state.deleteCat}/>
                                            <Label>Delete Category</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="rightsTitle">User rights</Label>
                                        <Row>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="au1" name="addUser" onChange={this.boxClicked} checked={this.state.addUser}/>
                                            <Label >Add Users</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="au2" name="seeUser" onChange={this.boxClicked} checked={this.state.seeUser}/>
                                            <Label>View Users</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="au3" name="editUser" onChange={this.boxClicked} checked={this.state.editUser}/>
                                            <Label>Edit Users</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="au4" name="deleteUser" onChange={this.boxClicked} checked={this.state.deleteUser}/>
                                            <Label>Delete Users</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>


                                    <FormGroup>
                                        <Label className="rightsTitle">Project rights</Label>
                                        <Row>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="ap1" name="addProj" onChange={this.boxClicked} checked={this.state.addProj}/>
                                            <Label >Add Project</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="ap2" name="seeProj" onChange={this.boxClicked} checked={this.state.seeProj}/>
                                            <Label>View Project</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="ap3" name="editProj" onChange={this.boxClicked} checked={this.state.editProj}/>
                                            <Label>Edit Project</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="ap4" name="stopProj" onChange={this.boxClicked} checked={this.state.stopProj}/>
                                            <Label>Stop Project</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="ap5" name="restartProj" onChange={this.boxClicked} checked={this.state.restartProj}/>
                                            <Label>Restart Project</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="rightsTitle">Activity rights</Label>
                                        <Row>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="aa1" name="addAct" onChange={this.boxClicked} checked={this.state.addAct}/>
                                            <Label >Add Activity</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="aa2" name="seeAct" onChange={this.boxClicked} checked={this.state.seeAct}/>
                                            <Label>View Activity</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="aa3" name="editAct" onChange={this.boxClicked} checked={this.state.editAct}/>
                                            <Label>Edit Activity</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="aa4" name="approveAct" onChange={this.boxClicked} checked={this.state.approveAct}/>
                                            <Label>Approve Activity</Label>
                                            </Col>
                                            <Col xs="2">
                                            <Input type="checkbox" className="filled-in" id="aa5" name="deleteAct" onChange={this.boxClicked} checked={this.state.deleteAct}/>
                                            <Label>Delete Activity</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="rightsTitle">Report rights</Label>
                                        <Row>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ar1" name="fullRep" onChange={this.boxClicked} checked={this.state.fullRep}/>
                                            <Label >Full Report</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ar2" name="actRep" onChange={this.boxClicked} checked={this.state.actRep}/>
                                            <Label>Activity Report</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ar3" name="budgetRep" onChange={this.boxClicked} checked={this.state.budgetRep}/>
                                            <Label>Budget Report</Label>
                                            </Col>
                                            <Col xs="3">
                                            <Input type="checkbox" className="filled-in" id="ar4" name="balRep" onChange={this.boxClicked} checked={this.state.balRep}/>
                                            <Label>Balance Sheet Report</Label>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                    <Label>Receipt rights</Label>
                                    <Row>
                                        <Col xs="3">
                                        <Input type="checkbox" className="filled-in" id="ar11" name="seeRec" onChange={this.boxClicked} checked={this.state.seeRec}/>
                                        <Label >See Receipt</Label>
                                        </Col>
                                        <Col xs="9"></Col>
                                    </Row>
                                </FormGroup>
                                </div>
                                }
                                {this.state.successModalMsg?<Alert color="success">{this.state.successModalMsg}</Alert>:null}
                                {this.state.erroModalMsg?<Alert color="danger">{this.state.erroModalMsg}</Alert>:null}
                                {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Updating...'}</div>:null}
                            </ModalBody>
                            <ModalFooter >
                                
                                    { this.state.catName ==="ADMIN"?null:
                                        <Row className="modalFooter">
                                            {this.state.CeditCat ===0?null:
                                            <Col xs="6" md="6">
                                                <Button onClick={this.updateCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-secondary float-left">Update Category</Button>
                                            </Col>
                                            }

                                            {this.state.CdelCat ===0?null:
                                                <Col xs="6" md="6">
                                                    <Button onClick={this.deleteCategory} className="btn btn-danger float-right">Delete Category</Button>
                                                </Col>
                                            }
                                        </Row>
                                    }
                            
                            </ModalFooter>
                        </Modal>: null}
                  
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Category ID</th>
                                    <th>Category Name</th>
                                    <th>Created AT</th>
                                    <th>View Category</th>
                                    
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                                <tbody>
                                    {catDataList}
                                </tbody>}
                        </Table>
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    catDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    catData : state.mainReducer.mainData,
    showCatData : state.mainReducer.showData,
    showCatDataStatus : state.mainReducer.showDataStatus,
    updateCatStatus : state.mainReducer.updateStatus,
    deleteCatStatus : state.mainReducer.deleteStatus,

    checkCatStatus : state.mainReducer.checkCatStatus,
    checkCatMsg : state.mainReducer.checkCatMsg,
  });
export default connect(
    mapStateToProps,
     {getMainData,getSelectedID_Data,resetShowCatDetails
        ,checkCategory,updateCategory,deleteData}
)(SeeUserCategory)
