import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
export class AddAllLoan extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        qty:"",total:"",cost:"",details:"",staff:"",cat:"",name:"",myDate:"",rate:"",period:""

    }

    componentDidMount(){

        /**refresh categories */
        this.props.getMainData("ALLPaymentData")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg,
                qty:"",total:"",myDate:"",details:"",name:"",rate:"",period:""
                })

            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    addCategory =(e)=>{

        const {total,details,staff,cat,myDate,rate,period} = this.state;

        if(cat===""||total===""||total==="0"||staff===""||myDate===""||rate===""||rate==="0"||period===""||period==="0"){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Enter all feilds marked with a *"})
        }else{
            this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
            const data = new FormData();
            data.append("myDate",myDate);
            data.append("amount",total);
            data.append("details",details);
            data.append("staff",staff);
            data.append("payType",cat);
            data.append("rate",rate);
            data.append("period",period);
        
            /**upload new user*/
            this.props.addUser(data,"ADD_ALLLOAN")
        }
    }

   

    render() {
        
        let catList = [];
        let staffDataList = []
        const myUserData =  this.state.mainData
        console.log(myUserData)

        if(myUserData !==null){
        
            if(myUserData.loanData.length>0){
                catList.push(<option key="" value="">SELECT LOAN TYPE</option>)
                myUserData.loanData.map((values,i) =>{
                    catList.push(
                        <option key={i} value={values.id}>{values.name}</option>
                    )
                });
            }else{
                catList.push(  
                    <option value="">No data found</option>   
                )
            }

            if(myUserData.userData.length>0){
                staffDataList.push(<option key="" value="">Select Member</option>)
                myUserData.userData.map((values,i) =>{
                    if(parseInt(values.status) ===1){
                    staffDataList.push(
                        <option key={i} value={values.id}>{values.clubNumber+" ("+values.firstName+" "+values.lastName+")"}</option>
                    )}
                });
            }else{
                staffDataList.push(  
                    <option value="">No Member found</option>   
                )
            }
        }
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>ADD LOAN</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        <Col xs="12" >
                            {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}
                          
                            <FormGroup>
                                <Label for="gender">Select Payment Type*</Label>
                                <Input type="select" onChange={this.setValue} name="cat">
                                    {catList}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">Select Member*</Label>
                                <Input type="select" onChange={this.setValue} name="staff">
                                    {staffDataList}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Start Date*</Label>
                                <Input type="date" onChange={this.setValue} value={this.state.myDate} name="myDate" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Rate*</Label>
                                <Input type="number" min="1" max="100" onChange={this.setValue} value={this.state.rate} name="rate" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Loan Period(Month)*</Label>
                                <Input type="number" min="1"  onChange={this.setValue} value={this.state.period} name="period" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Loan Amount*</Label>
                                <Input type="number" min="1" onChange={this.setValue} value={this.state.total} name="total" />
                            </FormGroup>

                            <FormGroup className="des">
                                <Label for="username"  className="loanLabel">Description</Label>
                                <Input type="textarea" onChange={this.setValue} className=" loanArea" value={this.state.details} name="details" />
                            </FormGroup>
                        
                        </Col>
                  
                        <Col xs="12" >
                            {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
                            <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Add Loan</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(AddAllLoan)
