import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {getData} from "../../Redux/ducks/mainReducer";
import {datesDiffe} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
export class MembersReport extends Component {
    state={
        isLoading:false,
        msg:null,
        mainData:null,
        searchAction:null,nameSearch:null, startFrom:null,endAt:null, 
        alertStatus :false, alertType :"error", alertTitle:null,
    }
  
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,mainData:userData})

            }else if(userDataStatus===false){
                this.setState({isLoading:false,mainData:null,closedLoanDataList:[],alertStatus :true, alertType :"error", alertTitle:msg})
            }
        }  
    }



    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt} = this.state

        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
            this.setState({isLoading:true})
            const data = new FormData();
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            this.props.getData(data,"GET_SEARCHMEMBERDATA")
            
        }
    }

    render() {
        const {mainData,nameSearch} = this.state;

     
        /**displaying  users*/
        let activeLoanDataList = [];

        let myNo1 = 0
        let totalContribution = 0
        let totalWelfare = 0
        let totalFines = 0
        let totalGivenOut = 0
        let totalLoanPayment = 0
        let totalExpenses = 0
        if(mainData !==null){
            if(mainData.length>0){
                mainData.map((values, i) =>{
                    myNo1 = myNo1+1
                    totalContribution = totalContribution+parseInt(values.totalContribution)
                    totalWelfare = totalWelfare+parseInt(values.totalWelfare)
                    totalFines = totalFines+parseInt(values.totalFines)
                    totalGivenOut = totalGivenOut+parseInt(values.totalGivenOut)
                    totalLoanPayment = totalLoanPayment+parseInt(values.totalLoanPayment)
                    totalExpenses = totalExpenses+parseInt(values.totalExpenses)
                    activeLoanDataList.push(
                        <tr key={i}>
                            <td>{myNo1}</td>
                            <td>{values.name}</td>
                            <td>{values.clubNumber}</td>
                            <td>{parseInt(values.totalContribution).toLocaleString()}</td>
                            <td>{parseInt(values.totalWelfare).toLocaleString()}</td>
                            <td>{parseInt(values.totalFines).toLocaleString()}</td>
                            <td>{parseInt(values.totalGivenOut).toLocaleString()}</td>
                            <td>{parseInt(values.totalLoanPayment).toLocaleString()}</td>
                            <td>{parseInt(values.totalExpenses).toLocaleString()}</td>
                      
                        </tr>
                    )
                })

                activeLoanDataList.push(
                    <tr style={{backgroundColor:"#85929E",color:"white"}}>
                        <td>{""}</td>
                        <td>{"TOTALS"}</td>
                        <td>{""}</td>
                        <td>{totalContribution.toLocaleString()}</td>
                        <td>{totalWelfare.toLocaleString()}</td>
                        <td>{totalFines.toLocaleString()}</td>
                        <td>{totalGivenOut.toLocaleString()}</td>
                        <td>{totalLoanPayment.toLocaleString()}</td>
                        <td>{totalExpenses.toLocaleString()}</td>
                  
                    </tr>
                )
            }

          
        }

    
     
        return (
            <div>
               
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW MEMBER REPORT</h4>
             

                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Col xs="12" md="12" className="tableWrapper">
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>

                      
                        </Row>
                        <div className='table-container'>
                            <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Name</th>
                                            <th>Club Number</th>
                                            <th>Contribution</th>
                                            <th>Welfare</th>
                                            <th>Fines Paid</th>
                                            <th>Loan Amount</th>
                                            <th>Loan Payments</th>
                                            <th>Expenses</th>
                                        </tr>
                                  
                                    </thead>
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                            {activeLoanDataList}
                                    
                                    </tbody>}
                            </Table>
                       </div>
                        </Col>
                        
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.getMyDataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.getMyData,
  
 });
export default connect(
    mapStateToProps,
    {getData}
)(MembersReport)
