import React, { Component } from 'react'
import {Link} from "react-router-dom";
import {Alert,Label,Row,Col,Table,FormGroup,Input, Button,Modal,
  ModalBody, ModalFooter,ModalHeader,Spinner} from 'reactstrap'
  import MyImage from '../../logo.png';
export class Dashboard extends Component{
  constructor(props) {
    super(props);
    this.state = {
      myDate:new Date().getFullYear(),
      modal: JSON.parse(window.localStorage.getItem('showWelcomeAlert'))
    };
  }
  
    closeAlert =()=>{
      this.setState({
          alertOpen:false
      
      })
    }
    closeModal = ()=>{
        window.localStorage.setItem('showWelcomeAlert',false);
        window.location.reload();
    }

    
 
    render(){
 
        return (
          <div>
            <div className="container">
                <Row className="mainWrraper">
              <div className="block-header dash">
                <h4>DASHBOARD</h4>
              </div>
              <Col xs="12">
                <div className="row">
                  {this.state.modal ? 
                    <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                    
                      <ModalBody className="modalBodyWrapper">
                        <Row>
                          <div className="col-xl-12 col-md-12 mb-12 text-center welcomeNote">
                            <img src={MyImage} alt="logo img" className='welImg' />

                            <h2>Yes We Can</h2>
                            <p>Version : {process.env.REACT_APP_VERSION}</p>
                            <p>Contact Us : +256786210221,<br/> <span className='welemail'>mutaniktechnologies@gmail.com</span></p>
                            <p><span> &copy; Developed & Maintained By <a href="https://mutanik.com" target="_blank" rel="noopener noreferrer">Mutanik Technologies(U) Ltd</a></span></p>
                          </div>
                        </Row>
                        <ModalFooter>
                          <Button onClick={this.closeModal} className="btn btn-block btn-lg btn-success">Okay</Button>

                        </ModalFooter>
                      </ModalBody>
                          
                  </Modal>: null}

                {/*this.state.addCat===0?null:
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Add Client</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="add_client" className="btn btn-primary">Add Client</Link></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-users fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/
                  }

                  {/*this.state.seeCat===0 && this.state.editCat===0 && this.state.delCat===0?null:
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">View Client</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="see_clients" className="btn btn-success">View Client</Link></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-eye fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>*/}

                  {/*this.state.addUser===0?null:
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-info shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-info2 text-uppercase mb-1">Add User</div>
                            <div className="row no-gutters align-items-center">
                              <div className="col-auto">
                                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800"><Link to="add_users" className="btn btn-danger">Add User</Link></div>
                              </div>
                              <div className="col">
                              
                              </div>
                            </div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-user-alt fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/
                  }

                  {/*this.state.seeUser===0 && this.state.editUser===0 && this.state.delUser===0?null:
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">View Users</div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="see_users" className="btn btn-warning">View Users</Link></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-eye fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>
              </Col>
           
           

            {/*<Col xs="12">
            <div className="row">
              {this.state.addProj===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-primary shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Asign Teacher</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="assign_teacher" className="btn btn-primary">Asign Teacher</Link></div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-project-diagram fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.state.seeProj===0 && this.state.editProj===0 && this.state.stopProj===0 && this.state.restartProj ===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">Add Student</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="add_student" className="btn btn-success">Add Student</Link></div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-eye fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.state.seeAct===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-info shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-info2 text-uppercase mb-1">View Students</div>
                        <div className="row no-gutters align-items-center">
                          <div className="col-auto">
                            <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800"><Link to="see_students" className="btn btn-danger">View Students</Link></div>
                          </div>
                          
                        </div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-tasks fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.state.addAct===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-warning shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Add Student Marks</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="add_update_marks" className="btn btn-warning">Add Student Marks</Link></div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-eye fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </Col>

          <Col xs="12">
            <div className="row">
            {this.state.fullRep===0 && this.state.actRep===0 && this.state.budgetRep===0 && this.state.balRep===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Add Subject</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="add_subject" className="btn btn-primary">Add Subject</Link></div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-chart-bar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.state.fullRep===0 && this.state.actRep===0 && this.state.budgetRep===0 && this.state.balRep===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">View Subjects</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="see_subjects" className="btn btn-success">View Subjects</Link></div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-chart-bar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.state.fullRep===0 && this.state.actRep===0 && this.state.budgetRep===0 && this.state.balRep===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">Add Papers / Charpters</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="add_chapters" className="btn btn-danger">Add Papers / Charpters</Link></div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-chart-bar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}

              {this.state.fullRep===0 && this.state.actRep===0 && this.state.budgetRep===0 && this.state.balRep===0?null:
              <div className="col-xl-3 col-md-6 mb-4">
                <div className="card border-left-success shadow h-100 py-2">
                  <div className="card-body">
                    <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Generate Reports</div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800"><Link to="generate_student_report" className="btn btn-warning">Generate Reports</Link></div>
                      </div>
                      <div className="col-auto">
                        <i className="fas fa-chart-bar fa-2x text-gray-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
              </Col>*/}
          </Row>
          </div>
          </div>
        )
    }
}

export default Dashboard
