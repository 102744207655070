import React,{Component} from 'react'
import {connect } from 'react-redux';
import {Link } from 'react-router-dom';
import {logOut,getLiveData} from '../../Redux/ducks/authReducer';

export class Nav extends Component{
    
    state={
        liveData:null,
    }
    
    componentDidMount() {
        this.checkUserLoggedIn();
        // Set up an interval to check every 10 seconds
        this.interval = setInterval(this.checkUserLoggedIn, 60000);
    }

    componentWillUnmount() {
        // Clear the interval when the component unmounts
        clearInterval(this.interval);
    }
    componentDidUpdate(prevProps){
        const{liveData,liveStatus} = this.props;
  
        /*if(liveStatus !== prevProps.liveStatus){
            if(liveStatus===true){
                this.setState({liveData:liveData})
            }
        } */
    }

    checkUserLoggedIn = () => {
        // Replace this with your actual authentication logic
        // For example, you can check if a token is present in localStorage or a session cookie
        const isLoggedIn = localStorage.getItem('accessToken') !== null;

        this.setState({ isLoggedIn });
    }
   
    render(){
        const userData = JSON.parse(window.localStorage.getItem('userData'))
        return (
            <div>
                <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                    </button>

                    
                    <div className="innerTitle">YES WE CAN <span className='loanDetails'> - CLUB MANAGEMENT SYSTEM</span></div>
                   
                    <div className="branchName">{userData.username} </div>
                    
                    <ul className="navbar-nav ml-auto">

                        
                        <div className="topbar-divider d-none d-sm-block"></div>
                        <li className="nav-item ">
                            <Link to="/login"  className="dropdown-item">
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}
const mapStateToProps = (state) =>({
    liveStatus : state.authReducer.liveStatus,
    liveData : state.authReducer.liveData,
});
export default connect(
    mapStateToProps,{logOut,getLiveData}
)(Nav)
