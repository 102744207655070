import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup,Modal,
    ModalBody, ModalFooter,ModalHeader} from 'reactstrap'
import {connect } from 'react-redux';
import {getData,addMyData} from "../../Redux/ducks/mainReducer";
import {checkRight} from "../../GeneralFunctions/general"
import moment from 'moment';
import ReactJsAlert from "reactjs-alert"
export class ApproveMonthPay extends Component {
    state={
        isLoading:false,
        modal:false,
        mainData:null,
        isUpdating:false,
        alertStatus :false, alertType :"error", alertTitle:null,
        selectedID:"", name:"",createdAt:"",fineType:"",comment:"",amount:"",
        rejReason:"",approvalAction:"0",photo:""
    }
    componentDidMount(){
        this.setState({isLoading:true})
        this.props.getData(null,"GETMONTH_APPROVAL")
    }
  
    componentDidUpdate(prevProps){
        const{getMyData,msg,userDataStatus,addMyData,addMyDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,mainData:getMyData})
            }
            if(userDataStatus===false){
                this.setState({isLoading:false,mainData:null,closedLoanDataList:[],alertStatus :true, alertType :"error", alertTitle:msg})
            }
        }  

        if(addMyDataStatus !== prevProps.addMyDataStatus){
            if(addMyDataStatus===true){
                this.setState({isUpdating:false,alertStatus :true, alertType :"success", alertTitle:msg,
                modal:false,
                })
                this.props.getData(null,"GETMONTH_APPROVAL")
            }
            if(addMyDataStatus===false){
                this.setState({isUpdating:false,alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    viewUser=(e)=>{
        const {mainData} =this.state
        const selectedID = e.target.id;

        const myUserData =  mainData.monthData
        myUserData.map((values, i) =>{
            if(parseInt(selectedID)===parseInt(values.id)){
                this.setState({
                    selectedID:selectedID,
                    name:values.member.firstName,
                    createdAt:moment(values.created_at).format('YYYY-MM-DD HH:mm:ss'),
                    fineType:"MONTH PAY",
                    comment:values.comment,
                    amount:values.amount,
                    modal:true,
                    photo:values.photo

                })
            }
        })
    }
    closeModal=()=>{
        this.setState({ modal:false})
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    submit = (e) =>{
        const {rejReason,approvalAction,selectedID} = this.state
        if(checkRight(114)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"You have no right to perform this action"})

        }else{
        
            if(parseInt(approvalAction) ===0){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Select Action"})
            }else if(parseInt(approvalAction) ===2 && rejReason===""){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Since 'Reject Payment' action has been selected , a reason for rejection must be given"})
            }else{
                this.setState({isUpdating:true})
                const data = new FormData();
                data.append("selectedID",selectedID);
                data.append("rejReason",rejReason);
                data.append("approvalAction",approvalAction);

                this.props.addMyData(data,"APPROVE_MONTH")
            }
        }
    }

    render() {
        const { redirect,redirectSelectedID,fineAction,mainData } = this.state;
        let myNo1 = 0
        let amountGiven1=0
        let activeLoanDataList=[]

        if(mainData !==null){
            const myUserData =  mainData.monthData
            myUserData.map((values, i) =>{
                myNo1 = myNo1+1
                amountGiven1 = amountGiven1+parseInt(values.amount) 
                
                activeLoanDataList.push(
                    <tr key={i}>
                        <td>{myNo1}</td>
                        <th>{"MONTH PAY"}</th>
                        <th>{values.member.clubNumber}</th>
                        <th>{values.member.firstName}</th>
                        <td>{values.amount}</td>
                        <td>{moment(values.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td><Button id={values.id} onClick={this.viewUser}>Take Action</Button></td>
                    </tr>
                )
            })
        }
        return (
            <div>
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW MONTH</h4>
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Fine Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                <Row> 
                                    <Col xs="12" >
                                
                                        <FormGroup>
                                            <Label for="name">Member Name : <b>{this.state.name}</b></Label>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="name">Pay Type : <b>{this.state.fineType}</b></Label>
                                        </FormGroup>

    
                                        <FormGroup>
                                            <Label for="name">Comment : <b>{this.state.comment}</b></Label>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="name">Amount : <b>{this.state.amount}</b></Label>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="name">Created At : <b>{this.state.createdAt}</b></Label>
                                        </FormGroup>

                                    </Col>
                                    <Col xs="12" >
                                       <img className="rImg" src={this.state.mainData.imgPath+"/receipts/"+this.state.photo} alt='receipt image'/>

                                    </Col>

                                    <Col xs="12" md="12">
                                        <FormGroup>
                                            <Label for="password2">Select Action</Label>
                                            <Input type="select" onChange={this.setValue} name="approvalAction">
                                                <option value="0">Select Action</option>:
                                                <option value="1">Approve Payment</option>
                                                <option value="2">Reject Payment</option> 
                                            </Input>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="password2">Reason For Rejection</Label>
                                            <Input type="textarea" onChange={this.setValue} name="rejReason">
                                            
                                            </Input>
                                        </FormGroup>
                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Submitting...'}</div>:null}

                                        <Button onClick={this.submit} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Submit Action</Button>
                                    </Col>
                                </Row>

                            </ModalBody>
                        </Modal>: null}
                        
                        
                        <Col xs="12" md="12" className="tableWrapper">
                        <Row>
                            <Col md="12" xs="12">
                                    <Label className='balB'> TOTAL AMOUNT :{amountGiven1.toLocaleString()}</Label>
                            </Col>
                        </Row>
                        <div className='table-container'>
                            <Table striped responsive>

                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Payment Type</th>
                                        <th>Club Number</th>
                                        <th>Member</th>
                                        <th>Amount</th>
                                        <th>Created At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                            
                                
                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                        {activeLoanDataList}
                                
                                </tbody>}
                            </Table>
                       </div>
                        </Col>
                        
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.getMyDataStatus,
    msg : state.mainReducer.msg,
    getMyData : state.mainReducer.getMyData,
    addMyData:state.mainReducer.addMyData,
    addMyDataStatus:state.mainReducer.addMyDataStatus
 });
export default connect(
    mapStateToProps,
    {getData,addMyData}
)(ApproveMonthPay)
