import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table,Modal,
    ModalBody, ModalFooter,ModalHeader} from 'reactstrap'
import {getName2,getName,datesDiffe,isDateInSelectedRage,checkRight} from "../../GeneralFunctions/general"

import ReactJsAlert from "reactjs-alert"
export class SeeExpense extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        expenseDataList:[],
        catList:[],
        staffDataList:[],
        alertStatus :false, alertType :"error", alertTitle:null,
        cdate:null,startFrom:null,endAt:null,modal:false,selectedID:null,
        qty:"",total:"",cost:"",details:"",staff:"",cat:"",name:"",totalAmount:0

    }

    componentDidMount(){
        /**refresh categories */
        this.props.getMainData("EXPENSECAT")
    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
       
                this.setState({isSaving:false,mainData:mainData
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg, cdate:""
                })

                /**refresh categories */
                this.props.getMainData("EXPENSECAT")
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }



  

    viewUser=(e)=>{
        const currentDate = this.state.mainData.settingData.currentDate;

        const selectedID = e.target.id;
        const myUserData =  this.state.mainData.expenseData
        var cat = null
        var staff = null
        var entryDate = null

        myUserData.map((values, i) =>{
            if(parseInt(values.id) ===parseInt(selectedID)){
                staff = values.staffID
                cat = values.catID
                entryDate = values.entryDate
                this.setState({
                    qty:values.qty,
                    total:values.amount,
                    cost:values.cost,
                    details:values.details,
                    staff:values.staffID,
                    cat:values.catID,
                    name:values.name
                })
            }
        });

        

        if(currentDate ===entryDate){
            //check if you have right
            if(checkRight(66)===false){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to edit expenses on current date"})
            }else{
                this.setState({modal:true,selectedID:selectedID})
                /**work on cat */
                let catList =[]
                if(this.state.mainData.expenseCatData.length>0){
                    this.state.mainData.expenseCatData.map((values,i) =>{
                        if(parseInt(values.id) ===parseInt(cat)){
                            catList.push(
                                <option key={i} value={values.id}>{values.name}</option>
                            )
                        }
                        
                    });

                    this.state.mainData.expenseCatData.map((values,i) =>{
                        if(parseInt(values.status) ===1){
                            catList.push(
                                <option key={i} value={values.id}>{values.name}</option>
                            )
                        }
                        
                    });
                }else{
                    catList.push(  
                        <option value="">No category found</option>   
                    )
                }

                /**work on staff */
                let staffDataList = []
                if( this.state.mainData.userData.length>0){
                
                    this.state.mainData.userData.map((values,i) =>{
                        if(parseInt(values.id) ===parseInt(staff)){
                            staffDataList.push(
                                <option key={i} value={values.id}>{values.username+" ("+values.fname+")"}</option>
                            )
                        }
                    });

                    staffDataList.push(<option key="" value="">SELECT STAFF</option>)
                    this.state.mainData.userData.map((values,i) =>{
                        if(parseInt(values.status) ===1){
                        staffDataList.push(
                            <option key={i} value={values.id}>{values.username+" ("+values.fname+")"}</option>
                        )}
                    });
                }else{
                    staffDataList.push(  
                        <option value="">No staff found</option>   
                    )
                }

                this.setState({catList:catList,staffDataList:staffDataList})
            }
                
        }else{
            if(checkRight(67)===false){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to edit expenses"})
            }else{
                this.setState({modal:true,selectedID:selectedID})
                /**work on cat */
                let catList =[]
                if(this.state.mainData.expenseCatData.length>0){
                    this.state.mainData.expenseCatData.map((values,i) =>{
                        if(parseInt(values.id) ===parseInt(cat)){
                            catList.push(
                                <option key={i} value={values.id}>{values.name}</option>
                            )
                        }
                        
                    });

                    this.state.mainData.expenseCatData.map((values,i) =>{
                        if(parseInt(values.status) ===1){
                            catList.push(
                                <option key={i} value={values.id}>{values.name}</option>
                            )
                        }
                        
                    });
                }else{
                    catList.push(  
                        <option value="">No category found</option>   
                    )
                }

                /**work on staff */
                let staffDataList = []
                if( this.state.mainData.userData.length>0){
                
                    this.state.mainData.userData.map((values,i) =>{
                        if(parseInt(values.id) ===parseInt(staff)){
                            staffDataList.push(
                                <option key={i} value={values.id}>{values.clubNumber+" ("+values.firstName+")"}</option>
                            )
                        }
                    });

                    staffDataList.push(<option key="" value="">Select Member</option>)
                    this.state.mainData.userData.map((values,i) =>{
                        if(parseInt(values.status) ===1){
                        staffDataList.push(
                            <option key={i} value={values.id}>{values.clubNumber+" ("+values.firstName+")"}</option>
                        )}
                    });
                }else{
                    staffDataList.push(  
                        <option value="">No member found</option>   
                    )
                }

                this.setState({catList:catList,staffDataList:staffDataList})
            }
        }

    }


    searchData = () =>{
        const {startFrom,endAt} = this.state

        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
            this.setState({isLoading:true})
            let expenseDataList = [];
            let totalAmount = 0 
            if(this.state.mainData !==null){
                const myUserData =  this.state.mainData.expenseData
                if(myUserData.length>0){
                    let myNo = 0
                    myUserData.map((values, i) =>{
                       
                        if(isDateInSelectedRage(startFrom,endAt,values.entryDate) ===true){
                            myNo = myNo+1
                            totalAmount = totalAmount+parseInt(values.amount)
                            expenseDataList.push(
                                <tr key={i}>
                                    <td>{myNo}</td>
                                    <td>{getName2(this.state.mainData.userData,values.staffID,"STAFF")}</td>
                                    <td>{getName(this.state.mainData.expenseCatData,values.catID)}</td>
                                    <td>{values.name}</td>
                                    <td>{values.qty}</td>
                                    <td>{values.cost}</td>
                                    <td>{values.amount}</td>
                                    <td>{values.details}</td>
                                    <td>{getName2(this.state.mainData.userData,values.addedBy,"STAFF")}</td>
                                    <td>{values.entryDate}</td>
                                    <td>{values.created_at}</td>
                                    <td><Button id={values.id} onClick={this.viewUser}>Edit Expense</Button></td>
                                </tr>
                            )
                        }
                        
                    });
                }
            }

            if (expenseDataList.length>0){
                this.setState({expenseDataList:expenseDataList,isLoading:false,totalAmount:totalAmount})
            }else{
                this.setState({isLoading:false,expenseDataList:[], alertStatus :true, alertType :"info", alertTitle:"No expenses found in the selected range"})

            }
        }
    }

    closeModal = ()=>{
        this.props.getMainData("EXPENSECAT")
        const {startFrom,endAt} = this.state
        this.setState({modal:false})

        this.setState({isLoading:true})
        let expenseDataList = [];
        if(this.state.mainData !==null){
            const myUserData =  this.state.mainData.expenseData
            if(myUserData.length>0){
                let myNo = 0
                myUserData.map((values, i) =>{
                    
                    if(isDateInSelectedRage(startFrom,endAt,values.entryDate) ===true){
                        myNo = myNo+1
                        expenseDataList.push(
                            <tr key={i}>
                                <td>{myNo}</td>
                                <td>{getName2(this.state.mainData.userData,values.staffID,"STAFF")}</td>
                                <td>{getName(this.state.mainData.expenseCatData,values.catID)}</td>
                                <td>{values.name}</td>
                                <td>{values.qty}</td>
                                <td>{values.cost}</td>
                                <td>{values.amount}</td>
                                <td>{values.details}</td>
                                <td>{getName2(this.state.mainData.userData,values.addedBy,"STAFF")}</td>
                                <td>{values.entryDate}</td>
                                <td>{values.created_at}</td>
                                <td><Button id={values.id} onClick={this.viewUser}>Edit Expense</Button></td>
                            </tr>
                        )
                    }
                    
                });
            }
        }

        if (expenseDataList.length>0){
            this.setState({expenseDataList:expenseDataList,isLoading:false})
        }else{
            this.setState({isLoading:false,expenseDataList:[], alertStatus :true, alertType :"info", alertTitle:"No expenses found in the selected range"})

        }
        
        //refresh categories
    }

    editExpenses =(e)=>{

        const {qty,total,cost,details,staff,cat,name,selectedID} = this.state;

        if(cat===""|| name===""||total===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Enter all feilds marked with a *"})
        }else if(parseInt(total) <0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Amount should not be less than zero"})
        }
        else{
            this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
            const data = new FormData();
            data.append("selectedID",selectedID);
            data.append("qty",qty);
            data.append("total",total);
            data.append("cost",cost);
            data.append("details",details);
            data.append("staff",staff);
            data.append("cat",cat);
            data.append("name",name);

            /**upload new user*/
            this.props.addUser(data,"UPDATE_EXPENSE")
        }
    }


    render() {
       
        return (
            <div>
                <div className="">
                    <div className="block-header">
                    <h4>SEE EXPENSES</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Edit Expenses</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                <Col xs="12" >
                                
                                    <FormGroup>
                                        <Label for="gender">Select Category*</Label>
                                        <Input type="select" onChange={this.setValue} name="cat">
                                            {this.state.catList}
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="gender">Select Staff</Label>
                                        <Input type="select" onChange={this.setValue} name="staff">
                                            {this.state.staffDataList}
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="username">Item Name*</Label>
                                        <Input type="text" onChange={this.setValue} value={this.state.name} name="name" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="username">Item Quantity</Label>
                                        <Input type="number" min="1" onChange={this.setValue} value={this.state.qty} name="qty" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="username">Item Cost</Label>
                                        <Input type="number" min="1" onChange={this.setValue} value={this.state.cost} name="cost" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="username">Total Amount*</Label>
                                        <Input type="number" min="1" onChange={this.setValue} value={this.state.total} name="total" />
                                    </FormGroup>

                                    <FormGroup className="des">
                                        <Label for="username"  className="loanLabel">Description</Label>
                                        <Input type="textarea" onChange={this.setValue} className=" loanArea" value={this.state.details} name="details" />
                                    </FormGroup>
                                
                                </Col>
                            
                    
                                <Col xs="12" >
                                    {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
                                    <Button onClick={this.editExpenses} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Edit Expense</Button>
                                </Col>
                            </ModalBody>
                        </Modal>
                        :null}
                        
                        <Col xs="12" md="12" className="tableWrapper">
                            <Row>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label for="dob">Start From*</Label>
                                        <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label for="dob">End At*</Label>
                                        <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label for="dob"></Label>
                                        <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                    </FormGroup>
                                </Col>
                                <Col md="12" xs="12">
                                    <Label className='balB'>TOTAL AMOUNT : {this.state.totalAmount.toLocaleString()}</Label>
                                </Col>
                            </Row>
                            <div className='table-container'>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Taken By</th>
                                        <th>Category</th>
                                        <th>Item</th>
                                        <th>Qty</th>
                                        <th>Cost</th>
                                        <th>Amount</th>
                                        <th>Details</th>
                                        <th>Added By</th>
                                        <th>Entry Date</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    {this.state.expenseDataList}
                                </tbody>
                            </Table>

                            </div>
                            
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(SeeExpense)
