import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {getData} from "../../Redux/ducks/mainReducer";
import {datesDiffe} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
export class SeeGivenOut extends Component {
    state={
        isLoading:false,
        msg:null,
        mainData:null,
        searchAction:null,nameSearch:null, startFrom:null,endAt:null, 
        alertStatus :false, alertType :"error", alertTitle:null,
    }
  
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,mainData:userData})

            }else if(userDataStatus===false){
                this.setState({isLoading:false,mainData:null,closedLoanDataList:[],alertStatus :true, alertType :"error", alertTitle:msg})
            }
        }  
    }



    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt} = this.state

        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
            this.setState({isLoading:true})
            const data = new FormData();
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            this.props.getData(data,"GIVEN_OUTDATA")
            
        }
    }

    render() {
        const {mainData,nameSearch} = this.state;

     
        /**displaying  users*/
        let activeLoanDataList = [];

        let myNo1 = 0
        let amountGiven1 = 0
        let totalBalance1 = 0
        if(mainData !==null){
            const myUserData =  this.state.mainData.loanData
            if(myUserData.length>0){
                myNo1 = 0
                amountGiven1=0
                totalBalance1=0
      
                myUserData.map((values, i) =>{
                    myNo1 = myNo1+1
                    amountGiven1 = amountGiven1+parseInt(values.amount)
                    totalBalance1 = totalBalance1+parseInt(values.amountExpected -values.amountPaid)
                    activeLoanDataList.push(
                        <tr key={i}>
                            <td>{myNo1}</td>
                            <th scope="row">{values.loanID}</th>
                            <td>{values.member.firstName+" "+values.member.lastName}</td>
                            <td>{values.accNo}</td>
                            <td>{values.duration}</td>
                            <td>{values.processingFee}</td>
                            <td>{values.intrestRate}</td>
                            <td>{values.amount}</td>
                            <td>{values.amountExpected}</td>
                            <td>{values.amountPaid}</td>
                            <td>{values.amountExpected -values.amountPaid}</td>
                            <td>{values.startDate}</td>
                            <td>{values.endDate}</td>
                            <td>{values.monthlyPay}</td>
                            <td>{values.security}</td>
                            <td>{values.guarator ==null? "": values.guarator.firstName+" "+values.guarator.lastName}</td>
                            <td>{values.approvedby.firstName+" "+values.approvedby.lastName}</td>
                            <td>{values.created_AT}</td>
                        </tr>
                    )
                })
            }
        }

    
        const searchword = nameSearch
        const searchAction = this.state.searchAction
    
        if (searchword) {
            if(activeLoanDataList.length>0){
                if(this.state.mainData !==null){
                    const myUserData =  mainData.loanData
                    if(myUserData.length>0){
                        myNo1 = 0
                        amountGiven1=0
                        totalBalance1=0
                        activeLoanDataList = []
                        myUserData.map((values, i) =>{
   
                            if(searchAction ==="LOANID"){
                                if(values.loanID === searchword.toUpperCase() ){
                                    myNo1 = myNo1+1
                                    amountGiven1 = amountGiven1+parseInt(values.amount)
                                    totalBalance1 = totalBalance1+parseInt(values.amountExpected -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo1}</td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.member.firstName+" "+values.member.lastName}</td>
                                            <td>{values.accNo}</td>
                                            <td>{values.duration}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrestRate}</td>
                                            <td>{values.amount}</td>
                                            <td>{values.amountExpected}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.amountExpected -values.amountPaid}</td>
                                            <td>{values.startDate}</td>
                                            <td>{values.endDate}</td>
                                            <td>{values.monthlyPay}</td>
                                            <td>{values.security}</td>
                                            <td>{values.guarator ==null? "": values.guarator.firstName+" "+values.guarator.lastName}</td>
                                            <td>{values.approvedby.firstName+" "+values.approvedby.lastName}</td>
                                            <td>{values.created_AT}</td>
                                        </tr>
                                    )
                                }
                            }else if(searchAction ==="ACCOUNT"){
                                if(values.accNo === searchword.toUpperCase() ){
                                    myNo1 = myNo1+1
                                    amountGiven1 = amountGiven1+parseInt(values.amount)
                                    totalBalance1 = totalBalance1+parseInt(values.amountExpected -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                             <td>{myNo1}</td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.member.firstName+" "+values.member.lastName}</td>
                                            <td>{values.accNo}</td>
                                            <td>{values.duration}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrestRate}</td>
                                            <td>{values.amount}</td>
                                            <td>{values.amountExpected}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.amountExpected -values.amountPaid}</td>
                                            <td>{values.startDate}</td>
                                            <td>{values.endDate}</td>
                                            <td>{values.monthlyPay}</td>
                                            <td>{values.security}</td>
                                            <td>{values.guarator ==null? "": values.guarator.firstName+" "+values.guarator.lastName}</td>
                                            <td>{values.approvedby.firstName+" "+values.approvedby.lastName}</td>
                                            <td>{values.created_AT}</td>
                                        </tr>
                                    )
                                }
                            }
                        });
                    }
                }
            }else{
                alert("No data found, Consider setting search range")
            }
        }
        
        return (
            <div>
               
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW GIVEN OUT LOANS</h4>
             

                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Col xs="12" md="12" className="tableWrapper">
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>

                            <Col md="12" xs="12">
                                    <Label className='balB'>TOTAL LOANS : {myNo1.toLocaleString()}, TOTAL AMOUNT GIVEN :{amountGiven1.toLocaleString()}, TOTAL LOAN BAL : {totalBalance1.toLocaleString()}</Label>
                            </Col>
                        </Row>
                        <div className='table-container'>
                            <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Loan ID</th>
                                            <th>Mmember</th>
                                            <th>Account</th>
                                            <th>Loan Duration</th>
                                            <th>Processing Fee</th>
                                            <th>Rate(%)</th>
                                            <th>Loan Amount</th>
                                            <th>Amount Expected</th>
                                            <th>Amount Paid</th>
                                            <th>Loan Balance</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Installment</th>
                                            <th>Security</th>
                                            <th>Guarator</th>
                                            <th>Approved By</th>
                                            <th>Date</th>
        
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                            <th></th>
                                            <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="ACCOUNT" ?this.state.nameSearch:null} id="ACCOUNT"/></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                            {activeLoanDataList}
                                    
                                    </tbody>}
                            </Table>
                       </div>
                        </Col>
                        
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.getMyDataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.getMyData,
  
 });
export default connect(
    mapStateToProps,
    {getData}
)(SeeGivenOut)
