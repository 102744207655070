import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,FormGroup,Input, Button,Modal,
     ModalBody, ModalFooter,ModalHeader,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {addUser,getMainData} from "../../Redux/ducks/mainReducer";
import {getName,getName2,checkRight} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import MultiSelect from "@kenshooui/react-multi-select";
export class SeeUsers extends Component {
    state={
        isLoading:false,
   
        userData:null,
        catData:null,
        showData:null,

        msg:null,
        modal:false,
    
        currentID:'',
        isUpdating:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,
        isModalLoading:false,


        subData:[],
        rightList:[],
        selectedRight: [],
        alertStatus :false, alertType :"error", alertTitle:null,
        accType:"",branch:"",surname:"",firstname:"",othername:"",gender:"",dob:"",marital_status:"",
        religion:"",title:"",nin:"",phone:"",phone2:"",email:"",password1:"",
        password2:"",photo:"",address:"",nin:"",position:"",kin:"",kinPhone:"",username:"",
        branchDataList:[],religionDataList:[],catDataList:[],selectedRight:[],branchesList:[],
        selectedBranch:[],mainData:null,searchAction:null,nameSearch:null,refNo:"",adminStatus:null,

        details:false,summary:false,giveLoan:false,payLoan:false,
        loanDetails:false,loanPayments:false,chart:false,addFines:false,
        activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false,

        loanType:"",rate:"20",pFee:"",payMode:"INSTALLMENTS",amount:"",period:"30",startPay:"",schedule:"1",g2Relationship:"",g1Relationship:"",
            action:"AUTOMATIC RENEWA",staff:"",security:"",secDes:"",g1Name:"",g1Phone1:"",g1Phone2:"",g2Name:"",g2Phone1:"",g2Phone2:"",modalPreview:false,
            
            activeLoanStatus:null,
    }
    componentDidMount(){
        this.setState({isLoading:true})
        this.props.getMainData("USER");
    }

    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus,updateStatus} = this.props;
        
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,userData:userData.userData,msg:msg,mainData:userData})

            }else if(userDataStatus===false){
                this.setState({isLoading:true,userData:null,msg:null})
            }
        }

        //update status
        if(updateStatus !== prevProps.updateStatus){
            if(updateStatus===true){
                this.setState({
                    isUpdating:false, btnDisabled:false,
                    alertStatus :true, alertType :"success", alertTitle:msg
              })
            }
            if(updateStatus===false){
                this.setState({
                    isUpdating:false, btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg
              })
            }
        }
    }
    /**for multiple select */
    handleSelectChange = (selectedRight) =>{
        this.setState({ selectedRight});
    }
    handleSelectChange2 = (selectedBranch) =>{
        this.setState({ selectedBranch});
    }

    closeModal = ()=>{
        this.setState({modal:false,passwordModal:false})
        //refresh categories
        this.props.getMainData("USER");
    }
   
    viewUser=async(e)=>{
      
        const selectedID = e.target.id;
        /**get cat details */
    
        const myUserData =  this.state.userData

        let savedReligion = null
        let savedAccount = null
        let notRights = null
        myUserData.map((values, i) =>{
         
            if(parseInt(values.id) === parseInt(selectedID)){
    
                savedReligion = values.religion
                savedAccount = values.catID
                notRights = values.rightsNotAllowed
        
                this.setState({
                    modal:true,currentID:selectedID,
                    isUpdating:false,btnDisabled:false,
                    accType:values.catID,branch:values.branchID,surname:values.lastName,firstname:values.firstName,gender:values.gender,
                    dob:values.dob,religion:values.religion,nin:values.nin,phone:values.phone,phone2:values.phone2,
                    email:values.email,photo:values.image,address:values.address,kin:values.kin,
                    kinPhone:values.kinPhone,username:values.clubNumber,password1:"",password2:"",status:values.status,refNo:values.referenceNo,
                    adminStatus:values.adminLogin,

                    details:true,summary:false,giveLoan:false,payLoan:false,
                    loanDetails:false,loanPayments:false,chart:false,addFines:false,generateLoanRequest:false,
                    activeFines:false,paidFines:false,forgivenFines:false,editLoan:false, modalPreview:false,reopenLoan:false,
                })
            }
            
        
        });
       
    
        //religionDataList
        const religionDataList = [];
        if(this.state.mainData !==null){
            const myreligionData =  this.state.mainData.religionData
            //loop through the array
            myreligionData.map((values,i) =>{
                if(parseInt(values.id) ===parseInt(savedReligion)){
                    this.setState({religion:values.id})
                    religionDataList.push(
                        <option key={i} value={values.id}>{values.name}</option>
                    )
                }
               
            });

            myreligionData.map((values2,i) =>{
                if(parseInt(values2.id) !==parseInt(savedReligion)){
                    religionDataList.push(
                        <option key={i} value={values2.id}>{values2.name}</option>
                    )
                }
               
            });
          
        }

        ////rights
        var notRights2 = notRights.substring(1, notRights.length-1);
        const arrayNotRights = notRights2.split(',');
        let arrayNotRights2 =[]
        for (let i = 0; i < arrayNotRights.length; i++) {
            const sRight = parseInt(arrayNotRights[i].replace(/"/g, ''))
            arrayNotRights2.push(sRight)
        }
        const catDataList = [];
        let selectedRight =[]
        let rightList =[]
        if(this.state.mainData !==null){
            const myCatData =  this.state.mainData.catData
            myCatData.map((values,i) =>{
                if(parseInt(values.catID) ===parseInt(savedAccount)){
                    this.setState({position:values.catID})
                    catDataList.push(
                        <option key={i} value={values.catID}>{values.catName}</option>
                    )

                    var rights = values.rights.substring(1, values.rights.length-1);
                    const arrayRight = rights.split(',');
                    for (let i = 0; i < arrayRight.length; i++) {
                        const sRight = parseInt(arrayRight[i].replace(/"/g, ''))
                        this.state.mainData.rightsData.map((values3, k) =>{
                            if(sRight ===parseInt(values3.id)){
                                rightList.push(
                                    {id: values3.id, label: values3.name}
                                )

                                if (arrayNotRights2.includes(values3.id)){
                                   console.log("right maches")
                                }else{
                                    selectedRight.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                }
                            }
                            
                        });
                    }


                }
            });

            //loop through the array
            myCatData.map((values,i) =>{
                if(parseInt(values.catID) !==parseInt(savedAccount)){
                    catDataList.push(
                        <option key={i} value={values.catID}>{values.catName}</option>
                    )
                }        
            });
        }
 
        
        this.setState({
            modal:true,currentID:selectedID,
            isUpdating:false,btnDisabled:false,
            religionDataList:religionDataList,
            catDataList:catDataList,
            selectedRight:selectedRight,
            rightList:rightList
        })
    }
    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})

        if(e.target.name ==="position"){
            let catID = e.target.value

            ////rights
            let selectedRight =[]
            let rightList =[]
            if(this.state.mainData !==null){
                const myCatData =  this.state.mainData.catData
                myCatData.map((values,i) =>{
                    if(parseInt(values.catID) ===parseInt(catID)){
                
                        var rights = values.rights.substring(1, values.rights.length-1);
                        const arrayRight = rights.split(',');
                        for (let i = 0; i < arrayRight.length; i++) {
                            const sRight = parseInt(arrayRight[i].replace(/"/g, ''))
                            this.state.mainData.rightsData.map((values3, k) =>{
                                if(sRight ===parseInt(values3.id)){
                                    rightList.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                    selectedRight.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                    
                                }
                                
                            });
                        }
                    }
                });
            }
 
        
            this.setState({
                selectedRight:selectedRight,
                rightList:rightList
            })
        }
    }
      /**add new user*/
    updateData =(e)=>{
        if(checkRight(62)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{

            const {surname,firstname,gender,dob,
                religion,phone,phone2,email,password1,password2,address,nin,position,kin,kinPhone,
                rightList,selectedRight,currentID,status,adminStatus} = this.state;
            if(surname===""||firstname===""||phone===""||address===""||nin===""||position===""){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Please enter all fields marked with *"})
            }else if(selectedRight.length<1){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Select at least one right"})
            }else if(password1 !=="" && (password1 !==password2)){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Passwords do not match"})
            }else{
                this.setState({erroModalMsg:"",isUpdating:true,btnDisabled:true})
            
                /**arranging  rights */
                const rightSelectedList = [];
                selectedRight.map((values) =>{
                    rightSelectedList.push(values.id)
                })

                /**arranging  rights */
                const rightList2 = [];
                rightList.map((values) =>{
                    rightList2.push(values.id)
                })

           


                const data = new FormData();
                data.append("surname",surname);
                data.append("firstname",firstname);
                data.append("gender",gender);
                data.append("dob",dob);
                data.append("religion",religion);
                data.append("address",address);
                data.append("nin",nin);
                data.append("phone",phone);
                data.append("rightsList",rightList2);
                data.append("rightSelectedList",rightSelectedList);
                data.append("phone2",phone2);
                data.append("email",email);
                data.append("password1",password1);
                data.append("password2",password2);
                data.append("position",position);
                data.append("kin",kin);
                data.append("kinPhone",kinPhone);
                data.append("currentID",currentID);
                data.append("status",status);
                data.append("adminStatus",adminStatus);
                
                /**upload new user*/
                this.props.addUser(data,"UPDATE_USER")
            }
        }
    }
    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    setActions= e =>{
        const v = e.target.value
        this.setState({erroModalMsg:"",successModalMsg:'',isUpdating:false,showPayDetails:false,paymentDataList:[],
        /**payment chart */
        selectedDate:null,
        chartStartDate:null,chartEndDate:null,searchLoanPaymentChart:"",searchloanIDChart:"", showCalender:false,
        chartList:[],loanStartDate:null,receiptPath:null
        })
        if (v==="1"){
            this.setState({details:true,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="2"){
            this.setState({details:false,summary:true,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="3"){
            this.setState({details:false,summary:false,giveLoan:true,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="4"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:true,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="5"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:true,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="6"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:true,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="7"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:true,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        
        if (v==="8"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:true,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="9"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:true,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="10"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:true,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="11"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:true,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="12"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:true,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="13"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,reopenLoan:false,generateLoanRequest:true})
        }

        if (v==="14"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:true})
        }


    }
    addLoan=(e)=>{
        
        const {loanType,rate,pFee,amount,period,startPay,schedule,
            staff,security,g1Name,g1Phone1,g2Name,g2Phone1,giveLoan,generateLoanRequest,
            rloanType,rrate,rpFee,ramount,rperiod,rschedule,
            rstaff,rsecurity,rg1Name,rg1Phone1,rg2Name,rg2Phone1,ridPosition,idPosition} = this.state

        this.setState({isUpdating:false})
          
        if(loanType===""||rate===""||amount==="" ||period==="" ||startPay==="" ||schedule===""||
        security===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Please enter all fields marked with a *"})
        }else if(parseInt(rate)<1 ||parseInt(rate)>100){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Interest rate must be between 1 and 100"})
        }else{
            /*const data = new FormData();
            data.append("loanID",null);
            data.append("loanType",rloanType);
            data.append("rate",rrate);
            data.append("pFee",rpFee);
            data.append("payMode",rpayMode);
            data.append("amount",ramount);
            data.append("period",rperiod);
            data.append("action",raction);
            data.append("schedule",rschedule);
            data.append("g2Relationship",rg2Relationship);
            data.append("g1Relationship",rg1Relationship);
            data.append("action",action);
            data.append("staff",rstaff);
            data.append("security",rsecurity);
            data.append("secDes",rsecDes);
            data.append("g1Name",rg1Name);
            data.append("g1Phone1",rg1Phone1);
            data.append("g1Phone2",rg1Phone2);
            data.append("g2Name",rg2Name);
            data.append("g2Phone1",rg2Phone1);
            data.append("g2Phone2",rg2Phone2);
            data.append("userID",currentID);
            data.append("idPosition",ridPosition);
            data.append("currentAddress",rcurrentAddress);
            data.append("loanAction","ADD_LOAN_REQUEST");
            this.props.addLoanData(data,"SUBMITAPPROVAL")*/
        }
        
       

    }

    confirm=(e)=>{
        const {generateLoanRequest,editLoan,loanType,rate,pFee,payMode,amount,period,startPay,schedule,g2Relationship,g1Relationship,
            action,staff,security,secDes,g1Name,g1Phone1,g1Phone2,g2Name,g2Phone1,g2Phone2,currentID,clientDetailsData,idPosition,
        
            rloanType,rrate,rpFee,rpayMode,ramount,rperiod,rschedule,rg2Relationship,rg1Relationship,rcurrentAddress,
            raction,rstaff,rsecurity,rsecDes,rg1Name,rg1Phone1,rg1Phone2,rg2Name,rg2Phone1,rg2Phone2,ridPosition
        
        } = this.state
        
        this.setState({isUpdating:true,btnDisabled:true})
        if(generateLoanRequest===true){
            if(checkRight(99)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to submit loan request approval"})
            }else{
                const data = new FormData();
                data.append("loanID",null);
                data.append("loanType",rloanType);
                data.append("rate",rrate);
                data.append("pFee",rpFee);
                data.append("payMode",rpayMode);
                data.append("amount",ramount);
                data.append("period",rperiod);
                data.append("action",raction);
                data.append("schedule",rschedule);
                data.append("g2Relationship",rg2Relationship);
                data.append("g1Relationship",rg1Relationship);
                data.append("action",action);
                data.append("staff",rstaff);
                data.append("security",rsecurity);
                data.append("secDes",rsecDes);
                data.append("g1Name",rg1Name);
                data.append("g1Phone1",rg1Phone1);
                data.append("g1Phone2",rg1Phone2);
                data.append("g2Name",rg2Name);
                data.append("g2Phone1",rg2Phone1);
                data.append("g2Phone2",rg2Phone2);
                data.append("userID",currentID);
                data.append("idPosition",ridPosition);
                data.append("currentAddress",rcurrentAddress);
                data.append("loanAction","ADD_LOAN_REQUEST");
                this.props.addLoanData(data,"SUBMITAPPROVAL")
            }
            
        }else if(editLoan===false){
            if(checkRight(28)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to add loan"})
            }else{
                /**upload new loan*/
                const data = new FormData();
                data.append("loanID",null);
                data.append("loanType",loanType);
                data.append("rate",rate);
                data.append("pFee",pFee);
                data.append("payMode",payMode);
                data.append("amount",amount);
                data.append("period",period);
                data.append("startPay",startPay);
                data.append("schedule",schedule);
                data.append("g2Relationship",g2Relationship);
                data.append("g1Relationship",g1Relationship);
                data.append("action",action);
                data.append("staff",staff);
                data.append("security",security);
                data.append("secDes",secDes);
                data.append("g1Name",g1Name);
                data.append("g1Phone1",g1Phone1);
                data.append("g1Phone2",g1Phone2);
                data.append("g2Name",g2Name);
                data.append("g2Phone1",g2Phone1);
                data.append("g2Phone2",g2Phone2);
                data.append("userID",currentID);
                data.append("idPosition",idPosition);
                data.append("loanAction","ADD_LOAN");
                this.props.addLoanData(data,"ADDLOAN")
            }
            
        }else{
            //edit loan
            if(checkRight(79)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to edit loan"})
            }else{
                /**upload new loan*/
                const data = new FormData();
                data.append("loanID",clientDetailsData.activeLoanData.id);
                data.append("loanAction","EDIT_LOAN");
                data.append("loanType",loanType);
                data.append("rate",rate);
                data.append("pFee",pFee);
                data.append("payMode",payMode);
                data.append("amount",amount);
                data.append("period",period);
                data.append("startPay",startPay);
                data.append("schedule",schedule);
                data.append("g2Relationship",g2Relationship);
                data.append("g1Relationship",g1Relationship);
                data.append("action",action);
                data.append("staff",staff);
                data.append("security",security);
                data.append("secDes",secDes);
                data.append("g1Name",g1Name);
                data.append("g1Phone1",g1Phone1);
                data.append("g1Phone2",g1Phone2);
                data.append("g2Name",g2Name);
                data.append("g2Phone1",g2Phone1);
                data.append("g2Phone2",g2Phone2);
                data.append("userID",currentID);
                this.props.addLoanData(data,"ADDLOAN")
            }
        }
    }
    render() {
        const {mainData} =this.state
        /**displaying  users*/
        let userDataList = [];
        if(this.state.userData !==null){
            const myUserData =  this.state.userData
            
            let no = 0
            if(myUserData.length>0){
                myUserData.map((values, i) =>{
                
                        no = no+1
                        userDataList.push(
                            <tr key={i}>
                                <th scope="row">{no}</th>
                              
                                <td>{getName2(this.state.mainData.catData,values.catID,"ACCOUNT")}</td>
                                <td>{values.clubNumber}</td>
                                <td>{values.firstName}</td>
                                <td>{values.lastName}</td>
                                <td>{values.address}</td>
                                <td>{values.phone}</td>
                                <td>{values.created_at}</td>
                                {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                <td><Label className="status2">Inactive</Label></td>
                                }
                            
                                <td><Button id={values.id} onClick={this.viewUser}>View Details</Button></td>
                            </tr>
                        )
                    
                });
            }else{
                userDataList.push(
                    <div>
                        <br/><Alert color="primary">No user data uploaded yet</Alert>
                    </div>
                )
            }
        }

        /**displaying  categories*/
        const catDataList = [];
        if(this.state.catData !==null){
            const myCatData =  this.state.catData
            //loop through the array
            if(myCatData.length>0){
                
                this.state.accType? 
                catDataList.push(<option value={this.state.accType}>{this.state.accType}</option>)
                :catDataList.push(<option value="">SELECT ACCOUNT TYPE</option>)
                myCatData.map((values, i) =>{
                    catDataList.push(
                        <option value={values.catName}>{values.catName}</option>
                    )
                
                });
            }else{
                catDataList.push(
                    <option value="">No category uploaded yet</option>   
                )
            }
        }

     

        const catDataList2 = [];
        if(this.state.mainData !==null){
            const myCatData2 =  this.state.mainData.catData
            //loop through the array
            if(myCatData2.length>0){
                catDataList2.push(<option value="">SELECT ACCOUNT</option>)
                myCatData2.map((values, i) =>{
                    catDataList2.push(
                        <option value={values.catID}>{values.catName}</option>
                    )
                
                });
            }
        }
       
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
            if(userDataList.length>0){
                if(mainData !==null){
                    let myUserData =  mainData.userData
                    userDataList = []
             
                    if(myUserData.length>0){
                        let no = 0
                        myUserData.map((values, i) =>{
                           
                           if(searchAction ==="NAME"){
                                
                                if(values.clubNumber === searchword.toUpperCase()){
                                    no = no+1
                                    userDataList.push(
                                        <tr key={i}>
                                            <th scope="row">{no}</th>
                                
                                            <td>{getName2(this.state.mainData.catData,values.catID,"ACCOUNT")}</td>
                                            <td>{values.clubNumber}</td>
                                            <td>{values.firstName}</td>
                                            <td>{values.lastName}</td>
                                            <td>{values.address}</td>
                                            <td>{values.phone}</td>
                                            <td>{values.created_at}</td>
                                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                            <td><Label className="status2">Inactive</Label></td>
                                            }
                                        
                                            <td><Button id={values.id} onClick={this.viewUser}>View Details</Button></td>
                                        </tr>
                                    )
                                    
                                }
                        
                            }else if(searchAction ==="ACCOUNT"){
                                if(parseInt(values.catID) === parseInt(searchword)){
                                    no = no+1
                                    userDataList.push(
                                        <tr key={i}>
                                            <th scope="row">{no}</th>
                                            <td>{getName2(this.state.mainData.catData,values.catID,"ACCOUNT")}</td>
                                            <td>{values.clubNumber}</td>
                                            <td>{values.firstName}</td>
                                            <td>{values.lastName}</td>
                                            <td>{values.address}</td>
                                            <td>{values.phone}</td>
                                            <td>{values.created_at}</td>
                                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                            <td><Label className="status2">Inactive</Label></td>
                                            }
                                        
                                            <td><Button id={values.id} onClick={this.viewUser}>View Details</Button></td>
                                        </tr>
                                    )
                                }
                            }
                        });

                    
                    }
                }
            }
        }

        //loanTypesDataList
        const loanTypesDataList = [];
        if(this.state.mainData !==null){
            const myCatData2 =  this.state.mainData.loantypeData

            //loop through the array
            if(myCatData2.length>0){
                loanTypesDataList.push(<option value="">Select Loan Type</option>)
                myCatData2.map((values, i) =>{
                    loanTypesDataList.push(
                        <option value={values.id}>{values.name}</option>
                    )
                
                });
            }
        }

        const monthsList = [];
        monthsList.push(
            <option value="0">Select Loan Period</option>
        )
        for(var x=1;x<=24;x++){
            monthsList.push(
                <option value={x}>{x}</option>
            )
        }


        
        

        return (
            <div>
                <div className="">
                <Row className="">
                    <div className="block-header">
                    <h4>VIEW MEMBER</h4>
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Member Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                            {this.state.isModalLoading?
                                <div><br/>
                                    <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                </div>:
                                <div>
                                    <FormGroup className="loanBox2">
                                        <Input type="select" onChange={this.setActions} className="loanBox" name="payMode">
                                            <option value="1">MEMBER DETAILS</option>
                                            <option value="4">SEE PAYMENTS</option> 
                                            <option value="4">LOAN DETAILS</option>
                                            <option value="4">SEE FINES</option>
                                            <option value="4">CURRENT REPORT</option>





                                            {/*<option value="13">REQUEST LOAN APPROVAL</option>
                                            <option value="4">PAY LOAN</option>
                                            <option value="12">EDIT LOAN</option>
                                            <option value="14">REOPEN CLOSED LOAN</option>
                                            
                                            {/*<option value="2">ACCOUNT SUMMARY</option> 
                                            <option value="5">LOAN DETAILS</option> 
                                            <option value="6">LOAN PAYMENTS</option> 
                                            <option value="7">PAYMENT CHART</option> 
                                            <option value="8">ADD FINES</option> 
                                            <option value="9">SEE FINES</option> 
                                            {/*<option value="10">PAID FINES</option>  
                                            <option value="11">FORGIVEN FINES</option>*/} 
                                         
                                        </Input>
                                    </FormGroup><br/>

                                    {/**Client Details */}
                                    {this.state.details ===true?
                                    <Row> 
                                        <Col xs="6" >
                                            
                                        
                                            <FormGroup>
                                                <Label for="name">Club Number*</Label>
                                                <Input type="text"  disabled onChange={this.setValue} value={this.state.username} name="username" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="name">Reference Number</Label>
                                                <Input type="text"  disabled onChange={this.setValue} value={this.state.refNo} name="refNo" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="username">Surname*</Label>
                                                <Input type="text" onChange={this.setValue} value={this.state.surname} name="surname" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="username">Firstname*</Label>
                                                <Input type="text" onChange={this.setValue} value={this.state.firstname} name="firstname"/>
                                            </FormGroup>
                                        
                                            <FormGroup>
                                                <Label for="gender">Gender*</Label>
                                                <Input type="select" onChange={this.setValue} name="gender">
                                                    {this.state.gender ==="Male"?<option value="Male">Male</option>:
                                                    <option value="Female">Female</option> 
                                                    }
                                                    <option value="Male">Male</option> 
                                                    <option value="Female">Female</option> 
                                                </Input>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="dob">Date Of Birth</Label>
                                                <Input type="date" onChange={this.setValue} value={this.state.dob} name="dob"/>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="religion">Religion</Label>
                                                <Input type="select" onChange={this.setValue} name="religion">
                                                    {this.state.religionDataList}
                                                </Input>
                                            </FormGroup>
                                        
                                            <FormGroup>
                                                <Label for="username">Address*</Label>
                                                <Input type="text" onChange={this.setValue} value={this.state.address} name="address"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="username">National ID*</Label>
                                                <Input type="text" onChange={this.setValue} value={this.state.nin} name="nin"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="phone">Phone No.*</Label>
                                                <Input type="number" onChange={this.setValue} value={this.state.phone} name="phone"/>
                                            </FormGroup>
                                            
                                        </Col>
                                        <Col xs="6" >
                                            <FormGroup>
                                                <Label for="phone2">Phone No. 2</Label>
                                                <Input type="number" onChange={this.setValue} value={this.state.phone2} name="phone2" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <Input type="email" onChange={this.setValue} value={this.state.email} name="email" />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="position">Select Account Type*</Label>
                                                <Input type="select" onChange={this.setValue} name="position">
                                                    {this.state.catDataList}
                                                </Input>
                                            </FormGroup>

                                        
                                            <FormGroup>
                                                <Label for="teaching_sub">Rights *(Uncheck to revoke right)</Label>
                                                <MultiSelect 
                                                    responsiveHeight={'210px'}
                                                    items={this.state.rightList}
                                                    selectedItems={this.state.selectedRight}
                                                    onChange={this.handleSelectChange}
                                                />
                                            </FormGroup>

                                            
                                            <FormGroup>
                                                <Label for="username">Next of Kin</Label>
                                                <Input type="text" onChange={this.setValue} value={this.state.kin} name="kin"/>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="username">Next of Kin Phone</Label>
                                                <Input type="text" onChange={this.setValue} value={this.state.kinPhone} name="kinPhone"/>
                                            </FormGroup> 

                                            <FormGroup>
                                                <Label for="password1">New Password</Label>
                                                <Input type="password" onChange={this.setValue} value={this.state.password1} name="password1"/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="password2">Confrim New Password</Label>
                                                <Input type="password" onChange={this.setValue} value={this.state.password2} name="password2"/>
                                            </FormGroup>

                                        </Col>
                                        <Col xs="12" md="12">
                                            <FormGroup>
                                                <Label for="password2">Member Status</Label>
                                                <Input type="select" disabled onChange={this.setValue} name="status">
                                                    {parseInt(this.state.status) ===1?
                                                    <option value="1">Active</option>:
                                                    <option value="0">Deactivated</option>
                                                    }
                                                    <option value="1">Active</option>
                                                    <option value="0">Deactivated</option> 
                                                </Input>
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="password2">Use Admin Dashboard</Label>
                                                <Input type="select" onChange={this.setValue} name="adminStatus">
                                                    {parseInt(this.state.adminStatus) ===1?
                                                    <option value="1">Yes</option>:
                                                    <option value="0">No</option>
                                                    }
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option> 
                                                </Input>
                                            </FormGroup>
                                            {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Updating...'}</div>:null}

                                            <Button onClick={this.updateData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Update User</Button>
                                        </Col>
                                    </Row>:null}

                                     {/**giveLoan */}
                                     {this.state.giveLoan ===true?
                                    <>
                                        {checkRight(28)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            {parseInt(this.state.activeLoanStatus) ===1?
                                                <Row>
                                                    <Col xs="12" md="12">
                                                        <Label className="activeLoan">Client has active loan</Label>
                                                    </Col>
                                                </Row>:
                                                <Row>
                                                 
                                                       
                                                        <>
                                                            <Col xs="6" md="6">
                                                                <FormGroup className="loanBox2">
                                                                    <Label className="loanLabel">Loan Type*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="loanType">
                                                                            {loanTypesDataList}
                                                                    </Input>
                                                                </FormGroup>

                                                                <FormGroup  className="loanBox2">
                                                                    <Label className="loanLabel">Interest(%)*</Label>
                                                                    <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rate} name="rate"  />
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Payment Mode*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="payMode">
                                                                
                                                                        <option value="INSTALLMENTS">INSTALLMENTS</option> 
                                                                        <option value="PAY AT ONCE">PAY AT ONCE</option> 
                                                                    </Input>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label  className="loanLabel">Loan Amount(Shs)* </Label>
                                                                    <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.amount} name="amount"/>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label  className="loanLabel">Processing Fee</Label>
                                                                    <Input type="number" min={0}   onChange={this.setValue} className="loanBox" value={this.state.pFee} name="pFee"/>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Loan Period(months)*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="period">
                                                                        {monthsList}
                                                                    </Input>
                                                                </FormGroup>

                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Start Payment*</Label>
                                                                    <Input type="date"  onChange={this.setValue} className="loanBox" value={this.state.startPay} name="startPay" />
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label className="loanLabel">Payment Schedule(days)*</Label>
                                                                    <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.schedule} name="schedule"/>
                                                                </FormGroup>

                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Expired Loan Action*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="action">
                                                                        <option value="AUTOMATIC RENEWAL">AUTOMATIC RENEWAL</option> 
                                                                        <option value="AUTOMATIC FINING">AUTOMATIC FINING</option> 
                                                                    </Input>
                                                                </FormGroup>
                                                            
                                                            </Col>

                                                            <Col xs="6" md="6">
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="name" className="loanLabel">Security Type*</Label>
                                                                    <Input type="text"   className="loanBox" onChange={this.setValue} value={this.state.security} name="security"/>
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Security Description</Label>
                                                                    <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.secDes} name="secDes" />
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 1 Name*</Label>
                                                                    <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.g1Name} name="g1Name"/>
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 1 Phone*</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g1Phone1} name="g1Phone1" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 1 Phone 2</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g1Phone2} name="g1Phone2"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Relationship</Label>
                                                                    <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.g1Relationship} name="g1Relationship" />
                                                                </FormGroup>

                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 2 Name*</Label>
                                                                    <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.g2Name} name="g2Name" />
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 2 Phone*</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g2Phone1} name="g2Phone1"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 2 Phone 2</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g2Phone2} name="g2Phone2"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Relationship</Label>
                                                                    <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.g2Relationship} name="g2Relationship"/>
                                                                </FormGroup>
                                                                
                                                               
                                                            </Col>
                                                        </>

                                                   

                                                    <Col xs="12" md="12"><br/>
                                                        <FormGroup className="loanBox2">
                                                            {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                        </FormGroup>
                                                    </Col>

                                                    <Row className="modalFooter">
                                                        <Col xs="1" md="4">.</Col>
                                                        <Col xs="10" md="4">
                                                            <Button onClick={this.addLoan} className="btn btn-success loanBtn">Add Loan</Button>
                                                        </Col>
                                                        <Col xs="1" md="4">.</Col>
                                                        </Row>
                                                </Row>
                                            }
                                        </>}
                                    </>
                                    :null}
                                </div>}
                            </ModalBody>
                        </Modal>: null}
                        <div className='table-container'>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>No</th>
                                  
                                    <th>Account Type</th>
                                    <th>Club Number</th>
                                    <th>Firstname</th>
                                    <th>Lastname</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Created AT</th>
                                    <th>Status</th>
                                    <th>View User</th>
                                </tr>
                                <tr>
                                    <th></th>
                                   
                               
                                    <th><Input type="select"  className="clientName"  onChange={this.setSearchValue} name="branch2" id="ACCOUNT">
                                            {catDataList2}
                                        </Input></th>
                               
                                    <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                    
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                                {userDataList}
                            </tbody>}
                        </Table>
                        </div>

                     
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
    showData : state.mainReducer.showData,
    catData : state.mainReducer.catData,
    catStatus : state.mainReducer.catStatus,
    updateStatus:state.mainReducer.addUserStatus,
  
  });
export default connect(
    mapStateToProps,
    {addUser,getMainData}
)(SeeUsers)
