import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'
import MultiSelect from "@kenshooui/react-multi-select";
import ReactJsAlert from "reactjs-alert"
export class AddAccount extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        selectedRight: [],
        alertStatus :false, alertType :"error", alertTitle:null,
        cdate:null,accDes:""

    }

    componentDidMount(){
        this.props.getMainData("ACCOUNTS")
    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData})

            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg, cdate:"",selectedRight:[]
                })

                /**refresh categories */
                this.props.getMainData("ACCOUNTS")
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    addCategory =(e)=>{
        const {cdate,selectedRight,accDes} = this.state;

        if(cdate===null){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Enter new account type"})
        }else if(accDes===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select account descriptor"})
        }else if(selectedRight.length<1){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select atleast one right"})
        }else{
            this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})

            /**arranging  subjects */
            const rightList = [];
            selectedRight.map((values) =>{
                rightList.push(values.id)
            })
            const data = new FormData();
            data.append("rightList",rightList);
            data.append("name",cdate);
            data.append("accDes",accDes);
            /**upload new user*/
            this.props.addUser(data,"ADD_ACCOUNT")
        }
    }

    /**for multiple select */
    handleSelectChange = (selectedRight) =>{
        this.setState({ selectedRight});
    }


    render() {
        let rightDataList = [];
        let descriptorDataList =[]
        const myUserData =  this.state.mainData
       
        if(myUserData !==null){
            if(myUserData.rightsData.length>0){
                myUserData.rightsData.map((values, i) =>{
                    rightDataList.push(
                        {id: values.id, label: values.name}
                    )
                });
            }

            myUserData.descriptorData.map((values, i) =>{
                descriptorDataList.push(
                    <option value={values.id}>{values.name}</option>
                )
            });
        }
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>ADD ACCOUNT TYPE</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        <Col xs="12" >
                            {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}

                            <FormGroup>
                                <Label for="username">Add New Account Type*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.cdate} name="cdate" />
                            </FormGroup>
                            <FormGroup>
                                <Label for="gender">Account Descriptor*</Label>
                                <Input type="select" onChange={this.setValue} name="accDes">
                                    <option value="">SELECT ACCOUNT DESCRIPTOR</option> 
                                    {descriptorDataList}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="teaching_sub">Select Rights *</Label>
                                <MultiSelect 
                                    responsiveHeight={'500px'}
                                    items={rightDataList}
                                    selectedItems={this.state.selectedRight}
                                    onChange={this.handleSelectChange}
                                />
                            </FormGroup>
                        
                        </Col>
                  
                        <Col xs="12" >
                            {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
                            <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Add Account Type</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(AddAccount)
