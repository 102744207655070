import {createStore, applyMiddleware,compose} from 'redux';
import thunk from 'redux-thunk';
import  reducers from "./ducks/index";

const middleware = [thunk];
const rootReducers = reducers;
const initialState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducers, initialState, composeEnhancers(
  applyMiddleware(...middleware)
));


//for only production
//const store = createStore(rootReducers,initialState, applyMiddleware(...middleware));

export default store;