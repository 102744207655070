import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData} from "../../Redux/ducks/mainReducer";
import {getName2} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
export class SeeDefaulters extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null

    }
    componentDidMount(){
        this.setState({isLoading:true})
        this.props.getMainData("GENERAL_PAYDATA")
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,msg:msg,mainData:userData})
            }else{
                this.setState({isLoading:true,mainData:null,msg:null})
            }
        }  
    }

    

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }


    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    render() {
        const { redirect,redirectSelectedID } = this.state;

        if (redirect) {
            return <Redirect to={{pathname:"/see_clients", state:{redirectSelectedID:redirectSelectedID}}}/>
        }
        /**displaying  users*/
        let activeLoanDataList = [];
        let totalPayment2 = 0
        let myNo = 0
    
        if(this.state.mainData !==null){
            const myUserData =  this.state.mainData.loanData
            if(myUserData.length>0){
                myNo = 0
                myUserData.map((values, i) =>{
                    if(parseInt(values.defaulterStatus) ===1){
                        myNo = myNo+1
                        totalPayment2= totalPayment2+parseInt(values.expectedAmount -values.amountPaid)
                        activeLoanDataList.push(
                            <tr key={i}>
                                <td>{myNo}</td>
                                <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                <th scope="row">{values.loanID}</th>
                                <td>{values.clientName}</td>
                                <td>{values.account}</td>
                                <td>{values.payMode}</td>
                                <td>{values.loanPeriod}</td>
                                <td>{values.processingFee}</td>
                                <td>{values.intrest}</td>
                                <td>{values.loanAmount}</td>
                                <td>{values.expectedAmount}</td>
                                <td>{values.amountPaid}</td>
                                <td>{values.expectedAmount -values.amountPaid}</td>
                                <td>{values.startPayDate}</td>
                                <td>{values.endPayDate}</td>
                                <td>{values.schedule}</td>
                                <td>{values.installments}</td>
                                <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                
                              
                                <td>{values.created_at}</td>
                            </tr>
                        )
                    }
                });
            }else{
                activeLoanDataList.push(
                    <div>
                        <br/><Alert color="primary">No data found</Alert>
                    </div>
                )
            }
        }

        /**account */
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
            totalPayment2 = 0
            activeLoanDataList = []
            if(this.state.mainData !==null){
                const myUserData =  this.state.mainData.loanData
                if(myUserData.length>0){
                    myNo = 0
                    myUserData.map((values, i) =>{
                        if(parseInt(values.defaulterStatus) ===1){
                            
                            if(searchAction ==="LOANID"){
                                if(values.loanID === searchword.toUpperCase() ){
                                    myNo = myNo+1
                                    totalPayment2= totalPayment2+parseInt(values.expectedAmount -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.clientName}</td>
                                            <td>{values.account}</td>
                                            <td>{values.payMode}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.loanAmount}</td>
                                            <td>{values.expectedAmount}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.expectedAmount -values.amountPaid}</td>
                                            <td>{values.startPayDate}</td>
                                            <td>{values.endPayDate}</td>
                                            <td>{values.schedule}</td>
                                            <td>{values.installments}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                            
                                          
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }else if(searchAction ==="NAME"){
                                var myarray = values.clientName.split(' ');
                                if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() || values.name === searchword.toUpperCase()){
                                    myNo = myNo+1
                                    totalPayment2= totalPayment2+parseInt(values.expectedAmount -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.clientName}</td>
                                            <td>{values.account}</td>
                                            <td>{values.payMode}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.loanAmount}</td>
                                            <td>{values.expectedAmount}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.expectedAmount -values.amountPaid}</td>
                                            <td>{values.startPayDate}</td>
                                            <td>{values.endPayDate}</td>
                                            <td>{values.schedule}</td>
                                            <td>{values.installments}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                            
                                          
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }else if(searchAction ==="ACCOUNT"){
                                if(values.account === searchword.toUpperCase() ){
                                    myNo = myNo+1
                                    totalPayment2= totalPayment2+parseInt(values.expectedAmount -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.clientName}</td>
                                            <td>{values.account}</td>
                                            <td>{values.payMode}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.loanAmount}</td>
                                            <td>{values.expectedAmount}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.expectedAmount -values.amountPaid}</td>
                                            <td>{values.startPayDate}</td>
                                            <td>{values.endPayDate}</td>
                                            <td>{values.schedule}</td>
                                            <td>{values.installments}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                            
                                          
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }else if(searchAction ==="MODE"){
                                if(values.payMode === searchword.toUpperCase() ){
                                    myNo = myNo+1
                                    totalPayment2= totalPayment2+parseInt(values.expectedAmount -values.amountPaid)
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.clientName}</td>
                                            <td>{values.account}</td>
                                            <td>{values.payMode}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.loanAmount}</td>
                                            <td>{values.expectedAmount}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.expectedAmount -values.amountPaid}</td>
                                            <td>{values.startPayDate}</td>
                                            <td>{values.endPayDate}</td>
                                            <td>{values.schedule}</td>
                                            <td>{values.installments}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                            
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }
                        }
                    });
                }
            }
        }

     
        return (
            <div>
             
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW DEFAULTERED LOAN</h4>
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <Col md="12" xs="12" className="tableWrapper">
                            <Label className='balB'>TOTAL CLIENTS : {myNo.toLocaleString()},  TOTAL AMOUNT : {totalPayment2.toLocaleString()}</Label>
                        </Col>
                        <div className='table-container'>
                        
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Details</th>
                                    <th>Loan ID</th>
                                    <th>Client Name</th>
                                    <th>Account</th>
                                    <th>Pay Mode</th>
                                    <th>Loan Duration</th>
                                    <th>Processing Fee</th>
                                    <th>Rate(%)</th>
                                    <th>Loan Amount</th>
                                    <th>Amount Expected</th>
                                    <th>Amount Paid</th>
                                    <th>Loan Balance</th>
                                    <th>Start Payment</th>
                                    <th>End Payment</th>
                                    <th>Schedule </th>
                                    <th>Installment</th>
                                    <th>Field Agent</th>
                                    <th>Added By</th>
                                    <th>Date</th>
 
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                    <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="ACCOUNT" ?this.state.nameSearch:null} id="ACCOUNT"/></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="MODE" ?this.state.nameSearch:null} id="MODE"/></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                 
                                   
                                   
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                            
                                    {activeLoanDataList}
                               
                            </tbody>}
                        </Table>
                        </div>
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
  
 });
export default connect(
    mapStateToProps,
    {getMainData}
)(SeeDefaulters)
