import React from 'react';
import {Route} from 'react-router-dom';
export const PublicRoute = ({component: Component,...rest}) => (
    <Route {...rest} component={(props) => (
        <div>
            <div id="wrapper">
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <div className="container-fluid">
                            <Component {...props} />
                        </div>
                    </div>
                </div> 
            </div>
            <a className="scroll-to-top rounded" href="#page-top">
                <i className="fas fa-angle-up"></i>
            </a> 
        </div>
    )} />
)

export default PublicRoute;