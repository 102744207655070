import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {addLoanData,getData} from "../../Redux/ducks/mainReducer";
import {datesDiffe,getName} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"

export class DailyReport extends Component {
    state={
        isLoading:false,
        msg:null,
        btnDisabled:false,
        status:null,
        reportData:null,
        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,staff:"",
        alertStatus :false, alertType :"error", alertTitle:null,
        errorMsg:null,
        myBranches:[],currentBranchID:null,branchData:null,tOpenBal:0,tCapital:0,
        tCollection:0,tPFee:0,tFines:0,tunknown:0,tRenewed:0,tCashout:0,tExpenses:0,tCloseBal:0,
        reportDataList:[]
    }

    componentDidMount(){
        const userD = JSON.parse(window.localStorage.getItem('userData'));
      
        let myBranches1 =[]
        myBranches1.push(<option value={userD.branchID}>{userD.branchName}</option>)
        this.setState({
            myBranches:myBranches1,
            currentBranchID:userD.branchID
        })

        const data = new FormData();
        data.append("userID",userD.userID);
        this.props.getData(data,"GETMYBRANCH");
    }
   
    componentDidUpdate(prevProps){
        const{msg,reportData,reportStatus,getMyData,getMyDataStatus} = this.props;
        const {currentBranchID} = this.state
       
        if(getMyDataStatus !==prevProps.getMyDataStatus){
            if(getMyDataStatus===true){
                let newBranches = this.state.myBranches
                if(getMyData.myBranches.length>0){
                    newBranches.push(<option value={"0"}>{"ALL BRANCHES"}</option>)
                    getMyData.branchData.map((values,i) =>{
                        if(parseInt(this.state.currentBranchID) !==parseInt(values.id)){
                            if(getMyData.myBranches.includes(values.id.toString())) {
                                newBranches.push(<option value={values.id}>{values.name}</option>)
                            }
                        }
                        
                    })
                }
                this.setState({branchData:getMyData,myBranches:newBranches})
            }else if(getMyDataStatus===false){
                this.setState({branchData:null})
            }

        }

        if(reportStatus !==prevProps.reportStatus){
            if(reportStatus===true){
                const myCatData = reportData
                let reportDataList =[]
                let tOpenBal=0;let tCapital=0;let tCollection=0;let tPFee=0;let tFines=0;
                let tunknown=0;let tRenewed=0;let tCashout=0;let tExpenses=0;let tCloseBal=0
                
                if(myCatData.length>0){
                    var no = 0
                   
                    if(parseInt(currentBranchID) !==0){
                        myCatData[0].map((values,i) =>{
                            no = no+1
                            tCapital= tCapital+parseInt(values.totalCapital)
                            tCollection= tCollection+parseInt(values.totalCashin)
                            tPFee= tPFee+parseInt(values.totalProcessingFee)
                            tFines= tFines+parseInt(values.totalFines)
                            tunknown= tunknown+parseInt(values.totalUnknownPayment)
                            tRenewed= tRenewed+parseInt(values.totalCashoutRenewed)
                            tCashout= tCashout+parseInt(values.totalCashout)
                            tExpenses= tExpenses+parseInt(values.totalExpenses)
                           
                            reportDataList.push(
                                <tr key={i}>
                                    <td>{no}</td>
                                    <td>{getName(this.state.branchData.branchData,values.branchID)}</td>
                                    <td>{values.balanceDate}</td>
                                    <td>{values.openingBalance.toLocaleString()}</td>
                                    <td>{values.totalCapital.toLocaleString()}</td>
                                    <td>{values.totalCashin.toLocaleString()}</td>
                                    <td>{values.totalProcessingFee.toLocaleString()}</td>
                                    <td>{values.totalFines.toLocaleString()}</td>
                                    <td>{values.totalUnknownPayment.toLocaleString()}</td>
                                    <td>{values.totalCashoutRenewed.toLocaleString()}</td>
                                    <td>{values.totalCashout.toLocaleString()}</td>
                                    <td>{values.totalExpenses.toLocaleString()}</td>
                                    <td>{values.closingBalance.toLocaleString()}</td>
                                    <td>{values.created_at}</td>
                                </tr>
                            )
                        });
                    }else{
                       
                        myCatData.map((values2,i) =>{
                            let values = values2[0]
                            if(values){
                                no = no+1
                                tOpenBal= tOpenBal+parseInt(values.openingBalance)
                                tCapital= tCapital+parseInt(values.totalCapital)
                                tCollection= tCollection+parseInt(values.totalCashin)
                                tPFee= tPFee+parseInt(values.totalProcessingFee)
                                tFines= tFines+parseInt(values.totalFines)
                                tunknown= tunknown+parseInt(values.totalUnknownPayment)
                                tRenewed= tRenewed+parseInt(values.totalCashoutRenewed)
                                tCashout= tCashout+parseInt(values.totalCashout)
                                tExpenses= tExpenses+parseInt(values.totalExpenses)
                                tCloseBal= tCloseBal+parseInt(values.closingBalance)
                           
                                reportDataList.push(
                                    <tr key={i}>
                                        <td>{no}</td>
                                        <td>{getName(this.state.branchData.branchData,values.branchID)}</td>
                                        <td>{values.balanceDate}</td>
                                        <td>{values.openingBalance.toLocaleString()}</td>
                                        <td>{values.totalCapital.toLocaleString()}</td>
                                        <td>{values.totalCashin.toLocaleString()}</td>
                                        <td>{values.totalProcessingFee.toLocaleString()}</td>
                                        <td>{values.totalFines.toLocaleString()}</td>
                                        <td>{values.totalUnknownPayment.toLocaleString()}</td>
                                        <td>{values.totalCashoutRenewed.toLocaleString()}</td>
                                        <td>{values.totalCashout.toLocaleString()}</td>
                                        <td>{values.totalExpenses.toLocaleString()}</td>
                                        <td>{values.closingBalance.toLocaleString()}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            }
                            
                        });
                    }
               
                }else{
                    reportDataList.push(  
                        <h1>No Data found</h1>   
                    )
                }

                this.setState({isLoading:false,reportData:reportData,btnDisabled:false,errorMsg:msg,reportDataList:reportDataList,
                    tOpenBal:parseInt(currentBranchID)===0?tOpenBal:"",tCapital:tCapital,tCollection:tCollection,tPFee:tPFee,tFines:tFines,tunknown:tunknown,tRenewed:tRenewed,
                    tCashout:tCashout,tExpenses:tExpenses,tCloseBal:parseInt(currentBranchID)===0?tCloseBal:"",
                })
            }else if(reportStatus===false){
                this.setState({isLoading:false,reportData:null,btnDisabled:false,errorMsg:msg})
            }
        }

    }

    viewUser=(e)=>{
        const selectedID = e.target.id;
        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
        
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt,staff,currentBranchID} = this.state

        if(startFrom ===null || endAt ===null || staff===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date "})
        }else if(parseInt(currentBranchID)===0 && (startFrom !==endAt)){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Since 'ALL BRANCHES' is selected, 'Start From' date must be the same as 'End At' date"})
        }else{
            this.setState({isLoading:true,btnDisabled:true,errorMsg:null})
            const data = new FormData();
            data.append("staff",staff);
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            data.append("currentBranch",currentBranchID);
            /**upload new user*/
            this.props.addLoanData(data,"DAILY_REPORT")
        }
    }

    render() {
        const {currentBranchID,tOpenBal,tCapital,reportDataList,
            tCollection,tPFee,tFines,tunknown,tRenewed,tCashout,tExpenses,tCloseBal} = this.state
            
        return (
            <div>
              
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>DAILY REPORT</h4>
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                            <Col md="3" xs="12">
                                <FormGroup>
                                    <Label for="dob">Select Branch*</Label>
                                    <Input type="select" onChange={this.setValue} name="currentBranchID">
                                        {this.state.myBranches}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="3" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                            <Col md="12" xs="12">
                            {this.state.errorMsg?<Alert color="success">{this.state.errorMsg}</Alert>:null}
                            </Col>
                        </Row>
                        <div className='table-container'>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>Branch</th>
                                    <th>Entry Date</th>
                                    <th>Opening Balance</th>
                                    <th>Capital Added</th>
                                    <th>Total Collection</th>
                                    <th>Processing Fee</th>
                                    <th>Total Fines</th>
                                    <th>Unknown Payments</th>
                                    <th>Renewed Loans</th>
                                    <th>Total Cashout</th>
                                    <th>Total Expenses</th>
                                    <th>Closing Balance</th>
                                    <th>Balance Date</th>
                                </tr>
                                <tr className='reportTotals'>
                                    <th></th>
                                    <th>TOTALS</th>
                                    <th></th>
                                    <th>{parseInt(currentBranchID) ===0?tOpenBal.toLocaleString():""}</th>
                                    <th>{tCapital.toLocaleString()}</th>
                                    <th>{tCollection.toLocaleString()}</th>
                                    <th>{tPFee.toLocaleString()}</th>
                                    <th>{tFines.toLocaleString()}</th>
                                    <th>{tunknown.toLocaleString()}</th>
                                    <th>{tRenewed.toLocaleString()}</th>
                                    <th>{tCashout.toLocaleString()}</th>
                                    <th>{tExpenses.toLocaleString()}</th>
                                    <th>{parseInt(currentBranchID) ===0?tCloseBal.toLocaleString():""}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner"/><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:

                            <tbody>
                                {reportDataList}
                            </tbody>}
                        </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    msg : state.mainReducer.msg,
    reportData:state.mainReducer.updateData,
    reportStatus:state.mainReducer.updateStatus,
    getMyDataStatus:state.mainReducer.getMyDataStatus,
    getMyData:state.mainReducer.getMyData
});
export default connect(
    mapStateToProps, {addLoanData,getData}
)(DailyReport)
