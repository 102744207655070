import {apis} from "../../myEnv.json"
import axios from 'axios';
import {logOut} from './authReducer';
const PROJ_DATA_RESET = 'project/proj_data_reset_success';
const GET_USERS_SUCCESS = 'project/get_users_success';
const GET_USERS_FAIL = 'project/get_users_fail';
const ADD_PROJ_SUCCESS = 'project/add_proj_success';
const ADD_PROJ_FAIL = 'project/add_proj_fail';
const ADD_ACT_SUCCESS = 'project/add_act_success';
const ADD_ACT_FAIL = 'project/add_act_fail';
const UPDATE_PROJ_SUCCESS = 'project/update_proj_success';
const UPDATE_PROJ_FAIL = 'project/update_proj_fail';
const UPDATE_ACT_SUCCESS = 'project/update_act_success';
const UPDATE_ACT_FAIL = 'project/update_act_fail';
const REPORT_DISPLAY_SUCCESS = 'project/report_display_success';
const REPORT_DISPLAY_FAIL = 'project/report_display_fail';
const REPORT_DOWNLOAD_SUCCESS  = 'project/report_download_success';
const REPORT_DOWNLOAD_FAIL  = 'project/report_download_fail';
const REPO_DATA_RESET = 'project/report_data_reset';
const USER_DATA_RESET = 'project/user_data_reset';
const initilState = {
    msg: null,
    userData:null,
    userDataStatus:null,
    dataFileStatus:null,
    newFilePath:null,
    updateStatus:null,
    reportDataStatus:null,
    repoDownloadStatus:null,

    repProjData:null,
    repUploadsData:null,
    repTotals:null,
    repGeneralSum:null,
    fileDownloadPath:null,
    
}
//Reducer
export default(state = initilState, action) => {
    switch (action.type) {
        case PROJ_DATA_RESET:
            return{
                dataStatus:false,
                mainData:null,
                showDataStatus:null,
                showData:null,
                deleteStatus:null,
                updateStatus:null,
                changeStatus:null,
                dataFileStatus:null,
                newFilePath:null,
                reportDataStatus:null,
                repProjData:null,
                repUploadsData:null,
                repTotals:null,
                repGeneralSum:null,
                
            };
        case REPO_DATA_RESET:{
            return {
                repoDownloadStatus:null,
                fileDownloadPath:null,
            }
        }
       
        case USER_DATA_RESET:{
            return {
                userData:null,
                userDataStatus:null,
            }
        }
        case GET_USERS_SUCCESS:
            return {
                ...state,
                userDataStatus:true,
                userData:action.payload.mainData,
                msg:action.payload.msg
            };
        case GET_USERS_FAIL:
            return {
                ...state,
                userDataStatus:true,
                userData:action.payload.mainData,
                msg:action.payload.msg
            };
        case ADD_PROJ_SUCCESS:
        case ADD_ACT_SUCCESS:
            return {
                ...state,
                dataFileStatus:true,
                msg:action.payload.msg
            };
        case ADD_PROJ_FAIL:
        case ADD_ACT_FAIL:
            return {
                ...state,
                dataFileStatus:false,
                msg:action.payload.msg
            };
        case UPDATE_PROJ_SUCCESS:
        case UPDATE_ACT_SUCCESS:
            return {
                ...state,
                updateStatus:true,
                msg:action.payload.msg,
                newFilePath:action.payload.newFilePath
            };
        case UPDATE_PROJ_FAIL:
        case UPDATE_ACT_FAIL:
            return {
                ...state,
                updateStatus:false,
                msg:action.payload.msg
            };
        case REPORT_DISPLAY_SUCCESS :
            return {
                ...state,
                reportDataStatus:true,
                msg:action.payload.msg,
                repProjData:action.payload.projData,
                repUploadsData:action.payload.uploadsData,
                repTotals:action.payload.totals,
                repGeneralSum:action.payload.generalSum,
            };
        case REPORT_DISPLAY_FAIL:
            return {
                ...state,
                reportDataStatus:false,
                msg:action.payload.msg,
                repProjData:null,
                repUploadsData:null,
                repTotals:null,
                repGeneralSum:null,
            };
            case REPORT_DOWNLOAD_SUCCESS:
                return {
                    ...state,
                    repoDownloadStatus:true,
                    msg:action.payload.msg,
                    fileDownloadPath:action.payload.path
                };
            case REPORT_DOWNLOAD_FAIL:
                return {
                    ...state,
                    repoDownloadStatus:false,
                    msg:action.payload.msg,
                    fileDownloadPath:action.payload.path
                };
     
        default:
            return state;
    }
};
/**get users */
export const getUsersData =() => dispatch =>{
    
    //resset intails
    dispatch({type:USER_DATA_RESET});
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    axios.post(appUrls.REACT_APP_API_URL+'/getUsers',null, config)
        .then(function(response){
            dispatch({
                type: GET_USERS_SUCCESS,
                payload:response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: GET_USERS_FAIL,
                payload: err.response.data
            })
        })
}

/** */
export const addDataFile =(formData,requestFrom) => dispatch =>{

    //resset intails
    dispatch({type:PROJ_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': ['multipart/form-data','application/json'],
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    var exactPath,typeSuccess,typeFail;
    if(requestFrom ==="PROJ"){
        exactPath = 'addProject';
        typeSuccess = ADD_PROJ_SUCCESS;
        typeFail = ADD_PROJ_FAIL
    }else if(requestFrom ==="ACT"){
        exactPath = 'addActivity';
        typeSuccess = ADD_ACT_SUCCESS;
        typeFail = ADD_ACT_SUCCESS
    }
    axios.post(appUrls.REACT_APP_API_URL+'/'+exactPath,formData, config)
        .then(function(response){
            dispatch({
                type: typeSuccess,
                payload:response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: typeFail,
                payload: err.response.data
            })
        })
}

export const updateProject = (projectFormData) => dispatch => {
    //resset intails
    dispatch({type:PROJ_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': ['multipart/form-data','application/json'],
            'Authorization' : 'Bearer '+ userD.token
        }
    }

   /*sending to back end*/
   axios.post(appUrls.REACT_APP_API_URL+'/updateProject', projectFormData, config)
       .then(function(response){
           dispatch({
               type: UPDATE_PROJ_SUCCESS,
               payload: response.data
           });
       })
       .catch(err =>{
           /**call logout */
           if(err.response.status === 401){dispatch(logOut(null));}
           dispatch({
               type: UPDATE_PROJ_FAIL,
               payload: err.response.data
           })
       })
}

/**update act */
export const updateAct = (actFormData) => dispatch => {
    //resset intails
    dispatch({type:PROJ_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': ['multipart/form-data','application/json'],
            'Authorization' : 'Bearer '+ userD.token
        }
    }

   /*sending to back end*/
   axios.post(appUrls.REACT_APP_API_URL+'/updateActivity', actFormData, config)
       .then(function(response){
           dispatch({
               type: UPDATE_ACT_SUCCESS,
               payload: response.data
           });
       })
       .catch(err =>{
           /**call logout */
           if(err.response.status === 401){dispatch(logOut(null));}
           dispatch({
               type: UPDATE_ACT_FAIL,
               payload: err.response.data
           })
       })
}


/**reportdisplay */
export const reportDisplay = (projID) => dispatch => {
    //resset intails
    dispatch({type:PROJ_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    const body = JSON.stringify({"id":projID});

   /*sending to back end*/
   axios.post(appUrls.REACT_APP_API_URL+'/displayReport', body, config)
       .then(function(response){
           dispatch({
               type: REPORT_DISPLAY_SUCCESS,
               payload: response.data
           });
       })
       .catch(err =>{
           /**call logout */
           if(err.response.status === 401){dispatch(logOut(null));}
           dispatch({
               type: REPORT_DISPLAY_FAIL,
               payload: err.response.data
           })
       })
}


/**downloadTest */
export const downloadReport = (repoType,repoFormat,repoCur,repoID) => dispatch => {
    dispatch({type:REPO_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    var exactPath
    if(repoType ==="GENERAL"){
        if(repoFormat ==="PDF"){
            exactPath = 'mainReportPdf';
        }else if(repoFormat ==="EXCEL"){
            exactPath = 'mainReportExcel';
        }
    }else if(repoType ==="ACT"){
        if(repoFormat ==="PDF"){
            exactPath = 'activityReportPdf';
        }else if(repoFormat ==="EXCEL"){
            exactPath = 'activityReportExcel';
        }
    }else if(repoType ==="BUDGET"){
        if(repoFormat ==="PDF"){
            exactPath = 'budgetReportPdf';
        }else if(repoFormat ==="EXCEL"){
            exactPath = 'exportBudgetExcel';
        }
    }else if(repoType ==="BALANCE"){
        if(repoFormat ==="PDF"){
            exactPath = 'balanceSheetPdf';
        }else if(repoFormat ==="EXCEL"){
            exactPath = 'balanceSheetExcel';
        }
    }

    const body = JSON.stringify({"id":repoID,"cur":repoCur});
    axios.post(appUrls.REACT_APP_API_URL+'/'+exactPath,body, config)
        .then(function(response){
            dispatch({
                type: REPORT_DOWNLOAD_SUCCESS,
                payload:response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: REPORT_DOWNLOAD_FAIL,
                payload: err.response.data
            })
        })
}