import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser,restUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner} from 'reactstrap'
import MultiSelect from "@kenshooui/react-multi-select";
import ReactJsAlert from "reactjs-alert"
import ImageCompressor from 'image-compressor.js';
import avator from '../../../src/avator.png'
export class AddClient extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        noCat:null,
        subData:[],
        selectedSubjects: [],

        branch:"",surname:"",gender:"",dob:"",marital_status:"",occup:"",
        nin:"",phone:"",phone2:"",email:"",photo:"",address:"",nin:"",kin:"",kinPhone:"",staff:"",
        alertStatus :false, alertType :"error", alertTitle:null,
        originalImage: null,
        compressedImage: null,myImg:null,
        storeImg:"NO",homeLat:"",homeLong:"",workLat:"",workLong:""

    }

    componentDidMount(){
        const{mainData,msg,mainDataStatus} = this.props;
        /**refresh categories */
        this.props.getMainData("STAFFREGDATA")

    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData})
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                    selectedSubjects: [],
                surname:"",dob:"",marital_status:"",occup:"",
                nin:"",phone:"",phone2:"",email:"",photo:"",address:"",nin:"",kin:"",kinPhone:"",
                alertStatus :true, alertType :"success", alertTitle:msg
                })
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    addUser =(e)=>{
        this.props.restUser()
        const {branch,surname,firstname,photo,gender,dob,marital_status,myImg,
           phone,phone2,email,address,nin,kin,kinPhone,staff,occup,compressedImage,storeImg,
        homeLat,homeLong,workLat,workLong} = this.state;

        if(branch ===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Please select branch"})

        }else if(staff===""||surname===""||dob===""||phone===""||occup ===""||
            gender===""||address===""||nin===""){
       
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else{
            this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
            fetch(compressedImage)
            .then((res) => res.blob())
            .then((blob) => {
                // Create a FormData object to send the image to Laravel
                this.setState({myImg:blob})
         
            });
        
            const data = new FormData();
            data.append("branch",branch);
         
            data.append("surname",surname);
            data.append("firstname",firstname);
            data.append("gender",gender);
            data.append("dob",dob);
            data.append("phone",phone);
            data.append("phone2",phone2);
            data.append("email",email);
            data.append("address",address);
            data.append("nin",nin);
            data.append("kin",kin);
            data.append("kinPhone",kinPhone);
            data.append("occup",occup);
            data.append("agent",staff);
            data.append('photo', myImg);
            data.append("storeImg",storeImg);
            data.append("homeLat",homeLat);
            data.append("homeLong",homeLong);
            data.append("workLat",workLat);
            data.append("workLong",workLong);
           
            /**upload new user*/
            this.props.addUser(data,"SYSTEM_CLIENT")
        }
    }



    handleImageChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onload = () => {
          this.setState({ originalImage: reader.result });
    
          // Use image-compressor.js to compress the image
          new ImageCompressor(file, {
            quality: 0.6, // Set the quality factor here (0.1 to 1)
            success: (result) => {
              const compressedDataURL = URL.createObjectURL(result);
              this.setState({ compressedImage: compressedDataURL,storeImg:"YES" });
             
            },
            error: (err) => {
              console.log('Image compression failed:', err.message);
            },
          });
        };
    
        reader.readAsDataURL(file);
    };

    setHome =(e)=>{
        if (navigator.onLine) {
            if("geolocation" in navigator){
                // Get the user's current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.setState({
                        homeLat: position.coords.latitude,
                        homeLong: position.coords.longitude,
                        alertStatus :true, alertType :"success", alertTitle:"Home address has been set"
                    });
                    },
                    (error) => {
                        this.setState({alertStatus :true, alertType :"error", alertTitle:error.message});
                    }
                );
            }else{
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Geolocation is not available in your browser"});
            } 
        }else{
            this.setState({alertStatus :true, alertType :"error", alertTitle:"No internet connection"});
        }
    }

    setWork =(e)=>{
        if (navigator.onLine) {
            if("geolocation" in navigator){
                // Get the user's current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.setState({
                        workLat: position.coords.latitude,
                        workLong: position.coords.longitude,
                        alertStatus :true, alertType :"success", alertTitle:"Work address has been set"
                    });
                    },
                    (error) => {
                        this.setState({alertStatus :true, alertType :"error", alertTitle:error.message});
                    }
                );
            }else{
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Geolocation is not available in your browser"});
            } 
        }else{
            this.setState({alertStatus :true, alertType :"error", alertTitle:"No internet connection"});
        }

    }

    render() {
        const { originalImage, compressedImage,mainData,homeLat,homeLong,workLat,workLong } = this.state;
        /**displaying  categories*/
        const staffDataList = [];
        if(mainData !==null){
            const myCatData =  mainData.userData
            const branchID = JSON.parse(window.localStorage.getItem('userData')).branchID
            //loop through the array
            if(myCatData.length>0){
                staffDataList.push(<option key="" value="">SELECT STAFF</option>)
                myCatData.map((values,i) =>{
                    if(parseInt(values.branchID) === parseInt(branchID)){
                        if(parseInt(values.status) === 1){
                            /*check agents*/
                            if(parseInt(mainData.SystemSetting.clientsToOnlyAgents) === 1){
                                if(parseInt(values.account.descriptorID)===3){
                                    staffDataList.push(
                                        <option key={i} value={values.id}>{values.username+" ("+values.fname+")"}</option>
                                    )
                                }
                            }else{
                                staffDataList.push(
                                    <option key={i} value={values.id}>{values.username+" ("+values.fname+")"}</option>
                                )
                            }
                        }
                        
                    }
                });
            }else{
                staffDataList.push(  
                    <option value="">No staff found</option>   
                )
            }
        }

        /**displaying  levels*/
        const religionDataList = [];
        if(mainData !==null){
            const myreligionData =  mainData.religionData
            //loop through the array
            if(myreligionData.length>0){
                religionDataList.push(<option key="" value="">SELECT RELIGION</option>)
                myreligionData.map((values,i) =>{
                    religionDataList.push(
                        <option key={i} value={values.id}>{values.name}</option>
                    )
                });
            }else{
                religionDataList.push(  
                    <option value="">No religion found</option>   
                )
            }
        }

        /**positionDataList */
        const positionDataList = [];
        if(mainData !==null){
            const myreligionData =  mainData.positionData
            //loop through the array
            if(myreligionData.length>0){
                positionDataList.push(<option key="" value="">SELECT STAFF POSITION</option>)
                myreligionData.map((values,i) =>{
                    positionDataList.push(
                        <option key={i} value={values.id}>{values.name}</option>
                    )
                });
            }
        }

        /**branchDataList */
        const branchDataList = [];
        if(mainData !==null){
            const myData =  mainData.branchData
            const branchID = JSON.parse(window.localStorage.getItem('userData')).branchID
            //loop through the array
            if(myData.length>0){
                branchDataList.push(<option key="" value="">SELECT BRANCH</option>)
                
                    myData.map((values,i) =>{
                        if(parseInt(values.id) === parseInt(branchID)){
                            branchDataList.push(
                                <option key={i} value={values.id}>{values.name}</option>
                            )
                        }
                    });
                
            }
        }

        /**positionDataList */
        const rightDataList = [];
        if(mainData !==null){
            const myData =  mainData.rightData
            //loop through the array
            if(myData.length>0){
                myData.map((values,i) =>{
                    rightDataList.push(
                        {id: values.id, label: values.name}
                    )
                });
            }
        }


        
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>ADD A NEW CLIENT</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        <Col xs="12" md="6">
                            {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}
                            <FormGroup>
                                
                                {compressedImage===null?
                                <img src={avator} alt="black" style={{ maxWidth: '300px',maxHeight:'200px' }} />

                                : 
                                <img src={compressedImage} alt="Compressed" style={{ maxWidth: '300px',height:'200px' }} />
                                }<br/>
                            <Label for="username">Client Photo</Label>
                            <Input type="file" onChange={this.handleImageChange} />
                            </FormGroup> 

                            <FormGroup>
                                <Label for="gender">Branch*</Label>
                                <Input type="select" onChange={this.setValue} name="branch">
                                    {branchDataList}
                                </Input>
                            </FormGroup>
                          
                     
                            <FormGroup>
                                <Label for="username">Name*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.surname} name="surname" />
                            </FormGroup>
                         
                            <FormGroup>
                                <Label for="gender">Gender*</Label>
                                <Input type="select" onChange={this.setValue} name="gender">
                                    <option value="">SELECT GENDER</option> 
                                    <option value="Male">Male</option> 
                                    <option value="Female">Female</option> 
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="dob">Date Of Birth*</Label>
                                <Input type="date" onChange={this.setValue} value={this.state.dob} name="dob"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">National ID*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.nin} name="nin"/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="username">Address*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.address} name="address"/>
                            </FormGroup>
                            

                        </Col>
                        <Col xs="12" md="6">
                           
                            <FormGroup>
                                <Label for="username">Occupation*</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.occup} name="occup"/>
                            </FormGroup> 
                            <FormGroup>
                                <Label for="phone">Phone No.*</Label>
                                <Input type="number" onChange={this.setValue} value={this.state.phone} name="phone"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="phone2">Phone No. 2</Label>
                                <Input type="number" onChange={this.setValue} value={this.state.phone2} name="phone2" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" onChange={this.setValue} value={this.state.email} name="email" />
                            </FormGroup>
   
                            <FormGroup>
                                <Label for="username">Next of Kin</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.kin} name="kin"/>
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Next of Kin Phone</Label>
                                <Input type="number" onChange={this.setValue} value={this.state.kinPhone} name="kinPhone"/>
                            </FormGroup> 
                            <br className='myHide'/>
                            <FormGroup>
                                <Button onClick={this.setHome} className="btn btn-block btn-lg btn-secondary addressBtn">Set Home Address</Button>
                                {homeLat===""||homeLong===""?<h6 style={{color:"rgb(235 77 123)",fontSize:"14px"}}>Home address has yet not been set</h6>:<h6 style={{color:"#69B063",fontSize:"14px"}}>Home address has been set</h6>}
                            </FormGroup> 
                            <FormGroup>
                                <Button onClick={this.setWork} className="btn btn-block btn-lg btn-secondary addressBtn">Set Work Address</Button>
                                {workLat===""||workLong===""?<h6 style={{color:"rgb(235 77 123)",fontSize:"14px"}}>Work address has yet not been set</h6>:<h6 style={{color:"#69B063",fontSize:"14px"}}>Work address has been set</h6>}
                            </FormGroup> 
                            <br className='myHide'/>
                            <FormGroup>
                                <Label for="gender">Brought By (Staff)*</Label>
                                <Input type="select" onChange={this.setValue} name="staff">
                                    {staffDataList}
                                </Input>
                            </FormGroup>
                        </Col>
                
                    <Col xs="12" >
                     
                        {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
      
                        <Button onClick={this.addUser} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Add Client</Button>
                    </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser,restUser}
)(AddClient)
