import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {addLoanData} from "../../Redux/ducks/mainReducer";
import {getName2,datesDiffe,isDateInSelectedRage} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
export class staffPerformanceReport extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        reportData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,startFrom2:null,endAt:null,endAt2:null,staff:"",
       
        alertStatus :false, alertType :"error", alertTitle:null,

        closedLoanDataList:[],limitTable:false,
        action:"GENERAL"
    }
   
    componentDidUpdate(prevProps){
        const{msg,reportData,reportStatus} = this.props;

        if(reportStatus !==prevProps.reportStatus){
            if(reportStatus===true){
                this.setState({isLoading:false,reportData:reportData,btnDisabled:false})

            }else if(reportStatus===false){
                this.setState({isLoading:false,reportData:null,btnDisabled:false})

            }
        }

    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value,reportData:null})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt,staff,action,startFrom2,endAt2} = this.state
        this.setState({isLoading:false,btnDisabled:false})

        if(startFrom ===null || endAt ===null || endAt2===null || startFrom2===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Range 1 From' date can not be greater than 'Range 1 Up to' date *"})
        }else if(datesDiffe(startFrom2,endAt2,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Range 2 From' date can not be greater than 'Range 2 Up to' date *"})
        }else if(datesDiffe(endAt,startFrom2,null)<1){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Range 1 Up to ' date can not be greater than 'Range 2 From' date *"})
        }else{
           
            this.setState({isLoading:true,btnDisabled:true})

            const data = new FormData();
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            data.append("startFrom2",startFrom2);
            data.append("endAt2",endAt2);
            /**upload new user*/
            this.props.addLoanData(data,"STAFF_PERFORMANCE_REPORT")
        }
         
    }

    render() {
   
        const range1DataList = [];
        const range2DataList = [];
        const differenceDataList = [];
        const differenceDataList2 = [];
        if(this.state.reportData !==null){
            const myCatData =  this.state.reportData
            //loop through the array
            if(myCatData.length>0){
                var no = 0
                let tBDClients1 = 0;
                let tBDCapital1 = 0;
                let tactiveCapital1 = 0;
                let tnewClients1 = 0;
                let tactiveClients1 = 0;
                let ttotalGivenOut1 = 0;
                let ttotalGiveOutRenewal1 = 0;
                let ttotalCollection1 = 0;
                let tBDCollection1 = 0;

                let tBDClients2 = 0;
                let tBDCapital2 = 0;
                let tactiveCapital2 = 0;
                let tnewClients2 = 0;
                let tactiveClients2 = 0;
                let ttotalGivenOut2 = 0;
                let ttotalGiveOutRenewal2 = 0;
                let ttotalCollection2 = 0;
                let tBDCollection2 = 0;
                myCatData.map((values,i) =>{
                    no = no+1
                
                    tactiveClients1 = tactiveClients1 +parseInt(values.activeClients1)
                    tnewClients1 = tnewClients1 +parseInt(values.newClients1)
                    ttotalGivenOut1 = ttotalGivenOut1 +parseInt(values.totalGivenOut1)
                    ttotalGiveOutRenewal1 = ttotalGiveOutRenewal1 +parseInt(values.totalGiveOutRenewal1)
                    ttotalCollection1 = ttotalCollection1 +parseInt(values.totalCollection1)
                    tactiveCapital1 = tactiveCapital1 +parseInt(values.activeCapital1)
                    tBDClients1 = tBDClients1 +parseInt(values.BDClients1)
                    tBDCollection1 = tBDCollection1 +parseInt(values.BDCollection1)
                    tBDCapital1 = tBDCapital1 +parseInt(values.BDCapital1)

                    tactiveClients2 = tactiveClients2 +parseInt(values.activeClients2)
                    tnewClients2 = tnewClients2 +parseInt(values.newClients2)
                    ttotalGivenOut2 = ttotalGivenOut2 +parseInt(values.totalGivenOut2)
                    ttotalGiveOutRenewal2 = ttotalGiveOutRenewal2 +parseInt(values.totalGiveOutRenewal2)
                    ttotalCollection2 = ttotalCollection2 +parseInt(values.totalCollection2)
                    tactiveCapital2 = tactiveCapital2 +parseInt(values.activeCapital2)
                    tBDClients2 = tBDClients2 +parseInt(values.BDClients2)
                    tBDCollection2 = tBDCollection2 +parseInt(values.BDCollection2)
                    tBDCapital2 = tBDCapital2 +parseInt(values.BDCapital2)

                    range1DataList.push(
                        <tr key={i}>
                            <td>{no}</td>
                            <td>{values.staffName}</td>
                            <td>{values.activeClients1}</td>
                            <td>{values.newClients1}</td>
                            <td>{values.totalGivenOut1}</td>
                            <td>{values.totalGiveOutRenewal1}</td>
                            <td>{values.totalCollection1}</td>
                            <td>{values.activeCapital1}</td>
                            <td>{values.BDClients1}</td>
                            <td>{values.BDCollection1}</td>
                            <td>{values.BDCapital1}</td>
                        </tr>
                    )

                    range2DataList.push(
                        <tr key={i}>
                            <td>{no}</td>
                            <td>{values.staffName}</td>
                            <td>{values.activeClients2}</td>
                            <td>{values.newClients2}</td>
                            <td>{values.totalGivenOut2}</td>
                            <td>{values.totalGiveOutRenewal2}</td>
                            <td>{values.totalCollection2}</td>
                            <td>{values.activeCapital2}</td>
                            <td>{values.BDClients2}</td>
                            <td>{values.BDCollection2}</td>
                            <td>{values.BDCapital2}</td>
                        </tr>
                    )
                    
                    differenceDataList.push(
                        <tr key={i}>
                            <td>{no}</td>
                            <td>{values.staffName}</td>
                            <td>{parseInt(values.activeClients2)-parseInt(values.activeClients1)}</td>
                            <td>{parseInt(values.newClients2)-parseInt(values.newClients1)}</td>
                            <td>{parseInt(values.totalGivenOut2)-parseInt(values.totalGivenOut1)}</td>
                            <td>{parseInt(values.totalGiveOutRenewal2)-parseInt(values.totalGiveOutRenewal1)}</td>
                            <td>{parseInt(values.totalCollection2)-parseInt(values.totalCollection1)}</td>
                            <td>{parseInt(values.activeCapital2)-parseInt(values.activeCapital1)}</td>
                            <td>{parseInt(values.BDClients2)-parseInt(values.BDClients1)}</td>
                            <td>{parseInt(values.BDCollection2)-parseInt(values.BDCollection1)}</td>
                            <td>{parseInt(values.BDCapital2)-parseInt(values.BDCapital1)}</td>
                        </tr>
                    )

                    let AactiveClients = values.activeClients1 !== 0 ? ((parseInt(values.activeClients2) - parseInt(values.activeClients1)) / parseInt(values.activeClients1)) * 100:0
                    let AtotalGivenOut = values.totalGivenOut1 !== 0 ? ((parseInt(values.totalGivenOut2) - parseInt(values.totalGivenOut1)) / parseInt(values.totalGivenOut1)) * 100:0
                    let AtotalCollection = values.totalCollection1 !== 0 ? ((parseInt(values.totalCollection2) - parseInt(values.totalCollection1)) / parseInt(values.totalCollection1)) * 100:0
                    let AactiveCapital = values.activeCapital1 !== 0 ? ((parseInt(values.activeCapital2) - parseInt(values.activeCapital1)) / parseInt(values.activeCapital1)) * 100:0
                    let ABDCapital = values.BDCapital1 !== 0 ? ((parseInt(values.BDCapital1) - parseInt(values.BDCapital2)) / parseInt(values.BDCapital1)) * 100:0
                   
                    differenceDataList2.push(
                        <tr key={i}>
                        <td>{no}</td>
                        <td>{values.staffName}</td>

                        <td>{AactiveClients.toFixed(1)+"%"}</td>
                        <td>{AtotalGivenOut.toFixed(1)+"%"}</td>
                        <td>{AtotalCollection.toFixed(1)+"%"}</td>
                        <td>{AactiveCapital.toFixed(1)+"%"}</td>
                        <td>{ABDCapital.toFixed(1)+"%"}</td>
                        <td>{((AactiveClients+AtotalGivenOut+AtotalCollection+AactiveCapital+ABDCapital)/5).toFixed(1)+"%"}</td>
                      </tr>
                    )
                });

                range1DataList.push(
                    <tr className='tableTotals'>
                        <td>{}</td>
                        <td>{"TOTALS"}</td>
                        <td>{tactiveClients1.toLocaleString()}</td>
                        <td>{tnewClients1.toLocaleString()}</td>
                        <td>{ttotalGivenOut1.toLocaleString()}</td>
                        <td>{ttotalGiveOutRenewal1.toLocaleString()}</td>
                        <td>{ttotalCollection1.toLocaleString()}</td>
                        <td>{tactiveCapital1.toLocaleString()}</td>
                        <td>{tBDClients1.toLocaleString()}</td>
                        <td>{tBDCollection1.toLocaleString()}</td>
                        <td>{tBDCapital1.toLocaleString()}</td>
                    </tr>
                )

                range2DataList.push(
                    <tr className='tableTotals'>
                        <td>{}</td>
                        <td>{"TOTALS"}</td>
                        <td>{tactiveClients2.toLocaleString()}</td>
                        <td>{tnewClients2.toLocaleString()}</td>
                        <td>{ttotalGivenOut2.toLocaleString()}</td>
                        <td>{ttotalGiveOutRenewal2.toLocaleString()}</td>
                        <td>{ttotalCollection2.toLocaleString()}</td>
                        <td>{tactiveCapital2.toLocaleString()}</td>
                        <td>{tBDClients2.toLocaleString()}</td>
                        <td>{tBDCollection2.toLocaleString()}</td>
                        <td>{tBDCapital2.toLocaleString()}</td>
                    </tr>
                )
                differenceDataList.push(
                    <tr className='tableTotals'>
                        <td>{}</td>
                        <td>{"TOTALS"}</td>
                        <td>{(parseInt(tactiveClients2)-parseInt(tactiveClients1)).toLocaleString()}</td>
                        <td>{(parseInt(tnewClients2)-parseInt(tnewClients1)).toLocaleString()}</td>
                        <td>{(parseInt(ttotalGivenOut2)-parseInt(ttotalGivenOut1)).toLocaleString()}</td>
                        <td>{(parseInt(ttotalGiveOutRenewal2)-parseInt(ttotalGiveOutRenewal1)).toLocaleString()}</td>
                        <td>{(parseInt(ttotalCollection2)-parseInt(ttotalCollection1)).toLocaleString()}</td>
                        <td>{(parseInt(tactiveCapital2)-parseInt(tactiveCapital1)).toLocaleString()}</td>
                        <td>{(parseInt(tBDClients2)-parseInt(tBDClients1)).toLocaleString()}</td>
                        <td>{(parseInt(tBDCollection2)-parseInt(tBDCollection1)).toLocaleString()}</td>
                        <td>{(parseInt(tBDCapital2)-parseInt(tBDCapital1)).toLocaleString()}</td>
                    </tr>
                )
            }else{
                range1DataList.push(  
                    <h1>No Data found</h1>   
                )
                range2DataList.push(  
                    <h1>No Data found</h1>   
                )
                differenceDataList.push(  
                    <h1>No Data found</h1>   
                )
            }
            
            
        }

     
        return (
            <div>
        
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h6>GENERATE STAFF PERFORMANCE REPORT</h6>
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row className="searchWrapper"> 
                            <Col md="4" xs="12" className="startFrom">
                                <Row>
                                    <Col md="6" xs="12">
                                        <FormGroup>
                                            <Label for="dob">Range 1 From*</Label>
                                            <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" xs="12">
                                        <FormGroup>
                                            <Label for="dob">Range 1 Up To*</Label>
                                            <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                        </FormGroup>  
                                    </Col>
                                </Row>
                                
                            </Col>


                            <Col md="4" xs="12" className="endAt">
                                <Row>
                                    <Col md="6" xs="12">
                                        <FormGroup>
                                            <Label for="dob">Range 2 From*</Label>
                                            <Input type="date" onChange={this.setValue} value={this.state.startFrom2} name="startFrom2"/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" xs="12">
                                        <FormGroup>
                                            <Label for="dob">Range 2 Up To*</Label>
                                            <Input type="date" onChange={this.setValue} value={this.state.endAt2} name="endAt2"/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                
                            </Col>
                        
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='table-container'>
                            <h6 class="tableTitle" style={{color:"#c7e0c9"}}>RANGE 1 SELECTION</h6>
                            <Table striped responsive className="fromTable">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Active Clients</th>
                                        <th>New Clients</th>
                                        <th>Given Out</th>
                                        <th>Renewed Loans</th>
                                        <th>Collection</th>
                                        <th>Active(Capital)</th>
                                        <th>B&D Clients</th>
                                        <th>B&D Collection</th>
                                        <th>B&D Capital</th>
                                    </tr>
                                </thead>
                                
                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {range1DataList}
                                
                                </tbody>}
                            </Table>
                            <h6 class="tableTitle" style={{color:"#e4c0d8"}}>RANGE 2 SELECTION</h6>
                            <Table striped responsive className="endTable">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Active Clients</th>
                                        <th>New Clients</th>
                                        <th>Given Out</th>
                                        <th>Renewed Loans</th>
                                        <th>Collection</th>
                                        <th>Active(Capital)</th>
                                        <th>B&D Clients</th>
                                        <th>B&D Collection</th>
                                        <th>B&D Capital</th>
                                    </tr>
                                </thead>
                                
                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {range2DataList}
                                
                                </tbody>}
                            </Table>
                            <h6 class="tableTitle" style={{color:"#007bff"}}>PERFORMANCE CHANGES</h6>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Active Clients</th>
                                        <th>New Clients</th>
                                        <th>Given Out</th>
                                        <th>Renewed Loans</th>
                                        <th>Collection</th>
                                        <th>Active(Capital)</th>
                                        <th>B&D Clients</th>
                                        <th>B&D Collection</th>
                                        <th>B&D Capital</th>
                                    </tr>
                                </thead>
                                
                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {differenceDataList}
                                
                                </tbody>}
                            </Table>
                            <h6 class="tableTitle" style={{color:"#dc3545"}}>PERCENTAGE CHANGES</h6>
                            <Table striped responsive className="changeTable">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Active Clients</th>
                                        <th>Given Out</th>
                                        <th>Collection</th>
                                        <th>Active(Capital)</th>
                                        <th>B&D Capital</th>
                                        <th>Average Change</th>
                                    </tr>
                                </thead>
                                
                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {differenceDataList2}
                                
                                </tbody>}
                            </Table>

                         

                        </div>
                        
                    </Col>
                </Row>
     
            </div>
        )
    }
}
const mapStateToProps = state =>({
    msg : state.mainReducer.msg,
    reportData:state.mainReducer.updateData,
    reportStatus:state.mainReducer.updateStatus,
});
export default connect(
    mapStateToProps, {addLoanData}
)(staffPerformanceReport)
