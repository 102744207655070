import {apis} from "../../myEnv.json"
import axios from 'axios';
const LOGOUT_SUCCESS = 'auth/logout_success';
const LOGIN_SUCCESS = 'auth/login_success';
const LOGIN_FAIL = 'auth/login_fail';
const SWITCH_RESET = 'auth/switch_reset';
const SWITCH_SUCCESS = 'auth/switch_success';
const SWITCH_FAIL = 'auth/switch_fail';
const LIVE_RESET = 'auth/LIVE_RESET';
const LIVE_SUCCESS = 'auth/LIVE_SUCCESS';
const LIVE_FAIL = 'auth/LIVE_FAIL';
const initilState = {
    token:null,
    userLoginStatus:'',
    userLoginMsg:'',
    liveData:null,
    liveStatus:null
};
//Reducer
export default (state = initilState, action) => {
    switch (action.type) {
        case LOGOUT_SUCCESS:
            window.localStorage.removeItem('userData');
            window.localStorage.setItem('showWelcomeAlert',false);
            return {
                ...state,
                token:null,
                userLoginStatus:'',
                userLoginMsg:'',
                loginStatus:window.localStorage.setItem('loginStatus',false)
            };
        case SWITCH_RESET:
            return {
                ...state,
                userLoginStatus:'',
                userLoginMsg:'',
            };
        case LIVE_RESET:
            return {
                ...state,
                liveData:null,
                liveStatus:null
            };
        case LOGIN_SUCCESS:
            const userData ={
                userID:action.payload.userID,
                token:action.payload.token.accessToken,
                loginStatus:true,
                rights:action.payload.rights,
                accType:action.payload.accType,
                branchID:action.payload.branchID,
                branchName:action.payload.branchName,
                resetPassword:action.payload.resetPassword,
                branchesAllowed:action.payload.branchesAllowed,
                userRightsCategory:action.payload.userRightsCategory,
                username:action.payload.username,
                usersData:action.payload.usersData,
                fineTypeData:action.payload.fineTypeData
            }
         
            window.localStorage.setItem('userData',JSON.stringify(userData));
            window.localStorage.setItem('showWelcomeAlert',true);
       
            return{
                ...state,
                token:action.payload.token.accessToken,
                userLoginStatus: action.payload.error,
                userLoginMsg:'',
                loginStatus:window.localStorage.setItem('loginStatus',true)
            };

        case LOGIN_FAIL:
            window.localStorage.removeItem('userData');
            window.localStorage.setItem('loginStatus',false);
            return{
                ...state,
                token:null,
                userLoginStatus: action.payload.error,
                userLoginMsg:action.payload.msg,
                loginStatus:window.localStorage.setItem('loginStatus',false)
            };

        case SWITCH_SUCCESS:
            const userData2 ={
                userID:action.payload.userID,
                token:action.payload.token.accessToken,
                rights:action.payload.rights,
                accType:action.payload.accType,
                branchID:action.payload.branchID,
                branchName:action.payload.branchName,
                resetPassword:action.payload.resetPassword,
                branchesAllowed:action.payload.branchesAllowed,
                userRightsCategory:action.payload.userRightsCategory,
                username:action.payload.username
            }
            window.localStorage.setItem('userData',JSON.stringify(userData2));
            
            return{
                ...state,
                token:action.payload.token.accessToken,
                userLoginStatus:true,
                userLoginMsg:action.payload.msg,
            };
    
        case SWITCH_FAIL:
            return{
                ...state,
                userLoginStatus: false,
                userLoginMsg:action.payload.msg,
            };
        case LIVE_SUCCESS:
            return{
                ...state,
                liveStatus:true,
                msg:action.payload.msg,
                liveData:action.payload.mainData,
            };

        case LIVE_FAIL:
            return{
                ...state,
                liveStatus: false,
                msg:action.payload.msg
            };
        
        default:
            return state;
    }
};

export const logOut = (calledFrom) =>(dispatch) => {
    /*dispatch LOGOUT_SUCCESS action */
    const userD = JSON.parse(window.localStorage.getItem('userData'));
  
    if(userD === null){
        /*header*/
        dispatch({ type: LOGOUT_SUCCESS });
        /**redirect to login page */
        if(calledFrom !=="loginPage"){
            window.location.href = process.env.REACT_APP_FRONTEND_URL+'/login';
        }
        
    }else{
        /*header*/
        const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
        const config = {
            headers: {'Content-Type': 'application/json','Authorization' : 'Bearer '+ userD.token}
        }
        axios.post(appUrls.REACT_APP_API_URL+'/logout',null, config)
        .then(function(response){
            dispatch({
                type: LOGOUT_SUCCESS,
            });
            /**redirect to login page */
            if(calledFrom !=="loginPage"){
                window.location.href = appUrls.REACT_APP_FRONTEND_URL+'/login';
            }
            
        })
        .catch(err =>{
            dispatch({
                type: LOGOUT_SUCCESS,
            })
             /**redirect to login page */
            if(calledFrom !=="loginPage"){
               
                window.location.href = process.env.REACT_APP_FRONTEND_URL+'/login';
            }
            
        })
    }
}

/**user login*/
export const userLogin = (typeForm,username,password) => dispatch =>{
    /*dispatch LOGOUT_SUCCESS action */
    dispatch({type: LOGOUT_SUCCESS});
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    /*header*/
    const config ={
        headers: { 'Content-Type': 'application/json' }
    }
    /*request body*/
    const body = JSON.stringify({username,password,typeForm});

    axios.post(appUrls.REACT_APP_API_URL+'/adminLogin', body, config)
        .then(function(response){
            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
            });
        })
        .catch(err =>{
            console.log(err)
            dispatch({
                type: LOGIN_FAIL,
                payload: err.response.data
            })
        })
}

export const switchBranch = (formData) => dispatch => {   
    dispatch({type: SWITCH_RESET});
    /*header*/
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    /*sending to back end*/
    axios.post(appUrls.REACT_APP_API_URL+'/switchBranch', formData, config)
        .then(function(response){
            dispatch({
                type: SWITCH_SUCCESS,
                payload: response.data
            });
        })
        .catch(err =>{
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: SWITCH_FAIL,
                payload: err.response.data
            })
        })

}

//getLiveData
export const getLiveData = () => dispatch => {   
    dispatch({type: LIVE_RESET});
    /*header*/
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    /*sending to back end*/
    axios.post(appUrls.REACT_APP_API_URL+'/getLiveData', {}, config)
        .then(function(response){
            dispatch({
                type: LIVE_SUCCESS,
                payload: response.data
            });
        })
        .catch(err =>{
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: LIVE_FAIL,
                payload: err.response.data
            })
        })

}
  
  