import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,FormGroup,Input, Button,Modal,
     ModalBody, ModalFooter,ModalHeader,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData,getCatData,getSelectedID_Data,addLoanData,changeStatus,changePassword} from "../../Redux/ducks/mainReducer";
import {getName,datesDiffe,getName2,getFine,checkRight,generateDates,isNumber} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import ReactToPrint from 'react-to-print';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import LoanDetails from '../Includes/LoanDetails';
import PaymentChart from '../Includes/PaymentChart';
export class SeeClients extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state={
            isLoading:false,
            isModalLoading:true,
            userData:null,
            catData:null,
            clientDetailsData:null,
            isRouting:false,

            msg:null,
            modal:false,
            modelTrack:false,
            deleteModal:false,
            passwordModal:false,
            currentID:'',
            erroModalMsg:"",
            successModalMsg:'',
            erroPassModalMsg:"",
            successPassModalMsg:'',
            isUpdatingPass:false,
            isUpdating:false,
            isDeleting:false,
            btnDisabled:false,
            deleteMsg:null,
            successDeleteMsg:null,
            status:null,

            currentStatus:'',
            mainData:null,

            UseeUser: 0,UeditUser: 0,UdelUser: 0,
            accountSearch:null,nameSearch:null,

            details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false,

            loanType:"",rate:"20",pFee:"",payMode:"INSTALLMENTS",amount:"",period:"30",startPay:"",schedule:"1",g2Relationship:"",g1Relationship:"",
            action:"AUTOMATIC RENEWA",staff:"",security:"",secDes:"",g1Name:"",g1Phone1:"",g1Phone2:"",g2Name:"",g2Phone1:"",g2Phone2:"",modalPreview:false,
            
            activeLoanStatus:null,     alertStatus :false, alertType :"error", alertTitle:null,idPosition:"",
            /**fine module */
            fineType:"INSTALLMENT FINE",fineAmount:"",finedFrom:"",finedTo:"",payAmount:"",bookNo:"",

            /**pay loan */
            showPayDetails : false,currentDateStatus:true,currentDate:null,totalFines:0,

            /**loan payment */
            searchloanID: "",paymentDataList:[],editAmount:"",editCode:"",oldAmount:0,editID:"",editLoanID:"", editPayModal:false,
            /**client details */
            surname:"",gender:"",dob:"",photo:"",address:"",nin:"",occup:"",kin:"",kinPhone:"",accStatus:"",email:"",blacklistReason:"",blacklist:"",
            blacklistedBy:"",uphone1:"",uphone2:"",homeLat:"",homeLong:"",workLat:"",workLong:"",

            /**payment chart */
            selectedDate:null,
            chartStartDate:null,chartEndDate:null,searchLoanPaymentChart:"",searchloanIDChart:"", showCalender:false,
            chartList:[],activeMonth:null,loanStartDate:null,trackUrl:null,

            /**see fines */
            selectFines:"",forgiveFineModal:false,forgiveAmount:"",

            /**loan request approval */
            rloanType:"",rrate:"20",rpFee:"",rpayMode:"INSTALLMENTS",ramount:"",rperiod:"30",rschedule:"1",rg2Relationship:"",rg1Relationship:"",
            raction:"AUTOMATIC RENEWA",rstaff:"",rsecurity:"",rsecDes:"",rg1Name:"",rg1Phone1:"",rg1Phone2:"",rg2Name:"",rg2Phone1:"",rg2Phone2:"",rcurrentAddress:"",
            ridPosition:"",editComment:"",showMoreDetails:false,receiptPath:null
            
        }
    }
    componentDidMount(){
       
        this.setState({isLoading:true})
        /**get rights object */

        this.props.getMainData("CLIENTS");
        /*try {
            if(this.props.location.state.redirectSelectedID !==null){
                alert("ok")
                let selectedID = this.props.location.state.redirectSelectedID
                this.props.getSelectedID_Data(selectedID,"CLIENT")
    
                this.setOther_selectedUser(selectedID)
            } 
        }catch(e) {
            //console.log('Error')
        }*/
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus,showDataStatus,showData,catData,
            catStatus,updateStatus,changedStatus,changePassStatus,updateData} = this.props;
        
        if(userDataStatus !== prevProps.userDataStatus){
        
            if(userDataStatus===true){
                this.setState({isLoading:false,userData:userData.userData,msg:msg,mainData:userData,
                })
            }else{
                this.setState({isLoading:true,userData:null,msg:null})
            }
        }

        if(showDataStatus !== prevProps.showDataStatus){
            
            if(showDataStatus===true){
                /**current date */
                const currentDate = showData.systemSettingData.currentDate
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                today = yyyy + '-' + mm + '-' + dd;

                // Calculate the next day's date
                const givenDate = new Date(currentDate);
                const nextDay = new Date(givenDate);
                nextDay.setDate(givenDate.getDate() + 1);
                let nexD=nextDay.toISOString().split('T')[0];
    

                if (datesDiffe(today,currentDate,null)===0) {
                    this.setState({currentDateStatus:true,startPay:nexD})
                }else{
                    this.setState({currentDateStatus:false,startPay:nexD})
                }

                
                var totalFines =  0
                if(showData !==null){
                    /**total fines */
                    const myData =  showData.finesData
                    if(myData.length>0){
                        myData.map((values, i) =>{
                            if(parseInt(values.status) ===1){
                                totalFines = totalFines + parseInt(values.amount) 
                            }
                        });
                    } 
                    /**loan types*/
                    const loanTypeData =  showData.loanTypeData
                    if(loanTypeData.length>0){
                        let myLen = 0
                        loanTypeData.map((values2, i) =>{
                            myLen = myLen+1
                            if(myLen===1){
                                this.setState({loanType:values2.id,rloanType:values2.id})
                            }
                        });
                    }
                }

               

                /*const datem = new Date(currentDate);
                const nextDate = datem.setDate(datem.getDate() + 1);
                alert(nextDate)*/
        
                this.setState({isModalLoading:false,clientDetailsData:showData,
                    activeLoanStatus:showData.activeLoanStatus,currentDate:currentDate,totalFines:totalFines,
                    surname:showData.userData.name,gender:showData.userData.sex,dob:showData.userData.dob,address:showData.userData.address,nin:showData.userData.nin,occup:showData.userData.occupation,
                    kin:showData.userData.kin,kinPhone:showData.userData.kinPhone,accStatus:showData.userData.status,email:showData.userData.email,blacklistReason:showData.userData.blacklistReason,blacklist:showData.userData.blacklistStatus,
                    uphone1:showData.userData.phone1,uphone2:showData.userData.phone2,blacklistedBy:showData.userData.blacklistedBy,
                    homeLat:showData.userData.homeLat,homeLong:showData.userData.homeLong,workLat:showData.userData.workLat,workLong:showData.userData.workLong

                })
               

                if(parseInt(showData.activeLoanStatus)===1){
                    this.setState({rloanType:showData.activeLoanData.loanType,loanType:showData.activeLoanData.loanType,
                        rate:showData.activeLoanData.intrest===null?0:showData.activeLoanData.intrest,
                        pFee:showData.activeLoanData.processingFee===null?0:showData.activeLoanData.processingFee,
                        payMode:showData.activeLoanData.payMode,amount:showData.activeLoanData.loanAmount,period:showData.activeLoanData.loanPeriod,
                        schedule:showData.activeLoanData.schedule,g2Relationship:showData.activeLoanData.g2Relationship,g1Relationship:showData.activeLoanData.g1Relationship,action:showData.activeLoanData.fineAction,staff:showData.activeLoanData.agent,security:showData.activeLoanData.securityType,
                        secDes:showData.activeLoanData.securityDes,g1Name:showData.activeLoanData.gName1,g1Phone1:showData.activeLoanData.gPhone1,g1Phone2:showData.activeLoanData.gPhone12,g2Name:showData.activeLoanData.gName2,g2Phone1:showData.activeLoanData.gPhone2,g2Phone2:showData.activeLoanData.gPhone22,
                        startPay:showData.activeLoanData.startPayDate

                    })
                }

            }else if(showDataStatus===false){
                this.setState({isModalLoading:false,showCatData:null,msg:null,erroModalMsg:msg})
            } 
        }
        /**cat data */
        if(catStatus !== prevProps.catStatus){
            if(catStatus===true){
                this.setState({catData:catData})
                
            }else if(catStatus===false){
                this.setState({catData:null})
            }
        }
        //update status
        if(updateStatus !== prevProps.updateStatus){
            if(updateStatus===true){
                this.setState({
                    modalPreview:false,
                    isUpdating:false, btnDisabled:false,
                    successModalMsg:msg,erroModalMsg:'',
                    isModalLoading:false,

                    loanType:"",rate:"",pFee:"",payMode:"INSTALLMENTS",amount:"",period:"",startPay:"",schedule:"",g2Relationship:"",g1Relationship:"",
                    action:"AUTOMATIC RENEWA",staff:"",security:"",secDes:"",g1Name:"",g1Phone1:"",g1Phone2:"",g2Name:"",g2Phone1:"",g2Phone2:"",modalPreview:false,
                    fineType:"INSTALLMENT FINE",fineAmount:"",finedFrom:"",finedTo:"",payAmount:"",bookNo:"",
                    editAmount:"",editCode:"",oldAmount:0,editID:"",editLoanID:"",editPayModal:false,forgiveAmount:"",receiptPath:null
                })
                this.setState({alertStatus :true, alertType :"success", alertTitle:msg})
                if(updateData !==null){
                    if(updateData.action ==="EDIT_PAY"){
                        this.setState({paymentDataList:[]})
                    }

                    if(updateData.receiptPath !==null){
                        this.setState({receiptPath:updateData.receiptPath})
                    }
                }


                /**refresh data */
                this.props.getSelectedID_Data(this.state.currentID,"CLIENT")
              
            }else if(updateStatus===false){
                this.setState({modalPreview:false,isModalLoading:false,isUpdating:false,btnDisabled:false,successModalMsg:'',
                    alertStatus :true, alertType :"error", alertTitle:msg
                })
            }
        }

        /**activating / deactivating user */
        if(changedStatus !== prevProps.changedStatus){
     
            if(changedStatus===true){
             
                /**refresh categories */
                this.props.getMainData("USER");
                this.setState({
                    isDeleting:false,successDeleteMsg:msg,deleteMsg:'',deleteModal:false,
                    modal:false
              }) 
            }else if(changedStatus===false){
                this.props.getMainData("USER");
                this.setState({successModalMsg:'',deleteMsg:msg,isDeleting:false,deleteModal:false,
                modal:false
            })
            }
        }

        /**changePassStatus */
        if(changePassStatus !== prevProps.changePassStatus){
            
            if(changePassStatus===true){
                
                this.setState({
                    isUpdatingPass:false,successPassModalMsg:msg,erroPassModalMsg:'',password1:"",password2:""
                }) 
            }else if(changePassStatus===false){
                this.setState({isUpdatingPass:false,successPassModalMsg:"",erroPassModalMsg:msg
                })
            }
        }
    }

    closeAlert =()=>{
        this.setState({
            alertOpen:false
        })
    }
    closeModal = ()=>{
        this.setState({modal:false,passwordModal:false})
        //refresh categories
        this.props.getMainData("CLIENTS");
    }

    closeModalPreview = ()=>{
        this.setState({modalPreview:false})
    }
    closeModalTrack = ()=>{
        this.setState({modelTrack:false})
    }
    closePasswordModal = () =>{
        this.setState({passwordModal:false,password1:'',password2:'',
        erroPassModalMsg:"",successPassModalMsg:'',isUpdatingPass:false,
        })
    }

    closeModalEditPay = ()=>{
        this.setState({editPayModal:false})
    }

    closeforgiveFineModal = ()=>{
        this.setState({forgiveFineModal:false})
    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.props.getSelectedID_Data(selectedID,"CLIENT")

        this.setOther_selectedUser(selectedID)   
    }
    setOther_selectedUser = selectedID =>{
   
        this.setState({
            modal:true,currentID:selectedID,
            isUpdating:false,btnDisabled:false,
            erroModalMsg:"",successDeleteMsg:"",
            successModalMsg:'',deleteMsg:"",
            details:false,summary:false,giveLoan:true,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,generateLoanRequest:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false, modalPreview:false,reopenLoan:false,

            loanType:"",rate:"20",pFee:"",payMode:"INSTALLMENTS",amount:"",period:"30",schedule:"1",g2Relationship:"",g1Relationship:"",
            action:"AUTOMATIC RENEWAL",staff:"",security:"",secDes:"",g1Name:"",g1Phone1:"",g1Phone2:"",g2Name:"",g2Phone1:"",g2Phone2:"",modalPreview:false,

            fineType:"INSTALLMENT FINE",fineAmount:"",finedFrom:"",finedTo:"",
            showPayDetails : false,currentDateStatus:true,currentDate:null,totalFines:0,

            searchloanID: "",paymentDataList:[],editPayModal:false,

            surname:"",gender:"",dob:"",photo:"",address:"",nin:"",occup:"",kin:"",kinPhone:"",accStatus:"",email:"",blacklistReason:"",blacklist:"",blacklistedBy:"",uphone1:"",uphone2:"",
            /**payment chart */
            selectedDate:null,
            chartStartDate:null,chartEndDate:null,searchLoanPaymentChart:"",searchloanIDChart:"", showCalender:false,
            chartList:[],loanStartDate:null

        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value,paymentDataList:[]}) 
        /*if(e.target.name==="amount"){
            this.calculateProcessingFee("pFee")
        }*/
    }

    calculateProcessingFee = (action) => {
     
        const {mainData,amount,clientDetailsData} = this.state
        /*if(mainData !==null){
            if(mainData.branchData.length>0){
                //get active branch
                const userD = JSON.parse(window.localStorage.getItem('userData'));
                const branchID = parseInt(userD.branchID)
                if(action==="pFee"){
                    let amount2 = amount
                    if(amount2 ===""|| parseInt(amount2)<1){
                        this.setState({alertStatus :true, alertType :"error", alertTitle:"First enter loan amount"})

                    }else{
                        mainData.branchData.map((values, i) =>{
                            if(parseInt(values.id)===branchID){
                                let gotIt = 0
                                values.processingfee.map((values2, i) =>{
                                    //for range
                                    if ((parseInt(amount2) >= parseInt(values2.min) && parseInt(amount2) <= parseInt(values2.max))) {
                                        // if new / old client
                                        if(clientDetailsData.newClient ==="YES"){
                                            gotIt = 1
                                            this.setState({pFee:values2.amount})
                                        }else{
                                            gotIt = 1
                                            this.setState({pFee:values2.amountOldClient})
                                        }
                                    }
                                })
                                if(parseInt(gotIt)===0){
                                    this.setState({pFee:0})
                                }
                            }
                        })
                    }
                    
                }
              


            }
        }*/

      };

    setActions= e =>{
        const v = e.target.value
        this.setState({erroModalMsg:"",successModalMsg:'',isUpdating:false,showPayDetails:false,paymentDataList:[],
        /**payment chart */
        selectedDate:null,
        chartStartDate:null,chartEndDate:null,searchLoanPaymentChart:"",searchloanIDChart:"", showCalender:false,
        chartList:[],loanStartDate:null,receiptPath:null
        })
        if (v==="1"){
            this.setState({details:true,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="2"){
            this.setState({details:false,summary:true,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="3"){
            this.setState({details:false,summary:false,giveLoan:true,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="4"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:true,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="5"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:true,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="6"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:true,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }

        if (v==="7"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:true,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        
        if (v==="8"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:true,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="9"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:true,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="10"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:true,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="11"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:true,editLoan:false,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="12"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:true,generateLoanRequest:false,reopenLoan:false})
        }
        if (v==="13"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,reopenLoan:false,generateLoanRequest:true})
        }

        if (v==="14"){
            this.setState({details:false,summary:false,giveLoan:false,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false,generateLoanRequest:false,reopenLoan:true})
        }


    }
    addLoan=(e)=>{
        
        const {loanType,rate,pFee,amount,period,startPay,schedule,
            staff,security,g1Name,g1Phone1,g2Name,g2Phone1,giveLoan,generateLoanRequest,
            rloanType,rrate,rpFee,ramount,rperiod,rschedule,
            rstaff,rsecurity,rg1Name,rg1Phone1,rg2Name,rg2Phone1,ridPosition,idPosition} = this.state

        this.setState({isUpdating:false})
          
        if(generateLoanRequest===true){
            
            if(rloanType===""||rrate==="" ||rpFee==="" ||ramount==="" ||rperiod==="" ||rschedule===""||rstaff===""||
            rsecurity==="" ||rg1Name==="" ||rg1Phone1==="" ||rg2Name==="" ||rg2Phone1===""||ridPosition===""){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Please enter all fields marked with a *"})
            }else if(parseInt(rrate)<1 ||parseInt(rrate)>100){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Interest rate must be between 1 and 100"})
            }else{
                this.setState({modalPreview:true})
    
            }
        }else{
          
            if(loanType===""||rate==="" ||pFee==="" ||amount==="" ||period==="" ||startPay==="" ||schedule===""||idPosition===""||
            security==="" ||g1Name==="" ||g1Phone1==="" ||g2Name==="" ||g2Phone1===""||staff===""){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Please enter all fields marked with a *"})
            }else if(parseInt(rate)<1 ||parseInt(rate)>100){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Interest rate must be between 1 and 100"})
            }else{
                this.setState({modalPreview:true})
            }
        }
       

    }

    confirm=(e)=>{
        const {generateLoanRequest,editLoan,loanType,rate,pFee,payMode,amount,period,startPay,schedule,g2Relationship,g1Relationship,
            action,staff,security,secDes,g1Name,g1Phone1,g1Phone2,g2Name,g2Phone1,g2Phone2,currentID,clientDetailsData,idPosition,
        
            rloanType,rrate,rpFee,rpayMode,ramount,rperiod,rschedule,rg2Relationship,rg1Relationship,rcurrentAddress,
            raction,rstaff,rsecurity,rsecDes,rg1Name,rg1Phone1,rg1Phone2,rg2Name,rg2Phone1,rg2Phone2,ridPosition
        
        } = this.state
        
        this.setState({isUpdating:true,btnDisabled:true})
        if(generateLoanRequest===true){
            if(checkRight(99)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to submit loan request approval"})
            }else{
                const data = new FormData();
                data.append("loanID",null);
                data.append("loanType",rloanType);
                data.append("rate",rrate);
                data.append("pFee",rpFee);
                data.append("payMode",rpayMode);
                data.append("amount",ramount);
                data.append("period",rperiod);
                data.append("action",raction);
                data.append("schedule",rschedule);
                data.append("g2Relationship",rg2Relationship);
                data.append("g1Relationship",rg1Relationship);
                data.append("action",action);
                data.append("staff",rstaff);
                data.append("security",rsecurity);
                data.append("secDes",rsecDes);
                data.append("g1Name",rg1Name);
                data.append("g1Phone1",rg1Phone1);
                data.append("g1Phone2",rg1Phone2);
                data.append("g2Name",rg2Name);
                data.append("g2Phone1",rg2Phone1);
                data.append("g2Phone2",rg2Phone2);
                data.append("userID",currentID);
                data.append("idPosition",ridPosition);
                data.append("currentAddress",rcurrentAddress);
                data.append("loanAction","ADD_LOAN_REQUEST");
                this.props.addLoanData(data,"SUBMITAPPROVAL")
            }
            
        }else if(editLoan===false){
            if(checkRight(28)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to add loan"})
            }else{
                /**upload new loan*/
                const data = new FormData();
                data.append("loanID",null);
                data.append("loanType",loanType);
                data.append("rate",rate);
                data.append("pFee",pFee);
                data.append("payMode",payMode);
                data.append("amount",amount);
                data.append("period",period);
                data.append("startPay",startPay);
                data.append("schedule",schedule);
                data.append("g2Relationship",g2Relationship);
                data.append("g1Relationship",g1Relationship);
                data.append("action",action);
                data.append("staff",staff);
                data.append("security",security);
                data.append("secDes",secDes);
                data.append("g1Name",g1Name);
                data.append("g1Phone1",g1Phone1);
                data.append("g1Phone2",g1Phone2);
                data.append("g2Name",g2Name);
                data.append("g2Phone1",g2Phone1);
                data.append("g2Phone2",g2Phone2);
                data.append("userID",currentID);
                data.append("idPosition",idPosition);
                data.append("loanAction","ADD_LOAN");
                this.props.addLoanData(data,"ADDLOAN")
            }
            
        }else{
            //edit loan
            if(checkRight(79)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to edit loan"})
            }else{
                /**upload new loan*/
                const data = new FormData();
                data.append("loanID",clientDetailsData.activeLoanData.id);
                data.append("loanAction","EDIT_LOAN");
                data.append("loanType",loanType);
                data.append("rate",rate);
                data.append("pFee",pFee);
                data.append("payMode",payMode);
                data.append("amount",amount);
                data.append("period",period);
                data.append("startPay",startPay);
                data.append("schedule",schedule);
                data.append("g2Relationship",g2Relationship);
                data.append("g1Relationship",g1Relationship);
                data.append("action",action);
                data.append("staff",staff);
                data.append("security",security);
                data.append("secDes",secDes);
                data.append("g1Name",g1Name);
                data.append("g1Phone1",g1Phone1);
                data.append("g1Phone2",g1Phone2);
                data.append("g2Name",g2Name);
                data.append("g2Phone1",g2Phone1);
                data.append("g2Phone2",g2Phone2);
                data.append("userID",currentID);
                this.props.addLoanData(data,"ADDLOAN")
            }
        }
    }

    deleteLoan=(e)=>{
        const {clientDetailsData} = this.state
        if(checkRight(80)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to delete loan"})

        }else{
            const data = new FormData();
            data.append("loanID",clientDetailsData.activeLoanData.id);
            this.props.addLoanData(data,"DELETELOAN")
        }

    }
  
    addFine=(e)=>{
        const {fineType,fineAmount,finedFrom,finedTo,currentID,clientDetailsData} = this.state

        this.setState({erroModalMsg:"",successModalMsg:'',isUpdating:false})

        if(fineType==="" ||fineAmount==="" ||finedFrom==="" ||finedTo===""){

            this.setState({alertStatus :true, alertType :"error", alertTitle:"Please enter all fields with a *"})
        }else if(parseInt(fineAmount)<1){
       
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Amount must be greater than zero"})
        }else if (datesDiffe(finedFrom,finedTo,null)<1) {
            
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Fined to date must be greater than fined from date"})
        }else{

            this.setState({erroModalMsg:"",isUpdating:true,btnDisabled:true}) 

            
            /**upload new loan*/
            const data = new FormData();
            data.append("fineType",fineType);
            data.append("fineAmount",fineAmount);
            data.append("finedFrom",finedFrom);
            data.append("finedTo",finedTo);
            data.append("userID",currentID);
            data.append("loanID",clientDetailsData.activeLoanData.loanID);

            this.props.addLoanData(data,"ADDFINE")
        }
    }

    showPayDetailsBtn =(e)=>{
        if (this.state.showPayDetails ===true){
            this.setState({showPayDetails:false})
        }else{
            this.setState({showPayDetails:true})
        }

    }

    /**payloan */
    addPay=(action)=>{
        const {bookNo,payAmount,clientDetailsData,currentID} = this.state
        let totalFines = clientDetailsData.activeLoanData.totalFines

        const loanBal = parseFloat(clientDetailsData.activeLoanData.expectedAmount) -parseFloat(clientDetailsData.activeLoanData.amountPaid)
        const totalBal = loanBal + parseFloat(totalFines)

        this.setState({erroModalMsg:"",successModalMsg:'',isUpdating:false,receiptPath:null})

        if(payAmount===""||bookNo===""){
         
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Please enter all fields with a *"})

        }else if(parseInt(payAmount)<1){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Amount should be greeter than zero"})
        }else if(parseInt(bookNo)<=0 ||parseInt(bookNo)>=1000){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Book number should be between zero and 1000"})
        }else if(parseInt(payAmount)>parseInt(totalBal)){

            this.setState({alertStatus :true, alertType :"error", alertTitle:"Amount should not exceed "+totalBal})

        }else{

            this.setState({erroModalMsg:"",isUpdating:true,btnDisabled:true,receiptPath:null}) 
 
            /**upload pay loan*/
            const data = new FormData();
            data.append("payAmount",payAmount);
            data.append("userID",currentID);
            data.append("bookNo",bookNo);
            data.append("loanID",clientDetailsData.activeLoanData.loanID);
            data.append("addedBy","");
            data.append("payDate","");
            data.append("setFrom","FRONT");
            data.append("action",action);

            this.props.addLoanData(data,"PAYLOAN")
        }
    }

    /**search loan paymen */
    searchLoanPayment=()=>{
        const {searchloanID,clientDetailsData} = this.state
        if(searchloanID===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select loan ID to search"})

        }else{
            let paymentDataList = []
            const myStaffData1 =  clientDetailsData
            if(myStaffData1 !==null){
                /**get payments */
                const myStaffData = clientDetailsData.payData
                if(myStaffData.length>0){
                    var no = 0
                    var loanBalance =0

                    clientDetailsData.loanData.map((values1, i) =>{
                        if(searchloanID ===values1.loanID.toUpperCase()){
                            loanBalance = values1.expectedAmount
                        }
                    })

                    myStaffData.map((values, i) =>{
                        
                        if(searchloanID ===values.loanID.toUpperCase()){
                            no = no+1
                            loanBalance = loanBalance-values.amount
                         
                            paymentDataList.push(
                                <tr key={i}>
                                    <td>{no}</td>
                                    <td>{values.id}</td>
                                    <td>{values.loanID}</td>
                                    <td>{values.bookNo}</td>
                                    <td>{values.amount.toLocaleString()}</td>
                                    <td>{getFine(clientDetailsData.finePaymentData,values.loanID,values.payCode).toLocaleString()}</td>
                                    <td>{loanBalance.toLocaleString()}</td>
                                    <td>{values.payDate}</td>
                                    <td>{values.created_at}</td>
                                    <td><Button id={values.id+"-"+values.loanID+"-"+values.payCode+"-"+values.amount} onClick={this.editPayment}>Edit</Button></td>
                                </tr>
                            )
                        }
                    })
                  
                }else{
                    paymentDataList.push(
                        <div>
                            <br/><Alert color="primary">No payments made on the selected loan </Alert>
                        </div>
                    )
                }
                this.setState({
                    paymentDataList :paymentDataList
                })
            }
        }
    }

    /**printPayment */
    printPayment=()=>{
        const {searchloanID} = this.state
        if(searchloanID===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select loan ID to download/print its payments"})
        }else{
            this.setState({isUpdating:true,receiptPath:null})
            const data = new FormData();
            data.append("loanID",searchloanID);
            this.props.addLoanData(data,"PRINTPAYMENT")
        }
    }


    //editPayment
    editPayment=(e)=>{
      
        const selectedloanID = e.target.id;
        //id,loanID,payCode,amount
        var nameArr = selectedloanID.split('-');
 
        const {activeLoanStatus,clientDetailsData} = this.state
        if(parseInt(activeLoanStatus) ===0){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Payments can only be editted if loan is still active"})
        }else{
            /**chek if its active loan */
            if(clientDetailsData.activeLoanData.loanID ===nameArr[1]){
                this.setState({editPayModal:true,editCode:nameArr[2],oldAmount:nameArr[3],editID:nameArr[0],editLoanID:nameArr[1]})
            }else{
         
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Selected loan is closed, no editting is allowed"})

            }

        }
    }

    //submitEdit
    submitEdit=(e)=>{
        const {editComment,editAmount,editCode,currentID,editID,editLoanID,clientDetailsData,totalFines,oldAmount} = this.state

        this.setState({erroModalMsg:"",successModalMsg:'',isUpdating:false})

        const loanBal = parseFloat(clientDetailsData.activeLoanData.expectedAmount) -parseFloat(clientDetailsData.activeLoanData.amountPaid-oldAmount)
        const totalBal = loanBal + parseFloat(totalFines)

        if(editAmount===""){
      
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Please enter all fields with a *"})
        }else if(editAmount<0){
       
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Amount can not be negative"})
        }else if(parseInt(editAmount)>parseInt(totalBal)){
       
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Amount should not exceed "+totalBal})
        }else{

            this.setState({erroModalMsg:"",isUpdating:true,btnDisabled:true}) 
 
            /**upload pay loan*/
            const data = new FormData();
            data.append("editAmount",editAmount);
            data.append("editCode",editCode);
            data.append("editID",editID);
            data.append("loanID",editLoanID);
            data.append("userID",currentID);
            data.append("oldAmount",oldAmount);
            data.append("editComment",editComment);
            
            this.props.addLoanData(data,"EDITLOANPAY")
        }
    }

    //updateClient
    updateClient=(e)=>{
        const {surname,gender,dob,photo,address,nin,occup,kin,kinPhone,accStatus,email,blacklistReason,blacklist,
        uphone1,uphone2,currentID,homeLat,homeLong,workLat,workLong} = this.state

        if(checkRight(23)===false && checkRight(102)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to update client details"})
        }else{
            if(surname===""||gender===""||dob===""||nin===""||address===""||uphone1===""||occup===""){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Please enter all feilds marked with *"})
            }else{

                this.setState({isUpdating:true,btnDisabled:true}) 
                /**upload pay loan*/
                const data = new FormData();
                data.append("userID",currentID);
                data.append("surname",surname);
                data.append("gender",gender);
                data.append("dob",dob);
                data.append("address",address);
                data.append("nin",nin);
                data.append("occup",occup);
                data.append("kin",kin);
                data.append("kinPhone",kinPhone);
                data.append("email",email);
                data.append("uphone1",uphone1);
                data.append("uphone2",uphone2);
                data.append("homeLat",homeLat);
                data.append("homeLong",homeLong);
                data.append("workLat",workLat);
                data.append("workLong",workLong);
             
                this.props.addLoanData(data,"UPDATECLIENT")
            }
        }

    }

    updateClientStatus = (e)=>{
        const {accStatus,currentID} = this.state
        if(checkRight(24)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to activate / deactivate client"})
        }else{
            this.setState({isUpdating:true,btnDisabled:true}) 
            /**upload pay loan*/
            const data = new FormData();
            data.append("userID",currentID);
            data.append("accStatus",accStatus);
        
            this.props.addLoanData(data,"CLIENT_ACTIVATE")
        }
    }

    updateBlacklistStatus = (e)=>{
        const {blacklistReason,blacklist,currentID} = this.state
        if(parseInt(blacklist)===1){
            if(checkRight(75)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to blacklist client"})
            }else{
                if(blacklistReason ===""||blacklistReason ===null){
                    this.setState({alertStatus :true, alertType :"error", alertTitle:"Enter a reason for blacklisting a client"})
                }else{
                    this.setState({isUpdating:true,btnDisabled:true}) 
                    /**upload pay loan*/
                    const data = new FormData();
                    data.append("userID",currentID);
                    data.append("blacklist",blacklist);
                    data.append("blacklistReason",blacklistReason);
          
                    this.props.addLoanData(data,"BLACKLIST")
                }
    
            }
        }else{
            if(checkRight(23)===false){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to unblacklist client"})
            }else{
                this.setState({isUpdating:true,btnDisabled:true}) 
                /**upload pay loan*/
                const data = new FormData();
                data.append("userID",currentID);
                data.append("blacklist",blacklist);
                this.props.addLoanData(data,"UNBLACKLIST")
            }
        }
        
    }

  

    handlePrint = () => {
        this.componentRef && this.componentRef.handlePrint();
    };

    handleDateClick = (date) => {
    this.setState({ selectedDate: date });
    };

    getPaymentStatus = (date) => {
        const {chartList} = this.state
                                              
        // Find the payment details for the given date
        const paymentDetails = chartList.filter(item => item.date === date);
       //console.log(paymentDetails)
        // Return the first payment detail if found, or null otherwise
        return paymentDetails.length > 0 ? paymentDetails[0] : null;
    };

    formatDate = (inputDate) => {
        const date = new Date(inputDate);
      
        // Get year, month, and day
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-based
        const day = String(date.getDate()).padStart(2, '0');
      
        // Construct the formatted date string in "YYYY-MM-DD" format
        const formattedDate = `${year}-${month}-${day}`;
      
        return formattedDate;
    };
    
    /**searchLoanPaymentChart */
    searchLoanPaymentChart=()=>{
    
        const {searchloanIDChart,clientDetailsData} = this.state
        if(searchloanIDChart===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select loan ID to search"})

        }else{
            this.setState({showCalender:false})
            let returnedDates = getName2(clientDetailsData.loanData,searchloanIDChart,"LOAN")
            let startDate = returnedDates[0]
            let endPayDate = returnedDates[1]
            let currentDate = clientDetailsData.systemSettingData.currentDate
            
            let paidAdvanceList = JSON.stringify(returnedDates[2])
            let loanInstallment = parseInt(returnedDates[3])
            let closedAt = returnedDates[4]
            let status = returnedDates[5]
            let datesList = null
            if(parseInt(status)===0){
                datesList = generateDates(startDate,closedAt)
            }else{
                const endPayDate2 = new Date(endPayDate);
                const currentDate2 = new Date(currentDate);
                if(currentDate2<=endPayDate2){
                    datesList = generateDates(startDate,currentDate)
                    //console.log(datesList)
                }else{
                    datesList = generateDates(startDate,currentDate)
                }
            }



            const clientDetails =  clientDetailsData
            if(clientDetails !==null){
                let chartList = []
                for (const date of datesList) {
                    let madePayment = 0
                    let paidAmount = 0
                    clientDetailsData.payData.map((values, i) =>{
                        if(values.loanID.toUpperCase() ===searchloanIDChart){
                            if(values.payDate ===date){
                                madePayment = 1
                                paidAmount =paidAmount+parseInt(values.amount)
                            }
                        }
                    })
                    /**if no payment checkAdvance */
                    if(madePayment===0||paidAmount===0){
                        if(paidAdvanceList.includes(date) ===true){
                            const newDateObject = {date:date, status: "advance", amount: loanInstallment, points: 2};
                            chartList.push(newDateObject)
                        }else{
                            const newDateObject = {date:date, status: "missed", amount: 0, points: -2};
                            chartList.push(newDateObject)
                        }
                    }

                    if(madePayment===1&&paidAmount>0){
                        if(paidAmount>=loanInstallment){
                            const newDateObject = {date:date, status: "full", amount: paidAmount, points: 1};
                            chartList.push(newDateObject)
                        }else{
                            const newDateObject = {date:date, status: "partial", amount: paidAmount, points: 1};
                            chartList.push(newDateObject)
                        }
                    }
               
                }

                // Extract the year and month from the chartStartDate
                const [year, month] = returnedDates[0].split('-').map(Number);
                console.log(returnedDates[0])
        
                // Create a new Date object with the extracted year and month
                let newChartStartDate = new Date(year, month - 1, 1);
              

                this.setState({
                    chartStartDate:returnedDates[0],
                    chartEndDate:returnedDates[1],
                    chartList:chartList,
                    showCalender:true,
                    activeMonth:newChartStartDate,
                    loanStartDate:returnedDates[0]
                })
            }

            
        }
    }

    handlePrevClick = () => {
        const activeData = new Date(this.state.activeMonth);
        const prevMonth = activeData.getMonth()-1
        const yr = activeData.getFullYear()
        const prevDate = new Date(yr, prevMonth, 1)
   
        this.setState({ activeMonth: prevDate });
    };
    
    handleNextClick = () => {
        const activeData = new Date(this.state.activeMonth);
        const nextMonth = activeData.getMonth()+1
        const yr = activeData.getFullYear()
        const nextDate = new Date(yr, nextMonth, 1)
   
        this.setState({ activeMonth: nextDate});
    };
    
    setHome =(e)=>{
        if (navigator.onLine) {
            if("geolocation" in navigator){
                // Get the user's current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.setState({
                        homeLat: position.coords.latitude,
                        homeLong: position.coords.longitude,
                        alertStatus :true, alertType :"success", alertTitle:"Home address has been set"
                    });
                    },
                    (error) => {
                        this.setState({alertStatus :true, alertType :"error", alertTitle:error.message});
                    }
                );
            }else{
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Geolocation is not available in your browser"});
            } 
        }else{
            this.setState({alertStatus :true, alertType :"error", alertTitle:"No internet connection"});
        }
    }

    setWork =(e)=>{
        if (navigator.onLine) {
            if("geolocation" in navigator){
                // Get the user's current location
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.setState({
                        workLat: position.coords.latitude,
                        workLong: position.coords.longitude,
                        alertStatus :true, alertType :"success", alertTitle:"Work address has been set"
                    });
                    },
                    (error) => {
                        this.setState({alertStatus :true, alertType :"error", alertTitle:error.message});
                    }
                );
            }else{
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Geolocation is not available in your browser"});
            } 
        }else{
            this.setState({alertStatus :true, alertType :"error", alertTitle:"No internet connection"});
        }

    }

    findAddress=(action)=>{
        const {homeLat,homeLong,workLat,workLong,currentID} = this.state
        if(checkRight(103)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to find client details"})
        }else{
            this.setState({isRouting:true})
            if (navigator.onLine){
                if("geolocation" in navigator){
                    // Get the user's current location
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            let myLat = position.coords.latitude
                            let myLong = position.coords.longitude
                            

                            if(action ==="HOME"){
                                if(isNumber(homeLat)===false||isNumber(homeLong)===false){
                                    this.setState({isRouting:false,alertStatus :true, alertType :"error", alertTitle:"Home address is not defined, first set it"});
                                
                                }else{
                                    const googleMapsUrl = `https://www.google.com/maps/dir/${myLat},${myLong}/${homeLat},${homeLong}/`
                                    this.setState({isRouting:false,modelTrack:true,trackUrl:googleMapsUrl});

                                    //report tracker
                                    const data = new FormData();
                                    data.append("userID",currentID);
                                    data.append("action",action);
                                    this.props.addLoanData(data,"REPORTTRACKER")
                                }
            
                            }else{
                                if(isNumber(workLat)===false||isNumber(workLong)===false){
                                    this.setState({isRouting:false,alertStatus :true, alertType :"error", alertTitle:"Work address is not defined, first set it"});
                                }else{
                                    const googleMapsUrl = `https://www.google.com/maps/dir/${myLat},${myLong}/${workLat},${workLong}/`
                                    this.setState({isRouting:false,modelTrack:true,trackUrl:googleMapsUrl});

                                    //report tracker
                                    const data = new FormData();
                                    data.append("userID",currentID);
                                    data.append("action",action);
                                    this.props.addLoanData(data,"REPORTTRACKER")
                                }
                            }
                        },
                        (error) => {
                            this.setState({isRouting:false,alertStatus :true, alertType :"error", alertTitle:error.message});
                        }


                    );
                
                }else{
                    this.setState({isRouting:false,alertStatus :true, alertType :"error", alertTitle:"Geolocation is not available in your browser"});
                } 
            }else{
                this.setState({isRouting:false, alertStatus :true, alertType :"error", alertTitle:"No internet connection"});
            }
        }
    }

    /**reopen loan*/
    reopenLoan=()=>{
        const {searchloanID} = this.state
        if(searchloanID===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select loan ID to reopen"})
        }else{
            this.setState({isUpdating:true,btnDisabled:true}) 
 
            /**upload pay loan*/
            const data = new FormData();
            data.append("searchloanID",searchloanID);
            this.props.addLoanData(data,"REOPENLOAN")
    
        }
    }

    /**searchFines */
    searchFines=()=>{
        const {searchloanID,selectFines,clientDetailsData} = this.state
        if(searchloanID===""||selectFines===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select loan ID to search"})
        }else{
           
            let paymentDataList = []
            const myStaffData1 =  clientDetailsData
            if(myStaffData1 !==null){
                    /**get paid fines*/
                    if(selectFines ==="PAID"){
                        const myStaffData = clientDetailsData.finePaymentData
                        if(myStaffData.length>0){
                            var no = 0
                            myStaffData.map((values, i) =>{
                                if(searchloanID ===values.loanID.toUpperCase() && parseInt(values.forgiven)===0){
                                    no = no+1
                                    paymentDataList.push(
                                        <tr key={i}>
                                            <td>{no}</td>
                                            <td>{values.loanID}</td>
                                            <td>{values.amount.toLocaleString()}</td>
                                            <td>{values.payCode}</td>
                                            <td>{getName2(clientDetailsData.staffData,values.added_by,"STAFF")}</td>
                                            <td>{values.payDate}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            })
                        
                        }else{
                            paymentDataList.push(
                                <div>
                                    <br/><Alert color="primary">No fine on the selected loan </Alert>
                                </div>
                            )
                        }
                    }

                    if(selectFines ==="FORGIVEN"){
                        const myStaffData = clientDetailsData.finePaymentData
                        if(myStaffData.length>0){
                            var no = 0
                            myStaffData.map((values, i) =>{
                                if(searchloanID ===values.loanID.toUpperCase() && parseInt(values.forgiven)===1){
                                    no = no+1
                                    paymentDataList.push(
                                        <tr key={i}>
                                            <td>{no}</td>
                                            <td>{values.loanID}</td>
                                            <td>{values.amount.toLocaleString()}</td>
                                            <td>{values.payCode}</td>
                                            <td>{getName2(clientDetailsData.staffData,values.added_by,"STAFF")}</td>
                                            <td>{values.payDate}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            })
                        
                        }else{
                            paymentDataList.push(
                                <div>
                                    <br/><Alert color="primary">No fine on the selected loan </Alert>
                                </div>
                            )
                        }
                    }

                    /**get not paid fines*/
                    if(selectFines ==="ACTIVE"){
                        const myStaffData = clientDetailsData.finesData
                        if(myStaffData.length>0){
                            var no = 0
                            if(this.state.clientDetailsData.activeLoanData.loanID ===searchloanID &&
                                parseInt(this.state.clientDetailsData.activeLoanData.totalFines)>0
                                ){
                                paymentDataList.push(
                                <tr style={{backgroundColor:"#B3E7CD",fontSize:10}}>
                                    <td></td>
                                    <td></td>
                                    <td>TOTAL UN PAID FINES</td>
                                    <td>{this.state.clientDetailsData.activeLoanData.totalFines.toString()}</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td><Button id={searchloanID} onClick={this.forgiveFine} className="btn btn-success">FORGIVE</Button></td>
                                    
                                </tr>)
                            }

                            myStaffData.map((values, i) =>{
                                if(searchloanID ===values.loanID.toUpperCase()){
                                    no = no+1
                                    paymentDataList.push(
                                        <tr key={i}>
                                            <td>{no}</td>
                                            <td>{values.loanID}</td>
                                            <td>{values.fineType}</td>
                                            <td>{values.amount.toLocaleString()}</td>
                                            <td>{values.finedFrom}</td>
                                            <td>{values.finedTo}</td>
                                       
                                            <td>{getName2(clientDetailsData.staffData,values.added_by,"STAFF")}</td>
                                            <td>{values.created_at}</td>

                                        </tr>
                                    )
                                }
                            })
                        
                        }else{
                            paymentDataList.push(
                                <div>
                                    <br/><Alert color="primary">No fine on the selected loan </Alert>
                                </div>
                            )
                        }
                    }
                this.setState({
                    paymentDataList :paymentDataList
                })
            }

            if(paymentDataList.length<1){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"No data found for the selected loan"})

            }

            
        }
    }

    forgiveFine=(e)=>{
        const loanID = e.target.id;
        if(checkRight(40)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to forgive fine"})
        }else{
            this.setState({forgiveFineModal:true})
        }
    }

    //submitForgivenFines
    submitForgivenFines=(e)=>{
        const {clientDetailsData,forgiveAmount} = this.state

        this.setState({isUpdating:false})

        if(forgiveAmount===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Please enter all fields with a *"})
        }else if(forgiveAmount<0){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Amount can not be negative"})
        }else if(parseInt(forgiveAmount)>parseInt(clientDetailsData.activeLoanData.totalFines)){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Amount should not exceed "+clientDetailsData.activeLoanData.totalFines})
        }else{

            this.setState({isUpdating:true,btnDisabled:true}) 
            /**upload pay loan*/
            const data = new FormData();
            data.append("amount",forgiveAmount);
            data.append("loanID",clientDetailsData.activeLoanData.loanID);
            this.props.addLoanData(data,"FORGIVEFINE")
        }
    }
    //getMoreDetails
    getMoreDetails=(e)=>{
        const {showMoreDetails} = this.state
        if(showMoreDetails===true){
            this.setState({showMoreDetails:false})
        }else{
            this.setState({showMoreDetails:true})
        }
    }

    closeMainBtn=(e)=>{
        this.setState({alertStatus: false})
    }

    render() {
        const {clientDetailsData,homeLat,homeLong,workLat,workLong,giveLoan,generateLoanRequest} = this.state
        const userD = JSON.parse(window.localStorage.getItem('userData'));
        /**displaying  users*/
        let userDataList = [];
        const myUserData =  this.state.userData

        if(myUserData !==null){
            if(myUserData.length>0){
                let no = 0
                myUserData.map((values, i) =>{
                    no = no+1
                    userDataList.push(
                        <tr key={i}>
                            <td><Button id={values.id} onClick={this.viewUser}>View Details</Button></td>
                            <th scope="row">{no}</th>
                            <td>{values.name}</td>
                            <td>{values.account}</td>
                            <td>{values.phone1}</td>
            
                            <td>{values.sex}</td>
                            <td>{values.address}</td>
                            
                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                            <td><Label className="status2">Inactive</Label></td>
                            }
                            <td>{values.created_at}</td>
                     
                        </tr>
                    )
                });
            }else{
                userDataList.push(
                    <div>
                        <br/><Alert color="primary">No clients data uploaded yet</Alert>
                    </div>
                )
            }
        }

        /**account */
        const searchword = this.state.accountSearch
        if (searchword) {
            if(myUserData !==null){
                userDataList = []
                if(myUserData.length>0){
                    let no = 0
                    myUserData.map((values, i) =>{
                        if(values.account === searchword.toUpperCase() ){
                            no = no+1
                            userDataList.push(
                                <tr key={i}>
                                    <td><Button id={values.id} onClick={this.viewUser}>View Details</Button></td>
                                    <th scope="row">{no}</th>
                                    <td>{values.name}</td>
                                    <td>{values.account}</td>
                                    <td>{values.phone1}</td>
                                    <td>{values.sex}</td>
                                    <td>{values.address}</td>
                                  
                                    {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                    <td><Label className="status2">Inactive</Label></td>
                                    }
                                     <td>{values.created_at}</td>
                                   
                                </tr>
                            )
                        }
                    });
                }
            }
        }

        /**account */
        const searchword2 = this.state.nameSearch
        if (searchword2) {
            if(myUserData !==null){
                userDataList = []
                if(myUserData.length>0){
                    let no =0
                    myUserData.map((values, i) =>{
                        var myarray = values.name.split(' ');
                        //console.log(myarray[0])
                        if(myarray[0] === searchword2.toUpperCase() || myarray[1] === searchword2.toUpperCase() || values.name === searchword2.toUpperCase()  ){
                            no = no+1
                            userDataList.push(
                                <tr key={i}>
                                    <td><Button id={values.id} onClick={this.viewUser}>View Details</Button></td>
                                    <th scope="row">{no}</th>
                                    <td>{values.name}</td>
                                    <td>{values.account}</td>
                                    <td>{values.phone1}</td>
                               
                                    <td>{values.sex}</td>
                                    <td>{values.address}</td>
                                    
                                    
                                    
                                    {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                    <td><Label className="status2">Inactive</Label></td>
                                    }
                                    <td>{values.created_at}</td>
                                    
                                </tr>
                            )
                        }
                    });
                }
            }
        }

        /**get agents */
        let staffDataList = [];
        let loanTypesDataList = [];
        let loanIDsList = [];
        const myStaffData1 =  this.state.clientDetailsData
        if(myStaffData1 !==null){
            /**get agents */
            const myStaffData =  this.state.clientDetailsData.staffData
            if(myStaffData.length>0){
                staffDataList.push(
                    <option value="">Select Agent</option>
                )
                myStaffData.map((values, i) =>{
                    if(parseInt(values.branchID)===parseInt(userD.branchID)){
                        if(parseInt(values.status)===1){
                            if(parseInt(this.state.clientDetailsData.systemSettingData.clientsToOnlyAgents) === 1){
                                
                                if(parseInt(values.account.descriptorID)===3){
                                    staffDataList.push(
                                        <option value={values.id}>{values.username}</option>
                                    )
                                }
                            }else{
                                staffDataList.push(
                                    <option value={values.id}>{values.username}</option>
                                )
                            }
 
                        }
                    }
                    
                 });
            }  

            /**loan types */
            const myloanData =  this.state.clientDetailsData.loanTypeData
            if(myloanData.length>0){
                myloanData.map((values, i) =>{
                    loanTypesDataList.push(
                        <option value={values.id}>{values.name}</option>
                    )
                });
            }
            /**loanIDs */
            const myloanData2 =  this.state.clientDetailsData.loanData
            if(myloanData2.length>0){
                loanIDsList.push(
                    <option value="">Select Loan ID</option>
                )
                myloanData2.map((values, i) =>{
                    loanIDsList.push(
                        <option value={values.loanID}>{values.loanID}</option>
                    )
                });
            }
        }

        let staffNames =[];
        if(clientDetailsData !==null){
            if(clientDetailsData.activeLoanData !==null){
                if(clientDetailsData.activeLoanData.loan_request !==null){
                    const staff = JSON.parse(clientDetailsData.activeLoanData.loan_request.visitedBy)
                    if(staff.length>0){
                        staff.map((values,i)=>{
                            staffNames.push(getName2(clientDetailsData.staffData,values,"STAFF"))
                        })
                    }
                }
            }  
        }

        return (
            <div>
             
                <Row>
                    <div className="block-header">
                        <h4>VIEW CLIENTS</h4>
                    </div>
                    <Col xs="12" className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.closeMainBtn()}/>
        
                        {this.state.successDeleteMsg?<Alert color="success" isOpen={this.state.alertOpen} toggle={this.closeAlert}>{this.state.successDeleteMsg}</Alert>:null}
                        {this.state.deleteMsg?<Alert color="danger" isOpen={this.state.alertOpen} toggle={this.closeAlert}>{this.state.deleteMsg}</Alert>:null}
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>User Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                {this.state.isModalLoading?
                                <div><br/>
                                    <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                </div>:
                                <div>

                                    <FormGroup className="loanBox2">
                                        <Input type="select" onChange={this.setActions} className="loanBox" name="payMode">
                                            <option value="3">GIVE LOAN</option> 
                                            <option value="13">REQUEST LOAN APPROVAL</option>
                                            <option value="4">PAY LOAN</option>
                                            <option value="12">EDIT LOAN</option>
                                            <option value="14">REOPEN CLOSED LOAN</option>
                                            <option value="1">CLIENT DETAILS</option>
                                            <option value="2">ACCOUNT SUMMARY</option> 
                                            <option value="5">LOAN DETAILS</option> 
                                            <option value="6">LOAN PAYMENTS</option> 
                                            <option value="7">PAYMENT CHART</option> 
                                            <option value="8">ADD FINES</option> 
                                            <option value="9">SEE FINES</option> 
                                            {/*<option value="10">PAID FINES</option>  
                                            <option value="11">FORGIVEN FINES</option>*/} 
                                         
                                        </Input>
                                    </FormGroup><br/>

                                    {/**giveLoan */}
                                    {this.state.giveLoan ===true?
                                    <>
                                        {checkRight(28)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            {parseInt(this.state.activeLoanStatus) ===1?
                                                <Row>
                                                    <Col xs="12" md="12">
                                                        <Label className="activeLoan">Client has active loan</Label>
                                                    </Col>
                                                </Row>:
                                                <Row>
                                                    {parseInt(this.state.clientDetailsData.userData.blacklistStatus) ===1?
                                                        <Col xs="12" md="12">
                                                            <Label className="activeLoan">Client is blacklisted</Label>
                                                        </Col>:
                                                    <>
                                                        {parseInt(this.state.clientDetailsData.userData.status) ===0?
                                                        <Col xs="12" md="12">
                                                            <Label className="activeLoan">Client is deactivated</Label>
                                                        </Col>:
                                                        <>
                                                            <Col xs="6" md="6">
                                                                <FormGroup className="loanBox2">
                                                                    <Label className="loanLabel">Loan Type*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="loanType">
                                                                            {loanTypesDataList}
                                                                    </Input>
                                                                </FormGroup>

                                                                <FormGroup  className="loanBox2">
                                                                    <Label className="loanLabel">Interest(%)*</Label>
                                                                    <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rate} name="rate"  />
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Payment Mode*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="payMode">
                                                                
                                                                        <option value="INSTALLMENTS">INSTALLMENTS</option> 
                                                                        <option value="PAY AT ONCE">PAY AT ONCE</option> 
                                                                    </Input>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label  className="loanLabel">Loan Amount(Shs)* </Label>
                                                                    <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.amount} name="amount"/>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label  className="loanLabel">Processing Fee* <span className='verySmall'>Click below</span></Label>
                                                                    <Input type="number" min={0}  onClick={() => this.calculateProcessingFee("pFee")} onChange={this.setValue} className="loanBox" value={this.state.pFee} name="pFee"/>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Loan Period(days)*</Label>
                                                                    <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.period} name="period" />
                                                                </FormGroup>

                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Start Payment*</Label>
                                                                    <Input type="date" min={this.state.currentDate} onChange={this.setValue} className="loanBox" value={this.state.startPay} name="startPay" />
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label className="loanLabel">Payment Schedule(days)*</Label>
                                                                    <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.schedule} name="schedule"/>
                                                                </FormGroup>

                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Expired Loan Action*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="action">
                                                                        <option value="AUTOMATIC RENEWAL">AUTOMATIC RENEWAL</option> 
                                                                        <option value="AUTOMATIC FINING">AUTOMATIC FINING</option> 
                                                                    </Input>
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Feild Agent*</Label>
                                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="staff">
                                                                        {staffDataList}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>

                                                            <Col xs="6" md="6">
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="name" className="loanLabel">Security Type*</Label>
                                                                    <Input type="text"   className="loanBox" onChange={this.setValue} value={this.state.security} name="security"/>
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Security Description</Label>
                                                                    <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.secDes} name="secDes" />
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 1 Name*</Label>
                                                                    <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.g1Name} name="g1Name"/>
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 1 Phone*</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g1Phone1} name="g1Phone1" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 1 Phone 2</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g1Phone2} name="g1Phone2"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Relationship</Label>
                                                                    <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.g1Relationship} name="g1Relationship" />
                                                                </FormGroup>

                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 2 Name*</Label>
                                                                    <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.g2Name} name="g2Name" />
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 2 Phone*</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g2Phone1} name="g2Phone1"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 2 Phone 2</Label>
                                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g2Phone2} name="g2Phone2"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Relationship</Label>
                                                                    <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.g2Relationship} name="g2Relationship"/>
                                                                </FormGroup>
                                                                
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">ID Position*</Label>
                                                                    <Input type="text" onChange={this.setValue} className="loanBox" name="idPosition" value={this.state.idPosition}/>
                                                                      
                                                                </FormGroup>
                                                            </Col>
                                                        </>}

                                                    </>}

                                                    <Col xs="12" md="12"><br/>
                                                        <FormGroup className="loanBox2">
                                                            {this.state.successModalMsg?<Alert color="success">{this.state.successModalMsg}</Alert>:null}
                                                            {this.state.erroModalMsg?<Alert color="danger">{this.state.erroModalMsg}</Alert>:null}
                                                            {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                        </FormGroup>
                                                    </Col>

                                                    <Row className="modalFooter">
                                                        <Col xs="1" md="4">.</Col>
                                                        <Col xs="10" md="4">
                                                            <Button onClick={this.addLoan} className="btn btn-secondary loanBtn">Review</Button>
                                                        </Col>
                                                        <Col xs="1" md="4">.</Col>
                                                        </Row>
                                                </Row>
                                            }
                                        </>}
                                    </>
                                    :null}

                                    {/**generateLoanRequest*/}
                                    {this.state.generateLoanRequest ===true?
                                    <>
                                        {checkRight(99)===false?
                                            <Alert color="danger">Unauthorized to generate loan request</Alert>
                                        :<>
                                            <Row>
                                                {parseInt(this.state.clientDetailsData.userData.blacklistStatus) ===1?
                                                    <Col xs="12" md="12">
                                                        <Label className="activeLoan">Client is blacklisted</Label>
                                                    </Col>:
                                                <>
                                                    {parseInt(this.state.clientDetailsData.userData.status) ===0?
                                                    <Col xs="12" md="12">
                                                        <Label className="activeLoan">Client is deactivated</Label>
                                                    </Col>:
                                                    <>
                                                        <Col xs="6" md="6">
                                                            <FormGroup className="loanBox2">
                                                                <Label className="loanLabel">Loan Type*</Label>
                                                                <Input type="select" onChange={this.setValue} className="loanBox" name="rloanType">
                                                                        {loanTypesDataList}
                                                                </Input>
                                                            </FormGroup>

                                                            <FormGroup  className="loanBox2">
                                                                <Label className="loanLabel">Interest(%)*</Label>
                                                                <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rrate} name="rrate"  />
                                                            </FormGroup>
                                                            <FormGroup  className="loanBox2">
                                                                <Label  className="loanLabel">Processing Fee*</Label>
                                                                <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rpFee} name="rpFee"/>
                                                            </FormGroup>
                                                    
                                                            <FormGroup className="loanBox2">
                                                                <Label for="accType" className="loanLabel">Payment Mode*</Label>
                                                                <Input type="select" onChange={this.setValue} className="loanBox" name="rpayMode">
                                                            
                                                                    <option value="INSTALLMENTS">INSTALLMENTS</option> 
                                                                    <option value="PAY AT ONCE">PAY AT ONCE</option> 
                                                                </Input>
                                                            </FormGroup>
                                                            <FormGroup  className="loanBox2">
                                                                <Label  className="loanLabel">Loan Amount(Shs)*</Label>
                                                                <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.ramount} name="ramount"/>
                                                            </FormGroup>
                                                            <FormGroup  className="loanBox2">
                                                                <Label for="phone" className="loanLabel">Loan Period(days)*</Label>
                                                                <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.rperiod} name="rperiod" />
                                                            </FormGroup>

                                                        
                                                            <FormGroup  className="loanBox2">
                                                                <Label className="loanLabel">Payment Schedule(days)*</Label>
                                                                <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.rschedule} name="rschedule"/>
                                                            </FormGroup>

                                                            <FormGroup className="loanBox2">
                                                                <Label for="accType" className="loanLabel">Expired Loan Action*</Label>
                                                                <Input type="select" onChange={this.setValue} className="loanBox" name="raction">
                                                                    <option value="AUTOMATIC RENEWAL">AUTOMATIC RENEWAL</option> 
                                                                    <option value="AUTOMATIC FINING">AUTOMATIC FINING</option> 
                                                                </Input>
                                                            </FormGroup>
                                                            <FormGroup className="loanBox2">
                                                                <Label for="accType" className="loanLabel">Feild Agent*</Label>
                                                                <Input type="select" onChange={this.setValue} className="loanBox" name="rstaff">
                                                                    {staffDataList}
                                                                </Input>
                                                            </FormGroup>
                                                            <FormGroup className="loanBox2">
                                                                <Label for="username"  className="loanLabel">Current Address</Label>
                                                                <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.rcurrentAddress} name="rcurrentAddress" />
                                                            </FormGroup>
                                                        </Col>

                                                        <Col xs="6" md="6">
                                                            <FormGroup className="loanBox2">
                                                                <Label for="name" className="loanLabel">Security Type*</Label>
                                                                <Input type="text"   className="loanBox" onChange={this.setValue} value={this.state.rsecurity} name="rsecurity"/>
                                                            </FormGroup>
                                                            <FormGroup className="loanBox2">
                                                                <Label for="username"  className="loanLabel">Security Description</Label>
                                                                <Input type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rsecDes} name="rsecDes" />
                                                            </FormGroup>
                                                            <FormGroup  className="loanBox2">
                                                                <Label for="phone" className="loanLabel">Guarator 1 Name*</Label>
                                                                <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.rg1Name} name="rg1Name"/>
                                                            </FormGroup>
                                                            <Row>
                                                                <Col  xs="6" md="6">
                                                                    <FormGroup  className="loanBox2">
                                                                        <Label for="phone" className="loanLabel">Guarator 1 Phone*</Label>
                                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg1Phone1} name="rg1Phone1" />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col  xs="6" md="6">
                                                                    <FormGroup  className="loanBox2">
                                                                        <Label for="phone" className="loanLabel">Guarator 1 Phone 2</Label>
                                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg1Phone2} name="rg1Phone2"/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            
                                                            <FormGroup className="loanBox2">
                                                                <Label for="username"  className="loanLabel">Relationship</Label>
                                                                <Input type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rg1Relationship} name="rg1Relationship" />
                                                            </FormGroup>

                                                            <FormGroup  className="loanBox2">
                                                                <Label for="phone" className="loanLabel">Guarator 2 Name*</Label>
                                                                <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.rg2Name} name="rg2Name" />
                                                            </FormGroup>
                                                            <Row>
                                                                <Col  xs="6" md="6">
                                                                    <FormGroup  className="loanBox2">
                                                                        <Label for="phone" className="loanLabel">Guarator 2 Phone*</Label>
                                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg2Phone1} name="rg2Phone1"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col  xs="6" md="6">
                                                                    <FormGroup  className="loanBox2">
                                                                        <Label for="phone" className="loanLabel">Guarator 2 Phone 2</Label>
                                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg2Phone2} name="rg2Phone2"/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            
                                                            <FormGroup className="loanBox2">
                                                                <Label for="username"  className="loanLabel">Relationship</Label>
                                                                <Input type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rg2Relationship} name="rg2Relationship"/>
                                                            </FormGroup>

                                                            <FormGroup className="loanBox2">
                                                                <Label for="accType" className="loanLabel">ID Position*</Label>
                                                                <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.ridPosition}  name="ridPosition"/>
                                                                 
                                                            </FormGroup>
                                                        </Col>
                                                    </>}

                                                </>}

                                                <Col xs="12" md="12"><br/>
                                                    <FormGroup className="loanBox2">
                                                        {this.state.successModalMsg?<Alert color="success">{this.state.successModalMsg}</Alert>:null}
                                                        {this.state.erroModalMsg?<Alert color="danger">{this.state.erroModalMsg}</Alert>:null}
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                    </FormGroup>
                                                </Col>

                                                <Row className="modalFooter">
                                                    <Col xs="1" md="4">.</Col>
                                                    <Col xs="10" md="4">
                                                        <Button onClick={this.addLoan} className="btn btn-secondary loanBtn">Review</Button>
                                                    </Col>
                                                    <Col xs="1" md="4">.</Col>
                                                    </Row>
                                            </Row>
                                        </>}
                                    </>
                                    :null}

                                    {/**AddFine */}
                                    {this.state.addFines ===true?
                                    <>
                                        {checkRight(36)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            {parseInt(this.state.activeLoanStatus) ===0?
                                                <Row>
                                                    <Col xs="12" md="12">
                                                        <Label className="activeLoan">Client has no active loan</Label>
                                                    </Col>
                                                </Row>:
                                                <Row>
                                                    <Col xs="0" md="3" className="smallHide">.</Col>
                                                    <Col xs="12" md="6">
                                                        <FormGroup className="loanBox3">
                                                            <Label className="loanLabel">Fine Type*</Label>
                                                            <Input type="select" onChange={this.setValue} className="loanBox" name="fineType">
                                                                <option value="INSTALLMENT FINE">INSTALLMENT FINE</option> 
                                                                <option value="GENERAL FINE">GENERAL FINE</option> 
                                                            </Input>
                                                        </FormGroup>

                                                        <FormGroup  className="loanBox3">
                                                            <Label className="loanLabel">Fined From*</Label>
                                                            <Input type="date" onChange={this.setValue} className="loanBox" value={this.state.finedFrom} name="finedFrom"  />
                                                        </FormGroup>
                                                        <FormGroup  className="loanBox3">
                                                            <Label className="loanLabel">Fined To*</Label>
                                                            <Input type="date" onChange={this.setValue} className="loanBox" value={this.state.finedTo} name="finedTo"  />
                                                        </FormGroup>
                                                    
                                                        <FormGroup  className="loanBox3">
                                                            <Label  className="loanLabel">Enter Amount Fined(Shs)*</Label>
                                                            <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.fineAmount} name="fineAmount"/>
                                                        </FormGroup>
                        
                                                    </Col>

                                                    <Col xs="0" md="3" className="smallHide">.</Col>

                                                    <Col xs="12" md="12"><br/>
                                                    <FormGroup className="loanBox2">
                                                        {this.state.successModalMsg?<Alert color="success">{this.state.successModalMsg}</Alert>:null}
                                                        {this.state.erroModalMsg?<Alert color="danger">{this.state.erroModalMsg}</Alert>:null}
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                    </FormGroup>
                                                    </Col>

                                                    <Row className="modalFooter">
                                                        <Col xs="0" md="4" className="smallHide">.</Col>
                                                        <Col xs="12" md="4">
                                                            <Button onClick={this.addFine} className="btn btn-secondary loanBtn">Add Fine</Button>
                                                        </Col>
                                                        <Col xs="0" md="4" className="smallHide">.</Col>
                                                    </Row>
                                                </Row>
                                            }
                                        </>}    
                                    </>
                                    :null}

                                    {/** See Fines*/}
                                    {this.state.activeFines ===true?
                                    <>
                                        <Modal  isOpen={this.state.forgiveFineModal} className="modalWrapper modal-dialog ">
                                            <ModalHeader toggle={this.closeforgiveFineModal}>Forgive Fine</ModalHeader>
                                            <ModalBody className="modalBodyWrapper">
                                                <Row>
                                                <Col xs="12" md="12">
                                        
                                                    <FormGroup  className="loanBox3">
                                                        <Label className="loanLabel2">LoanID</Label>
                                                        <Input type="text" className="loanBox" disabled value={this.state.clientDetailsData.activeLoanData.loanID}/>
                                                    </FormGroup>

                                                    <FormGroup  className="loanBox3">
                                                        <Label className="loanLabel2">Total Fines</Label>
                                                        <Input type="text" className="loanBox" disabled value={this.state.clientDetailsData.activeLoanData.totalFines} />
                                                    </FormGroup>
                                                    
                                                    <FormGroup  className="loanBox3">
                                                        <Label  className="loanLabel2">Enter Amount To Forgive*</Label>
                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.forgiveAmount} name="forgiveAmount"/>
                                                    </FormGroup>
                        
                                                </Col>

                                                <Col xs="12" md="12"><br/>
                                                    <FormGroup className="loanBox2">
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Forgiving...'}</div>:null}
                                                    </FormGroup>
                                                </Col>

                                                <Row className="modalFooter">

                                                    <Col xs="12" md="12">
                                                        <Button onClick={this.submitForgivenFines} className="btn btn-success loanBtn">Forgive Fine</Button>
                                                    </Col>
                                                </Row>
                                            </Row>

                                            </ModalBody>
                                        </Modal>
                                        
                                        <Row>
                                            <Col xs="12" md="4">
                                                <Input type="select" onChange={this.setValue} className="loanBox" name="searchloanID">
                                                    {loanIDsList}
                                                </Input>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <Input type="select" onChange={this.setValue} className="loanBox" name="selectFines">
                                                    <option value="">SELECT FINES</option>
                                                    <option value="ACTIVE">ADDED FINES</option>
                                                    <option value="PAID">PAID FINES</option>
                                                    <option value="FORGIVEN">FORGIVEN FINES</option>
                                                </Input>
                                            </Col>
                                            <Col xs="12" md="4">
                                                <Button onClick={this.searchFines} className="btn btn-secondary showPayDetailsBtn">SEARCH</Button>
                                            </Col>
                                            <Col xs="12" md="12">
                                                <div className='table-container2'>
                                                    <Table striped responsive>
                                                        <thead>
                                                            
                                                            
                                                            {this.state.selectFines ==="PAID" ||this.state.selectFines ==="FORGIVEN"?
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>LoanID</th>
                                                                    <th>Amount</th>
                                                                    <th>Pay Code</th>
                                                                    <th>Added By</th>
                                                                    <th>Pay Date</th>
                                                                    <th>Created At</th>
                                                                </tr>
                                                            :
                                                               
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>LoanID</th>
                                                                    <th>Fine Type</th>
                                                                    <th>Amount</th>
                                                                    <th>Fined From</th>
                                                                    <th>Fined To</th>
                                                                    <th>Added By</th>
                                                                    <th>Created At</th>
                                                                </tr> 
                                                            }
                                                              
                                                        </thead>
                                                        
                                                        <tbody>
                                                            {this.state.paymentDataList}
                                                        </tbody>
                                                    </Table>
                                                </div>
                    
                                            </Col>
                                        </Row>
                                    </>
                                    :null}


                                    {/**Pay loan */}
                                    {this.state.payLoan ===true?
                                    <>
                                        {checkRight(29)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            {parseInt(this.state.activeLoanStatus) ===0?
                                            <Row>
                                                <Col xs="12" md="12">
                                                    <Label className="activeLoan">Client has no active loan</Label>
                                                </Col>
                                            </Row>:
                                            <Row>
                                                <Col xs="12" md="12">
                                                    <Button onClick={this.showPayDetailsBtn} className="btn btn-secondary showPayDetailsBtn">{this.showPayDetailsBtn===true? "HIDE DETAILS": "SHOW DETAILS"}</Button>
                                                </Col>
                                                {this.state.showPayDetails ===true?
                                                    <>
                                                    
                                                    <Col xs="6" md="6">
                                                        <FormGroup className="topWords"><Label className="loanLabel">Loan Status : <span> {this.state.clientDetailsData.activeLoanData.creationStatus}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Loan ID : <span> {this.state.clientDetailsData.activeLoanData.loanID}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Account Number : <span> {this.state.clientDetailsData.activeLoanData.account}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Loan Date : <span> {this.state.clientDetailsData.activeLoanData.startDate}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Start Payment : <span> {this.state.clientDetailsData.activeLoanData.startPayDate}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">End Payment : <span> {this.state.clientDetailsData.activeLoanData.endPayDate}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Registered By : <span> {getName2(this.state.clientDetailsData.staffData,this.state.clientDetailsData.activeLoanData.agent,"STAFF")}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Paid Amount : <span> {this.state.clientDetailsData.activeLoanData.amountPaid.toLocaleString()}</span></Label></FormGroup>

                                                    </Col>
                                                    <Col xs="6" md="6">
                                                        <FormGroup className="topWords"><Label className="loanLabel">No of renewals : <span> {this.state.clientDetailsData.activeLoanData.timesRenewed}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Loan Schedule : <span> {this.state.clientDetailsData.activeLoanData.schedule+" days"}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Period : <span> {this.state.clientDetailsData.activeLoanData.loanPeriod+" days"}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Paid Up To : <span> {this.state.clientDetailsData.activeLoanData.paidUpTo}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Last Paid On : <span> {this.state.clientDetailsData.lastPayData !==null? this.state.clientDetailsData.lastPayData.payDate:""}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Installment : <span> {this.state.clientDetailsData.activeLoanData.installments.toLocaleString()}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">Total Fines : <span> {this.state.clientDetailsData.activeLoanData.totalFines.toString()}</span></Label></FormGroup>
                                                        <FormGroup className="topWords"><Label className="loanLabel">LOAN BALANCE : <span> {(parseFloat(this.state.clientDetailsData.activeLoanData.expectedAmount) -parseFloat(this.state.clientDetailsData.activeLoanData.amountPaid)).toLocaleString()}</span></Label></FormGroup>
                                                    </Col>
                                                    </>
                                                :null}

                                                <Col xs="12" md="12">
                                                    
                                                    <FormGroup  className={this.state.currentDateStatus ===true? "currerntDate yesCurrentDate":"currerntDate notCurrentDate"}>
                                                        <Label>CURRENT DATE : {this.state.currentDate}</Label>
                                                    </FormGroup>
                                                    <FormGroup  className="loanBox3">
                                                        <Label className="loanLabel2">Total Fines are cleared before loan payment</Label>
                                                    
                                                    </FormGroup>
                                                    <FormGroup  className="loanBox3">
                                                        <Label className="loanLabel2">Book Number</Label>
                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.bookNo} name="bookNo"  />
                                                    </FormGroup>
                                                    
                                                    <FormGroup  className="loanBox3">
                                                        <Label  className="loanLabel2">Enter Amount*</Label>
                                                        <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.payAmount} name="payAmount"/>
                                                    </FormGroup>
                        
                                                </Col>

                                                <Col xs="12" md="12"><br/>
                                                    <FormGroup className="loanBox2">
                                                        {this.state.receiptPath !==null?<a className="btn btn-success downloaBTN" target="_blank" href={this.state.receiptPath}>Download / Print Receipt</a>:null}
                                                        {this.state.successModalMsg?<Alert color="success">{this.state.successModalMsg}</Alert>:null}
                                                        {this.state.erroModalMsg?<Alert color="danger">{this.state.erroModalMsg}</Alert>:null}
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                    </FormGroup>
                                                </Col>

                                                <Row className="modalFooter">
                                                    <Col xs="6" md="6">
                                                        <Button onClick={()=>this.addPay("PRINT")} className="btn btn-success loanBtn">Pay & Print</Button>

                                                    </Col>
                                                    <Col xs="6" md="6">
                                                        <Button onClick={()=>this.addPay("NOTPRINT")} className="btn btn-success loanBtn">Pay Loan</Button>
                                                    </Col>
                                                  
                                                </Row>
                                            </Row>
                                            }
                                        </>}
                                    </>
                                    :null}

                                    {/** loan payments*/}
                                    {this.state.loanPayments ===true?
                                    <>
                                        {checkRight(33)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            <Modal  isOpen={this.state.editPayModal} className="modalWrapper modal-dialog ">
                                                <ModalHeader toggle={this.closeModalEditPay}>Edit Payment</ModalHeader>
                                                <ModalBody className="modalBodyWrapper">
                                                    <Row>
                                                    <Col xs="12" md="12">
                                            
                                                        <FormGroup  className="loanBox3">
                                                            <Label className="loanLabel2">Payment Code</Label>
                                                            <Input type="text" className="loanBox" disabled value={this.state.editCode}/>
                                                        </FormGroup>

                                                        <FormGroup  className="loanBox3">
                                                            <Label className="loanLabel2">Amount</Label>
                                                            <Input type="text" className="loanBox" disabled value={this.state.oldAmount} />
                                                        </FormGroup>
                                                        
                                                        <FormGroup  className="loanBox3">
                                                            <Label  className="loanLabel2">Enter New Amount*</Label>
                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.editAmount} name="editAmount"/>
                                                        </FormGroup>

                                                        <FormGroup  className="loanBox3">
                                                            <Label  className="loanLabel2">Enter Comment</Label>
                                                            <Input type="textarea" onChange={this.setValue} className="loanBox" value={this.state.editComment} name="editComment"/>
                                                        </FormGroup>
                            
                                                    </Col>

                                                    <Col xs="12" md="12"><br/>
                                                        <FormGroup className="loanBox2">
                                                            {this.state.successModalMsg?<Alert color="success">{this.state.successModalMsg}</Alert>:null}
                                                            {this.state.erroModalMsg?<Alert color="danger">{this.state.erroModalMsg}</Alert>:null}
                                                            {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Editing...'}</div>:null}
                                                        </FormGroup>
                                                    </Col>

                                                    <Row className="modalFooter">
                                                        <Col xs="4" md="4">.</Col>
                                                        <Col xs="4" md="4">
                                                            <Button onClick={this.submitEdit} className="btn btn-success loanBtn">Edit Payment</Button>
                                                        </Col>
                                                        <Col xs="4" md="4">.</Col>
                                                    </Row>
                                                </Row>

                                                </ModalBody>
                                            </Modal>
                                            
                                            <Row>
                                                <Col xs="12" md="6">
                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="searchloanID">
                                                        {loanIDsList}
                                                    </Input>
                                                </Col>
                                                <Col xs="12" md="3">
                                                    <Button onClick={this.printPayment} style={{fontSize:9}} className="btn btn-secondary showPayDetailsBtn">DOWNLOAD / PRINT PDF</Button>
                                                </Col>
                                                <Col xs="12" md="3">
                                                    <Button onClick={this.searchLoanPayment} style={{fontSize:9}} className="btn btn-secondary showPayDetailsBtn">SEARCH PAYMENTS</Button>
                                                </Col>

                                                <Col xs="12" md="12">
                                                    <FormGroup className="loanBox2">
                                                        {this.state.receiptPath !==null?<a className="btn btn-success downloaBTN" target="_blank" href={this.state.receiptPath}>Download / Print Receipt</a>:null}
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                    </FormGroup>
                                                    <div className='table-container2'>
                                                        <Table striped responsive>
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>ID</th>
                                                                    <th>LoanID</th>
                                                                    <th>Book No</th>
                                                                    <th>Loan Amount</th>
                                                                    <th>Fine Amount</th>
                                                                    <th>Loan Balance</th>
                                                                    <th>Paid At</th>
                                                                    <th>Created At</th>
                                                                    <th>Edit</th>
                                                                </tr>
                                                            </thead>
                                                            
                                                            <tbody>
                                                                {this.state.paymentDataList}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                        
                                                </Col>
                                            </Row>
                                        </>}
                                    </>
                                    :null}

                                    {/**Client Details */}
                                    {this.state.details ===true?
                                    <>
                                        {checkRight(30)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            <Row>
                                                <>
                                                <Col xs="6" md="6">
                                                    <FormGroup className="topWords"><Label className="loanLabel">Account Number : <span> {this.state.clientDetailsData.userData.account}</span></Label></FormGroup>
                                                    <FormGroup className="topWords"><Label className="loanLabel">Account Status : <span> {parseInt(this.state.clientDetailsData.userData.status) ===1? "Active":"Deactivated"}</span></Label></FormGroup>
                                                </Col>
                                                <Col xs="6" md="6">
                                                    <FormGroup className="topWords"><Label className="loanLabel">Registered By : <span> {getName2(this.state.clientDetailsData.staffData,this.state.clientDetailsData.userData.added_by,"STAFF")}</span></Label></FormGroup>
                                                    <FormGroup className="topWords"><Label className="loanLabel">Registered At : <span> {this.state.clientDetailsData.userData.created_at}</span></Label></FormGroup>
                                                </Col>

                                                <Col xs="6" >
                                                    <FormGroup>
                                                        <Label for="username">Name*</Label>
                                                        <Input type="text" onChange={this.setValue} value={this.state.surname} name="surname" />
                                                    </FormGroup>
                                                
                                                    <FormGroup>
                                                        <Label for="gender">Gender*</Label>
                                                        <Input type="select" onChange={this.setValue} name="gender">
                                                            <option value={this.state.gender}>{this.state.gender}</option>
                                                            <option value="Male">Male</option> 
                                                            <option value="Female">Female</option> 
                                                        </Input>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="dob">Date Of Birth*</Label>
                                                        <Input type="date" onChange={this.setValue} value={this.state.dob} name="dob"/>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="username">National ID*</Label>
                                                        <Input type="text" onChange={this.setValue} value={this.state.nin} name="nin"/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="username">Address*</Label>
                                                        <Input type="text" onChange={this.setValue} value={this.state.address} name="address"/>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="username">Occupation*</Label>
                                                        <Input type="text" onChange={this.setValue} value={this.state.occup} name="occup"/>
                                                    </FormGroup> 

                                                    <FormGroup>
                                                        <Button onClick={this.setHome} className="btn btn-block btn-lg btn-secondary addressBtn">Update Home Address</Button>
                                                        {isNumber(homeLat)===false||isNumber(homeLong)===false?<h6 style={{color:"rgb(235 77 123)",fontSize:"14px"}}>Home address has yet not been set</h6>:<h6 style={{color:"#69B063",fontSize:"14px"}}>Home address has been set</h6>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Button onClick={()=>this.findAddress("HOME")} className="btn btn-block btn-lg btn-success addressBtn">Find Home Address</Button>
                                                    </FormGroup>

                                                </Col>
                                                <Col xs="6" >
                            
                                                    <FormGroup>
                                                        <Label for="phone">Phone No.*</Label>
                                                        <Input type="number" onChange={this.setValue} value={this.state.uphone1} name="uphone1"/>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="phone2">Phone No. 2</Label>
                                                        <Input type="number" onChange={this.setValue} value={this.state.uphone2} name="uphone2" />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="email">Email</Label>
                                                        <Input type="email" onChange={this.setValue} value={this.state.email} name="email" />
                                                    </FormGroup>
                        
                                                    <FormGroup>
                                                        <Label for="username">Next of Kin</Label>
                                                        <Input type="text" onChange={this.setValue} value={this.state.kin} name="kin"/>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="username">Next of Kin Phone</Label>
                                                        <Input type="number" onChange={this.setValue} value={this.state.kinPhone} name="kinPhone"/>
                                                    </FormGroup> 
                                                    <FormGroup>
                                                        <Label for="gender" className="danger"><span className="danger">Status</span></Label>
                                                        <Input type="select" onChange={this.setValue} name="accStatus">
                                                            <option value={this.state.accStatus}>{parseInt(this.state.accStatus) ===1?"Active":"Deactivated"}</option>
                                                            <option value="1">Active</option> 
                                                            <option value="0">Deactivated</option> 
                                                        </Input>
                                                    </FormGroup>

                                                    <FormGroup>
                                                    <Button onClick={this.setWork} className="btn btn-block btn-lg btn-secondary addressBtn">Update Work Address</Button>
                                                    {isNumber(workLat)===false||isNumber(workLong)===false?<h6 style={{color:"rgb(235 77 123)",fontSize:"14px"}}>Work address has yet not been set</h6>:<h6 style={{color:"#69B063",fontSize:"14px"}}>Work address has been set</h6>}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Button onClick={()=>this.findAddress("WORK")} className="btn btn-block btn-lg btn-success addressBtn">Find Work Address</Button>
                                                    </FormGroup>
                                                </Col>
                                                </>
                                               

                                                <Col xs="12" md="12">
                                                {this.state.isRouting?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Calculating Route...'}</div>:null}
                                                    <FormGroup>
                                                        <Label for="gender"><span className="danger">Blacklist</span></Label>
                                                        <Input type="select" onChange={this.setValue} name="blacklist">
                                                            <option value={this.state.blacklist}>{parseInt(this.state.blacklist) ===1?"Yes":"No"}</option>
                                                            <option value="0">No</option> 
                                                            <option value="1">Yes</option> 
                                                        </Input>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="gender" > <span className="danger">Reason for Blacklist</span></Label>
                                                      
                                                        <Input type="textarea" onChange={this.setValue} className=" loanArea" value={this.state.blacklistReason} name="blacklistReason" />
                                                    </FormGroup>
                                                    
                                                    <br/>
                                                    <FormGroup className="loanBox2">
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Updating...'}</div>:null}
                                                    </FormGroup>
                                                </Col>

                                                <Row className="modalFooter">
                                                    <Col xs="12" md="4"><Button onClick={this.updateClientStatus} className="btn btn-secondary loanBtn">Update Client Status</Button></Col>
                                                    <Col xs="12" md="4">
                                                        <Button onClick={this.updateClient} className="btn btn-success loanBtn">Update Client</Button>
                                                    </Col>
                                                    <Col xs="12" md="4"><Button onClick={this.updateBlacklistStatus} className="btn btn-secondary loanBtn">Update Blacklist Client</Button></Col>
                                                </Row>
                                            </Row>
                                            
                                        </>}
                                    </>
                                    :null}

                                    {/** loan detials */}
                                    {this.state.loanDetails ===true?
                                    <>
                                        {checkRight(32)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            <LoanDetails mainData={clientDetailsData}/>
                                            
                                        </>}
                                    </>
                                    :null}

                                    {/**editLoan */}
                                    {this.state.editLoan ===true?
                                    <>
                                        {checkRight(79)===false && checkRight(80)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            {parseInt(this.state.activeLoanStatus) ===0?
                                                <Row>
                                                    <Col xs="12" md="12">
                                                        <Label className="activeLoan">Client has no active loan</Label>
                                                    </Col>
                                                </Row>:
                                                <Row>
                                                   
                                                    <Col xs="6" md="6">
                                                        <FormGroup className="loanBox2">
                                                            <Label className="loanLabel">Loan Type*</Label>
                                                            <Input type="select" onChange={this.setValue} className="loanBox" name="loanType">
                                                                <option value={this.state.loanType}>{getName(this.state.clientDetailsData.loanTypeData,this.state.loanType)}</option>
                                                                {loanTypesDataList}
                                                            </Input>
                                                        </FormGroup>

                                                        <FormGroup  className="loanBox2">
                                                            <Label className="loanLabel">Interest*</Label>
                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rate} name="rate"  />
                                                        </FormGroup>
                                                        <FormGroup  className="loanBox2">
                                                            <Label  className="loanLabel">Processing Fee*</Label>
                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.pFee} name="pFee"/>
                                                        </FormGroup>
                                                
                                                        <FormGroup className="loanBox2">
                                                            <Label for="accType" className="loanLabel">Payment Mode*</Label>
                                                            <Input type="select" onChange={this.setValue} className="loanBox" name="payMode">
                                                                <option value={this.state.payMode}>{this.state.payMode}</option>

                                                                <option value="INSTALLMENTS">INSTALLMENTS</option> 
                                                                <option value="PAY AT ONCE">PAY AT ONCE</option> 
                                                            </Input>
                                                        </FormGroup>
                                                        <FormGroup  className="loanBox2">
                                                            <Label  className="loanLabel">Loan Amount(Shs)*</Label>
                                                            <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.amount} name="amount"/>
                                                        </FormGroup>
                                                        <FormGroup  className="loanBox2">
                                                            <Label for="phone" className="loanLabel">Loan Period(days)*</Label>
                                                            <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.period} name="period" />
                                                        </FormGroup>

                                                        <FormGroup className="loanBox2">
                                                            <Label for="accType" className="loanLabel">Start Payment*</Label>
                                                            <Input type="date" min={this.state.currentDate} onChange={this.setValue} className="loanBox" value={this.state.startPay} name="startPay" />
                                                        </FormGroup>
                                                        <FormGroup  className="loanBox2">
                                                            <Label className="loanLabel">Payment Schedule(days)*</Label>
                                                            <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.schedule} name="schedule"/>
                                                        </FormGroup>

                                                        <FormGroup className="loanBox2">
                                                            <Label for="accType" className="loanLabel">Expired Loan Action*</Label>
                                                            <Input type="select" onChange={this.setValue} className="loanBox" name="action">
                                                                <option value={this.state.action}>{this.state.action}</option>
                                                                <option value="AUTOMATIC RENEWAL">AUTOMATIC RENEWAL</option> 
                                                                <option value="AUTOMATIC FINING">AUTOMATIC FINING</option> 
                                                            </Input>
                                                        </FormGroup>
                                                        <FormGroup className="loanBox2">
                                                            <Label for="accType" className="loanLabel">Feild Agent*</Label>
                                                            <Input type="select" onChange={this.setValue} className="loanBox" name="staff">
                                                            <option value={this.state.staff}>{getName2(this.state.clientDetailsData.staffData,this.state.staff,"STAFF")}</option>

                                                                {staffDataList}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col xs="6" md="6">
                                                        <FormGroup className="loanBox2">
                                                            <Label for="name" className="loanLabel">Security Type*</Label>
                                                            <Input type="text"   className="loanBox" onChange={this.setValue} value={this.state.security} name="security"/>
                                                        </FormGroup>
                                                        <FormGroup className="loanBox2">
                                                            <Label for="username"  className="loanLabel">Security Description</Label>
                                                            <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.secDes} name="secDes" />
                                                        </FormGroup>
                                                        <FormGroup  className="loanBox2">
                                                            <Label for="phone" className="loanLabel">Guarator 1 Name*</Label>
                                                            <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.g1Name} name="g1Name"/>
                                                        </FormGroup>
                                                        <Row>
                                                            <Col  xs="6" md="6">
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 1 Phone*</Label>
                                                                    <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g1Phone1} name="g1Phone1" />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col  xs="6" md="6">
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 1 Phone 2</Label>
                                                                    <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g1Phone2} name="g1Phone2"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        
                                                        <FormGroup className="loanBox2">
                                                            <Label for="username"  className="loanLabel">Relationship</Label>
                                                            <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.g1Relationship} name="g1Relationship" />
                                                        </FormGroup>

                                                        <FormGroup  className="loanBox2">
                                                            <Label for="phone" className="loanLabel">Guarator 2 Name*</Label>
                                                            <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.g2Name} name="g2Name" />
                                                        </FormGroup>
                                                        <Row>
                                                            <Col  xs="6" md="6">
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 2 Phone*</Label>
                                                                    <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g2Phone1} name="g2Phone1"/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col  xs="6" md="6">
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 2 Phone 2</Label>
                                                                    <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.g2Phone2} name="g2Phone2"/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        
                                                        <FormGroup className="loanBox2">
                                                            <Label for="username"  className="loanLabel">Relationship</Label>
                                                            <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.g2Relationship} name="g2Relationship"/>
                                                        </FormGroup>

                                                        <FormGroup  className="loanBox2">
                                                            <Label for="phone" className="loanLabel">ID Position*</Label>
                                                            <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.idPosition} name="idPosition" />
                                                        </FormGroup>
                                                    </Col>
                                                        

                                                 

                                                    <Col xs="12" md="12"><br/>
                                                    <FormGroup className="loanBox2">
                                                        {this.state.successModalMsg?<Alert color="success">{this.state.successModalMsg}</Alert>:null}
                                                        {this.state.erroModalMsg?<Alert color="danger">{this.state.erroModalMsg}</Alert>:null}
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                    </FormGroup>
                                                    </Col>

                                                    <Row className="modalFooter">
                                                        <Col xs="1" md="4">.</Col>
                                                        <Col xs="10" md="4">
                                                            <Button onClick={this.addLoan} className="btn btn-secondary loanBtn">Review</Button>
                                                        </Col>
                                                        <Col xs="1" md="4">.</Col>
                                                    </Row>
                                                </Row>
                                            }
                                        </>}
                                    </>
                                    :null}

                                    {/** loan payments chart*/}
                                    {this.state.chart ===true?
                                    <>
                                        {checkRight(35)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            
                                            <PaymentChart mainData={this.state.clientDetailsData} from="CLIENT"/>
                                       
                                        </>}
                                    </>
                                    :null}

                                    {/** reopen closed loans*/}
                                    {this.state.reopenLoan ===true?
                                    <>
                                        {checkRight(100)===false?
                                            <Alert color="danger">Unauthorized to perform this action</Alert>
                                        :<>
                                            <Row>
                                                <Col xs="12" md="6">
                                                    <Input type="select" onChange={this.setValue} className="loanBox" name="searchloanID">
                                                        {loanIDsList}
                                                    </Input>
                                                </Col>
                                                <Col xs="12" md="6">
                                                    <Button onClick={this.reopenLoan} className="btn btn-danger showPayDetailsBtn">REOPEN LOAN</Button>
                                                </Col>
                                                <Col xs="12">
                                                    {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}</div>:null}
                                                </Col>
                                            </Row>
                                        </>}
                                    </>
                                    :null}

                                    
                                </div>
                                }
                            </ModalBody>
                            
                            {this.state.modelTrack ? 
                                <Modal  isOpen={this.state.modelTrack} className="modalWrapper modal-dialog modal-sm">
                                    <ModalHeader toggle={this.closeModalTrack}>Find Address</ModalHeader>
                                    <ModalBody className="modalBodyWrapper">
                                        <div>
                                            <>
                                            <Row>
                                                <Col xs="12" md="12">
                                                    <FormGroup>
                                                        <Label for="name" className="loanLabel" style={{width:"100%",textAlign: "center"}}>Click Button Bellow To Find Address</Label>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <a href={this.state.trackUrl} className="btn btn-success" style={{width:"100%"}} target="_blank" rel="noopener noreferrer">
                                                        Find Address
                                                        </a>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            </>
                                        </div>
                                    </ModalBody>
                
                                </Modal>: 
                            null}

                        </Modal>: null}

                        {this.state.modalPreview ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModalPreview}>Confirm Loan Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                <div>
                                    <>
                                    <Row>
                                        <Col xs="6" md="6">
                                            <FormGroup  className="loanBox2">
                                                <Label className="loanLabel">Interest : {generateLoanRequest===true?this.state.rrate:this.state.rate}</Label>
                                            </FormGroup>
                                            <FormGroup  className="loanBox2">
                                                <Label  className="loanLabel">Processing Fee  : {generateLoanRequest===true?this.state.rpFee:this.state.pFee}</Label>
                   
                                            </FormGroup>
                                    
                                            <FormGroup className="loanBox2">
                                                <Label for="accType" className="loanLabel">Payment Mode  : {generateLoanRequest===true?this.state.rpayMode:this.state.payMode}</Label>
                                               
                                            </FormGroup>
                                            <FormGroup  className="loanBox2">
                                                <Label  className="loanLabel">Loan Amount(Shs)  : {generateLoanRequest===true?this.state.ramount:this.state.amount}</Label>
                                            </FormGroup>
                                            <FormGroup  className="loanBox2">
                                                <Label for="phone" className="loanLabel">Loan Period(days)   : {generateLoanRequest===true?this.state.rperiod:this.state.period}</Label>
                                            </FormGroup>

                                            <FormGroup className="loanBox2">
                                              
                                                <Label for="accType" className="loanLabel">{generateLoanRequest===true?"Current Address":"Start Payment"}   : {generateLoanRequest===true?this.state.rcurrentAddress:this.state.startPay}</Label>
                                            </FormGroup>
                                            <FormGroup  className="loanBox2">
                                                <Label className="loanLabel">Payment Schedule(days)   : {generateLoanRequest===true?this.state.rschedule:this.state.schedule}</Label>
  
                                            </FormGroup>

                                            <FormGroup className="loanBox2">
                                                <Label for="accType" className="loanLabel">Expired Loan Action  : {generateLoanRequest===true?this.state.raction:this.state.action}</Label>
                                            
                                            </FormGroup>

                                            <FormGroup className="loanBox2">
                                                <Label for="username"  className="loanLabel">Installment  : {generateLoanRequest===true?((parseFloat(this.state.ramount)+(parseFloat(this.state.ramount)*(parseFloat(this.state.rrate)/100)))/parseFloat(this.state.rperiod)):(parseFloat(this.state.amount)+(parseFloat(this.state.amount)*(parseFloat(this.state.rate)/100)))/parseFloat(this.state.period)}</Label>
                                            </FormGroup>

                                            <FormGroup className="loanBox2">
                                                <Label for="username"  className="loanLabel">ID Position  : {generateLoanRequest===true?this.state.ridPosition:this.state.idPosition}</Label>
                                            </FormGroup>
                                        </Col>

                                        <Col xs="6" md="6">
                                            <FormGroup className="loanBox2">
                                                <Label for="name" className="loanLabel">Security Type  : {generateLoanRequest===true?this.state.rsecurity:this.state.security}</Label>
                                            </FormGroup>
                                            <FormGroup className="loanBox2">
                                                <Label for="username"  className="loanLabel">Security Description  : {generateLoanRequest===true?this.state.rsecDes:this.state.secDes}</Label>
                                            </FormGroup>
                                            <FormGroup  className="loanBox2">
                                                <Label for="phone" className="loanLabel">Guarator 1 Name  : {generateLoanRequest===true?this.state.rg1Name:this.state.g1Name}</Label>
                                            </FormGroup>
                                            <Row>
                                                <Col  xs="6" md="6">
                                                    <FormGroup  className="loanBox2">
                                                        <Label for="phone" className="loanLabel">Guarator 1 Phone  : {generateLoanRequest===true?this.state.rg1Phone1:this.state.g1Phone1}</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col  xs="6" md="6">
                                                    <FormGroup  className="loanBox2">
                                                        <Label for="phone" className="loanLabel">Guarator 1 Phone 2  : {generateLoanRequest===true?this.state.rg1Phone2:this.state.g1Phone2}</Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            
                                            <FormGroup className="loanBox2">
                                                <Label for="username"  className="loanLabel">Relationship  : {generateLoanRequest===true?this.state.rg1Relationship:this.state.g1Relationship}</Label>
                                            </FormGroup>

                                            <FormGroup  className="loanBox2">
                                                <Label for="phone" className="loanLabel">Guarator 2 Name  : {generateLoanRequest===true?this.state.rg2Name:this.state.g2Name}</Label>
                                            </FormGroup>
                                            <Row>
                                                <Col  xs="6" md="6">
                                                    <FormGroup  className="loanBox2">
                                                        <Label for="phone" className="loanLabel">Guarator 2 Phone  : {generateLoanRequest===true?this.state.rg2Phone1:this.state.g2Phone1}</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col  xs="6" md="6">
                                                    <FormGroup  className="loanBox2">
                                                        <Label for="phone" className="loanLabel">Guarator 2 Phone 2  : {generateLoanRequest===true?this.state.rg2Phone2:this.state.g2Phone2}</Label>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        
                                            <FormGroup className="loanBox2">
                                                <Label for="username"  className="loanLabel">Relationship  : {generateLoanRequest===true?this.state.rg2Relationship:this.state.g2Relationship}</Label>
                                            </FormGroup>

                                            <FormGroup className="loanBox2">
                                                <Label for="username"  className="loanLabel">Expected Amount  : {generateLoanRequest===true?(parseFloat(this.state.ramount)+(parseFloat(this.state.ramount)*(parseFloat(this.state.rrate)/100))):(parseFloat(this.state.amount)+(parseFloat(this.state.amount)*(parseFloat(this.state.rate)/100)))}</Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    </>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Row className="modalFooter">
                                    {this.state.editLoan===true?
                                        <>
                                            <Col xs="6" md="6">
                                                <Button onClick={this.confirm}  disabled={this.state.btnDisabled? "disabled":null} className="btn btn-success loanBtn">Update Loan</Button>
                                            </Col>
                                            <Col xs="6" md="6">
                                                <Button onClick={this.deleteLoan}  disabled={this.state.btnDisabled? "disabled":null} className="btn btn-danger loanBtn">Delete Loan</Button>
                                            </Col>
                                        </>
                                    :
                                    <Col xs="12" md="12">
                                        <Button onClick={this.confirm}  disabled={this.state.btnDisabled? "disabled":null} className="btn btn-success loanBtn">{this.state.generateLoanRequest ===true? "SUBMIT LOAN REQUEST":"ADD LOAN "}</Button>
                                    </Col>
                                    }

                                </Row>
                            </ModalFooter>
                        </Modal>: null}
                        <>
                        {/*<Button onClick={this.handlePrint}>Print Table</Button>
                        <ReactToPrint
                        trigger={() => <Button>Hidden Print Button</Button>}
                        content={() => this.componentRef}
                                    />*/}
                            <div className='table-container' ref={el => (this.componentRef = el)}>
                        
                        <Table striped responsive  
                       >
                            <thead>
                                <tr>
                                    <th>Details</th>
                                    <th>No</th>
                                    <th>Client Name</th>
                                    <th>Account</th>
                                    <th>Phone</th>
                                
                                    <th>Gender</th>
                                    <th>Address</th>
                                    
                               
                                    <th>Status</th>
                                    <th>Date</th>
 
                                </tr>
                                <tr>
                                    <th></th>
                             
                                    <th></th>
                                    <th><Input type="text" className="clientName" onChange={this.setValue} value={this.state.nameSearch} name="nameSearch" id="username" /></th>
                                    <th><Input type="text" className="account" onChange={this.setValue} value={this.state.accountSearch} name="accountSearch" id="username" /></th>
                                   
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                  
                                   
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                            
                                    {userDataList}
                               
                            </tbody>}
                        </Table>
                        </div>
                        </>
                    </Col>
                </Row>
           
            </div>
        )
    }
}

const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
    showDataStatus : state.mainReducer.showDataStatus,
    showData : state.mainReducer.showData,
    catData : state.mainReducer.catData,
    catStatus : state.mainReducer.catStatus,
    updateStatus : state.mainReducer.updateStatus,
    updateData:state.mainReducer.updateData,
    changedStatus : state.mainReducer.changeStatus,
    changePassStatus : state.mainReducer.changePassStatus,
});

export default connect(
    mapStateToProps,
    {getMainData,getCatData,getSelectedID_Data,addLoanData,changeStatus,changePassword}
)(SeeClients)
