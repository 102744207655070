import {combineReducers} from 'redux';
import authReducer from './authReducer';
import mainReducer from './mainReducer';
import projectReducer from './projectReducer';

export default combineReducers({
    authReducer:authReducer,
    mainReducer:mainReducer,
    projectReducer:projectReducer

});