import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData} from "../../Redux/ducks/mainReducer";
import {getName2,getName,datesDiffe,isDateInSelectedRage} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
export class ReopenedLoan extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,
       
        alertStatus :false, alertType :"error", alertTitle:null,

        closedLoanDataList:[]
  

    }
    componentDidMount(){
        this.props.getMainData("GENERAL_PAYDATA")
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,msg:msg,mainData:userData})
            }else{
                this.setState({isLoading:true,mainData:null,msg:null})
            }
        }  
    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt} = this.state

        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
        
            this.setState({isLoading:true})
            let activeLoanDataList = [];
            if(this.state.mainData !==null){
                const myUserData =  this.state.mainData.ReopenedLoanData
                if(myUserData.length>0){
                    let myNo = 0
                    myUserData.map((values, i) =>{
                        if(isDateInSelectedRage(startFrom,endAt,values.created_at) ===true){
                            myNo = myNo+1
                            activeLoanDataList.push(
                                <tr key={i}>
                                    <td>{myNo}</td>
                                    <th scope="row">{values.loan["loanID"]}</th>
                                    <td>{values.loan["clientName"]}</td>
                                    <td>{values.loan["account"]}</td>
                                    <td>{getName(this.state.mainData.branchData,values.branchID)}</td>
                                    <td>{values.loanAmount}</td>
                                    <td>{values.expectedAmount}</td>
                                    <td>{values.paidAmount}</td>
                                    <td>{values.closed_at}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.reopenedBy,"STAFF")}</td>
                                    <td>{values.created_at}</td>
                                </tr>
                            )
                        }
                            
                        
                    });
                }
            }

            if (activeLoanDataList.length>0){
                this.setState({closedLoanDataList:activeLoanDataList,isLoading:false})
            }else{
                this.setState({isLoading:false,closedLoanDataList:[], alertStatus :true, alertType :"info", alertTitle:"No closed loans found in the selected range"})

            }
        }
    }

    render() {
        const { redirect,redirectSelectedID,closedLoanDataList,startFrom,endAt } = this.state;

        if (redirect) {
            return <Redirect to={{pathname:"/see_clients", state:{redirectSelectedID:redirectSelectedID}}}/>
        }
        /**displaying  users*/
        let activeLoanDataList = closedLoanDataList;
    
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
            if(activeLoanDataList.length>0){
                if(this.state.mainData !==null){
                    const myUserData =  this.state.mainData.ReopenedLoanData
                    if(myUserData.length>0){
                        let myNo = 0
                        activeLoanDataList = []
                        myUserData.map((values, i) =>{
 
                            if(isDateInSelectedRage(startFrom,endAt,values.created_at) ===true){
                                myNo = myNo+1
                                if(searchAction ==="LOANID"){
                                    if(values.loan["loanID"] === searchword.toUpperCase() ){
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo}</td>
                                                <th scope="row">{values.loan["loanID"]}</th>
                                                <td>{values.loan["clientName"]}</td>
                                                <td>{values.loan["account"]}</td>
                                                <td>{getName(this.state.mainData.branchData,values.branchID)}</td>
                                                <td>{values.loanAmount}</td>
                                                <td>{values.expectedAmount}</td>
                                                <td>{values.paidAmount}</td>
                                                <td>{values.closed_at}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.reopenedBy,"STAFF")}</td>
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }else if(searchAction ==="NAME"){
                                    var myarray = values.loan["clientName"].split(' ');
                                    if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() /*|| values.name === searchword.toUpperCase()*/){
                                        
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo}</td>
                                                <th scope="row">{values.loan["loanID"]}</th>
                                                <td>{values.loan["clientName"]}</td>
                                                <td>{values.loan["account"]}</td>
                                                <td>{getName(this.state.mainData.branchData,values.branchID)}</td>
                                                <td>{values.loanAmount}</td>
                                                <td>{values.expectedAmount}</td>
                                                <td>{values.paidAmount}</td>
                                                <td>{values.closed_at}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.reopenedBy,"STAFF")}</td>
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }else if(searchAction ==="ACCOUNT"){
                                    if(values.loan["account"] === searchword.toUpperCase() ){
                                    
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo}</td>
                                                <th scope="row">{values.loan["loanID"]}</th>
                                                <td>{values.loan["clientName"]}</td>
                                                <td>{values.loan["account"]}</td>
                                                <td>{getName(this.state.mainData.branchData,values.branchID)}</td>
                                                <td>{values.loanAmount}</td>
                                                <td>{values.expectedAmount}</td>
                                                <td>{values.paidAmount}</td>
                                                <td>{values.closed_at}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.reopenedBy,"STAFF")}</td>
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }
                            }
                        
                        });
                    }
                }
            }else{
                alert("No data found, Consider setting search range")
            }
        }
        

     
        return (
            <div>
               
                <Row className="mainWrraper">
                    <div className="block-header">
                        <h4>VIEW REOPENED LOANS</h4>
               
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                       <div className='table-container'>
                       <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Loan ID</th>
                                    <th>Client Name</th>
                                    <th>Account</th>
                                    <th>Branch</th>
                                    <th>Loan Amount</th>
                                    <th>Expected Amount</th>
                                    <th>Paid Amount</th>
                                    <th>Closed At</th>
                                    <th>Ropened By</th>
                                    <th>Reopened At</th>
                                
                                </tr>
                                <tr>
                                    <th></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                    <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="ACCOUNT" ?this.state.nameSearch:null} id="ACCOUNT"/></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>

                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                                    {activeLoanDataList}
                               
                            </tbody>}
                        </Table>
                       </div>
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
  
 });
export default connect(
    mapStateToProps,
    {getMainData}
)(ReopenedLoan)
