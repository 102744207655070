import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table,Modal,
    ModalBody, ModalFooter,ModalHeader} from 'reactstrap'
import {checkRight} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
export class ExpenseCategory extends Component {
    state={
        isLoading:true,
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        modal:false,
        alertStatus :false, alertType :"error", alertTitle:null,
        cdate:null,
        selectedID:null,name:"",status:null,details:"",cdetails:""

    }

    componentDidMount(){
        /**refresh categories */
        this.props.getMainData("EXPENSECAT")
    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData,isLoading:false
                })
            }else if(mainDataStatus===false){
                this.setState({isSaving:false,mainData:null,isLoading:false
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg, cdate:"",details:""
                })

                /**refresh categories */
                this.props.getMainData("EXPENSECAT")
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    /**add new user*/
    addCategory =(e)=>{
        if(checkRight(63)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{
            const {cdate,details} = this.state;

            if(cdate===null ){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Enter new expense category"})
            }else{
                this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})
                const data = new FormData();
                data.append("cdate",cdate);
                data.append("details",details);
    
                /**upload new user*/
                this.props.addUser(data,"ADD_EXPENSE_CAT")
            }
        }
        
    }

    closeModal = ()=>{
        this.setState({modal:false})
    }

    openModel=(e)=>{
        const selectedID = e.target.id;
        const name = e.target.name;
   

        var stringArray = name.split(',');

        this.setState({
            modal:true,
            selectedID:selectedID,
            name:stringArray[0],
            status:stringArray[1],
            cdetails:stringArray[2]
        })
    }

    updateExpense=(e)=>{
        const {name,selectedID,status,cdetails} = this.state
        if(checkRight(64)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{
            if(name===""){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Enter name"})
            }else{
                this.setState({isSaving:true})
                const data = new FormData();
                data.append("updateID",selectedID);
                data.append("name",name);
                data.append("status",status);
                data.append("details",cdetails);
                this.props.addUser(data,"UPDATE_EXPENSECAT")
            }
        }
    }

    render() {
        let expenseCatDataList = [];
        const myUserData =  this.state.mainData
        var no =0
        if(myUserData !==null){
            if(myUserData.expenseCatData.length>0){
                myUserData.expenseCatData.map((values, i) =>{
                    no = no+1
                    expenseCatDataList.push(
                        <tr key={i}>

                            <th scope="row">{no}</th>
                            <td>{values.name}</td>
                            <td>{values.description}</td>
                            <td>{values.created_at}</td>
                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                            <td><Label className="status2">Inactive</Label></td>
                            }
                            <td><Button id={values.id} name={values.name+","+values.status+","+values.description}  onClick={this.openModel}>Edit Category</Button></td>
                        </tr>
                    )
                });
            }else{
                expenseCatDataList.push(
                    <div>
                        <br/><Alert color="primary">No expense categories uploaded yet</Alert>
                    </div>
                )
            }
        }
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>EXPENSE CATEGORY</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Edit Expenses Category</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                <Col xs="12" >
                                
                    
                                    <FormGroup>
                                        <Label for="username">Name*</Label>
                                        <Input type="text" onChange={this.setValue} value={this.state.name} name="name" />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="username">Description</Label>
                                        <Input type="textarea" onChange={this.setValue} value={this.state.cdetails} name="cdetails" />
                                    </FormGroup>

                                    <FormGroup className="des">
                                        <Label for="username"  className="loanLabel">Status</Label>
                                        <Input type="select" onChange={this.setValue} name="status">
                                            <option value={1}>{parseInt(this.state.status)===1?"Active":"Deactivate"}</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Deactivate</option>
                                        </Input>
                                    </FormGroup>
                                
                                </Col>
                            
                    
                                <Col xs="12" >
                                    {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Updating...'}</div>:null}
                                    <Button onClick={this.updateExpense} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-success">Edit Expense Category</Button>
                                </Col>
                            </ModalBody>
                        </Modal>
                        :null}
                        <Col xs="12" >
                            {this.state.addCatMsg?<Alert color="danger">{this.state.addCatMsg}</Alert>:null}

                            <FormGroup>
                                <Label for="username">Add New Category</Label>
                                <Input type="text" onChange={this.setValue} value={this.state.cdate} name="cdate" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="username">Description</Label>
                                <Input type="textarea" onChange={this.setValue} value={this.state.details} name="details" />
                            </FormGroup>
                        
                        </Col>
                  
                        <Col xs="12" >
                        
                            {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
                            <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Add Expense Category</Button>
                        </Col>

                        
                        <Col xs="12" md="12" className="tableWrapper">
                            <div className='table-container'>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                        <th>Date</th>
                              
                                        <th>Status</th>
                                        <th>Edit</th>
                                    </tr>
                                </thead>
                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                
                                <tbody>
                                    {expenseCatDataList}
                                </tbody>}
                            
                            </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(ExpenseCategory)
