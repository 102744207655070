import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {addLoanData} from "../../Redux/ducks/mainReducer";
import {getName2,datesDiffe,isDateInSelectedRage} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
export class GenerateStaffGeneralReport extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        reportData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,staff:"",
       
        alertStatus :false, alertType :"error", alertTitle:null,

        closedLoanDataList:[],limitTable:false,
        action:"GENERAL"
    }
   
    componentDidUpdate(prevProps){
        const{msg,reportData,reportStatus} = this.props;

        if(reportStatus !==prevProps.reportStatus){
            if(reportStatus===true){
                this.setState({isLoading:false,reportData:reportData,btnDisabled:false})

            }else if(reportStatus===false){
                this.setState({isLoading:false,reportData:null,btnDisabled:false})

            }
        }

    }

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value,reportData:null})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt,staff,action} = this.state
        this.setState({isLoading:false,btnDisabled:false})
        if(action==="GENERAL"){
            if(startFrom ===null || endAt ===null || staff===null){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
            }else if(datesDiffe(startFrom,endAt,null)<0){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
            }else{
                if(startFrom!==endAt){
                    this.setState({limitTable:true})
                }else{
                    this.setState({limitTable:false}) 
                }

                this.setState({isLoading:true,btnDisabled:true})
    
                const data = new FormData();
                data.append("staff",staff);
                data.append("startFrom",startFrom);
                data.append("endAt",endAt);
                data.append("action",action);
            
                /**upload new user*/
                this.props.addLoanData(data,"STAFF_GENERAL_REPORT")
            }
        }else{
            this.setState({isLoading:true,btnDisabled:true})
    
            const data = new FormData();
            data.append("staff",staff);
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            data.append("action",action);
        
            /**upload new user*/
            this.props.addLoanData(data,"STAFF_GENERAL_REPORT")
        }   
    }

    render() {
   
        const reportDataList = [];
        const reportDataCapitalList = [];
        if(this.state.reportData !==null){
            const myCatData =  this.state.reportData
            //loop through the array
            if(this.state.action ==="GENERAL"){
                if(myCatData.length>0){
                    var no = 0
                    let tClients =0
                    let aClients =0
                    let tpClient =0
                    let tnPaid=0
                    let tAPaid=0
                    let tCleared=0
                    let tRClient=0
                    let tNClient=0
                    let tBDClient=0
                    let tBDPClient=0
                    let tBDAmount=0
                    let tCollection=0
                    let tGivenOut=0
                    let tClosingClient=0
                    let tActiveClosingClient=0
                
                    myCatData.map((values,i) =>{
                        no = no+1
                      
                        tCleared = tCleared +parseInt(values.clearedClients)
                        tRClient = tRClient +parseInt(values.renewedClients)
                        tNClient = tNClient +parseInt(values.newClients)
                        tBDClient = tBDClient +parseInt(values.totalDefaulterClients)
                        tBDPClient = tBDPClient +parseInt(values.defaulterPaidClients)
                        tBDAmount = tBDAmount +parseInt(values.defaulterAmountPaid)
                        tCollection = tCollection +parseInt(values.totalCollection)
                        tGivenOut = tGivenOut +parseInt(values.totalGiveOut)

                        if(this.state.limitTable===false){
                            tClients = tClients +parseInt(values.activeClients)
                            aClients = aClients+(parseInt(values.activeClients)-parseInt(values.totalDefaulterClients))
                            tpClient = tpClient +parseInt(values.paidClients)
                            tnPaid = tnPaid +parseInt(values.notPaidClients)
                            tAPaid = tAPaid +parseInt(values.paidInAdvance)
                            tActiveClosingClient = tActiveClosingClient +parseInt(values.activeClosingClient)
                            tClosingClient = tClosingClient +parseInt(values.closingClients)
                        }


    
                        reportDataList.push(
                            <tr key={i}>
                                <td>{no}</td>
                                <td>{values.staffName}</td>
                                {this.state.limitTable===false?<>
                                    <td>{values.activeClients}</td>
                                    <td>{parseInt(values.activeClients)-parseInt(values.totalDefaulterClients)}</td>
                                    <td>{values.paidClients}</td>
                                    <td>{values.notPaidClients}</td>
                                    <td>{values.paidInAdvance}</td>
                                </>:null}
                                
                                <td>{values.clearedClients}</td>
                                <td>{values.renewedClients}</td>
                                <td>{values.newClients}</td>
                                <td>{values.totalDefaulterClients.toLocaleString()}</td>
                                <td>{values.defaulterPaidClients.toLocaleString()}</td>
                                <td>{values.defaulterAmountPaid.toLocaleString()}</td>
                                <td>{values.totalCollection.toLocaleString()}</td>
                                <td>{values.totalGiveOut.toLocaleString()}</td>
                                {this.state.limitTable===false?
                                <>
                                    <td>{values.activeClosingClient}</td>
                                    <td>{values.closingClients}</td>
                                </>
                                :null}
                                <td>{values.cp}</td>
                            </tr>
                        )
                    });
    
                    reportDataList.push(
                        <tr className='tableTotals'>
                            <td>{}</td>
                            <td>{"TOTALS"}</td>
                            {this.state.limitTable===false?<>
                                <td>{tClients.toLocaleString()}</td>
                                <td>{aClients.toLocaleString()}</td>
                                <td>{tpClient.toLocaleString()}</td>
                                <td>{tnPaid.toLocaleString()}</td>
                                <td>{tAPaid.toLocaleString()}</td>
                            </>:null}
                            
                            <td>{tCleared.toLocaleString()}</td>
                            <td>{tRClient.toLocaleString()}</td>
                            <td>{tNClient.toLocaleString()}</td>
                            <td>{tBDClient.toLocaleString()}</td>
                            <td>{tBDPClient.toLocaleString()}</td>
                            <td>{tBDAmount.toLocaleString()}</td>
                            <td>{tCollection.toLocaleString()}</td>
                            <td>{tGivenOut.toLocaleString()}</td>
                            {this.state.limitTable===false?
                            <>
                                <td>{tActiveClosingClient.toLocaleString()}</td>
                                <td>{tClosingClient.toLocaleString()}</td>
                            </>
                            :null}
                            <td>{}</td>
                        </tr>
                    )
                }else{
                    reportDataList.push(  
                        <h1>No Data found</h1>   
                    )
                }
            }else{
                if(myCatData.length>0){
                    let no = 0
                    let ttotalActiveClient = 0
                    let tActiveCapital = 0

                    let ttotalBDClient = 0
                    let ttBDCapital = 0

                    myCatData.map((values,i) =>{
                        no = no+1
                        ttotalActiveClient = ttotalActiveClient+parseInt(values.totalActiveClient)
                        tActiveCapital = tActiveCapital+(parseInt(values.capitalActive))

                        ttotalBDClient = ttotalBDClient+parseInt(values.totalBDClient)
                        ttBDCapital = ttBDCapital+(parseInt(values.capitalBD))
                    
                        reportDataCapitalList.push(
                            <tr key={i}>
                                <td>{no}</td>
                                <td>{values.staffName}</td>
                                <td>{(parseInt(values.totalActiveClient)+parseInt(values.totalBDClient)).toLocaleString()}</td>
                                <td>{values.totalActiveClient.toLocaleString()}</td>
                                <td>{values.capitalActive.toLocaleString()}</td>
                                <td>{values.totalBDClient.toLocaleString()}</td>
                                <td>{values.capitalBD.toLocaleString()}</td>
                            </tr>
                        )

                    })
                    reportDataCapitalList.push(
                        <tr className='tableTotals'>
                            <td>{}</td>
                            <td>{"TOTALS"}</td>
                            <td>{(ttotalActiveClient+ttotalBDClient).toLocaleString()}</td>
                            <td>{ttotalActiveClient.toLocaleString()}</td>
                            <td>{tActiveCapital.toLocaleString()}</td>
                            <td>{ttotalBDClient.toLocaleString()}</td>
                            <td>{ttBDCapital.toLocaleString()}</td>
                        </tr>
                    )
                }else{
                    reportDataCapitalList.push(  
                        <h1>No Data found</h1>   
                    )
                }
            }
            
        }

     
        return (
            <div>
        
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h6>GENERATE STAFF GENERAL REPORT</h6>
                    <h6 className='pageComment'>For capital select "SHOW STAFF CAPITAL", similar dates selection displays daily agent report</h6>

                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                            <Col md={this.state.action ==="CAPITAL"?"6":"3"} xs="12">
                                <FormGroup>
                                    <Label for="dob">Select Action*</Label>
                                    <Input type="select" onChange={this.setValue} name="action"> 
                                        <option value="GENERAL">SHOW GENERAL REPORT</option> 
                                        <option value="CAPITAL">SHOW STAFF CAPITAL</option> 
                                    </Input>
                                </FormGroup>
                            </Col>
                            {
                                this.state.action ==="GENERAL"?<>
                                    <Col md="3" xs="12">
                                        <FormGroup>
                                            <Label for="dob">Start From*</Label>
                                            <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                        </FormGroup>
                                    </Col>
                                    <Col md="3" xs="12">
                                        <FormGroup>
                                            <Label for="dob">End At*</Label>
                                            <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                        </FormGroup>
                                    </Col>
                                </>:null
                            }
                            
                            <Col md={this.state.action ==="CAPITAL"?"6":"3"} xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='table-container'>
                            {this.state.action ==="GENERAL"?
                                <Table striped responsive className="staffGeneralReportTable">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Name</th>
                                            {this.state.limitTable===false?<>
                                                <th>Total Clients(TC)</th>
                                                <th>Active Clients(AC) (TC-TB&D)</th>
                                                <th>Paid Clients (PC)</th>
                                                <th>Not Paid (AC-(PC+PA))</th>
                                                <th>Paid In Advance (PA)</th>
                                            </>:null}
                                        
                                            <th>Cleared Clients (CC)</th>
                                            <th>Renewed Clients (RC)</th>
                                            <th>New Clients(NC)</th>
                                            <th>Total B&D Clients(TB&D)</th>
                                            <th>Paid B&D Clients</th>
                                            <th>B&D Collection</th>
                                            <th>Total Collection</th>
                                            <th>Given Out</th>
                                            {this.state.limitTable===false?
                                            <>
                                                <th>Active Clossing Clients ((AC+NC+RN)-CC)</th>
                                                <th>Clossing Clients ((TC+NC+RN)-CC)</th>
                                            </>
                                            :null}
                                            <th>CP(%) (((PC+PA)/AC)*100)</th>
                                        </tr>
                                    </thead>
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                        {reportDataList}
                                    
                                    </tbody>}
                                </Table>:
                                <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Name</th>
                                            <th>Total Clients</th>
                                            <th>Active Clients</th>
                                            <th>Active Clients Capital</th>
                                            <th>B&D Clients</th>
                                            <th>B&D Capital</th>
    
                                        </tr>
                                    </thead>
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                        {reportDataCapitalList}
                                    
                                    </tbody>}
                                </Table>

                            }

                        </div>
                        
                    </Col>
                </Row>
     
            </div>
        )
    }
}
const mapStateToProps = state =>({
    msg : state.mainReducer.msg,
    reportData:state.mainReducer.updateData,
    reportStatus:state.mainReducer.updateStatus,
});
export default connect(
    mapStateToProps, {addLoanData}
)(GenerateStaffGeneralReport)
