import React, { Component,PureComponent } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {addLoanData} from "../../Redux/ducks/mainReducer";
import {datesDiffe,getMonth} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"

import {BarChart, LineChart, Line, Bar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export class StaffGraph extends Component {
    state={
        isLoading:false,
   
        userData:null,
        catData:null,
        clientDetailsData:null,
        msg:null,
        currentID:'',
        btnDisabled:false,
        status:null,

        currentStatus:'',
        reportData:null,
        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,staff:"",
        alertStatus :false, alertType :"error", alertTitle:null,
        closedLoanDataList:[],errorMsg:null,searchAction:"1",myYear:new Date().getFullYear(),
        startFrom2:new Date().getMonth()+1,endAt2:new Date().getMonth()+1
    }
   
    componentDidUpdate(prevProps){
        const{msg,reportData,reportStatus} = this.props;
        if(reportStatus !==prevProps.reportStatus){
            if(reportStatus===true){
                this.setState({isLoading:false,reportData:reportData,btnDisabled:false,errorMsg:msg})
            }else{
                this.setState({isLoading:false,reportData:null,btnDisabled:false,errorMsg:msg})
            }
        }
    }

    viewUser=(e)=>{
        const selectedID = e.target.id;
        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = () =>{
        const {startFrom,endAt,searchAction,startFrom2,endAt2,myYear} = this.state
     
        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else if(startFrom ===endAt){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be the same as 'End At' date *"})

        }else{
            this.setState({isLoading:true,btnDisabled:true,errorMsg:null})
            const data = new FormData();
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            data.append("searchAction",searchAction);
            data.append("myYear",myYear);
            /**upload new user*/
            this.props.addLoanData(data,"STAFF_GRAPH")
        }
     
        
    }

    render() {
        const yearList = [];
        yearList.push(
            <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option> 
        )
        for (let x=2020;x<=2050;x++){
            yearList.push(
                <option value={x}>{x}</option> 
            )
        }

        const monthList = [];
        monthList.push(
            <option value={new Date().getMonth()+1}>{getMonth(new Date().getMonth()+1)}</option> 
        )
        for (let x=1;x<=12;x++){
            monthList.push(
                <option value={x}>{getMonth(x)}</option> 
            )
        }
     
        return (
            <div>
              
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>STAFF PERFORMANCE GRAPH</h4>
                    </div>
                    <Col xs="12" className="mainWrraper2" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        <Row>
                          
                            { parseInt(this.state.searchAction)===1?<>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label for="dob">Start From*</Label>
                                        <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                    </FormGroup>
                                </Col>
                                <Col md="4" xs="12">
                                    <FormGroup>
                                        <Label for="dob">End At*</Label>
                                        <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                    </FormGroup>
                                </Col>
                            </>:null}

                           
                        
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>

                            {this.state.isLoading?
                            <Col xs="12">
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </Col>:null}
    
                        </Row>
                        {this.state.reportData !==null?
                        <ResponsiveContainer width="100%" height="85%">
                            <BarChart
                            width={500}
                            height={300}
                            data={this.state.reportData}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                             <CartesianGrid strokeDasharray="3 3" />
                            
                            <XAxis dataKey={"Username"}/>:
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="Loans_Givenout" fill="#1220A9" />
                            <Bar dataKey="Total_Cashout" fill="#15A912" />
                            <Bar dataKey="Total_Collection" fill="#A99115" />
                            <Bar dataKey="B&D_Collection" fill="#EB3E3E" />
                            </BarChart>
                        </ResponsiveContainer>:null}


                    
                    </Col>
                </Row>
     
            </div>
        )
    }
}
const mapStateToProps = state =>({
    msg : state.mainReducer.msg,
    reportData:state.mainReducer.updateData,
    reportStatus:state.mainReducer.updateStatus,
});
export default connect(
    mapStateToProps, {addLoanData}
)(StaffGraph)
