import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,addUser} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table,Modal,
    ModalBody, ModalFooter,ModalHeader} from 'reactstrap'
import {getName2,checkRight,getName} from "../../GeneralFunctions/general"
import MultiSelect from "@kenshooui/react-multi-select";
import ReactJsAlert from "reactjs-alert"
export class SeeAccounts extends Component {
    state={
        isSaving:false,
        isLoading:true,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
        rightDataList:[],
        selectedRight:[],
        statusList:[],
        alertStatus :false, alertType :"error", alertTitle:null,
        cdate:null,status:null,modal:false,selectedID:null,
        descriptorID:null

    }

    componentDidMount(){
        this.setState({isSaving:true})
        this.props.getMainData("ACCOUNTS")
    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData,isLoading:false})
            }else if(mainDataStatus===false){
                this.setState({isSaving:false,isLoading:false})
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,
                alertStatus :true, alertType :"success", alertTitle:msg,
                })

                /**refresh categories */
                this.props.getMainData("ACCOUNTS")
            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        } 
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    handleSelectChange = (selectedRight) =>{
        this.setState({ selectedRight});
    }


    viewUser=(e)=>{
  
        const selectedID = e.target.id;
        this.setState({modal:true,selectedID:selectedID})
        const myUserData =  this.state.mainData.accountsData
        let catList =[]
        myUserData.map((values, i) =>{
            if(parseInt(values.catID) ===parseInt(selectedID)){
                if(parseInt(values.status)===1){
                    catList.push(<option key={i} value={values.status}>{"Active"}</option>)
                    catList.push(<option value={0}>{"Innactive"}</option>)
                }else{
                    catList.push(<option key={i} value={values.status}>{"Innactive"}</option>)
                    catList.push(<option value={1}>{"Active"}</option>)
                }

                var rights = values.rights.substring(1, values.rights.length-1);
                const arrayRight = rights.split(',');
                let selectedRight =[]
                for (let i = 0; i < arrayRight.length; i++) {
                    const sRight = parseInt(arrayRight[i].replace(/"/g, ''))
                    this.state.mainData.rightsData.map((values3, k) =>{
                        if(sRight ===parseInt(values3.id)){
                            selectedRight.push(
                                {id: values3.id, label: values3.name}
                            )
                        }
                        
                    });
                }
                this.setState({
                    cdate:values.catName,
                    status:values.status,
                    statusList:catList,
                    selectedRight:selectedRight,
                    descriptorID:values.descriptorID
                })

            }
        });

        let rightDataList = [];
        const myUserData2 =  this.state.mainData
        if(myUserData2 !==null){
            if(myUserData2.rightsData.length>0){
                myUserData2.rightsData.map((values, i) =>{
                    rightDataList.push(
                        {id: values.id, label: values.name}
                    )
                });
            }
        }

        this.setState({rightDataList:rightDataList})
        
    }


 
    closeModal = ()=>{
        this.props.getMainData("ACCOUNTS")
        this.setState({modal:false})
    }

    updateAccount =(e)=>{
        if(checkRight(5)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{
            const {cdate,selectedRight,status,selectedID,descriptorID} = this.state;

            if(cdate===null ){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Enter account type"})
            }else if(selectedRight.length<1){
                this.setState({alertStatus :true, alertType :"error", alertTitle:"Select atleast one right"})
            }else{
                this.setState({erroMsg:null,isSaving:true,btnDisabled:true,successMsg:null})

                /**arranging  subjects */
                const rightList = [];
                selectedRight.map((values) =>{
                    rightList.push(values.id)
                })
                const data = new FormData();
                data.append("status",status);
                data.append("selectedID",selectedID);
                data.append("rightList",rightList);
                data.append("name",cdate);
                data.append("accDes",descriptorID);
            
                /**upload new user*/
                this.props.addUser(data,"UPDATE_ACCOUNT")
            }
        }
    }


    render() {

        let accountDataList = [];
        let descriptorDataList =[]
        const myUserData =  this.state.mainData
       
        if(myUserData !==null){
            if(myUserData.accountsData.length>0){
                let myNo = 0
                myUserData.accountsData.map((values, i) =>{
                    myNo = myNo+1
                    accountDataList.push(
                        <tr key={i}>
                            <td>{myNo}</td>
                            <td>{values.catName}</td>
                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                <td><Label className="status2">Inactive</Label></td>}
                            <td>{getName(this.state.mainData.descriptorData,values.descriptorID)}</td>
                            <td>{getName2(this.state.mainData.userData,values.addedBy,"STAFF")}</td>
            
                            <td>{values.created_At}</td>
                            <td><Button id={values.catID} onClick={this.viewUser}>Edit Account</Button></td>
                        </tr>
                    )
                });
            }else{

            }

            myUserData.descriptorData.map((values, i) =>{
                descriptorDataList.push(
                    <option value={values.id}>{values.name}</option>
                )
            });
        }
       
        return (
            <div>
                <div className="container">
                    <div className="block-header">
                    <h4>ACCOUNT TYPE</h4>
                    </div>
                    <Row className="mainWrraper">
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Account Type</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                <Col xs="12" >
                                    <FormGroup>
                                        <Label for="username">Add New Account Type*</Label>
                                        <Input type="text" onChange={this.setValue} value={this.state.cdate} name="cdate" />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="gender">Account Descriptor*</Label>
                                        <Input type="select" onChange={this.setValue} name="descriptorID">
                                          
                                            <option value={this.state.descriptorID}>{getName(this.state.mainData.descriptorData,this.state.descriptorID)}</option>
                                            {descriptorDataList}
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="teaching_sub">Select Rights *</Label>
                                        <MultiSelect 
                                            responsiveHeight={'500px'}
                                            items={this.state.rightDataList}
                                            selectedItems={this.state.selectedRight}
                                            onChange={this.handleSelectChange}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="gender">Select Account Status</Label>
                                        <Input type="select" onChange={this.setValue} name="status">
                                            {this.state.statusList}
                                        </Input>
                                    </FormGroup>

                                </Col>
                            
                    
                                <Col xs="12" >
                                    {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
                                    <Button onClick={this.updateAccount} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Update Account</Button>
                                </Col>
                            </ModalBody>
                        </Modal>
                        :null}
                        
                        <Col xs="12" md="12" className="tableWrapper">
                            <div className="table-container">
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Account Type</th>
                                        <th>Status</th>
                                        <th>Account Descriptor</th>
                                        <th>Added By</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                {this.state.isLoading?
                                <tbody>
                                    <tr>
                                        <td colSpan="8">
                                            <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                        </td>
                                    </tr>
                                </tbody>:
                                <tbody>
                                    {accountDataList}
                                </tbody>}
                            </Table>
                            </div>
                            
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    addUserStatus:state.mainReducer.addUserStatus,
});
export default connect(
    mapStateToProps, {getMainData,addUser}
)(SeeAccounts)
