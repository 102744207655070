import React, { Component } from 'react'
import {Link,Redirect} from "react-router-dom";
import {getName,getName2,getLevel} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import {Label,Row,Col,FormGroup} from 'reactstrap'

export class LoanRequestDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertStatus :false, alertType :"error", alertTitle:null,
            clientDetailsData:this.props.mainData
        };
    
    }

    render() {
        return (
            <div>
                <Row>
                                                
                    <Col xs="6" md="6">
                        <FormGroup className="topWords"><Label className="loanLabel">Client Name : <span> {this.state.clientDetailsData.loanReqData.client["name"]}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Account Number : <span> {this.state.clientDetailsData.loanReqData.client["account"]}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Client Status : <span > <b style={{color:"blue"}}>{this.state.clientDetailsData.loanReqData.loanStatus}</b></span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Status : <span> {parseInt(this.state.clientDetailsData.loanReqData.status)===1?"OPEN":"CLOSED"}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Status Level : <span> {getLevel(this.state.clientDetailsData.loanReqData.levelStatus)}</span></Label></FormGroup>
                        
                        <FormGroup className="topWords"><Label className="loanLabel">Loan Type : <span> {getName(this.state.clientDetailsData.loanTypeData,this.state.clientDetailsData.loanReqData.loanType)}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Payment Mode : <span> {this.state.clientDetailsData.loanReqData.payMode}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Loan Amount : <span> {this.state.clientDetailsData.loanReqData.requestedAmount.toLocaleString()}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Processing Fee: <span> {this.state.clientDetailsData.loanReqData.processingFee}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Interest Rate : <span> {this.state.clientDetailsData.loanReqData.intrest+"%"}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Request Date : <span> {this.state.clientDetailsData.loanReqData.requestSubmissionDate}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Added By : <span> {getName2(this.state.clientDetailsData.staffData,this.state.clientDetailsData.loanReqData.added_By,"STAFF")}</span></Label></FormGroup>
                    </Col>
                    <Col xs="6" md="6">
                        <FormGroup className="topWords"><Label className="loanLabel">Loan Schedule : <span> {this.state.clientDetailsData.loanReqData.schedule+" days"}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Security Type : <span> {this.state.clientDetailsData.loanReqData.securityType}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Security Description  : <span> {this.state.clientDetailsData.loanReqData.securityDes}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Guarator 1 : <span> {this.state.clientDetailsData.loanReqData.gName1}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Guarator 1 Phone : <span> {this.state.clientDetailsData.loanReqData.gPhone1+","+this.state.clientDetailsData.loanReqData.gPhone12}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Relationship : <span> {this.state.clientDetailsData.loanReqData.g1Relationship}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Guarator 2 : <span> {this.state.clientDetailsData.loanReqData.gName2}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Guarator 2 Phone : <span> {this.state.clientDetailsData.loanReqData.gPhone2+","+this.state.clientDetailsData.loanReqData.gPhone22}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Relationship : <span> {this.state.clientDetailsData.loanReqData.g2Relationship}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Agent : <span> {getName2(this.state.clientDetailsData.staffData,this.state.clientDetailsData.loanReqData.agent,"STAFF")}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">Period : <span> {this.state.clientDetailsData.loanReqData.loanPeriod+" days"}</span></Label></FormGroup>
                        <FormGroup className="topWords"><Label className="loanLabel">ID Position : <span> {this.state.clientDetailsData.loanReqData.idPosition}</span></Label></FormGroup>


                    </Col>
                    
                    
                </Row>
            </div>
        )
    }
}

export default (LoanRequestDetails)