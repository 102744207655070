import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,FormGroup,Input, Button,Modal,
     ModalBody, ModalFooter,ModalHeader,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {addUser,getMainData} from "../../Redux/ducks/mainReducer";
import {getName,getName2,checkRight} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import MultiSelect from "@kenshooui/react-multi-select";
export class ActivateUser extends Component {
    state={
        isLoading:false,
   
        userData:null,
        catData:null,
        showData:null,

        msg:null,
        modal:false,
    
        currentID:'',
        isUpdating:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,


        subData:[],
        rightList:[],
        selectedRight: [],
        alertStatus :false, alertType :"error", alertTitle:null,
        accType:"",branch:"",surname:"",firstname:"",othername:"",gender:"",dob:"",marital_status:"",
        religion:"",title:"",nin:"",phone:"",phone2:"",email:"",password1:"",
        password2:"",photo:"",address:"",nin:"",position:"",kin:"",kinPhone:"",username:"",
        branchDataList:[],religionDataList:[],catDataList:[],selectedRight:[],branchesList:[],
        selectedBranch:[],mainData:null,searchAction:null,nameSearch:null,refNo:"",adminStatus:null
    }
    componentDidMount(){
        this.setState({isLoading:true})
        this.props.getMainData("USER");
    }

    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus,updateStatus} = this.props;
        
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,userData:userData.userData,msg:msg,mainData:userData})

            }else if(userDataStatus===false){
                this.setState({isLoading:true,userData:null,msg:null})
            }
        }

        //update status
        if(updateStatus !== prevProps.updateStatus){
            if(updateStatus===true){
                this.setState({
                    isUpdating:false, btnDisabled:false,
                    alertStatus :true, alertType :"success", alertTitle:msg
              })
            }
            if(updateStatus===false){
                this.setState({
                    isUpdating:false, btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg
              })
            }
        }
    }
    /**for multiple select */
    handleSelectChange = (selectedRight) =>{
        this.setState({ selectedRight});
    }
    handleSelectChange2 = (selectedBranch) =>{
        this.setState({ selectedBranch});
    }

    closeModal = ()=>{
        this.setState({modal:false,passwordModal:false})
        //refresh categories
        this.props.getMainData("USER");
    }
   
    viewUser=async(e)=>{
      
        const selectedID = e.target.id;
        /**get cat details */
    
        const myUserData =  this.state.userData

        let savedReligion = null
        let savedAccount = null
        let notRights = null
        myUserData.map((values, i) =>{
         
            if(parseInt(values.id) === parseInt(selectedID)){
    
                savedReligion = values.religion
                savedAccount = values.catID
                notRights = values.rightsNotAllowed
        
                this.setState({
                    modal:true,currentID:selectedID,
                    isUpdating:false,btnDisabled:false,
                    accType:values.catID,branch:values.branchID,surname:values.lastName,firstname:values.firstName,gender:values.gender,
                    dob:values.dob,religion:values.religion,nin:values.nin,phone:values.phone,phone2:values.phone2,
                    email:values.email,photo:values.image,address:values.address,kin:values.kin,
                    kinPhone:values.kinPhone,username:values.clubNumber,password1:"",password2:"",status:values.status,refNo:values.referenceNo,
                    adminStatus:values.adminLogin
                })
            }
            
        
        });
       
    
        //religionDataList
        const religionDataList = [];
        if(this.state.mainData !==null){
            const myreligionData =  this.state.mainData.religionData
            //loop through the array
            myreligionData.map((values,i) =>{
                if(parseInt(values.id) ===parseInt(savedReligion)){
                    this.setState({religion:values.id})
                    religionDataList.push(
                        <option key={i} value={values.id}>{values.name}</option>
                    )
                }
               
            });

            myreligionData.map((values2,i) =>{
                if(parseInt(values2.id) !==parseInt(savedReligion)){
                    religionDataList.push(
                        <option key={i} value={values2.id}>{values2.name}</option>
                    )
                }
               
            });
          
        }

        ////rights
        var notRights2 = notRights.substring(1, notRights.length-1);
        const arrayNotRights = notRights2.split(',');
        let arrayNotRights2 =[]
        for (let i = 0; i < arrayNotRights.length; i++) {
            const sRight = parseInt(arrayNotRights[i].replace(/"/g, ''))
            arrayNotRights2.push(sRight)
        }
        const catDataList = [];
        let selectedRight =[]
        let rightList =[]
        if(this.state.mainData !==null){
            const myCatData =  this.state.mainData.catData
            myCatData.map((values,i) =>{
                if(parseInt(values.catID) ===parseInt(savedAccount)){
                    this.setState({position:values.catID})
                    catDataList.push(
                        <option key={i} value={values.catID}>{values.catName}</option>
                    )

                    var rights = values.rights.substring(1, values.rights.length-1);
                    const arrayRight = rights.split(',');
                    for (let i = 0; i < arrayRight.length; i++) {
                        const sRight = parseInt(arrayRight[i].replace(/"/g, ''))
                        this.state.mainData.rightsData.map((values3, k) =>{
                            if(sRight ===parseInt(values3.id)){
                                rightList.push(
                                    {id: values3.id, label: values3.name}
                                )

                                if (arrayNotRights2.includes(values3.id)){
                                   console.log("right maches")
                                }else{
                                    selectedRight.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                }
                            }
                            
                        });
                    }


                }
            });

            //loop through the array
            myCatData.map((values,i) =>{
                if(parseInt(values.catID) !==parseInt(savedAccount)){
                    catDataList.push(
                        <option key={i} value={values.catID}>{values.catName}</option>
                    )
                }        
            });
        }
 
        
        this.setState({
            modal:true,currentID:selectedID,
            isUpdating:false,btnDisabled:false,
            religionDataList:religionDataList,
            catDataList:catDataList,
            selectedRight:selectedRight,
            rightList:rightList
        })
    }
    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})

        if(e.target.name ==="position"){
            let catID = e.target.value

            ////rights
            let selectedRight =[]
            let rightList =[]
            if(this.state.mainData !==null){
                const myCatData =  this.state.mainData.catData
                myCatData.map((values,i) =>{
                    if(parseInt(values.catID) ===parseInt(catID)){
                
                        var rights = values.rights.substring(1, values.rights.length-1);
                        const arrayRight = rights.split(',');
                        for (let i = 0; i < arrayRight.length; i++) {
                            const sRight = parseInt(arrayRight[i].replace(/"/g, ''))
                            this.state.mainData.rightsData.map((values3, k) =>{
                                if(sRight ===parseInt(values3.id)){
                                    rightList.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                    selectedRight.push(
                                        {id: values3.id, label: values3.name}
                                    )
                                    
                                }
                                
                            });
                        }
                    }
                });
            }
 
        
            this.setState({
                selectedRight:selectedRight,
                rightList:rightList
            })
        }
    }
      /**add new user*/
    updateData =(e)=>{
        if(checkRight(112)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to perform this action"})
        }else{

            const {status,currentID} = this.state;
                const data = new FormData();
                data.append("currentID",currentID);
                data.append("status",status);
        
                /**upload new user*/
                this.props.addUser(data,"UPDATE_USER_STATUS")
            
        }
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }
    render() {
        const {mainData} =this.state
        /**displaying  users*/
        let userDataList = [];
        if(this.state.userData !==null){
            const myUserData =  this.state.userData
            
            let no = 0
            if(myUserData.length>0){
                myUserData.map((values, i) =>{
                
                        no = no+1
                        userDataList.push(
                            <tr key={i}>
                                <th scope="row">{no}</th>
                              
                                <td>{getName2(this.state.mainData.catData,values.catID,"ACCOUNT")}</td>
                                <td>{values.clubNumber}</td>
                                <td>{values.firstName}</td>
                                <td>{values.lastName}</td>
                                <td>{values.address}</td>
                                <td>{values.phone}</td>
                                <td>{values.created_at}</td>
                                {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                <td><Label className="status2">Inactive</Label></td>
                                }
                            
                                <td><Button id={values.id} onClick={this.viewUser}>Edit Status</Button></td>
                            </tr>
                        )
                    
                });
            }else{
                userDataList.push(
                    <div>
                        <br/><Alert color="primary">No user data uploaded yet</Alert>
                    </div>
                )
            }
        }

        /**displaying  categories*/
        const catDataList = [];
        if(this.state.catData !==null){
            const myCatData =  this.state.catData
            //loop through the array
            if(myCatData.length>0){
                
                this.state.accType? 
                catDataList.push(<option value={this.state.accType}>{this.state.accType}</option>)
                :catDataList.push(<option value="">SELECT ACCOUNT TYPE</option>)
                myCatData.map((values, i) =>{
                    catDataList.push(
                        <option value={values.catName}>{values.catName}</option>
                    )
                
                });
            }else{
                catDataList.push(  
                    <option value="">No category uploaded yet</option>   
                )
            }
        }

     

        const catDataList2 = [];
        if(this.state.mainData !==null){
            const myCatData2 =  this.state.mainData.catData
            //loop through the array
            if(myCatData2.length>0){
                catDataList2.push(<option value="">SELECT ACCOUNT</option>)
                myCatData2.map((values, i) =>{
                    catDataList2.push(
                        <option value={values.catID}>{values.catName}</option>
                    )
                
                });
            }
        }
       
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
            if(userDataList.length>0){
                if(mainData !==null){
                    let myUserData =  mainData.userData
                    userDataList = []
             
                    if(myUserData.length>0){
                        let no = 0
                        myUserData.map((values, i) =>{
                           
                           if(searchAction ==="NAME"){
                                
                                if(values.clubNumber === searchword.toUpperCase()){
                                    no = no+1
                                    userDataList.push(
                                        <tr key={i}>
                                            <th scope="row">{no}</th>
                                
                                            <td>{getName2(this.state.mainData.catData,values.catID,"ACCOUNT")}</td>
                                            <td>{values.clubNumber}</td>
                                            <td>{values.firstName}</td>
                                            <td>{values.lastName}</td>
                                            <td>{values.address}</td>
                                            <td>{values.phone}</td>
                                            <td>{values.created_at}</td>
                                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                            <td><Label className="status2">Inactive</Label></td>
                                            }
                                        
                                            <td><Button id={values.id} onClick={this.viewUser}>Edit Status</Button></td>
                                        </tr>
                                    )
                                    
                                }
                        
                            }else if(searchAction ==="ACCOUNT"){
                                if(parseInt(values.catID) === parseInt(searchword)){
                                    no = no+1
                                    userDataList.push(
                                        <tr key={i}>
                                            <th scope="row">{no}</th>
                                            <td>{getName2(this.state.mainData.catData,values.catID,"ACCOUNT")}</td>
                                            <td>{values.clubNumber}</td>
                                            <td>{values.firstName}</td>
                                            <td>{values.lastName}</td>
                                            <td>{values.address}</td>
                                            <td>{values.phone}</td>
                                            <td>{values.created_at}</td>
                                            {parseInt(values.status) ===1?<td><Label className="status1">Active</Label></td>:
                                            <td><Label className="status2">Inactive</Label></td>
                                            }
                                        
                                            <td><Button id={values.id} onClick={this.viewUser}>Edit Status</Button></td>
                                        </tr>
                                    )
                                }
                            }
                        });

                    
                    }
                }
            }
        }

        return (
            <div>
                <div className="">
                <Row className="">
                    <div className="block-header">
                    <h4>VIEW MEMBER</h4>
                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>User Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                            <Row> 
                      
                                <Col xs="12" md="12">
                                    <FormGroup>
                                        <Label for="password2">Member Status</Label>
                                        <Input type="select" onChange={this.setValue} name="status">
                                            {parseInt(this.state.status) ===1?
                                            <option value="1">Active</option>:
                                            <option value="0">Deactivated</option>
                                            }
                                            <option value="1">Active</option>
                                            <option value="0">Deactivated</option> 
                                        </Input>
                                    </FormGroup>

                                    {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Updating...'}</div>:null}

                                    <Button onClick={this.updateData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Activate / Deactivate Member </Button>
                                </Col>
                            </Row>
                            </ModalBody>
                        </Modal>: null}
                        <div className='table-container'>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>No</th>
                                  
                                    <th>Account Type</th>
                                    <th>Club Number</th>
                                    <th>Firstname</th>
                                    <th>Lastname</th>
                                    <th>Address</th>
                                    <th>Phone</th>
                                    <th>Created AT</th>
                                    <th>Status</th>
                                    <th>Edit Status</th>
                                </tr>
                                <tr>
                                    <th></th>
                                   
                               
                                    <th><Input type="select"  className="clientName"  onChange={this.setSearchValue} name="branch2" id="ACCOUNT">
                                            {catDataList2}
                                        </Input></th>
                               
                                    <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                    
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                                {userDataList}
                            </tbody>}
                        </Table>
                        </div>

                     
                    </Col>
                </Row>
            </div>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
    showData : state.mainReducer.showData,
    catData : state.mainReducer.catData,
    catStatus : state.mainReducer.catStatus,
    updateStatus:state.mainReducer.addUserStatus,
  
  });
export default connect(
    mapStateToProps,
    {addUser,getMainData}
)(ActivateUser)
