import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,FormGroup,Modal,
    ModalBody, ModalFooter,ModalHeader} from 'reactstrap'
import {connect } from 'react-redux';
import {getData,getSelectedID_Data,addLoanData} from "../../Redux/ducks/mainReducer";
import {getName,getName2,datesDiffe,isDateInSelectedRage,checkRight,getLevel} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
import MultiSelect from "@kenshooui/react-multi-select";
import LoanRequestDetails from '../Includes/LoanRequestDetails';
import LoanRequestFeildReport from '../Includes/LoanRequestFeildReport';
import LoanRequestApprovals from '../Includes/LoanRequestApprovals';
import PaymentHistory from '../Includes/PaymentHistory';
import PaymentChart from '../Includes/PaymentChart';

export class SeeLoanRequest extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,
        searchAction:null,nameSearch:null,branch2:"",statusLevel:"",status:"",
        
        
        redirect:false,
        redirectSelectedID:null, startFrom:null,endAt:null,
        alertStatus :false, alertType :"error", alertTitle:null,
        closedLoanDataList:[],myNo:0,amountGiven:0,totalBalance:0,

        requestDetails:true,feildReport:false,approve:false,editRequest:false,giveLoan:false,showApprovedDetails:false,
        clientDetailsData:null,staffList:[],selectedStaff:[],showDetails:false,showFeildReport:false,showApprovals:false,approvedUpdate:false,
        showPaymentHistory:false,

      
        proposedAmount:"",cname:"",phone1:"",phone2:"",cAdress:"",biz:"",security:"",secDes:"",assComment:"",
        approveAction:"",approveComment:"",approvedAmount:"",

        rloanType:"",rrate:"20",rpFee:"",rpayMode:"INSTALLMENTS",ramount:"",rperiod:"30",rschedule:"1",rg2Relationship:"",rg1Relationship:"",
        raction:"AUTOMATIC RENEWA",rstaff:"",rsecurity:"",rsecDes:"",rg1Name:"",rg1Phone1:"",rg1Phone2:"",rg2Name:"",rg2Phone1:"",rg2Phone2:"",rcurrentAddress:"",
        ridPosition:"",startPay:"",

        agentsDataList:[],loanTypeDataList:[]

    }
  
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus,showDataStatus,showData,updateStatus,updateData} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,mainData:userData})

                let activeLoanDataList = [];
                let myNo = 0
                if(userData !==null){
                    const myUserData =  userData.loanData
                    if(myUserData.length>0){
                    
                        myUserData.map((values, i) =>{
                            myNo = myNo+1
                            activeLoanDataList.push(
                                <tr key={i}>
                                    <td>{myNo}</td>
                                    <td><Button id={values.id} onClick={this.viewUser}>Details</Button></td>
                                    <td>{values.client["name"]}</td>
                                    <td>{values.client["account"]}</td>
                                    <td>{getLevel(values.levelStatus)}</td>
                                    <td>{parseInt(values.status)===1?"OPEN":"CLOSED"}</td>
                                    <td>{getName(userData.branchesData,values.branchID)}</td>
                                    <td>{values.loanPeriod}</td>
                                    <td>{values.processingFee}</td>
                                    <td>{values.intrest}</td>
                                    <td>{values.requestedAmount}</td>
                                    <td>{values.schedule}</td>
                                    <td>{getName2(userData.staffData,values.agent,"STAFF")}</td>
                                    <td>{getName2(userData.staffData,values.added_By,"STAFF")}</td>
                                    <td>{values.requestSubmissionDate}</td>
                                    <td>{values.created_at}</td>
                                </tr>
                            )
                                
                            
                        });
                    }
                }

                if (activeLoanDataList.length>0){
                    this.setState({closedLoanDataList:activeLoanDataList,isLoading:false,
                    myNo:myNo
                    })
                }

            }else if(userDataStatus===false){
                this.setState({isLoading:true,mainData:null,closedLoanDataList:[],alertStatus :true, alertType :"info", alertTitle:msg})
            }
        }  

        if(showDataStatus !== prevProps.showDataStatus){
            if(showDataStatus===true){
                const branchID = JSON.parse(window.localStorage.getItem('userData')).branchID
                const staffList =[]
                const selectedStaff =[]
                const agentsDataList =[]
                const loanTypeDataList =[]
                if(showData !==null){
                    /**loan types*/
                    const loanTypeData =  showData.loanTypeData
                    if(loanTypeData.length>0){
                       
                        loanTypeData.map((values2, i) =>{
                            if(parseInt(showData.loanReqData.loanType)===parseInt(values2.id)){
                                loanTypeDataList.push(
                                    <option value={values2.id}>{values2.name}</option>
                                )
                            }
                            
                        });
                        let myLen = 0
                        loanTypeData.map((values2, i) =>{
                            myLen = myLen+1
                            if(myLen===1){
                                this.setState({loanType:values2.id})
                            }
                            if(parseInt(values2.branchID)===parseInt(branchID)){
                                loanTypeDataList.push(
                                    <option value={values2.id}>{values2.name}</option>
                                )
                            }
                           
                        });
                    }

                    /*staffList*/
                    const staffData2 =  showData.staffData
                    const selectedStaffArray =  JSON.parse(showData.loanReqData.visitedBy)

                    if(staffData2.length>0){
                        staffData2.map((values2, i) =>{
                            if(parseInt(values2.id)===parseInt(showData.loanReqData.agent)){
                                agentsDataList.push(<option value={values2.id}>{values2.fname+" "+values2.lname}</option>)

                            }
                            if(parseInt(values2.status)===1){
                                if(parseInt(values2.branchID)===parseInt(branchID)){
                                    staffList.push({id: values2.id, label: values2.fname+" "+values2.lname})
                                    //check if a id exists 
                                    if (selectedStaffArray.includes(values2.id)) {
                                        selectedStaff.push({id: values2.id, label: values2.fname+" "+values2.lname})
                                    }

                                    if(parseInt(values2.account.descriptorID)===3){
                                        agentsDataList.push(
                                            <option value={values2.id}>{values2.fname+" "+values2.lname}</option>
                                        )
                                    }
                                }
                            }
                        });
                    }
                }
         

            
                const staffData3 =  showData.loanReqData
                this.setState({isModalLoading:false,clientDetailsData:showData,staffList:staffList,
                    cname:staffData3.client["name"],phone1:staffData3.phone1,phone2:staffData3.phone2,cAdress:staffData3["currentAddress"],
                    biz:staffData3.client["occupation"],security:staffData3.securityType,secDes:staffData3.securityDes,proposedAmount:staffData3.agentProposedAmount,
                    selectedStaff:selectedStaff,assComment:staffData3.assComment,

                    rloanType:staffData3.loanType,rrate:staffData3.intrest,rpFee:staffData3.processingFee,rpayMode:staffData3.payMode,ramount:staffData3.requestedAmount
                    ,rperiod:staffData3.loanPeriod,rschedule:staffData3.schedule,rg2Relationship:staffData3.g2Relationship,rg1Relationship:staffData3.g1Relationship,
                    raction:staffData3.fineAction,rstaff:staffData3.agent,rsecurity:staffData3.securityType,rsecDes:staffData3.securityDes,rg1Name:staffData3.gName1,
                    rg1Phone1:staffData3.gPhone1,rg1Phone2:staffData3.gPhone12,rg2Name:staffData3.gName2,rg2Phone1:staffData3.gPhone2,rg2Phone2:staffData3.gPhone22,
                    rcurrentAddress:staffData3.currentAddress,ridPosition:staffData3.idPosition,

                    agentsDataList:agentsDataList,loanTypeDataList:loanTypeDataList,
                    startPay:showData.settingsData.currentDate

                })
               

               

            }else if(showDataStatus===false){
                this.setState({isModalLoading:false,showCatData:null,alertStatus :true, alertType :"error", alertTitle:msg})
            } 
        }

        if(updateStatus !== prevProps.updateStatus){
            if(updateStatus===true){
                this.setState({isUpdating:false,alertStatus :true, alertType :"success", alertTitle:msg})
                this.closeModal()
            }else if(updateStatus===false){
                this.setState({isUpdating:false,alertStatus :true, alertType :"error", alertTitle:msg})
            } 
        }
    }

    handleSelectChange = (selectedStaff) =>{
        this.setState({ selectedStaff});
    }


    viewUser=(e)=>{
        const selectedID = e.target.id;
        this.props.getSelectedID_Data(selectedID,"LOANREQ")

        this.setOther_selectedUser(selectedID)   
    }
    setOther_selectedUser = selectedID =>{
        const {clientDetailsData} = this.state

        this.setState({
            modal:true,currentID:selectedID,
            isUpdating:false,btnDisabled:false,isModalLoading:true,
           
            requestDetails:true,feildReport:false,approve:false,editRequest:false,giveLoan:false,showApprovedDetails:false,
            clientDetailsData:null,staffList:[],selectedStaff:[],showDetails:false,showFeildReport:false,showApprovals:false,approvedUpdate:false,
            showPaymentHistory:false,
        
            proposedAmount:"",cname:"",phone1:"",phone2:"",cAdress:"",biz:"",security:"",secDes:"",assComment:"",
            approveAction:"",approveComment:"",approvedAmount:"",

            rloanType:"",rrate:"20",rpFee:"",rpayMode:"INSTALLMENTS",ramount:"",rperiod:"30",rschedule:"1",rg2Relationship:"",rg1Relationship:"",
            raction:"AUTOMATIC RENEWA",rstaff:"",rsecurity:"",rsecDes:"",rg1Name:"",rg1Phone1:"",rg1Phone2:"",rg2Name:"",rg2Phone1:"",rg2Phone2:"",rcurrentAddress:"",
            ridPosition:"",startPay:"",

            agentsDataList:[],loanTypeDataList:[]
            /*details:false,summary:false,giveLoan:true,payLoan:false,
            loanDetails:false,loanPayments:false,chart:false,addFines:false,generateLoanRequest:false,
            activeFines:false,paidFines:false,forgivenFines:false,editLoan:false, modalPreview:false,reopenLoan:false,

            loanType:"",rate:"20",pFee:"",payMode:"INSTALLMENTS",amount:"",period:"30",schedule:"1",g2Relationship:"",g1Relationship:"",
            action:"AUTOMATIC RENEWAL",staff:"",security:"",secDes:"",g1Name:"",g1Phone1:"",g1Phone2:"",g2Name:"",g2Phone1:"",g2Phone2:"",modalPreview:false,

            fineType:"INSTALLMENT FINE",fineAmount:"",finedFrom:"",finedTo:"",
            showPayDetails : false,currentDateStatus:true,currentDate:null,totalFines:0,

            searchloanID: "",paymentDataList:[],editPayModal:false,

            surname:"",gender:"",dob:"",photo:"",address:"",nin:"",occup:"",kin:"",kinPhone:"",accStatus:"",email:"",blacklistReason:"",blacklist:"",blacklistedBy:"",uphone1:"",uphone2:"",
            /**payment chart */
         

        })
    }
    closeModal = ()=>{
        this.setState({isLoading:true,modal:false,
            isModalLoading:false
          })
        const data = new FormData();
        data.append("startFrom",this.state.startFrom);
        data.append("endAt",this.state.endAt);
        this.props.getData(data,"LOAN_REQUESTS")
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setActions= e =>{
        const v = e.target.value
        this.setState({isUpdating:false,showPayDetails:false,paymentDataList:[],
            showDetails:false,showFeildReport:false,showApprovals:false,showApprovedDetails:false,showPaymentHistory:false
        })
     
        if (v==="1"){
            this.setState({requestDetails:true,feildReport:false,approve:false,giveLoan:false,approvedUpdate:false,editRequest:false,})
        }

        if (v==="2"){
            this.setState({requestDetails:false,feildReport:true,approve:false,giveLoan:false,approvedUpdate:false,editRequest:false,})
        }

        if (v==="3"){
            this.setState({requestDetails:false,feildReport:false,approve:true,giveLoan:false,approvedUpdate:false,editRequest:false,})
        }

        if (v==="4"){
            this.setState({requestDetails:false,feildReport:false,approve:false,giveLoan:false,approvedUpdate:false,editRequest:true,})
        }
        if (v==="5"){
            this.setState({requestDetails:false,feildReport:false,approve:false,editRequest:false,approvedUpdate:false, giveLoan:true})
        }
        if (v==="6"){
            this.setState({requestDetails:false,feildReport:false,approve:false,editRequest:false,giveLoan:false,approvedUpdate:true})
        }

        
    }

    setSearchValue = (e) =>{
        this.setState({[e.target.name]:e.target.value,nameSearch:e.target.value,searchAction:e.target.id})
    }

    searchData = (e) =>{
        const {startFrom,endAt} = this.state

        if(startFrom ===null || endAt ===null){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill in all feilds marked with a *"})
        }else if(datesDiffe(startFrom,endAt,null)<0){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"'Start From' date can not be greater than 'End At' date *"})
        }else{
            this.setState({isLoading:true})
            const data = new FormData();
            data.append("startFrom",startFrom);
            data.append("endAt",endAt);
            this.props.getData(data,"LOAN_REQUESTS")
            
        }
    }

    submitFeildReport=(e)=>{
        const {proposedAmount,phone1,phone2,cAdress,biz,security,secDes,selectedStaff,staffList,assComment,currentID} = this.state
        if(checkRight(105)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to submit a feild report"})
        }else{
            if(phone1===""||phone1===null||cAdress===""||biz===""||security===""||proposedAmount===""||parseInt(proposedAmount)===0){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill all feilds marked with *"})
            }else if(selectedStaff.length===0){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Select staff who assessed the client"})
            }else{
                /**arranging  staff */
                const staffSelectedList = [];
                selectedStaff.map((values) =>{
                    staffSelectedList.push(values.id)
                })

                this.setState({isUpdating:true})
                const data = new FormData();
                data.append("currentID",currentID);
                data.append("phone1",phone1);
                data.append("phone2",phone2);
                data.append("cAdress",cAdress);
                data.append("biz",biz);
                data.append("security",security);
                data.append("secDes",secDes);
                data.append("assComment",assComment);
                data.append("staffSelectedList",JSON.stringify(staffSelectedList));
                data.append("proposedAmount",proposedAmount);
                data.append("action","SUBMIT");
                this.props.addLoanData(data,"SUBMIT_FEILDREPO")
            }
        }
    }

    editFeildReport=(e)=>{
        const {proposedAmount,phone1,phone2,cAdress,biz,security,secDes,selectedStaff,staffList,assComment,currentID} = this.state
        if(checkRight(106)===false){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Unauthorized to edit a feild report"})
        }else{
            if(phone1===""||phone1===null||cAdress===""||biz===""||security===""||proposedAmount===""||parseInt(proposedAmount)===0){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Fill all feilds marked with *"})
            }else if(selectedStaff.length===0){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Select staff who assessed the client"})
            }else{
                /**arranging  staff */
                const staffSelectedList = [];
                selectedStaff.map((values) =>{
                    staffSelectedList.push(values.id)
                })

                this.setState({isUpdating:true})
                const data = new FormData();
                data.append("currentID",currentID);
                data.append("phone1",phone1);
                data.append("phone2",phone2);
                data.append("cAdress",cAdress);
                data.append("biz",biz);
                data.append("security",security);
                data.append("secDes",secDes);
                data.append("assComment",assComment);
                data.append("staffSelectedList",JSON.stringify(staffSelectedList));
                data.append("proposedAmount",proposedAmount);
                data.append("action","EDIT");
                this.props.addLoanData(data,"SUBMIT_FEILDREPO")
            }
        }
    }

    showBTN=(action)=>{
        const {showDetails,showFeildReport,showApprovals,showApprovedDetails,showPaymentHistory} = this.state
        if(action==="DETAILS"){
            if(showDetails===true){this.setState({showDetails:false})}else{this.setState({showDetails:true})}
        }

        if(action==="REPORT"){
            if(showFeildReport===true){this.setState({showFeildReport:false})}else{this.setState({showFeildReport:true})}
        }
        if(action==="APPROVAL"){
            if(showApprovals===true){this.setState({showApprovals:false})}else{this.setState({showApprovals:true})}
        }
        if(action==="GIVE"){
            if(showApprovedDetails===true){this.setState({showApprovedDetails:false})}else{this.setState({showApprovedDetails:true})}
        }
        if(action==="PAYHISTORY"){
            if(showPaymentHistory===true){this.setState({showPaymentHistory:false})}else{this.setState({showPaymentHistory:true})}
        }

        

        
    }

    approveLoan=(e)=>{
        const{approveComment,approveAction,approvedAmount,currentID} = this.state
        if(approveAction===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Select action"})
        }else if(parseInt(approveAction)===1){
            if(parseInt(approvedAmount)===0||approvedAmount===""){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Since Approve action is selected, please enter approved amount"})
            }else{
                this.setState({isUpdating:true})
                const data = new FormData();
                data.append("currentID",currentID);
                data.append("approveComment",approveComment);
                data.append("approveAction",approveAction);
                data.append("approvedAmount",approvedAmount);
                this.props.addLoanData(data,"APPROVE_REQ")
            }
        }else{
            if(approveComment===""){
                this.setState({  alertStatus :true, alertType :"error", alertTitle:"Since Reject action is selected, please enter comment for rejection"})
            }else{
                this.setState({isUpdating:true})
                const data = new FormData();
                data.append("currentID",currentID);
                data.append("approveComment",approveComment);
                data.append("approveAction",approveAction);
                data.append("approvedAmount",approvedAmount);
                this.props.addLoanData(data,"APPROVE_REQ")
            }
        }

        
    }

    editLoanRequest=(e)=>{
        const {currentID,
            rloanType,rrate,rpFee,ramount,rperiod,rschedule,rsecDes,rg1Phone2,rg2Phone2,rcurrentAddress,
            rg1Relationship,rg2Relationship,raction,rpayMode,rstaff,rsecurity,rg1Name,rg1Phone1,rg2Name,rg2Phone1,ridPosition} = this.state

       
    
        if(rloanType===""||rrate==="" ||rpFee==="" ||ramount==="" ||rperiod==="" ||rschedule===""||rstaff===""||
        rsecurity==="" ||rg1Name==="" ||rg1Phone1==="" ||rg2Name==="" ||rg2Phone1===""||ridPosition===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Please enter all fields marked with a *"})
        }else if(parseInt(rrate)<1 ||parseInt(rrate)>100){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Interest rate must be between 1 and 100"})
        }else{
            this.setState({isUpdating:true})
            const data = new FormData();
            data.append("currentID",currentID);
            data.append("loanID",null);
            data.append("loanType",rloanType);
            data.append("rate",rrate);
            data.append("pFee",rpFee);
            data.append("payMode",rpayMode);
            data.append("amount",ramount);
            data.append("period",rperiod);
            data.append("action",raction);
            data.append("schedule",rschedule);
            data.append("g2Relationship",rg2Relationship);
            data.append("g1Relationship",rg1Relationship);
            data.append("staff",rstaff);
            data.append("security",rsecurity);
            data.append("secDes",rsecDes);
            data.append("g1Name",rg1Name);
            data.append("g1Phone1",rg1Phone1);
            data.append("g1Phone2",rg1Phone2);
            data.append("g2Name",rg2Name);
            data.append("g2Phone1",rg2Phone1);
            data.append("g2Phone2",rg2Phone2);
            data.append("idPosition",ridPosition);
            data.append("currentAddress",rcurrentAddress);
            this.props.addLoanData(data,"UPDATE_REQ")

        }
       
    }

    giveApprovedLoan=(e)=>{
        const {currentID,clientDetailsData,startPay,
            rloanType,rrate,rpFee,ramount,rperiod,rschedule,rsecDes,rg1Phone2,rg2Phone2,rcurrentAddress,
            rg1Relationship,rg2Relationship,raction,rpayMode,rstaff,rsecurity,rg1Name,rg1Phone1,rg2Name,rg2Phone1,ridPosition} = this.state

        this.setState({isUpdating:true})
        const data = new FormData();
        data.append("currentID",currentID);
        data.append("loanType",rloanType);
        data.append("rate",rrate);
        data.append("pFee",rpFee);
        data.append("payMode",rpayMode);
        data.append("amount",clientDetailsData.loanReqData.approvedAmount);
        data.append("period",rperiod);
        data.append("action",raction);
        data.append("schedule",rschedule);
        data.append("g2Relationship",rg2Relationship);
        data.append("g1Relationship",rg1Relationship);
        data.append("staff",rstaff);
        data.append("security",rsecurity);
        data.append("secDes",rsecDes);
        data.append("g1Name",rg1Name);
        data.append("g1Phone1",rg1Phone1);
        data.append("g1Phone2",rg1Phone2);
        data.append("g2Name",rg2Name);
        data.append("g2Phone1",rg2Phone1);
        data.append("g2Phone2",rg2Phone2);
        data.append("idPosition",ridPosition);
        data.append("currentAddress",rcurrentAddress);
        data.append("startPay",startPay);
        this.props.addLoanData(data,"GIVE_APPR_LOAN")

    }
    
    render() {
        const {mainData, redirect,redirectSelectedID,closedLoanDataList,myNo,branch2,statusLevel,status} = this.state;

        if (redirect) {
            return <Redirect to={{pathname:"/see_clients", state:{redirectSelectedID:redirectSelectedID}}}/>
        }
        /**displaying  users*/
        let activeLoanDataList = closedLoanDataList;

        let myNo1 = myNo
    
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
    
        if (searchword) {
         
            if(activeLoanDataList.length>0){
                if(this.state.mainData !==null){
                    const myUserData =  this.state.mainData.loanData
                    if(myUserData.length>0){
                        myNo1 = 0
                        activeLoanDataList = []
                        myUserData.map((values, i) =>{
   
                            if(searchAction ==="NAME"){
                                var myarray = values.client["name"].split(' ');
                                if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() || values.client["name"] === searchword.toUpperCase()){
                                    myNo1 = myNo1+1
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.id} onClick={this.viewUser}>Details</Button></td>
                                            <td>{values.client["name"]}</td>
                                            <td>{values.client["account"]}</td>
                                            <td>{getLevel(values.levelStatus)}</td>
                                            <td>{parseInt(values.status)===1?"OPEN":"CLOSED"}</td>
                                            <td>{getName(mainData.branchesData,values.branchID)}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.requestedAmount}</td>
                                            <td>{values.schedule}</td>
                                            <td>{getName2(mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.requestSubmissionDate}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }else if(searchAction ==="ACCOUNT"){
                                if(values.client["account"] === searchword.toUpperCase()){
                                    myNo1 = myNo1+1
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.id} onClick={this.viewUser}>Details</Button></td>
                                            <td>{values.client["name"]}</td>
                                            <td>{values.client["account"]}</td>
                                            <td>{getLevel(values.levelStatus)}</td>
                                            <td>{parseInt(values.status)===1?"OPEN":"CLOSED"}</td>
                                            <td>{getName(mainData.branchesData,values.branchID)}</td>

                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.requestedAmount}</td>
                                            <td>{values.schedule}</td>
                                            <td>{getName2(mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.requestSubmissionDate}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }else if(searchAction ==="BRANCH"){
                               
                                if(parseInt(values.branchID) === parseInt(searchword)){
                                    myNo1 = myNo1+1
                                   
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.id} onClick={this.viewUser}>Details</Button></td>
                                            <td>{values.client["name"]}</td>
                                            <td>{values.client["account"]}</td>
                                            <td>{getLevel(values.levelStatus)}</td>
                                            <td>{parseInt(values.status)===1?"OPEN":"CLOSED"}</td>
                                            <td>{getName(mainData.branchesData,values.branchID)}</td>

                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.requestedAmount}</td>
                                            <td>{values.schedule}</td>
                                            <td>{getName2(mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.requestSubmissionDate}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                    
                                }
                            }else if(searchAction ==="STATUSLEVEL"){
                                if(parseInt(values.levelStatus) === parseInt(statusLevel)){
                                    myNo1 = myNo1+1
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.id} onClick={this.viewUser}>Details</Button></td>
                                            <td>{values.client["name"]}</td>
                                            <td>{values.client["account"]}</td>
                                            <td>{getLevel(values.levelStatus)}</td>
                                            <td>{parseInt(values.status)===1?"OPEN":"CLOSED"}</td>
                                            <td>{getName(mainData.branchesData,values.branchID)}</td>

                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.requestedAmount}</td>
                                            <td>{values.schedule}</td>
                                            <td>{getName2(mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.requestSubmissionDate}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                    
                                }
                            }else if(searchAction ==="STATUS"){
                                console.log(status)
                                if(parseInt(values.status) === parseInt(status)){
                                    myNo1 = myNo1+1
                                    activeLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.id} onClick={this.viewUser}>Details</Button></td>
                                            <td>{values.client["name"]}</td>
                                            <td>{values.client["account"]}</td>
                                            <td>{getLevel(values.levelStatus)}</td>
                                            <td>{parseInt(values.status)===1?"OPEN":"CLOSED"}</td>
                                            <td>{getName(mainData.branchesData,values.branchID)}</td>

                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.requestedAmount}</td>
                                            <td>{values.schedule}</td>
                                            <td>{getName2(mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{getName2(mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.requestSubmissionDate}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                    
                                }
                            }

                        });
                    }
                }
            }else{
                alert("No data found, Consider setting search range")
            }
        }

        let branchDataList=[]
        let staffDataList=[]
        let loanTypesDataList=[]
        if(mainData !==null){
            if(mainData.branchesData.length>0){
                mainData.branchesData.map((values, i) =>{
                    branchDataList.push(
                        <option value={values.id}>{values.name}</option>
                    )
                });
            }
        }
        
        return (
            <div>
               
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW LOAN REQUESTS </h4>
                    <h6 className='pageComment'>Loan Request details,field report,approve/reject,edit,give</h6>

                    </div>
                    <Col xs="12" className="mainWrraper" >
                        <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                        {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Client Loan Request Details</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                            {this.state.isModalLoading?
                                <div><br/>
                                    <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                </div>:
                                <div>
                                    <FormGroup className="loanBox2">
                                        <Input type="select" onChange={this.setActions} className="loanBox" name="payMode">
                                            <option value="1">LOAN REQUEST DETAILS</option> 
                                            <option value="2">FEILD REPORT</option>
                                            <option value="3">APPROVE / REJECT REQUEST</option>
                                            <option value="6">UPDATE APPROVED REQUEST</option>
                                            <option value="4">EDIT LOAN REQUEST</option>
                                            <option value="5">GIVE APPROVED LOAN</option>
                                        </Input>
                                    </FormGroup><br/>

                                    {/**requestDetails */}
                                    {this.state.requestDetails ===true?
                                        <Row>
                                            
                                            <Col xs="12" md="12">
                                                <Button onClick={() => this.showBTN("PAYHISTORY")} className="btn btn-secondary showPayDetailsBtn2">{this.state.showPaymentHistory===true?"HIDE":"SHOW"} PAYMENT HISTORY</Button>
                                                {this.state.showPaymentHistory===true?
                                                <>
                                                    <PaymentHistory mainData={this.state.clientDetailsData}/>
                                                    <PaymentChart mainData={this.state.clientDetailsData} from="LOANREQUEST"/>
                                                </>
                                               
                                                :null}
                                            </Col>
                                           <Col xs="12" md="12">
                                                <Button onClick={() => this.showBTN("DETAILS")} className="btn btn-secondary showPayDetailsBtn2">{this.state.showDetails===true?"HIDE":"SHOW"} REQUEST DETAILS</Button>
                                                {this.state.showDetails===true?
                                                <LoanRequestDetails mainData={this.state.clientDetailsData}/>
                                                :null}
                                            </Col>
                                            <Col xs="12" md="12">
                                                <Button onClick={() => this.showBTN("REPORT")} className="btn btn-secondary showPayDetailsBtn2">{this.state.showFeildReport===true?"HIDE":"SHOW"} FIELD REPORT</Button>
                                                {this.state.showFeildReport===true?
                                                <LoanRequestFeildReport mainData={this.state.clientDetailsData}/>
                                                :null}
                                            </Col>

                                            <Col xs="12" md="12">
                                                <Button onClick={() => this.showBTN("APPROVAL")} className="btn btn-secondary showPayDetailsBtn2">{this.state.showApprovals===true?"HIDE":"SHOW"} APPROVAL/REJECTION</Button>
                                                {this.state.showApprovals===true?
                                                <LoanRequestApprovals mainData={this.state.clientDetailsData}/>
                                                :null}
                                            </Col>
                                        </Row>
                                    :null}

                                     {/**feildReport */}
                                     {this.state.feildReport ===true?
                                        <> 
                                            {parseInt(this.state.clientDetailsData.loanReqData.status) ===0?
                                            <>
                                            <Alert color="danger">This loan request is already closed</Alert>
                                            </>:
                                            <>
                                            {parseInt(this.state.clientDetailsData.loanReqData.approvalStatus)===1||
                                            parseInt(this.state.clientDetailsData.loanReqData.status)===0?
                                                <Alert color="danger">Loan Request is already approved/closed</Alert>
                                            :<Row>
                                                
                                                <Col xs="12" md="12">
                                                    <FormGroup className="loanBox2">
                                                        <Label className="loanLabel">Client Name</Label>
                                                        <Input type="text" disabled className="loanBox" value={this.state.cname} name="cname"/>
                                                               
                                                    </FormGroup>
                                                    <Row>
                                                        <Col xs="12" md="6">
                                                            <Label className="loanLabel">Phone No 1*</Label>
                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.phone1} name="phone1"  />
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <Label className="loanLabel">Phone No 2</Label>
                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.phone2} name="phone2"  />
                                                        </Col>
                                                    </Row>

                                                    <FormGroup  className="loanBox2">
                                                        <Label className="loanLabel">Current Address*</Label>
                                                        <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.cAdress} name="cAdress"  />
                                                    </FormGroup>

                                                    <FormGroup  className="loanBox2">
                                                        <Label className="loanLabel">Business*</Label>
                                                        <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.biz} name="biz"  />
                                                    </FormGroup>

                                                    <FormGroup className="loanBox2">
                                                        <Label for="name" className="loanLabel">Security Type*</Label>
                                                        <Input type="text"   className="loanBox" onChange={this.setValue} value={this.state.security} name="security"/>
                                                    </FormGroup>
                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Security Description</Label>
                                                        <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.secDes} name="secDes" />
                                                    </FormGroup>

                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Assessment Comment</Label>
                                                        <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.assComment} name="assComment" />
                                                    </FormGroup>

                                                    <FormGroup  className="loanBox2">
                                                        <Label  className="loanLabel">Proposed Amount(Shs)*</Label>
                                                        <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.proposedAmount} name="proposedAmount"/>
                                                    </FormGroup>
                                                    
                                                    <FormGroup>
                                                        <Label for="teaching_sub">Assessed By*</Label>
                                                        <MultiSelect 
                                                            responsiveHeight={'210px'}
                                                            items={this.state.staffList}
                                                            selectedItems={this.state.selectedStaff}
                                                            onChange={this.handleSelectChange}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="12" md="12"><br/>
                                                    <FormGroup className="loanBox2">
                                                        {parseInt(this.state.clientDetailsData.loanReqData.levelStatus)===1?
                                                        <>
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                                                        <Button onClick={this.submitFeildReport} className="btn btn-success loanBtn">Submit Field Report</Button></>:null}

                                                        {parseInt(this.state.clientDetailsData.loanReqData.levelStatus)===2||parseInt(this.state.clientDetailsData.loanReqData.levelStatus)==3?<>
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Editing...'}</div>:null}
                                                        <Button onClick={this.editFeildReport} className="btn btn-success loanBtn">Edit Field Report</Button></>:null}
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                            </>}
                                        </>
                                    :null}

                                    {/**approve */}
                                    {this.state.approve ===true?
                                        <>
                                        {checkRight(107)===false?
                                            <Alert color="danger">Unauthorized to approve/ reject loan requests</Alert>
                                        :<>
                                            {parseInt(this.state.clientDetailsData.loanReqData.levelStatus)===3||
                                            parseInt(this.state.clientDetailsData.loanReqData.levelStatus)===1
                                            ?
                                                <Alert color="danger">{parseInt(this.state.clientDetailsData.loanReqData.levelStatus)===3?"Rejected loan request ":"Loan request with no feild report"} can not be approved, add desired information</Alert>
                                            :
                                            <>
                                                {parseInt(this.state.clientDetailsData.loanReqData.approvalStatus)===1||
                                                parseInt(this.state.clientDetailsData.loanReqData.status)===0?
                                                    <Alert color="danger">Loan Request is already approved/closed</Alert>
                                                :
                                                <Row>
                                                    <Col xs="12" md="12">
                                                        <Button onClick={() => this.showBTN("DETAILS")} className="btn btn-secondary showPayDetailsBtn2">SHOW / HIDE REQUEST DETAILS</Button>
                                                        {this.state.showDetails===true?
                                                        <LoanRequestDetails mainData={this.state.clientDetailsData}/>
                                                        :null}

                                                    </Col>
                                                    <Col xs="12" md="12">
                                                        <Button onClick={() => this.showBTN("REPORT")} className="btn btn-secondary showPayDetailsBtn2">SHOW / HIDE FIELD REPORT</Button>
                                                        {this.state.showFeildReport===true?
                                                        <LoanRequestFeildReport mainData={this.state.clientDetailsData}/>
                                                        :null}
                                                    </Col>

                                                    <Col xs="12" md="12">
                                                        <FormGroup className="loanBox2">
                                                            <Label for="username"  className="loanLabel">Select Action</Label>
                                                            <Input type="select" onChange={this.setValue} name="approveAction">
                                                                <option value={""}>SELECT ACTION</option>
                                                                <option value="1">APPROVE LOAN REQUEST</option>
                                                                <option value="2">REJECT & ALLOW FOR CHANGES</option>
                                                                <option value="3">REJECT & CLOSE REQUEST</option>

                                                            </Input>
                                                        </FormGroup>

                                                        <FormGroup className="loanBox2">
                                                            <Label for="username"  className="loanLabel">Enter Approved Amount</Label>
                                                            <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.approvedAmount} name="approvedAmount"/>
                                                            
                                                        </FormGroup>

                            
                                                        <FormGroup className="loanBox2">
                                                            <Label for="username"  className="loanLabel">Approval / Rejection Comment</Label>
                                                            <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.approveComment} name="approveComment" />
                                                        </FormGroup><br/>

                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}</div>:null}

                                                        <Button onClick={this.approveLoan} className="btn btn-danger showPayDetailsBtn">SUBMIT</Button>
                                                    </Col>
                                                
                                                </Row>}
                                            </>}
                                        </>}
                                    </>
                                    :null}

                                    {/**edit loan req */}
                                    {this.state.editRequest ===true?
                                    <>
                                        {checkRight(108)===false?
                                            <Alert color="danger">Unauthorized to edit loan request</Alert>
                                        :<>
                                            {parseInt(this.state.clientDetailsData.loanReqData.approvalStatus)===1||
                                            parseInt(this.state.clientDetailsData.loanReqData.status)===0?
                                                <Alert color="danger">Loan Request is already approved/closed</Alert>

                                            :<Row>
                                                <Col xs="6" md="6">
                                                    <FormGroup className="loanBox2">
                                                        <Label className="loanLabel">Loan Type*</Label>
                                                        <Input type="select" onChange={this.setValue} className="loanBox" name="rloanType">
                                                                {this.state.loanTypeDataList}
                                                                
                                                        </Input>
                                                    </FormGroup>

                                                    <FormGroup  className="loanBox2">
                                                        <Label className="loanLabel">Interest(%)*</Label>
                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rrate} name="rrate"  />
                                                    </FormGroup>
                                                    <FormGroup  className="loanBox2">
                                                        <Label  className="loanLabel">Processing Fee*</Label>
                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rpFee} name="rpFee"/>
                                                    </FormGroup>
                                            
                                                    <FormGroup className="loanBox2">
                                                        <Label for="accType" className="loanLabel">Payment Mode*</Label>
                                                        <Input type="select" onChange={this.setValue} className="loanBox" name="rpayMode">
                                                            <option value="INSTALLMENTS">INSTALLMENTS</option> 
                                                            <option value="PAY AT ONCE">PAY AT ONCE</option> 
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup  className="loanBox2">
                                                        <Label  className="loanLabel">Loan Amount(Shs)*</Label>
                                                        <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.ramount} name="ramount"/>
                                                    </FormGroup>
                                                    <FormGroup  className="loanBox2">
                                                        <Label for="phone" className="loanLabel">Loan Period(days)*</Label>
                                                        <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.rperiod} name="rperiod" />
                                                    </FormGroup>

                                                
                                                    <FormGroup  className="loanBox2">
                                                        <Label className="loanLabel">Payment Schedule(days)*</Label>
                                                        <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.rschedule} name="rschedule"/>
                                                    </FormGroup>

                                                    <FormGroup className="loanBox2">
                                                        <Label for="accType" className="loanLabel">Expired Loan Action*</Label>
                                                        <Input type="select" onChange={this.setValue} className="loanBox" name="raction">
                                                            <option value="AUTOMATIC RENEWAL">AUTOMATIC RENEWAL</option> 
                                                            <option value="AUTOMATIC FINING">AUTOMATIC FINING</option> 
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup className="loanBox2">
                                                        <Label for="accType" className="loanLabel">Feild Agent*</Label>
                                                        <Input type="select" onChange={this.setValue} className="loanBox" name="rstaff">
                                                            {this.state.agentsDataList}
                                                        </Input>
                                                    </FormGroup>
                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Current Address</Label>
                                                        <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.rcurrentAddress} name="rcurrentAddress" />
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="6" md="6">
                                                    <FormGroup className="loanBox2">
                                                        <Label for="name" className="loanLabel">Security Type*</Label>
                                                        <Input type="text"   className="loanBox" onChange={this.setValue} value={this.state.rsecurity} name="rsecurity"/>
                                                    </FormGroup>
                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Security Description</Label>
                                                        <Input type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rsecDes} name="rsecDes" />
                                                    </FormGroup>
                                                    <FormGroup  className="loanBox2">
                                                        <Label for="phone" className="loanLabel">Guarator 1 Name*</Label>
                                                        <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.rg1Name} name="rg1Name"/>
                                                    </FormGroup>
                                                    <Row>
                                                        <Col  xs="6" md="6">
                                                            <FormGroup  className="loanBox2">
                                                                <Label for="phone" className="loanLabel">Guarator 1 Phone*</Label>
                                                                <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg1Phone1} name="rg1Phone1" />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col  xs="6" md="6">
                                                            <FormGroup  className="loanBox2">
                                                                <Label for="phone" className="loanLabel">Guarator 1 Phone 2</Label>
                                                                <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg1Phone2} name="rg1Phone2"/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Relationship</Label>
                                                        <Input type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rg1Relationship} name="rg1Relationship" />
                                                    </FormGroup>

                                                    <FormGroup  className="loanBox2">
                                                        <Label for="phone" className="loanLabel">Guarator 2 Name*</Label>
                                                        <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.rg2Name} name="rg2Name" />
                                                    </FormGroup>
                                                    <Row>
                                                        <Col  xs="6" md="6">
                                                            <FormGroup  className="loanBox2">
                                                                <Label for="phone" className="loanLabel">Guarator 2 Phone*</Label>
                                                                <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg2Phone1} name="rg2Phone1"/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col  xs="6" md="6">
                                                            <FormGroup  className="loanBox2">
                                                                <Label for="phone" className="loanLabel">Guarator 2 Phone 2</Label>
                                                                <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg2Phone2} name="rg2Phone2"/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    
                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Relationship</Label>
                                                        <Input type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rg2Relationship} name="rg2Relationship"/>
                                                    </FormGroup>

                                                    <FormGroup className="loanBox2">
                                                        <Label for="accType" className="loanLabel">ID Position*</Label>
                                                        <Input type="text" onChange={this.setValue} className="loanBox" value={this.state.ridPosition}  name="ridPosition"/>
                                                            
                                                    </FormGroup>
                                                </Col>

                                                <Col xs="12" md="12"><br/>
                                                    <FormGroup className="loanBox2">
                                                        {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Editing...'}</div>:null}
                                                        <Button onClick={this.editLoanRequest} className="btn btn-success loanBtn">EDIT REQUEST</Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>}
                                        </>}
                                    </>
                                    :null}

                                    {/**give loan */}
                                    {this.state.giveLoan ===true?
                                    <>
                                        {checkRight(109)===false?
                                            <Alert color="danger">Unauthorized to give approved loan</Alert>
                                        :<>
                                            {parseInt(this.state.clientDetailsData.loanReqData.approvalStatus)===0||
                                            parseInt(this.state.clientDetailsData.loanReqData.status)===0?
                                                <Alert color="danger">Loan Request is not yet approved/closed</Alert>
                                            :<>
                                                {parseInt(this.state.clientDetailsData.activeLoanStatus)===1?
                                                    <Alert color="danger">User has a running loan, first clear it</Alert>
                                                :<Row>
                                                    <Col xs="12">
                                                        <Button onClick={() => this.showBTN("GIVE")} className="btn btn-secondary showPayDetailsBtn2">{this.state.showApprovedDetails===true?"HIDE":"SHOW"} APPROVED DETAILS</Button>
                                                        {this.state.showApprovedDetails===true?
                                                        <Row>
                                                            <Col xs="6" md="6">
                                                                <FormGroup className="loanBox2">
                                                                    <Label className="loanLabel">Loan Type*</Label>
                                                                    <Input type="select" disabled onChange={this.setValue} className="loanBox" name="rloanType">
                                                                        {this.state.loanTypeDataList}   
                                                                    </Input>
                                                                </FormGroup>

                                                                <FormGroup  className="loanBox2">
                                                                    <Label className="loanLabel">Interest(%)*</Label>
                                                                    <Input disabled type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rrate} name="rrate"  />
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label  className="loanLabel">Processing Fee*</Label>
                                                                    <Input disabled type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rpFee} name="rpFee"/>
                                                                </FormGroup>
                                                        
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Payment Mode*</Label>
                                                                    <Input disabled type="select" onChange={this.setValue} className="loanBox" name="rpayMode">
                                                                        <option value="INSTALLMENTS">INSTALLMENTS</option> 
                                                                        <option value="PAY AT ONCE">PAY AT ONCE</option> 
                                                                    </Input>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label  className="loanLabel">Loan Amount(Shs)*</Label>
                                                                    <Input disabled type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.clientDetailsData.loanReqData.approvedAmount} name="ramount"/>
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Loan Period(days)*</Label>
                                                                    <Input disabled type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.rperiod} name="rperiod" />
                                                                </FormGroup>

                                                            
                                                                <FormGroup  className="loanBox2">
                                                                    <Label className="loanLabel">Payment Schedule(days)*</Label>
                                                                    <Input disabled type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.rschedule} name="rschedule"/>
                                                                </FormGroup>

                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Expired Loan Action*</Label>
                                                                    <Input disabled type="select" onChange={this.setValue} className="loanBox" name="raction">
                                                                        <option value="AUTOMATIC RENEWAL">AUTOMATIC RENEWAL</option> 
                                                                        <option value="AUTOMATIC FINING">AUTOMATIC FINING</option> 
                                                                    </Input>
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">Feild Agent*</Label>
                                                                    <Input disabled type="select" onChange={this.setValue} className="loanBox" name="rstaff">
                                                                        {this.state.agentsDataList}
                                                                    </Input>
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Current Address</Label>
                                                                    <Input disabled type="text" onChange={this.setValue} className="loanBox" value={this.state.rcurrentAddress} name="rcurrentAddress" />
                                                                </FormGroup>
                                                            </Col>

                                                            <Col xs="6" md="6">
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="name" className="loanLabel">Security Type*</Label>
                                                                    <Input disabled type="text"   className="loanBox" onChange={this.setValue} value={this.state.rsecurity} name="rsecurity"/>
                                                                </FormGroup>
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Security Description</Label>
                                                                    <Input disabled type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rsecDes} name="rsecDes" />
                                                                </FormGroup>
                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 1 Name*</Label>
                                                                    <Input disabled type="text" onChange={this.setValue} className="loanBox" value={this.state.rg1Name} name="rg1Name"/>
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 1 Phone*</Label>
                                                                            <Input disabled type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg1Phone1} name="rg1Phone1" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 1 Phone 2</Label>
                                                                            <Input disabled type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg1Phone2} name="rg1Phone2"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Relationship</Label>
                                                                    <Input disabled type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rg1Relationship} name="rg1Relationship" />
                                                                </FormGroup>

                                                                <FormGroup  className="loanBox2">
                                                                    <Label for="phone" className="loanLabel">Guarator 2 Name*</Label>
                                                                    <Input disabled type="text" onChange={this.setValue} className="loanBox" value={this.state.rg2Name} name="rg2Name" />
                                                                </FormGroup>
                                                                <Row>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 2 Phone*</Label>
                                                                            <Input disabled type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg2Phone1} name="rg2Phone1"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col  xs="6" md="6">
                                                                        <FormGroup  className="loanBox2">
                                                                            <Label for="phone" className="loanLabel">Guarator 2 Phone 2</Label>
                                                                            <Input disabled type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.rg2Phone2} name="rg2Phone2"/>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                
                                                                <FormGroup className="loanBox2">
                                                                    <Label for="username"  className="loanLabel">Relationship</Label>
                                                                    <Input disabled type="textarea" onChange={this.setValue} className="loanBox loanArea " value={this.state.rg2Relationship} name="rg2Relationship"/>
                                                                </FormGroup>

                                                                <FormGroup className="loanBox2">
                                                                    <Label for="accType" className="loanLabel">ID Position*</Label>
                                                                    <Input disabled type="text" onChange={this.setValue} className="loanBox" value={this.state.ridPosition}  name="ridPosition"/>
                                                                        
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        :null}
                                                    </Col>
                                                        
                                                    <Col xs="12" md="12">
                                                        <FormGroup className="loanBox2">
                                                            <Label for="accType" className="loanLabel">Start Payment*</Label>
                                                            <Input type="date" min={this.state.clientDetailsData.settingsData.currentDate} onChange={this.setValue} className="loanBox" value={this.state.startPay} name="startPay" />
                                                        </FormGroup>
                                                        <br/>
                                                        <FormGroup className="loanBox2">
                                                            {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Editing...'}</div>:null}
                                                            <Button onClick={this.giveApprovedLoan} className="btn btn-success loanBtn">GIVE LOAN</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>}
                                            </>}
                                        </>}
                                    </>
                                    :null}

                                    {/**approvedUpdate */}
                                    {this.state.approvedUpdate ===true?
                                        <>
                                        {checkRight(110)===false?
                                            <Alert color="danger">Unauthorized to update approved loan requests</Alert>
                                        :<>
                                            {parseInt(this.state.clientDetailsData.loanReqData.approvalStatus)===0||
                                            parseInt(this.state.clientDetailsData.loanReqData.status)===0?
                                                <Alert color="danger">Loan Request is not yet approved or loan is already given</Alert>
                                            :
                                            <Row>
                                                <Col xs="12" md="12">
                                                    <Button onClick={() => this.showBTN("DETAILS")} className="btn btn-secondary showPayDetailsBtn2">SHOW / HIDE REQUEST DETAILS</Button>
                                                    {this.state.showDetails===true?
                                                    <LoanRequestDetails mainData={this.state.clientDetailsData}/>
                                                    :null}

                                                </Col>
                                                <Col xs="12" md="12">
                                                    <Button onClick={() => this.showBTN("REPORT")} className="btn btn-secondary showPayDetailsBtn2">SHOW / HIDE FIELD REPORT</Button>
                                                    {this.state.showFeildReport===true?
                                                    <LoanRequestFeildReport mainData={this.state.clientDetailsData}/>
                                                    :null}
                                                </Col>

                                                <Col xs="12" md="12">
                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Select Action</Label>
                                                        <Input type="select" onChange={this.setValue} name="approveAction">
                                                            <option value={""}>SELECT ACTION</option>
                                                            <option value="1">APPROVE LOAN REQUEST</option>
                                                       

                                                        </Input>
                                                    </FormGroup>

                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Enter Approved Amount</Label>
                                                        <Input type="number" min={0} onChange={this.setValue} className="loanBox" value={this.state.approvedAmount} name="approvedAmount"/>
                                                        
                                                    </FormGroup>

                        
                                                    <FormGroup className="loanBox2">
                                                        <Label for="username"  className="loanLabel">Approval Comment</Label>
                                                        <Input type="textarea" onChange={this.setValue} className="loanBox loanArea" value={this.state.approveComment} name="approveComment" />
                                                    </FormGroup><br/>

                                                    {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}</div>:null}

                                                    <Button onClick={this.approveLoan} className="btn btn-danger showPayDetailsBtn">SUBMIT</Button>
                                                </Col>
                                            
                                            </Row>}
                                        </>}
                                    </>
                                    :null}

                                </div>}
                            </ModalBody>
                        </Modal>: null}
                        
   
                        <Col xs="12" md="12" className="tableWrapper">
                        <Row>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">Start From*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.startFrom} name="startFrom"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob">End At*</Label>
                                    <Input type="date" onChange={this.setValue} value={this.state.endAt} name="endAt"/>
                                </FormGroup>
                            </Col>
                            <Col md="4" xs="12">
                                <FormGroup>
                                    <Label for="dob"></Label>
                                    <Button onClick={this.searchData} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Search</Button>
                                </FormGroup>
                            </Col>

                            {/*<Col md="12" xs="12">
                                    <Label className='balB'>TOTAL LOANS : {myNo1.toLocaleString()}, TOTAL AMOUNT GIVEN :{amountGiven1.toLocaleString()}, TOTAL LOAN BAL : {totalBalance1.toLocaleString()}</Label>
                            </Col>*/}
                        </Row>
                        <div className='table-container'>
                            <Table striped responsive>
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Details</th>
                                            <th>Client Name</th>
                                            <th>Account</th>
                                            <th>Request Level</th>
                                            <th>Status</th>
                                            <th>Branch</th>
                                            <th>Loan Duration</th>
                                            <th>Processing Fee</th>
                                            <th>Rate(%)</th>
                                            <th>Requested Amount</th>
                                            <th>Schedule </th>
                                            <th>Field Agent</th>
                                            <th>Added By</th>
                                            <th>Entry Date</th>
                                            <th>Creation Date</th>

                                        </tr>
                                        <tr>
                                           
                                            <th></th>
                                            <th></th>

                                            <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                            <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="ACCOUNT" ?this.state.nameSearch:null} id="ACCOUNT"/></th>
                                            <th><Input type="select" className="account" onChange={this.setSearchValue} name="statusLevel" id="STATUSLEVEL">
                                                    <option value="">SELECT</option>
                                                    <option value="1">PENDING</option>
                                                    <option value="2">FEILD REPORT</option>
                                                    <option value="3">REJECTED</option>
                                                    <option value="4">APPROVED</option>
                                                    <option value="5">EXPIRED</option>
                                                </Input>
                                            </th>
                                            <th><Input type="select" className="account" onChange={this.setSearchValue}  name="status" id="STATUS">
                                                    <option value="">SELECT</option>
                                                    <option value="1">OPEN</option>
                                                    <option value="0">CLOSED</option>
                                                </Input>
                                            </th>
                                            <th><Input type="select"  className="clientName"  onChange={this.setSearchValue} name="branch2" id="BRANCH">
                                                    <option value="">SELECT BRANCH</option>
                                                    {branchDataList}
                                                </Input></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>

                                        </tr>
                                    </thead>
                                    
                                    {this.state.isLoading?
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                            </td>
                                        </tr>
                                    </tbody>:
                                    
                                    <tbody>
                                            {activeLoanDataList}
                                    
                                    </tbody>}
                            </Table>
                       </div>
                        </Col>
                        
                    </Col>
                </Row>
          
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.getMyDataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.getMyData,
    showDataStatus : state.mainReducer.showDataStatus,
    showData : state.mainReducer.showData,

    updateStatus : state.mainReducer.updateStatus,
    updateData : state.mainReducer.updateData,
  
 });
export default connect(
    mapStateToProps,
    {getData,getSelectedID_Data,addLoanData}
)(SeeLoanRequest)
