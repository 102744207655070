import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner,Modal,ModalBody,ModalFooter,ModalHeader,FormGroup} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData,addLoanData} from "../../Redux/ducks/mainReducer";
import {getName2} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
import ReactJsAlert from "reactjs-alert"
export class RenewedLoans extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null,

        fAgentID:'',fAgentName:"",loanID:'',clientName:'',clientPhone:'',
        gName1:'',g1Relationship:'',gPhone1:'',gPhone12:'',
        gName2:'',gPhone2:'',gPhone22:'',g2Relationship:'',
        intrest:'',payMode:'',processingFee:'',loanPeriod:'',
        startPayDate:'',endPayDate:'',schedule:'',installments:'',
        loanAmount:'',amountPaid:'',expectedAmount:'',loanBalance:'',

        loanBalance2:'',schedule2:'',loanPeriod2:'',processingFee2:'',intrest2:'',fAgentID2:'',
        staffList:[],startDate2:'', 
        alertStatus :false, alertType :"error", alertTitle:null,

    }
    componentDidMount(){
        this.setState({isLoading:true})
        this.props.getMainData("GENERAL_PAYDATA")
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus,updateStatus,updateData} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                this.setState({isLoading:false,msg:msg,mainData:userData})
            }else{
                this.setState({isLoading:true,mainData:null,msg:null})
            }
        }  

        if(updateStatus !== prevProps.updateStatus){
            if(updateStatus===true){
                this.setState({isUpdating:false,modal:false,alertStatus :true, alertType :"success", alertTitle:msg})
                this.props.getMainData("GENERAL_PAYDATA")
            }else{
                this.setState({isUpdating:true,msg:null})
            }
        }  
    }

    

    viewUser=(e)=>{
        const {mainData} = this.state
        const selectedID = e.target.id;
        this.setState({modal:true,redirectSelectedID:selectedID,isUpdating:false})

        if(mainData !==null){
            const loanData =  mainData.loanData
            if(loanData.length>0){
                loanData.map((values, i) =>{
                 
                    if(parseInt(values.id) === parseInt(selectedID)){
                        var loanBalance = values.expectedAmount-values.amountPaid
                        this.setState({
                            startDate2:'',
                            fAgentName:getName2(mainData.staffData,values.agent,"STAFF"),
                            fAgentID:values.agent,loanID:values.loanID,clientName:values.clientName,clientPhone:values.agent,
                            gName1:values.gName1,g1Relationship:values.g1Relationship,gPhone1:values.gPhone1,gPhone12:values.gPhone12,
                            gName2:values.gName2,gPhone2:values.gPhone2,gPhone22:values.gPhone22,g2Relationship:values.g2Relationship,
                            intrest:values.intrest,payMode:values.payMode,processingFee:values.processingFee,loanPeriod:values.loanPeriod,
                            startPayDate:values.startPayDate,endPayDate:values.endPayDate,schedule:values.schedule,installments:values.installments,
                            loanAmount:values.loanAmount,amountPaid:values.amountPaid,expectedAmount:values.expectedAmount,loanBalance:loanBalance,

                            loanBalance2:loanBalance,schedule2:values.schedule,loanPeriod2:values.loanPeriod,fAgentID2:values.agent,
                        })

                       

                        /**client details */
                        const clientData =  mainData.userData
                        if(clientData.length>0){
                            clientData.map((values2, i) =>{
                                if(parseInt(values2.id) === parseInt(values.clientID)){
                                    this.setState({
                                        clientPhone:values2.phone1+", "+values2.phone2
                                    })
                                }
                            })
                        }

                        /**renewal settings */
                        const RenewalSettingData =  mainData.RenewalSettingData
                        if(RenewalSettingData.length>0){
                            RenewalSettingData.map((values3, i) =>{ 
                                var processingFee = 0
                                var intrest = 0
                                if(parseInt(values3.allowProcessingFee) ===1){
                                    const ProcessingFeeData =  mainData.ProcessingFeeData
                                    if(ProcessingFeeData.length>0){
                                        ProcessingFeeData.map((values4, i) =>{ 
                                            if(parseInt(loanBalance) >= parseInt(values4.min) && parseInt(loanBalance) <= parseInt(values4.max)){
                                                processingFee = values4.amount
                                            }

                                        })
                                    }
                                }

                                if(parseInt(values3.allowRenewalIntrest) ===1){
                                    intrest = values3.renewalRate
                                }
                              
                                this.setState({
                                    processingFee2:processingFee,intrest2:intrest
                                })
                                
                            })
                        }

                        /**work on staffData**/
                        let staffList = [];
                        const staffData =  mainData.staffData
                        if(staffData.length>0){
                            staffData.map((values5, i) =>{
                                if(parseInt(values5.id) ===parseInt(values.agent)){
                                    staffList.push(
                                        //values.agent
                                        <option value={values5.id}>{values5.fname+" "+values5.lname}</option>
                                    )
                                }
                            })

                            staffData.map((values5, i) =>{
                                if(parseInt(values5.id) !==parseInt(values.agent)){
                                    staffList.push(
                                        //values.agent
                                        <option value={values5.id}>{values5.fname+" "+values5.lname}</option>
                                    )
                                }
                            })
                        }
                        this.setState({staffList:staffList})

                    }
                })
            }
        }

    }


    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    closeModal = ()=>{
        this.setState({modal:false})
        //refresh categories
        this.props.getMainData("GENERAL_PAYDATA")
    }

    renewLoan = () =>{
        const {redirectSelectedID,loanPeriod2,schedule2,intrest2,processingFee2,loanBalance2,startDate2,fAgentID2} = this.state
        if(loanPeriod2==="" ||startDate2==="" ||schedule2===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Please enter all fields with a *"})
        }else{
            this.setState({isUpdating:true})
            /**upload new loan*/
            const data = new FormData();
            data.append("loanID",redirectSelectedID);
            data.append("rate",intrest2);
            data.append("pFee",processingFee2);
            data.append("amount",loanBalance2);
            data.append("period",loanPeriod2);
            data.append("startPay",startDate2);
            data.append("schedule",schedule2);
            data.append("staff",fAgentID2);
            
            this.props.addLoanData(data,"RENEWLOAN")
        }
    }

    render() {

        /**displaying  users*/
        let activeLoanDataList = [];
    
        if(this.state.mainData !==null){
            const myUserData =  this.state.mainData.loanData
            if(myUserData.length>0){
                let myNo = 0
                myUserData.map((values, i) =>{
                    if(parseInt(values.status) ===1){
                        if(parseInt(values.requestRenewal) ===1){
                            myNo = myNo+1
                            activeLoanDataList.push(
                                <tr key={i}>
                                    <td>{myNo}</td>
                                    <td><Button id={values.id} onClick={this.viewUser}>Approve Renewal</Button></td>
                                    <th scope="row">{values.loanID}</th>
                                    <td>{values.clientName}</td>
                                    <td>{values.account}</td>
                                    <td>{values.payMode}</td>
                                    <td>{values.loanPeriod}</td>
                                    <td>{values.processingFee}</td>
                                    <td>{values.intrest}</td>
                                    <td>{values.loanAmount}</td>
                                    <td>{values.expectedAmount}</td>
                                    <td>{values.amountPaid}</td>
                                    <td>{values.expectedAmount -values.amountPaid}</td>
                                    <td>{values.startPayDate}</td>
                                    <td>{values.endPayDate}</td>
                                    <td>{values.schedule}</td>
                                    <td>{values.installments}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                    <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                    
                                    <td>{values.created_at}</td>
                                </tr>
                            )
                        }
                    }
                });
            }else{
                activeLoanDataList.push(
                    <div>
                        <br/><Alert color="primary">No loan renewal requests found</Alert>
                    </div>
                )
            }
        }

        /**account */
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
            activeLoanDataList = []
            if(this.state.mainData !==null){
                const myUserData =  this.state.mainData.loanData
                if(myUserData.length>0){
                    let myNo = 0
                    myUserData.map((values, i) =>{
                        if(parseInt(values.status) ===1){
                            if(parseInt(values.requestRenewal) ===1){
                                myNo = myNo+1
                                if(searchAction ==="LOANID"){
                                    if(values.loanID === searchword.toUpperCase() ){
                            
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo}</td>
                                                <td><Button id={values.id} onClick={this.viewUser}>Approve Renewal</Button></td>
                                                <th scope="row">{values.loanID}</th>
                                                <td>{values.clientName}</td>
                                                <td>{values.account}</td>
                                                <td>{values.payMode}</td>
                                                <td>{values.loanPeriod}</td>
                                                <td>{values.processingFee}</td>
                                                <td>{values.intrest}</td>
                                                <td>{values.loanAmount}</td>
                                                <td>{values.expectedAmount}</td>
                                                <td>{values.amountPaid}</td>
                                                <td>{values.expectedAmount -values.amountPaid}</td>
                                                <td>{values.startPayDate}</td>
                                                <td>{values.endPayDate}</td>
                                                <td>{values.schedule}</td>
                                                <td>{values.installments}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                                
                                            
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }else if(searchAction ==="NAME"){
                                    var myarray = values.clientName.split(' ');
                                    if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase() || values.name === searchword.toUpperCase()){
                                
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo}</td>
                                                <td><Button id={values.id} onClick={this.viewUser}>Approve Renewal</Button></td>
                                                <th scope="row">{values.loanID}</th>
                                                <td>{values.clientName}</td>
                                                <td>{values.account}</td>
                                                <td>{values.payMode}</td>
                                                <td>{values.loanPeriod}</td>
                                                <td>{values.processingFee}</td>
                                                <td>{values.intrest}</td>
                                                <td>{values.loanAmount}</td>
                                                <td>{values.expectedAmount}</td>
                                                <td>{values.amountPaid}</td>
                                                <td>{values.expectedAmount -values.amountPaid}</td>
                                                <td>{values.startPayDate}</td>
                                                <td>{values.endPayDate}</td>
                                                <td>{values.schedule}</td>
                                                <td>{values.installments}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                                
                                            
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }else if(searchAction ==="ACCOUNT"){
                                    if(values.account === searchword.toUpperCase() ){
                                    
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo}</td>
                                                <td><Button id={values.id} onClick={this.viewUser}>Approve Renewal</Button></td>
                                                <th scope="row">{values.loanID}</th>
                                                <td>{values.clientName}</td>
                                                <td>{values.account}</td>
                                                <td>{values.payMode}</td>
                                                <td>{values.loanPeriod}</td>
                                                <td>{values.processingFee}</td>
                                                <td>{values.intrest}</td>
                                                <td>{values.loanAmount}</td>
                                                <td>{values.expectedAmount}</td>
                                                <td>{values.amountPaid}</td>
                                                <td>{values.expectedAmount -values.amountPaid}</td>
                                                <td>{values.startPayDate}</td>
                                                <td>{values.endPayDate}</td>
                                                <td>{values.schedule}</td>
                                                <td>{values.installments}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                                
                                            
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }else if(searchAction ==="MODE"){
                                    if(values.payMode === searchword.toUpperCase() ){
                                    
                                        activeLoanDataList.push(
                                            <tr key={i}>
                                                <td>{myNo}</td>
                                                <td><Button id={values.id} onClick={this.viewUser}>Approve Renewal</Button></td>
                                                <th scope="row">{values.loanID}</th>
                                                <td>{values.clientName}</td>
                                                <td>{values.account}</td>
                                                <td>{values.payMode}</td>
                                                <td>{values.loanPeriod}</td>
                                                <td>{values.processingFee}</td>
                                                <td>{values.intrest}</td>
                                                <td>{values.loanAmount}</td>
                                                <td>{values.expectedAmount}</td>
                                                <td>{values.amountPaid}</td>
                                                <td>{values.expectedAmount -values.amountPaid}</td>
                                                <td>{values.startPayDate}</td>
                                                <td>{values.endPayDate}</td>
                                                <td>{values.schedule}</td>
                                                <td>{values.installments}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                                <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                                
                                            
                                                <td>{values.created_at}</td>
                                            </tr>
                                        )
                                    }
                                }
                            }
                        }
                    });
                }
            }
        }

     
        return (
            <div>
             
                <Row className="mainWrraper">
                <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                    <div className="block-header">
                    <h4>VIEW LOAN RENEWAL REQUEST</h4>
                    </div>
                    <Col xs="12" className="mainWrraper2" >
                    {this.state.modal ? 
                        <Modal  isOpen={this.state.modal} className="modalWrapper modal-dialog modal-lg">
                            <ModalHeader toggle={this.closeModal}>Approve Loan Renewal Request</ModalHeader>
                            <ModalBody className="modalBodyWrapper">
                                
                                <Row>
                                    <Col xs="6" md="6">
                                        <h6>OLD LOAN DETAILS</h6>

                                
                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel"><b>Field Agent :</b> {this.state.fAgentName}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label className="loanLabel"><b>Loan ID :</b> {this.state.loanID}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label className="loanLabel"><b>Client Name :</b> {this.state.clientName}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label className="loanLabel"><b>Client Phone(s) :</b> {this.state.clientPhone}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel"><b>Guarator 1 Name :</b> {this.state.gName1}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel"><b>Guarator 1 Relationship :</b> {this.state.g1Relationship}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel"><b>Guarator 1 Phone(s) :</b> {this.state.gPhone1+", "+this.state.gPhone12}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel"><b>Guarator 2 Name :</b> {this.state.gName2}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel"><b>Guarator 2 Relationship :</b> {this.state.g2Relationship}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel"><b>Guarator 2 Phone(s) :</b> {this.state.gPhone2+", "+this.state.gPhone22}</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label className="loanLabel"><b>Interest :</b> {this.state.intrest} %</Label>
                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label  className="loanLabel"><b>Processing Fee :</b> {this.state.processingFee}</Label>
                                        </FormGroup>
                                
                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel"><b>Payment Mode :</b> {this.state.payMode}</Label>
                                            
                                        </FormGroup>
                                    
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel"><b>Loan Period :</b> {this.state.loanPeriod} days</Label>
                                        </FormGroup>

                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel"><b>Start Payment :</b> {this.state.startPayDate}</Label>
                                        </FormGroup>

                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel"><b>End Payment :</b> {this.state.endPayDate}</Label>
                                        </FormGroup>

                                        <FormGroup  className="loanBox2">
                                            <Label className="loanLabel"><b>Payment Schedule :</b> {this.state.schedule} days</Label>
                                        </FormGroup>

                                        <FormGroup  className="loanBox2">
                                            <Label  className="loanLabel"><b>Loan Amount(Shs) :</b> {this.state.loanAmount}</Label>
                                        </FormGroup>

                                        <FormGroup  className="loanBox2">
                                            <Label  className="loanLabel"><b>Total Amount Expected :</b> {this.state.expectedAmount}</Label>
                                        </FormGroup>

                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel"><b>Amount Paid :</b> {this.state.amountPaid}</Label>
                                        </FormGroup>

                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel"><b>LOAN BALANCE :</b> {this.state.loanBalance}</Label>
                                        </FormGroup>


                                    </Col>

                                    <Col xs="6" md="6">
                                        <h6>NEW LOAN DETAILS</h6>
                                        <FormGroup  className="loanBox2">
                                            <Label className="loanLabel">Interest*</Label>
                                            <Input type="number" disabled className="loanBox" value={this.state.intrest2} name="intrest2"/>

                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label  className="loanLabel">Processing Fee*</Label>
                                            <Input type="number" onChange={this.setValue} className="loanBox" value={this.state.processingFee2} name="processingFee2"/>

                                        </FormGroup>
                                
                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel">Payment Mode* </Label>
                                            <Input type="text"  disabled  className="loanBox" value={this.state.payMode} name="amount"/>

                                        </FormGroup>
                                        <FormGroup  className="loanBox2">
                                            <Label  className="loanLabel">Loan Amount(Shs)*</Label>
                                            <Input type="number"  disabled  className="loanBox" value={this.state.loanBalance2} name="loanBalance2"/>
                                        </FormGroup>
                                    
                                        <FormGroup  className="loanBox2">
                                            <Label for="phone" className="loanLabel">Loan Period(days)* </Label>
                                            <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.loanPeriod2} name="loanPeriod2"/>

                                        </FormGroup>

                                        <FormGroup className="loanBox2">
                                            <Label for="accType" className="loanLabel">Start Payment* </Label>
                                            <Input type="date" min={this.state.endPayDate}  onChange={this.setValue} className="loanBox" value={this.state.startDate2} name="startDate2"/>

                                        </FormGroup>

                                        <FormGroup  className="loanBox2">
                                            <Label className="loanLabel">Payment Schedule(days)* </Label>
                                            <Input type="number" min={1} onChange={this.setValue} className="loanBox" value={this.state.schedule2} name="schedule2"/>

                                        </FormGroup>

                                        <FormGroup  className="loanBox2">
                                            <Label  className="loanLabel">Field Agent</Label>
                                            <Input type="select" onChange={this.setValue} name="fAgentID2">
                                                {this.state.staffList}
                                            </Input>
                                        
                                        </FormGroup>

                                    </Col>

                                    <Col xs="12" md="12"><br/>
                                        <FormGroup className="loanBox2">
                                            {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Approving...'}</div>:null}
                                            <Button onClick={this.renewLoan} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-success">Renew Loan</Button>

                                        </FormGroup>
                                    </Col>

                                    
                                </Row>

                            </ModalBody>
                        </Modal>:null}
                        <div className='table-container'>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Details</th>
                                    <th>Loan ID</th>
                                    <th>Client Name</th>
                                    <th>Account</th>
                                    <th>Pay Mode</th>
                                    <th>Loan Duration</th>
                                    <th>Processing Fee</th>
                                    <th>Rate(%)</th>
                                    <th>Loan Amount</th>
                                    <th>Amount Expected</th>
                                    <th>Amount Paid</th>
                                    <th>Loan Balance</th>
                                    <th>Start Payment</th>
                                    <th>End Payment</th>
                                    <th>Schedule </th>
                                    <th>Installment</th>
                                    <th>Field Agent</th>
                                    <th>Added By</th>
                                    <th>Date</th>
 
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                    <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="ACCOUNT" ?this.state.nameSearch:null} id="ACCOUNT"/></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="MODE" ?this.state.nameSearch:null} id="MODE"/></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                 
                                   
                                   
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="8">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                            
                                    {activeLoanDataList}
                               
                            </tbody>}
                        </Table>
                        </div>       
                        
                    </Col>
                </Row>
            
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,

    updateStatus:state.mainReducer.updateStatus,
    updateData:state.mainReducer.updateData
  
 });
export default connect(
    mapStateToProps,
    {getMainData,addLoanData}
)(RenewedLoans)
