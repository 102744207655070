import React, { Component } from 'react'
import {Alert,Label,Row,Col,Table,Input, Button,Spinner} from 'reactstrap'
import {connect } from 'react-redux';
import {getMainData} from "../../Redux/ducks/mainReducer";
import {getName2,datesDiffe} from "../../GeneralFunctions/general"
import {Redirect} from 'react-router-dom';
export class SoonToExpire extends Component {
    state={
        isLoading:false,
        isModalLoading:true,
        userData:null,
        catData:null,
        clientDetailsData:null,

        msg:null,
        modal:false,
        deleteModal:false,
        passwordModal:false,
        currentID:'',
        erroModalMsg:"",
        successModalMsg:'',
        erroPassModalMsg:"",
        successPassModalMsg:'',
        isUpdatingPass:false,
        isUpdating:false,
        isDeleting:false,
        btnDisabled:false,
        deleteMsg:null,
        successDeleteMsg:null,
        status:null,

        currentStatus:'',
        mainData:null,


        searchAction:null,nameSearch:null,redirect:false,
        redirectSelectedID:null,expireLoanData:[],searchLimit:"1",searchAgent:"0"

    }
    componentDidMount(){
        this.setState({isLoading:true})
        this.props.getMainData("GENERAL_PAYDATA")
    }
    componentDidUpdate(prevProps){
        const{userData,msg,userDataStatus} = this.props;
        if(userDataStatus !== prevProps.userDataStatus){
            if(userDataStatus===true){
                /**get expire data */
                let expireLoanData =[]
                if(userData !==null){
                    const myUserData =  userData.loanData
                    if(myUserData.length>0){
                        myUserData.map((values, i) =>{
                            if(parseInt(values.status) ===1){
                                if(datesDiffe(new Date(),values.endPayDate,null)>0){
                                    expireLoanData.push(values)
                                }
                            }
                        });
                    }
                }
                this.setState({isLoading:false,msg:msg,mainData:userData,expireLoanData:expireLoanData})

            }else{
                this.setState({isLoading:true,mainData:null,msg:null})
            }
        }  
    }

    

    viewUser=(e)=>{
        const selectedID = e.target.id;

        this.setState({
            redirect:true,
            redirectSelectedID:selectedID
        })
    }


    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
        if(e.target.name==="searchAgent"){
            this.setState({nameSearch:e.target.value,searchAction:"STAFF"})
        }
    }

    setSearchValue = (e) =>{
        this.setState({nameSearch:e.target.value,searchAction:e.target.id})
    }

    render() {
        const { redirect,redirectSelectedID,expireLoanData,searchLimit ,searchAgent} = this.state;

        if (redirect) {
            return <Redirect to={{pathname:"/see_clients", state:{redirectSelectedID:redirectSelectedID}}}/>
        }
        /**displaying  users*/
        let expireLoanDataList = [];
        let staffDataList = [];
 
        let agentsList=[]

        if(expireLoanData.length>0){
            let myNo = 0
            expireLoanData.map((values, i) =>{
                myNo = myNo+1
                if (!agentsList.includes(values.agent)) {
                    agentsList.push(values.agent)
                }
                if(datesDiffe(new Date(),values.endPayDate,null)>0){
                    expireLoanDataList.push(
                        <tr key={i}>
                            <td>{myNo}</td>
                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                            <th scope="row">{values.loanID}</th>
                            <td>{values.clientName}</td>
                            <td>{values.account}</td>
                            <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                            <td>{values.startPayDate}</td>
                            <td>{values.endPayDate}</td>
                            <td>{values.loanPeriod}</td>
                            <td>{values.processingFee}</td>
                            <td>{values.intrest}</td>
                            <td>{values.loanAmount}</td>
                            <td>{values.expectedAmount}</td>
                            <td>{values.amountPaid}</td>
                            <td>{values.expectedAmount -values.amountPaid}</td>
                            <td>{values.schedule}</td>
                            <td>{values.installments}</td>
                            
                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                            <td>{values.created_at}</td>
                        </tr>
                    )
                }
            });
        }else{
            expireLoanDataList.push(
                <tr>
                    <td colSpan="10">
                        <br/><Alert color="primary">No loans soon to expire found, consider checking for expired loans</Alert>
                    </td>
                </tr>
            )
        }

        if(agentsList.length>0){
            agentsList.map((values, i) =>{
                staffDataList.push(
                    <option value={values}>{getName2(this.state.mainData.staffData,values,"STAFF")}</option>
                )
            })
        }
    
        /**account */
        const searchword = this.state.nameSearch
        const searchAction = this.state.searchAction
        if (searchword) {
         
            expireLoanDataList = []
            if(expireLoanData.length>0){
                let myNo = 0
                expireLoanData.map((values, i) =>{
                    myNo = myNo+1
                    if(datesDiffe(new Date(),values.endPayDate,null)>0){
                        if(searchAction ==="LOANID"){
                            if(values.loanID === searchword.toUpperCase()){
                                expireLoanDataList.push(
                                    <tr key={i}>
                                        <td>{myNo}</td>
                                        <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                        <th scope="row">{values.loanID}</th>
                                        <td>{values.clientName}</td>
                                        <td>{values.account}</td>
                                        <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                                        <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                        <td>{values.startPayDate}</td>
                                        <td>{values.endPayDate}</td>
                                        <td>{values.loanPeriod}</td>
                                        <td>{values.processingFee}</td>
                                        <td>{values.intrest}</td>
                                        <td>{values.loanAmount}</td>
                                        <td>{values.expectedAmount}</td>
                                        <td>{values.amountPaid}</td>
                                        <td>{values.expectedAmount -values.amountPaid}</td>
                                        <td>{values.schedule}</td>
                                        <td>{values.installments}</td>
                                     
                                        <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            }
                        }else if(searchAction ==="NAME"){
                            var myarray = values.clientName.split(' ');
                            
                            if(myarray[0] === searchword.toUpperCase() || myarray[1] === searchword.toUpperCase()){
                                expireLoanDataList.push(
                                    <tr key={i}>
                                        <td>{myNo}</td>
                                        <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                        <th scope="row">{values.loanID}</th>
                                        <td>{values.clientName}</td>
                                        <td>{values.account}</td>
                                        <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                                        <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                        <td>{values.startPayDate}</td>
                                        <td>{values.endPayDate}</td>
                                        <td>{values.loanPeriod}</td>
                                        <td>{values.processingFee}</td>
                                        <td>{values.intrest}</td>
                                        <td>{values.loanAmount}</td>
                                        <td>{values.expectedAmount}</td>
                                        <td>{values.amountPaid}</td>
                                        <td>{values.expectedAmount -values.amountPaid}</td>
                                        <td>{values.schedule}</td>
                                        <td>{values.installments}</td>
                                       
                                        <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            }
                        }else if(searchAction ==="ACCOUNT"){
                            if(values.account === searchword.toUpperCase() ){
                                
                                expireLoanDataList.push(
                                    <tr key={i}>
                                        <td>{myNo}</td>
                                        <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                        <th scope="row">{values.loanID}</th>
                                        <td>{values.clientName}</td>
                                        <td>{values.account}</td>
                                        <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                                        <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                        <td>{values.startPayDate}</td>
                                        <td>{values.endPayDate}</td>
                                        <td>{values.loanPeriod}</td>
                                        <td>{values.processingFee}</td>
                                        <td>{values.intrest}</td>
                                        <td>{values.loanAmount}</td>
                                        <td>{values.expectedAmount}</td>
                                        <td>{values.amountPaid}</td>
                                        <td>{values.expectedAmount -values.amountPaid}</td>
                                        <td>{values.schedule}</td>
                                        <td>{values.installments}</td>
                                      
                                        <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            }
                        }else if(searchAction ==="MODE"){
                            if(parseInt(searchLimit)===1){
                                if(parseInt(datesDiffe(new Date(),values.endPayDate,null)) === parseInt(searchword)){
                                    expireLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.clientName}</td>
                                            <td>{values.account}</td>
                                            <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{values.startPayDate}</td>
                                            <td>{values.endPayDate}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.loanAmount}</td>
                                            <td>{values.expectedAmount}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.expectedAmount -values.amountPaid}</td>
                                            <td>{values.schedule}</td>
                                            <td>{values.installments}</td>
                                          
                                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }else{
                                if(parseInt(datesDiffe(new Date(),values.endPayDate,null)) <= parseInt(searchword)){
                                    expireLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.clientName}</td>
                                            <td>{values.account}</td>
                                            <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{values.startPayDate}</td>
                                            <td>{values.endPayDate}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.loanAmount}</td>
                                            <td>{values.expectedAmount}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.expectedAmount -values.amountPaid}</td>
                                            <td>{values.schedule}</td>
                                            <td>{values.installments}</td>
                                          
                                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }
                        }else if(searchAction ==="STAFF"){
                            if(parseInt(searchAgent)>0){
                                if(parseInt(values.agent) === parseInt(searchword)){
                                    expireLoanDataList.push(
                                        <tr key={i}>
                                            <td>{myNo}</td>
                                            <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                            <th scope="row">{values.loanID}</th>
                                            <td>{values.clientName}</td>
                                            <td>{values.account}</td>
                                            <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                                            <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                            <td>{values.startPayDate}</td>
                                            <td>{values.endPayDate}</td>
                                            <td>{values.loanPeriod}</td>
                                            <td>{values.processingFee}</td>
                                            <td>{values.intrest}</td>
                                            <td>{values.loanAmount}</td>
                                            <td>{values.expectedAmount}</td>
                                            <td>{values.amountPaid}</td>
                                            <td>{values.expectedAmount -values.amountPaid}</td>
                                            <td>{values.schedule}</td>
                                            <td>{values.installments}</td>
                                          
                                            <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                            <td>{values.created_at}</td>
                                        </tr>
                                    )
                                }
                            }else{
                                expireLoanDataList.push(
                                    <tr key={i}>
                                        <td>{myNo}</td>
                                        <td><Button id={values.clientID} onClick={this.viewUser}>View Details</Button></td>
                                        <th scope="row">{values.loanID}</th>
                                        <td>{values.clientName}</td>
                                        <td>{values.account}</td>
                                        <td>{datesDiffe(new Date(),values.endPayDate,null)}</td>
                                        <td>{getName2(this.state.mainData.staffData,values.agent,"STAFF")}</td>
                                        <td>{values.startPayDate}</td>
                                        <td>{values.endPayDate}</td>
                                        <td>{values.loanPeriod}</td>
                                        <td>{values.processingFee}</td>
                                        <td>{values.intrest}</td>
                                        <td>{values.loanAmount}</td>
                                        <td>{values.expectedAmount}</td>
                                        <td>{values.amountPaid}</td>
                                        <td>{values.expectedAmount -values.amountPaid}</td>
                                        <td>{values.schedule}</td>
                                        <td>{values.installments}</td>
                                      
                                        <td>{getName2(this.state.mainData.staffData,values.added_By,"STAFF")}</td>
                                        <td>{values.created_at}</td>
                                    </tr>
                                )
                            
                            }
                        }
                    }
                    
                });
            }
            
        }

     
        return (
            <div>
   
                <Row className="mainWrraper">
                    <div className="block-header">
                    <h4>VIEW LOANS SOON TO EXPIRE</h4>
                    </div>
                    <Col xs="12" className="mainWrraper2" >
                        <div className='table-container'>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Details</th>
                                    <th>Loan ID</th>
                                    <th>Client Name</th>
                                    <th>Account</th>
                                    <th>Days To Expire</th>
                                    <th>Field Agent</th>
                                    <th>Start Payment</th>
                                    <th>End Payment</th>
                                    <th>Loan Duration</th>
                                    <th>Processing Fee</th>
                                    <th>Rate(%)</th>
                                    <th>Loan Amount</th>
                                    <th>Amount Expected</th>
                                    <th>Amount Paid</th>
                                    <th>Loan Balance</th>
                                    <th>Schedule </th>
                                    <th>Installment</th>
                                   
                                    <th>Added By</th>
                                    <th>Date</th>
 
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="LOANID" ?this.state.nameSearch:null} id="LOANID"/></th>
                                    <th><Input type="text" className="clientName" onChange={this.setSearchValue} value={this.state.searchAction ==="NAME" ?this.state.nameSearch:null} id="NAME"/></th>
                                    <th><Input type="text" className="account" onChange={this.setSearchValue} value={this.state.searchAction ==="ACCOUNT" ?this.state.nameSearch:null} id="ACCOUNT"/></th>
                                    <th>
                                        <Input type="select" onChange={this.setValue} name="searchLimit">
                                            <option value="1">Exact Days</option> 
                                            <option value="2">Selected Days & Below</option> 
                                        </Input>
                                        <Input type="text" className="account2" onChange={this.setSearchValue} value={this.state.searchAction ==="MODE" ?this.state.nameSearch:null} id="MODE"/>

                                    </th>
                                    <th>
                                        <Input type="select" className="account2" onChange={this.setValue} name="searchAgent">
                                            <option value="0">SELECT </option> 
                                            {staffDataList}
                                        </Input>
                                    </th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                 
                                   
                                   
                                </tr>
                            </thead>
                            
                            {this.state.isLoading?
                            <tbody>
                                <tr>
                                    <td colSpan="10">
                                        <Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}
                                    </td>
                                </tr>
                            </tbody>:
                            
                            <tbody>
                                {expireLoanDataList.sort(function(a, b) {
                                const aValue = parseInt(a.props.children[5].props.children, 10);
                                const bValue = parseInt(b.props.children[5].props.children, 10);
                              
                                return aValue - bValue;
                                })}
                            </tbody>}
                        </Table>
                        </div>
                        
                    </Col>
                </Row>
              
            </div>
        )
    }
}
const mapStateToProps = state =>({
    userDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    userData : state.mainReducer.mainData,
  
 });
export default connect(
    mapStateToProps,
    {getMainData}
)(SoonToExpire)
