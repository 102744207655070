import React, { Component } from 'react'
import {connect } from 'react-redux';
import {getName2,getName,checkRight} from "../../GeneralFunctions/general"
import ReactJsAlert from "reactjs-alert"
import {Alert,Label,Row,Col,Table,FormGroup,Input, Button,
    Spinner} from 'reactstrap'
import {addLoanData} from "../../Redux/ducks/mainReducer";
import SeeClients from '../Pages/SeeClients';
export class LoanDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertStatus :false, alertType :"error", alertTitle:null,
            clientDetailsData:this.props.mainData,showMoreDetails:false,
            searchloanID: "",showDetails:false,activeLoanData:null,activeLoanStatus:0
        };
    
    }
    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }
    componentDidUpdate(prevProps){
        const{updateStatus,updateData,msg} = this.props;
          //update status
          if(updateStatus !== prevProps.updateStatus){
            if(updateStatus===true){
                this.setState({alertStatus :true, alertType :"success", alertTitle:msg})
                /**refresh data */
                SeeClients.getSelectedID_Data(this.state.activeLoanData.clientID,"CLIENT")
              
            }else if(updateStatus===false){
                this.setState({modalPreview:false,isModalLoading:false,isUpdating:false,btnDisabled:false,successModalMsg:'',
                    alertStatus :true, alertType :"error", alertTitle:msg
                })
            }
        }
    }

    /**search loan paymen */
    searchLoanPayment=()=>{
        const {searchloanID,clientDetailsData,showDetails} = this.state
        if(searchloanID===""){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Select loan ID to search",showDetails:false})
      
        }else{
            const myStaffData1 =  clientDetailsData
            if(myStaffData1 !==null){
                let activeLoanData = null
                let activeLoanStatus =0
                clientDetailsData.loanData.map((values1, i) =>{
                    if(searchloanID ===values1.loanID.toUpperCase()){
                        activeLoanData = values1
                        if(parseInt(values1.status)===1){
                            activeLoanStatus=1
                        }
                    }
                })

                this.setState({
                    showDetails :true,
                    activeLoanData:activeLoanData,
                    activeLoanStatus:activeLoanStatus
                })
            }
        }
    }

    markDefaulter = (e)=>{
        const {activeLoanData} = this.state
        if(checkRight(78)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to declare this client a defaulter"})
        }else{
            const data = new FormData();
            data.append("loanID",activeLoanData.id);
            this.props.addLoanData(data,"MARKDEFAULTER")
        }
    }
    //makeRenewalRequest
    makeRenewalRequest = (e)=>{
        const {activeLoanData} = this.state
        if(checkRight(91)===false){
            this.setState({alertStatus :true, alertType :"error", alertTitle:"Unauthorized to take this action"})
        }else{
            const data = new FormData();
            data.append("loanID",activeLoanData.id);
            this.props.addLoanData(data,"GENERATE_RENWALREQUEST")
        }
    }
    //getMoreDetails
    getMoreDetails=(e)=>{
        const {showMoreDetails} = this.state
        if(showMoreDetails===true){
            this.setState({showMoreDetails:false})
        }else{
            this.setState({showMoreDetails:true})
        }
    }

    render() {
        const {clientDetailsData,activeLoanData} = this.state
        let loanIDsList = [];
        const myStaffData1 =  clientDetailsData
        if(myStaffData1 !==null){
            /**loanIDs */
            const myloanData2 =  clientDetailsData.loanData
            if(myloanData2.length>0){
                loanIDsList.push(
                    <option value="">Select Loan ID</option>
                )
                myloanData2.map((values, i) =>{
                    loanIDsList.push(
                        <option value={values.loanID}>{values.loanID}</option>
                    )
                });
            }
        }

        let staffNames =[];
    
        if(activeLoanData !==null){
            if(activeLoanData.loan_request !==null){
                const staff = JSON.parse(activeLoanData.loan_request.visitedBy)
                if(staff.length>0){
                    staff.map((values,i)=>{
                        staffNames.push(getName2(clientDetailsData.staffData,values,"STAFF"))
                    })
                }
            }
        }  
        

        return (
            <div>
                <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                <Row style={{"margin-bottom":"10px"}}>
                    <Col xs="12" md="12" className="text-center myName">
                        <h6>{"Loan Details"}</h6>
                    </Col>
                    <Col xs="12" md="6">
                        <Input type="select" onChange={this.setValue} className="loanBox" name="searchloanID">
                            {loanIDsList}
                        </Input>
                    </Col>
                   
                    <Col xs="12" md="6">
                        <Button onClick={this.searchLoanPayment} style={{fontSize:12}} className="btn btn-secondary showPayDetailsBtn">SEARCH PAYMENTS</Button>
                    </Col>
                    {this.state.showDetails ===true?
                    <Row style={{"margin-left":"5px","margin-right":"5px"}}>
                        <Col xs="6" md="6">
                            <FormGroup className="topWords"><Label className="loanLabel">Client Name : <span> {activeLoanData.client.name}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Account Number : <span> {activeLoanData.client.account}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Creation Status : <span> {activeLoanData.creationStatus}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Request Renewal Status : <span> {parseInt(activeLoanData.requestRenewal) ===1?"YES":"NO"}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">No of renewals : <span> {activeLoanData.timesRenewed}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Defaulter Status : <span> {parseInt(activeLoanData.defaulterStatus) ===1?"YES":"NO"}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Loan ID : <span> {activeLoanData.loanID}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Loan Type : <span> {getName(clientDetailsData.loanTypeData,activeLoanData.loanType)}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Payment Mode : <span> {activeLoanData.payMode}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Loan Amount : <span> {activeLoanData.loanAmount}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Processing Fee: <span> {activeLoanData.processingFee}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Interest Rate : <span> {activeLoanData.intrest+"%"}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Loan Date : <span> {activeLoanData.startDate}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Start Payment : <span> {activeLoanData.startPayDate}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">End Payment : <span> {activeLoanData.endPayDate}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Renewal Mode : <span> {activeLoanData.fineAction}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">ID Position : <span> {activeLoanData.idPosition}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Registered By : <span> {getName2(clientDetailsData.staffData,activeLoanData.added_By,"STAFF")}</span></Label></FormGroup>

                        </Col>
                        <Col xs="6" md="6">
                            <FormGroup className="topWords"><Label className="loanLabel">Loan Schedule : <span> {activeLoanData.schedule+" days"}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Security Type : <span> {activeLoanData.securityType}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Security Description  : <span> {activeLoanData.securityDes}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Guarator 1 : <span> {activeLoanData.gName1}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Guarator 1 Phone : <span> {activeLoanData.gPhone1+","+activeLoanData.gPhone12}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Relationship : <span> {activeLoanData.g1Relationship}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Guarator 2 : <span> {activeLoanData.gName2}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Guarator 2 Phone : <span> {activeLoanData.gPhone2+","+activeLoanData.gPhone22}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Relationship : <span> {activeLoanData.g2Relationship}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Agent : <span> {getName2(clientDetailsData.staffData,activeLoanData.agent,"STAFF")}</span></Label></FormGroup>

                            <FormGroup className="topWords"><Label className="loanLabel">Period : <span> {activeLoanData.loanPeriod+" days"}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Paid Up To : <span> {activeLoanData.paidUpTo}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Installment : <span> {activeLoanData.installments.toLocaleString()}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Total Fines : <span> {activeLoanData.totalFines.toString()}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Expected Amount : <span> {activeLoanData.expectedAmount.toLocaleString()}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">Paid Amount : <span> {activeLoanData.amountPaid.toLocaleString()}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel">LOAN BALANCE : <span> {(parseFloat(activeLoanData.expectedAmount) -parseFloat(activeLoanData.amountPaid)).toLocaleString()}</span></Label></FormGroup>
                            <FormGroup className="topWords"><Label className="loanLabel"><Button onClick={this.getMoreDetails} className="btn btn-secondary loanBtn">Get More Details</Button></Label></FormGroup>
                        </Col>

                        {this.state.showMoreDetails===true?
                        <>
                            {activeLoanData.loan_request ===null?
                            <Col xs="12" md="12">
                                <Alert color="danger">Loan did not pass through approval process, no details</Alert>
                                
                            </Col>:
                            <Col xs="12" md="12">
                                <FormGroup className="topWords"><Label className="loanLabel">Loan Request Date : <span> {activeLoanData.loan_request.requestSubmissionDate}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Requested Amount : <span> {activeLoanData.loan_request.requestedAmount.toLocaleString()}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Entered By : <span> {getName2(clientDetailsData.staffData,activeLoanData.loan_request.added_By,"STAFF")}</span></Label></FormGroup>

                                <FormGroup className="topWords"><Label className="loanLabel">Feild Report Date : <span> {activeLoanData.loan_request.feildReportSubmissionDate}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Assessment Comment : <span> {activeLoanData.loan_request.assComment}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Proposed Amount : <span> {activeLoanData.loan_request.agentProposedAmount.toLocaleString()}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Assessed By : <span> {staffNames.join()}</span></Label></FormGroup>
                                
                                <FormGroup className="topWords"><Label className="loanLabel">Approval Date : <span> {activeLoanData.loan_request.ApprovalDate}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Approval Comment : <span> {activeLoanData.loan_request.approvalComment}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Approved Amount : <span> {activeLoanData.loan_request.approvedAmount.toLocaleString()}</span></Label></FormGroup>
                                <FormGroup className="topWords"><Label className="loanLabel">Approved By : <span> {getName2(clientDetailsData.staffData,activeLoanData.loan_request.approvedBy,"STAFF")}</span></Label></FormGroup>
                            </Col>}
                
                        </>:null}
                        
                        <Col xs="12" md="12"><br/>
                            <FormGroup className="loanBox2">
                                {this.state.isUpdating?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Adding...'}</div>:null}
                            </FormGroup>
                        </Col>
                        {parseInt(this.state.activeLoanStatus) ===1?
                            <Row className="modalFooter">
                                <Col xs="12" md="6">
                                    <Button onClick={this.makeRenewalRequest} className="btn btn-danger loanBtn disabled">Generate Loan Renewal Request</Button>
                                </Col>
                                <Col xs="12" md="6">
                                    {parseInt(activeLoanData.defaulterStatus)===1?
                                    <Button className="btn btn-secondary loanBtn disabled">Marked As Defaulter On {activeLoanData.defaultDate}</Button>
                                    :
                                    <Button onClick={this.markDefaulter} className="btn btn-success loanBtn">Mark As Defaulter</Button>}
                                </Col>
        
                            </Row>
                        :null}
                    </Row>:null}
                </Row>
                <hr/>
            </div>
        )
    }
}
const mapStateToProps = state =>({
    msg : state.mainReducer.msg,
    updateStatus : state.mainReducer.updateStatus,
    updateData:state.mainReducer.updateData,
 
});
export default connect(
    mapStateToProps,
    {addLoanData}
)(LoanDetails)